import React, {Fragment} from 'react'

const Statement3 = () => {
  return (
    <Fragment>
      <p className="text-12 grey justify">
        <u>
          <b style={{color: 'black'}}>
            Statement for Self-Certified Sophisticated Investor
          </b>
        </u>
      </p>

      <p className="text-12 grey justify">
        I declare that I am a self-certified sophisticated investor for the
        purposes of the FinancialServices and Markets Act (Financial Promotion)
        Order 2005.
      </p>
      <p className="text-12 grey justify">I understand that this means:</p>
      <p className="text-12 grey justify">
        (a) I can receive financial promotions that may not have been approved
        by a personauthorised by the Financial Services Authority;
      </p>
      <p className="text-12 grey justify">
        (b) the content of such financial promotions may not conform to rules
        issued by the FinancialServices Authority;
      </p>
      <p className="text-12 grey justify">
        (c){' '}
        <b style={{color: 'black'}}>
          by signing this statement I may lose significant rights;
        </b>
      </p>
      <p className="text-12 grey justify">
        (d)I may have no right to complain to either of the following -
      </p>
      <p className="text-12 grey justify">
        &nbsp;&nbsp;&nbsp; (i) the Financial Services Authority; or{' '}
      </p>
      <p className="text-12 grey justify">
        &nbsp;&nbsp;&nbsp; (ii) the Financial Ombudsman Scheme;
      </p>
      <p className="text-12 grey justify">
        (e) I may have no right to seek compensation from the Financial Services
        CompensationScheme.
      </p>
      <p className="text-12 grey justify">
        I am a self-certified sophisticated investor because at least one of the
        following applies -
      </p>
      <p className="text-12 grey justify">
        (a) I am a member of a network or syndicate of business angels and have
        been so for atleast the last six months prior to the date below;
      </p>
      <p className="text-12 grey justify">
        (b) I have made more than one investment in an unlisted company in the
        two years prior tothe date below;{' '}
      </p>
      <p className="text-12 grey justify">
        (c) I am working, or have worked in the two years prior to the date
        below, in a professionalcapacity in the private equity sector, or in the
        provision of finance for small and mediumenterprises;{' '}
      </p>
      <p className="text-12 grey justify">
        (d)I am currently, or have been in the two years prior to the date
        below, a director of acompany with an annual turnover of at least £1
        million.
      </p>
      <p className="text-12 grey justify">
        <b style={{color: 'black'}}>
          I accept that I can lose my property and other assets from making
          investment decisionsbased on financial promotions.
        </b>
      </p>
      <p className="text-12 grey justify">
        I am aware that it is open to me to seek advice from someone who
        specialises in advising oninvestments.
      </p>
    </Fragment>
  )
}

export default Statement3
