import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

export const ContactDetails = styled.div`
  margin: 2.5em 0 1em;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    grid-template-columns: 1fr;
    grid-row: auto;
  }

  .contact-item {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 1em;
    color: ${Colors.SECONDARY_50};
    font-size: 10px;
    letter-spacing: 0px;
    width: max-content;
  }
  span {
    height: 15px;
  }

  /* an arbitrary container of fixed height */
  .contact-col {
    display: flex;
    flex-direction: column;

    > :nth-child(3) {
      margin-top: 1.8em;
    }
  }

  .get-in-touch {
    margin-top: 0.5em;
    width: 100%;
  }
`
