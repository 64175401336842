import React from 'react'

import rectangle from 'assets/images/rectangle.png'

import {
  WelcomeCard,
  WelcomeTextContainer,
  WelcomeTitle,
  WelcomeDescription,
  WelcomeGraphics,
} from './styles'

interface WelcomeProps {
  displayName?: string
  certified: boolean
}

const Welcome: React.FC<WelcomeProps> = ({displayName, certified}) => {
  return (
    <WelcomeCard>
      <WelcomeTextContainer>
        <WelcomeTitle>Hi, {displayName}</WelcomeTitle>

        <WelcomeDescription>
          {certified
            ? `Welcome to your dashboard. From here you can view deals, register interest and submit offers on deals.`
            : `  Welcome to your dashboard. Before you can access deals, please self
          certify.`}
        </WelcomeDescription>
      </WelcomeTextContainer>

      <WelcomeGraphics src={rectangle} alt="dashboard vector graphics" />
    </WelcomeCard>
  )
}

export default Welcome
