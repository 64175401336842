import React, {useRef} from 'react'
import styled from 'styled-components'
import {useFormContext, Controller} from 'react-hook-form'
import {Calendar} from 'react-ikonate'
import DatePicker from 'react-datepicker'

import Input from 'components/Input'
import Checkbox from 'components/Checkbox'

import {Section} from '../../styles'

import Colors from 'styles/colors'

const DateWrapper = styled.div<{error: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 3px;
  padding: 8px 5px 6px;
  border: ${({error}) =>
    error ? `1px solid ${Colors.ERROR}` : `1px solid ${Colors.SECONDARY_25}`};

  width: max-content;

  input {
    font-size: 12px;

    border: none;
    outline: none;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${Colors.SECONDARY_50};
      font-size: 10px;
      font-weight: 300;
      opacity: 1;
    }
  }

  .cal-icon {
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    color: ${Colors.PRIMARY};
  }

  /** Override date picker lib style */
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__current-month {
    color: ${Colors.PRIMARY} !important;
  }

  .react-datepicker__day--selected {
    background-color: ${Colors.PRIMARY} !important;
    color: ${Colors.WHITE} !important;
  }
`

const Step1 = ({
  formStep,
  isCompany,
}: {
  formStep: number
  isCompany: boolean
}) => {
  const dateRef = useRef<any>()
  const {register, setValue, errors, control} = useFormContext()
  const error = Boolean(errors.date)

  return (
    <Section show={formStep === 0}>
      <h2>Sign Non Discolsure Argreement (NDA)</h2>

      <span className="text-lg grey">
        Click in the input below and define a NDA signature date
      </span>

      <Controller
        name="date"
        defaultValue={new Date()}
        rules={{
          required: true,
        }}
        {...{control}}
        render={({value, ...others}) => {
          return (
            <DateWrapper {...{error}}>
              <DatePicker
                ref={dateRef}
                dateFormat="dd/MM/yyyy"
                selected={value}
                placeholderText="Click to select a date"
                disabledKeyboardNavigation
                {...others}
              />
              <Calendar
                className="cal-icon"
                onClick={() => {
                  if (dateRef && dateRef.current) {
                    dateRef.current.onInputClick()
                  }
                }}
              />
            </DateWrapper>
          )
        }}
      />

      <h3>Your Details </h3>

      <div className="row">
        <Input
          name="firstName"
          label="First Name"
          type="text"
          placeholder="First name"
          onClear={() => setValue('firstName', '')}
          error={{
            status: errors.firstName,
            message: 'First name is required',
          }}
          ref={register({
            required: true,
            maxLength: 80,
          })}
        />

        <Input
          name="lastName"
          label="Last Name"
          type="text"
          placeholder="Last name"
          onClear={() => setValue('lastName', '')}
          error={{
            status: errors.firstName,
            message: 'First name is required',
          }}
          ref={register({
            required: true,
            maxLength: 80,
          })}
        />
      </div>

      <Checkbox
        name="isCompany"
        label="Are you signing NDA on behalf of a company?"
        ref={register}
      />

      <h3>Your Address</h3>

      <div className="row">
        <Input
          name="addressLine1"
          label="Address Line 1"
          type="text"
          placeholder="Address Line 1"
          onClear={() => setValue('addressLine1', '')}
          error={{
            status: errors.addressLine1,
            message: 'Address Line 1 is required',
          }}
          ref={register({
            required: !isCompany,
            maxLength: 200,
          })}
          disabled={isCompany}
        />

        <Input
          name="addressLine2"
          label="Address Line 2"
          type="text"
          placeholder="Address Line 2"
          onClear={() => setValue('addressLine2', '')}
          error={{
            status: errors.addressLine2,
            message: 'Address Line 2 requires text only',
          }}
          ref={register({
            maxLength: 100,
          })}
          disabled={isCompany}
        />
      </div>

      <div className="row">
        <Input
          name="city"
          label="City"
          type="text"
          placeholder="City"
          onClear={() => setValue('city', '')}
          error={{
            status: errors.city,
            message: 'City is required',
          }}
          ref={register({
            required: !isCompany,
            maxLength: 32,
          })}
          disabled={isCompany}
        />

        <Input
          name="postcode"
          label="Postcode"
          type="text"
          placeholder="Postcode"
          onClear={() => setValue('postcode', '')}
          error={{
            status: errors.postcode,
            message: 'Postcode is required',
          }}
          ref={register({
            required: !isCompany,
            maxLength: 32,
          })}
          disabled={isCompany}
        />
      </div>
    </Section>
  )
}
export default Step1
