import {ContentState, convertFromRaw, convertToRaw, EditorState} from 'draft-js'
import {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import {
  DealContact,
  DealForm,
  DealInterest,
  DealStatus,
  File,
  MessageProps,
  Workspace,
} from 'types'
import {DEAL_SECTORS, DEAL_TYPES} from 'config/data/options.config'
import {fetchCaplinkedWorkspaces} from 'services/admin'
import {getDealInterests} from 'services/deal-interests'
import {createDeal, getDeal, updateDeal} from 'services/deals'
import {deleteFiles, filesUpload, fileUpload} from 'services/files'
import {errorDefault} from 'utils/errors'

import {Option} from 'components/Select'

type CreateDealLocationState =
  | {
      dealId?: string
    }
  | undefined

type CreateDealState = {
  loading: boolean
  dealInterests: DealInterest[]
  removeFiles: File[]
  dealId?: string
  slug?: string
  status?: DealStatus
}

/**
 * Functions & State used by the CreateDeal form
 */
const useCreateDeal = (
  getValues: (name: string) => any,
  setValue: (name: string, value: any) => void
) => {
  const history = useHistory()
  const location = useLocation<CreateDealLocationState>()

  const [caplinkedWorkspaces, setCaplinkedWorkspaces] = useState<Option[]>([])

  const [state, setState] = useState<CreateDealState>({
    loading: true,
    dealInterests: [],
    removeFiles: [],
    dealId: location.state?.dealId,
    slug: undefined,
    status: undefined,
  })
  const {dealId, removeFiles} = state

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'success',
  })

  /**
   * Handle files removing from Teaser & Desk
   * @param filesToRemove - array of files
   */
  const onRemoveFiles = (filesToRemove: File[]) => {
    setState(prevState => ({
      ...prevState,
      removeFiles: [...removeFiles, ...filesToRemove],
    }))
  }

  // Create deal form: set inputs values
  const populateForm = (formData: DealForm) => {
    for (let key in formData) {
      switch (key) {
        case 'type':
          const type = DEAL_TYPES.find(item => item.value === formData[key])
          setValue(key, type)
          break
        case 'contact':
          const {name, phone, email, position} = formData[key] || {
            name: '',
            phone: '',
            email: '',
            position: '',
          }
          setValue('contactName', name)
          setValue('phone', phone)
          setValue('email', email)
          setValue('position', position)
          break
        case 'sector':
          const sector = DEAL_SECTORS.find(item => item.value === formData[key])

          setValue(key, sector)
          break
        case 'featuredImage':
          if (formData[key]) {
            setValue(
              key,
              Array.isArray(formData[key]) ? formData[key] : [formData[key]]
            )
          } else {
            setValue(key, [])
          }
          break
        case 'caplinkedWorkspaceId':
          const workspace = caplinkedWorkspaces.find(
            item => item?.value?.toString() === formData[key]?.toString()
          )
          setValue(key, workspace)
          break
        case 'teaserFile':
          if (formData[key]) {
            setValue(
              key,
              Array.isArray(formData[key]) ? formData[key] : [formData[key]]
            )
          } else {
            setValue(key, [])
          }
          break
        case 'deskFiles':
          if (formData[key]) {
            setValue(
              key,
              Array.isArray(formData[key]) ? formData[key] : [formData[key]]
            )
          } else {
            setValue(key, [])
          }
          break
        case 'ndaFileDocument':
          if (formData[key]) {
            setValue(
              key,
              Array.isArray(formData[key]) ? formData[key] : [formData[key]]
            )
          } else {
            setValue(key, [])
          }
          break
        case 'ndaFile':
          let editorState = undefined

          if (formData[key]) {
            editorState = EditorState.createWithContent(
              convertFromRaw(formData[key])
            )
          }

          setValue(key, editorState)
          break
        case 'processLetter':
          let editorState2 = undefined

          if (formData[key]) {
            const blocksFromHtml = htmlToDraft(formData[key])
            const {contentBlocks, entityMap} = blocksFromHtml

            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            )
            editorState2 = EditorState.createWithContent(contentState)
          }

          setValue(key, editorState2)
          break
        case 'video':
          const {url} = formData[key] || {
            url: '',
          }
          setValue('vimeo', url)
          break
        default:
          setValue(key, formData[key])
      }
    }
  }

  // Get workspaces data
  useEffect(() => {
    const fetchWorkspaces = async () => {
      const workspaces: Workspace[] = await fetchCaplinkedWorkspaces()

      setCaplinkedWorkspaces(
        workspaces.map((w: Workspace) => {
          return {
            label: w.name,
            value: w.id.toString(),
          }
        })
      )
    }

    fetchWorkspaces()
  }, [])

  const fetchDeal = async (dealId: string) => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }))

    try {
      const [dealInterests, deal] = await Promise.all([
        getDealInterests(dealId),
        getDeal(dealId),
      ])

      const {
        caplinkedWorkspaceId,
        isPrivate,
        status,
        slug,
        indicativeOfferDeadline,
        finalOfferDeadline,
        ...restDeal
      } = deal

      const dealFormValues: DealForm = {
        status: status || 'DRAFT',
        isPrivate: isPrivate || false,
        caplinkedWorkspaceId: caplinkedWorkspaceId
          ? caplinkedWorkspaceId.toString()
          : '',
        indicativeOfferDeadline: indicativeOfferDeadline
          ? indicativeOfferDeadline.toDate()
          : undefined,
        finalOfferDeadline: finalOfferDeadline
          ? finalOfferDeadline.toDate()
          : undefined,
        ...restDeal,
      }

      /**
       * Populate form with existing data from deal just fetched
       */
      populateForm(dealFormValues)

      setState(prevState => ({
        ...prevState,
        loading: false,
        slug,
        dealInterests,
        status,
      }))
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }))

      setToast({
        value: errorDefault,
        type: 'error',
        callback: () => history.goBack(),
      })
    }
  }

  // Get deal data
  useEffect(() => {
    if (caplinkedWorkspaces.length > 0) {
      if (dealId) {
        fetchDeal(dealId)
      } else {
        setState(prevState => ({
          ...prevState,
          loading: false,
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caplinkedWorkspaces])

  const refetchDealData = async () => {
    if (dealId) {
      await fetchDeal(dealId)
    }
  }

  // Create / update deal
  const handleSaveDeal = async (
    formData: any,
    dealStatus: Extract<DealStatus, 'PUBLISHED' | 'DRAFT'> = 'DRAFT'
  ) => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }))

    try {
      const {
        isPrivate,
        phone,
        position,
        email,
        contactName,
        investmentSize,
        sector,
        name,
        company,
        caplinkedWorkspaceId,
        type,
        ndaFile,
        processLetter,
        vimeo,
        description,
        offerMessage,
        indicativeOfferDeadline,
        finalOfferDeadline,
        isAnoynamousDeal,
      } = formData

      // upload submitted files to Firebase Storage
      let deskFiles: any[] = []
      if (formData?.deskFiles?.length > 0) {
        deskFiles = await filesUpload(formData.deskFiles, dealId)
      }
      let featuredImage: any = undefined
      if (formData?.featuredImage[0]) {
        featuredImage = await fileUpload(formData.featuredImage[0], dealId)
      }
      let ndaFileDocument: any = undefined
      if (formData?.ndaFileDocument[0]) {
        ndaFileDocument = await fileUpload(formData.ndaFileDocument[0], dealId)
      }
      let teaserFile: any = undefined
      if (formData?.teaserFile[0]) {
        teaserFile = await fileUpload(formData.teaserFile[0], dealId, 'public')
      }

      // format contact details properly
      let contact: DealContact = {
        name: contactName,
        email,
        phone,
        position,
      }
      if (dealStatus === 'DRAFT') {
        Object.keys(contact).forEach(key => {
          if (contact[key] === undefined || contact[key] === '') {
            delete contact[key]
          }
        })
      }

      // process html input field values
      let processLetterHtml: string | null =
        processLetter &&
        draftToHtml(convertToRaw(processLetter.getCurrentContent()))
      if (!processLetterHtml || processLetterHtml === '<p><br></p>') {
        processLetterHtml = null
      }

      // format update data properly
      let updateData: DealForm = {
        name,
        company,
        description,
        isAnoynamousDeal,
        sector: sector?.value,
        investmentSize,
        isPrivate,
        contact: Object.keys(contact).length > 0 ? contact : undefined,
        ndaFile: ndaFile && convertToRaw(ndaFile.getCurrentContent()),
        ndaFileDocument,
        teaserFile,
        deskFiles,
        processLetter: processLetterHtml,
        featuredImage,
        offerMessage: offerMessage || null,
        indicativeOfferDeadline,
        finalOfferDeadline,
        caplinkedWorkspaceId: caplinkedWorkspaceId?.value
          ? parseInt(caplinkedWorkspaceId.value)
          : null,
        caplinkedWorkspaceName: caplinkedWorkspaceId?.label
          ? caplinkedWorkspaceId.label
          : null,
        type: type?.value,
        video: vimeo
          ? {
              url: vimeo,
            }
          : null,
      }

      if (dealStatus === 'DRAFT') {
        Object.keys(updateData).forEach(key => {
          if (
            updateData[key] === undefined ||
            updateData[key] === '' ||
            updateData[key] === 0
          ) {
            delete updateData[key]
          }
        })
      }

      const didUserUpdateDealStatus = Boolean(dealStatus !== state.status)

      // create or update the deal
      let savedDealData: DealForm
      if (dealId) {
        savedDealData = await updateDeal(dealId, {
          ...updateData,
          status: didUserUpdateDealStatus ? dealStatus : undefined,
        })
      } else {
        savedDealData = await createDeal({
          ...updateData,
          status: dealStatus,
        })
      }

      /**
       * Populate form with new created or updated deal data
       */
      populateForm({
        ...savedDealData,
        indicativeOfferDeadline: savedDealData.indicativeOfferDeadline
          ? new Date(savedDealData.indicativeOfferDeadline._seconds * 1000)
          : undefined,
        finalOfferDeadline: savedDealData.finalOfferDeadline
          ? new Date(savedDealData.finalOfferDeadline._seconds * 1000)
          : undefined,
      })

      // delete removed files from Firebase Storage
      if (removeFiles && removeFiles.length > 0) {
        await deleteFiles(removeFiles)
      }

      let message = ''
      switch (dealId) {
        case undefined:
          if (savedDealData.status === 'DRAFT') {
            message = 'Deal created as draft'
          }
          if (savedDealData.status === 'PUBLISHED') {
            message = 'Deal created and published'
          }
          break
        default:
          if (savedDealData.status === 'DRAFT') {
            message = 'Deal draft updated'
          }
          if (savedDealData.status === 'PUBLISHED') {
            message = 'Deal updated'
          }
      }

      setToast({
        value: message,
        type: 'success',
      })

      setState(prevState => ({
        ...prevState,
        loading: false,
        removeFiles: [],
        dealId: savedDealData.id,
        slug: savedDealData.slug,
        status: savedDealData.status,
      }))
    } catch (error) {
      setToast({
        value: !getValues('name')
          ? 'Overview > Deal Title is required'
          : error.response?.data?.title,
        type: 'error',
      })

      setState(prevState => ({
        ...prevState,
        loading: false,
      }))
    }
  }

  return {
    caplinkedWorkspaces,
    state,
    toast,
    setToast,
    onRemoveFiles,
    handleSaveDeal,
    refetchDealData,
  }
}

export default useCreateDeal
