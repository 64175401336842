import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'

export const DealFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @media (min-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  .col {
    > :not(:first-child) {
      margin-top: 32px;
    }
  }
`
