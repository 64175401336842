import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

export const Container = styled.div<{visible?: boolean}>`
  position: fixed;
  text-align: left;

  display: ${({visible}) => (visible ? 'block' : 'none')};

  background-color: ${Colors.WHITE};

  width: 40%;
  height: auto;

  z-index: 10000;

  border: 1px solid ${Colors.SECONDARY_25};

  padding: 16px;
  margin: 16px;

  bottom: 0;
  right: 0;

  @media (max-width: ${Breakpoints.md}) {
    width: auto;
    height: auto;

    margin: 0;
    padding: 10px;

    h3,
    p,
    a {
      font-size: 10px !important;
    }
  }

  h3 {
    color: ${Colors.SECONDARY};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0px;
  }

  p {
    color: ${Colors.GREY};
    font-size: 12px;
    font-weight: 300;

    text-align: justify;
  }

  a {
    color: ${Colors.SECONDARY};
    font-size: 12px;
    font-weight: 300;
  }
`
