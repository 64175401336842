import React from 'react'
import {Breadcrumbs as MuiBreadcrumbs} from '@material-ui/core'

import {BreadcrumbLink, BreadcrumbText, BreadcrumbsContainer} from './styles'
import Colors from 'styles/colors'

interface BreadcrumbsProps {
  breadcrumbs: {
    type: 'link' | 'text'
    to?: string
    label: string
  }[]
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({breadcrumbs}) => {
  if (!Array.isArray(breadcrumbs) || breadcrumbs.length < 1) {
    return null
  }

  return (
    <BreadcrumbsContainer>
      <MuiBreadcrumbs
        aria-label="breadcrumb"
        style={{
          color: Colors.BLUE,
        }}
      >
        {breadcrumbs.map((bc, index) =>
          bc.type === 'link' && bc.to ? (
            <BreadcrumbLink key={`${bc.label}_${index}`} to={bc.to}>
              {bc.label}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbText key={`${bc.label}_${index}`}>
              {bc.label}
            </BreadcrumbText>
          )
        )}
      </MuiBreadcrumbs>
    </BreadcrumbsContainer>
  )
}

export default Breadcrumbs
