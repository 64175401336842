import React from 'react'
import styled from 'styled-components'
import {EditorState, Editor} from 'draft-js'

import Colors from 'styles/colors'

import {Section} from '../../styles'

export const Viewer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 369px;
  overflow-y: scroll;

  border-radius: 3px;
  border: 1px solid ${Colors.LIGHT_GREY};
  padding: 8px 16px;

  background-color: ${Colors.GREY_100};

  .DraftEditor-root {
    width: 100%;
  }
`

interface SignProps {
  formStep: number
  editorState: EditorState | null
}

const Sign: React.FC<SignProps> = ({formStep, editorState}) => {
  return (
    <Section show={formStep === 2}>
      <Viewer>
        {editorState && (
          <Editor onChange={() => {}} {...{editorState}} readOnly />
        )}
      </Viewer>
    </Section>
  )
}

export default Sign
