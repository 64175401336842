export const DEAL_STATUS = Object.freeze({
  DRAFT: 'DRAFT' as const,
  PUBLISHED: 'PUBLISHED' as const,
  CLOSED: 'CLOSED' as const,
})

export const DEAL_STATUS_TO_STRING = Object.freeze({
  DRAFT: 'Draft' as const,
  PUBLISHED: 'Live' as const,
  CLOSED: 'Closed' as const,
})
