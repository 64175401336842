import styled from 'styled-components'

import Colors from 'styles/colors'

export const AuthLayoutContainer = styled.div`
  background-color: ${Colors.BACKGROUND};

  display: flex;
  flex-direction: column;

  min-height: 100%;

  > .content {
    flex: 1 0 auto;
  }

  .footer {
    flex-shrink: 0;
  }
`
