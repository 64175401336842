import styled from 'styled-components'

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  &:hover {
    cursor: pointer;
  }
`

export const FileResults = styled.aside`
  display: flex;
  flex-direction: column;

  max-height: 170px;
  overflow-y: scroll;

  h4 {
    margin: 1rem 0;
  }

  .results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.5rem;
    grid-column-gap: 1rem;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & > span {
        cursor: pointer;
      }
    }
  }
`
