import styled from 'styled-components'

import Colors from 'styles/colors'

interface ContainerProps {
  disabled?: boolean
}
export const Container = styled.div<ContainerProps>`
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 2px;
    cursor: ${({disabled}) => (disabled ? 'unset' : 'pointer')};

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    > input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      border: 2px solid ${Colors.LIGHT_GREY};
      background-color: ${Colors.WHITE};

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 4px;
        top: 0;
        width: 5px;
        height: 10px;
        border: solid ${Colors.BLUE};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      /* background-color: #ccc; */
    }

    /* When the checkbox is checked, add a blue background */
    & input:checked ~ .checkmark {
      color: ${Colors.BLUE};
      border: 2px solid ${Colors.BLUE};
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    .checkbox-label {
      color: ${Colors.BLACK};
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    }

    .checkbox-description {
      color: ${Colors.GREY};
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    }
  }
`
