import React from 'react'

import {ErrorStyles} from './styles'

export interface ErrorMessageProps {
  error?: any
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({error = {}}) => {
  if (!error || !error.message) {
    return null
  }

  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors.length
  ) {
    return error.networkError.result.errors.map((error: any, i: number) => (
      <ErrorStyles key={i}>
        <p data-test="network-error-message">
          <strong>Error:</strong>
          {error.message}
        </p>
      </ErrorStyles>
    ))
  }

  return (
    <ErrorStyles>
      <p data-test="error">
        <strong>Error:</strong>
        {error.message}
      </p>
    </ErrorStyles>
  )
}

export default ErrorMessage
