import {useState, useEffect} from 'react'

/**
 * Custom quick and easy form hook 🥖
 *
 * @param initial - initial forms values
 * @param show - modal visibility boolean
 * @returns
 */
const useForm = (initial = {}, show?: boolean) => {
  // create a state object for our inputs
  const [inputs, setInputs] = useState<any>(initial)
  const initialValues = Object.values(initial).join('')

  useEffect(() => {
    // This function runs when the things we are watching change
    setInputs(initial)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  // Clear form modal state if modal visibility state is passed to the hook
  useEffect(() => {
    if (!show) {
      // if there is some data in the form the form will be clear on modal close
      const values = Object.keys(inputs)?.some(k => inputs[k])

      if (values) {
        setInputs(initial)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const handleChange = (event: any) => {
    const {removeFile} = event // pass file to remove
    let {value, name, type, files} = event.target

    if (type === 'number') value = parseInt(value)

    // Drag and drop documents
    if (type === 'file') {
      // Current files added
      const currentFiles = inputs[name] ? [...inputs[name]] : []

      // New drag and drop files
      let newFiles = files ? [...(files as any)] : []

      // /!\ Remove duplicates: if you add the same file(s) in newFiles but they were already included in currentFiles!
      newFiles = newFiles.filter(
        (newFile: File) =>
          !currentFiles.some(
            (f: File) => newFile.lastModified === f.lastModified
          )
      )

      // Case: File to remove!
      if (removeFile) {
        value = currentFiles.filter(
          (currentFile: File) => currentFile.name !== removeFile.name
        )
      } else if (inputs[name]) {
        // Case: File(s) to add!
        value = files && [...newFiles, ...currentFiles]
      } else {
        // Case: no existing file(s) in the form add new file(s)
        value = files && [...newFiles]
      }
    }

    setInputs({
      ...inputs,
      [name]: value,
    })
  }

  const resetForm = () => setInputs(initial)

  const clearForm = () => {
    const blankState = Object.fromEntries(
      Object.entries(inputs).map(([key, _]) => [key, ''])
    )

    setInputs(blankState)
  }

  return {
    inputs,
    handleChange,
    resetForm,
    clearForm,
  }
}

export default useForm
