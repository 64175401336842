import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import * as yup from 'yup'
import {useQuery} from 'react-query'

import useYupValidationResolver from 'hooks/useYupValidationResolver'
import {
  DEAL_INTEREST_STATUS,
  DEAL_INTEREST_STATUS_TO_STRING,
} from 'config/data/deal-interest.config'
import {DealInterestStatus, MessageProps} from 'types'
import {getDealInterests} from 'services/deal-interests'
import {sendEmailToDealUsers} from 'services/deals'

import Select, {Option} from 'components/Select'
import Input from 'components/Input'
import Button from 'components/styles/Button'
import Spinner from 'components/Spinner'
import Textarea from 'components/styles/Textarea'
import Card from 'components/Card'

import {
  EmailUsersContainer,
  Recipients,
  Recipient,
  InputContainer,
  SubmitButtonContainer,
  DeleteIcon,
} from './styles'

type Recipient = {
  email: string
  name?: string
}

type EmailUsersInputs = {
  dealStage: Option | null
  additionalRecipient: string
  recipients: Recipient[]
  subject: string
  message: string
}

const schema = yup.object().shape({
  dealStage: yup.object().nullable(true),
  additionalRecipient: yup.string(),
  recipients: yup
    .array()
    .of(
      yup.object().shape({
        email: yup.string().required(),
        name: yup.string(),
      })
    )
    .min(1, 'Please add at least 1 recipient')
    .required('Please add at least 1 recipient'),
  subject: yup.string().required('Email subject is required'),
  message: yup.string().required('Email message is required'),
})

const dealStageOptions: Option[] = [
  {
    value: 'ALL',
    label: 'All',
  },
  ...Object.values(DEAL_INTEREST_STATUS).map(value => ({
    value,
    label: DEAL_INTEREST_STATUS_TO_STRING[value],
  })),
]

interface EmailUsersProps {
  dealId: string
  setToast: React.Dispatch<React.SetStateAction<MessageProps>>
}

const EmailUsers: React.FC<EmailUsersProps> = ({dealId, setToast}) => {
  const {
    register,
    control,
    errors,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: {isDirty, isSubmitting},
    setError,
    reset: resetForm,
  } = useForm<EmailUsersInputs>({
    resolver: useYupValidationResolver(schema),
    defaultValues: {
      dealStage: null,
      additionalRecipient: '',
      recipients: [],
      subject: '',
      message: '',
    },
    shouldUnregister: false,
  })

  const addNewRecipient = () => {
    const {additionalRecipient} = getValues()
    const recipients = watch('recipients')

    if (additionalRecipient) {
      setValue('recipients', [
        ...recipients,
        {
          email: additionalRecipient,
        },
      ])
      setValue('additionalRecipient', '')
    }
  }

  const removeRecipient = (recipientToRemove: Recipient) => {
    const recipients = watch('recipients')

    setValue(
      'recipients',
      recipients.filter(
        recipient => recipient.email !== recipientToRemove.email
      )
    )
  }

  const onSubmit = async (data: EmailUsersInputs) => {
    try {
      const {recipients, subject, message} = data
      const recipientEmails = recipients.map(recipient => recipient.email)

      await sendEmailToDealUsers(dealId, recipientEmails, {subject, message})

      setToast({
        type: 'success',
        value: 'Users will recieve this update shortly',
      })

      resetForm()
    } catch (error) {
      setToast({
        type: 'error',
        value: 'We could not send your update, please try again',
      })
    }
  }

  const selectedDealStage = watch('dealStage')
  const {status: defaultRecipientsStatus} = useQuery<Recipient[]>(
    ['deal-email-recipients', selectedDealStage],
    async () => {
      const dealStage = selectedDealStage?.value as DealInterestStatus | 'ALL'

      const dealInterests = await getDealInterests(dealId, {
        ...(dealStage !== 'ALL'
          ? {
              status: dealStage,
            }
          : null),
      })

      if (!Array.isArray(dealInterests)) {
        return []
      } else {
        return dealInterests
          .filter(di => di.user)
          .map(di => ({
            email: di.user?.email ?? '',
            name: `${di.user?.firstName} ${di.user?.lastName}`,
          }))
      }
    },
    {
      enabled: Boolean(selectedDealStage && selectedDealStage.value),
      onError: () => {
        setError('dealStage', {
          type: 'manual',
          message:
            'Could not load the users at this deal stage (you can still add recipients manually)',
        })
      },
      onSuccess: data => {
        setValue('recipients', data)
      },
    }
  )

  const additionalRecipient = watch('additionalRecipient')
  const isAdditionalRecipientValid = yup
    .string()
    .email()
    .isValidSync(additionalRecipient)

  const recipients: Recipient[] = watch('recipients')

  return (
    <EmailUsersContainer>
      <Card
        padding
        title="Send Deal Update"
        subtitle="Select a stage to send this email to. This will send the email to all
          users at that stage of the deal."
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset
            disabled={isSubmitting}
            aria-disabled={isSubmitting}
            aria-busy={isSubmitting}
          >
            {/* Deal stage */}
            <Controller
              control={control}
              name="dealStage"
              render={props => (
                <Select
                  placeholder="Select deal stage..."
                  options={dealStageOptions}
                  isSearchable
                  error={{
                    status: Boolean(errors.dealStage),
                    message: errors.dealStage?.message,
                  }}
                  isDisabled={isSubmitting}
                  {...props}
                />
              )}
            />

            {/* Email subject */}
            <InputContainer>
              <Input
                ref={register}
                type="text"
                name="subject"
                label="Email subject"
                placeholder="Subject"
                error={{
                  status: Boolean(errors.subject),
                  message: errors.subject?.message,
                }}
              />
            </InputContainer>

            {/* Add additional recipient */}
            <InputContainer>
              <Input
                ref={register}
                type="text"
                name="additionalRecipient"
                label="Add additional recipient"
                placeholder="Enter recipient email"
                error={{
                  status: Boolean(errors.additionalRecipient),
                  message: errors.additionalRecipient?.message,
                }}
                btnText="Add recipient"
                btnDisabled={
                  !additionalRecipient ||
                  !isAdditionalRecipientValid ||
                  isSubmitting
                }
                onClick={addNewRecipient}
              />
            </InputContainer>

            {/* Email message */}
            <InputContainer
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
              }}
            >
              <label className="text-label" htmlFor="message">
                Email message
              </label>
              <Controller
                control={control}
                name="message"
                render={props => (
                  <Textarea
                    id="message"
                    placeholder="Type your message here"
                    className="text-base black"
                    rows={3}
                    error={Boolean(errors.message)}
                    disabled={isSubmitting}
                    {...props}
                  />
                )}
              />
              {Boolean(errors && errors.message) && (
                <span className="error">{errors?.message?.message}</span>
              )}
            </InputContainer>

            <SubmitButtonContainer>
              <Button type="submit" full disabled={!isDirty || isSubmitting}>
                Send
              </Button>
            </SubmitButtonContainer>
          </fieldset>
        </form>
      </Card>

      <Card
        padding
        title="Recipients"
        subtitle="Below is a list of recipients for this deal update."
      >
        {defaultRecipientsStatus === 'loading' && <Spinner />}

        {recipients.length < 1 && defaultRecipientsStatus !== 'loading' && (
          <div>
            <p className="input-info text-center">
              No recipients have been added to this update.
            </p>

            <p className="text-center red" style={{marginTop: 32}}>
              {errors.recipients ? (
                <p>{(errors.recipients as any)?.message}</p>
              ) : null}
            </p>
          </div>
        )}

        {recipients.length > 0 && (
          <Recipients>
            {recipients.map(recipient => (
              <Recipient key={recipient.email}>
                {recipient.name ? (
                  <span>
                    <p className="bold">{recipient.name}</p>

                    <p>{recipient.email}</p>
                  </span>
                ) : (
                  <p className="bold">{recipient.email}</p>
                )}

                <DeleteIcon
                  size="small"
                  onClick={() => removeRecipient(recipient)}
                />
              </Recipient>
            ))}
          </Recipients>
        )}
      </Card>
    </EmailUsersContainer>
  )
}

export default EmailUsers
