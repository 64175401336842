import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

import Colors from 'styles/colors'

export const UserDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media only screen and (min-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: ${Breakpoints.lg}) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: ${Breakpoints['2xl']}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const UserDetail = styled.div`
  .label {
    color: ${Colors.GREY};
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;

    margin: 0;
  }

  .value {
    color: ${Colors.BLACK};
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;

    margin: 0;
  }
`
