import {useState} from 'react'

const useModal = (defaultState = false) => {
  const [isShowing, setIsShowing] = useState(defaultState)

  const toggle = () => {
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    setIsShowing,
    toggle,
  }
}

export default useModal
