import React, {Fragment, useState} from 'react'
import {ChevronUp, ChevronDown} from 'react-ikonate'
import {ValueType} from 'react-select'
import {useQuery} from 'react-query'

import {Deal} from 'types'
import {useAuth} from 'hooks/useAuth'
import {QUERY_STALE_TIME} from 'config/react-query.config'
import {AVAILABLE_DEAL_FILTERS} from 'config/data/options.config'
import {getAvailableDeals} from 'services/deals'

import {Skeleton} from '@material-ui/lab'

import DealDetails from 'components/DealDetails'
import Select, {Option} from 'components/Select'

import Empty from '../Empty'
import {DealListCard, ExpandButton} from './styles'

const AvailableDeals: React.FC = () => {
  const {user} = useAuth()
  const userId = user?.uid ?? ''

  const [expanded, setExpanded] = useState(false)
  const handleToggleExpanded = () => setExpanded(prev => !prev)

  const [availableDealsFilter, setAvailableDealsFilter] = useState<Option>({
    label: 'All',
    value: 'ALL',
  })

  const {data: availableDeals = [], status} = useQuery<Deal[], Error>(
    ['available-deals', userId, availableDealsFilter],
    () => getAvailableDeals(userId, availableDealsFilter.value),
    {
      enabled: Boolean(userId),
      staleTime: QUERY_STALE_TIME.MEDIUM,
    }
  )

  const enableInteractions = status !== 'loading'

  return (
    <DealListCard
      title={'Available Deals'}
      padding
      titleRightComponent={
        <div
          style={{
            width: 140,
          }}
        >
          <Select
            defaultValue={{value: 'ALL', label: 'All'}}
            options={AVAILABLE_DEAL_FILTERS}
            value={availableDealsFilter}
            full
            onChange={(selected: ValueType<Option>) => {
              setAvailableDealsFilter(selected as Option)
              setExpanded(false)
            }}
            isDisabled={!enableInteractions}
          />
        </div>
      }
      bottomComponent={
        Array.isArray(availableDeals) && availableDeals.length > 1 ? (
          <ExpandButton
            styleless
            flex
            onClick={handleToggleExpanded}
            disabled={!enableInteractions}
          >
            {expanded ? 'Collapse section' : 'Expand section'}

            {expanded ? (
              <ChevronUp
                style={{
                  marginLeft: 6,
                }}
                fontSize="1.25rem"
                strokeWidth={3}
              />
            ) : (
              <ChevronDown
                style={{
                  marginLeft: 6,
                }}
                fontSize="1.25rem"
                strokeWidth={3}
              />
            )}
          </ExpandButton>
        ) : null
      }
    >
      {status === 'loading' ? (
        <Skeleton variant="rect" width="100%" height={357} />
      ) : (
        <Fragment>
          {Array.isArray(availableDeals) && availableDeals.length > 0 ? (
            availableDeals
              .filter((_, index) => {
                if (expanded) {
                  return true
                }
                return index === 0
              })
              .map(deal => (
                <DealDetails
                  key={deal.id}
                  deal={deal}
                  showDismissOption
                  showDealImage
                />
              ))
          ) : (
            <Empty />
          )}
        </Fragment>
      )}
    </DealListCard>
  )
}

export default AvailableDeals
