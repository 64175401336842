import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  h2 {
    margin-bottom: 0;
  }

  > :nth-child(n + 2) {
    margin-top: 2em;
  }

  margin: 5% auto;
`
