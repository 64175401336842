import styled from 'styled-components'

export interface SelectContainerProps {
  labelTop?: boolean
  labelLeft?: boolean
  full?: boolean
}

export const SelectContainer = styled.div<SelectContainerProps>`
  display: flex;
  flex-direction: ${({labelLeft}) => (labelLeft ? 'row' : 'column')};

  ${({full}) => full && `width: 100%;`}

  ${({labelLeft}) =>
    labelLeft &&
    `
  justify-content: center;
  align-items: center;
   `};

  .input-label {
    margin-top: ${({labelTop}) => (labelTop ? '1em' : 0)};
  }
`
