import {Ellypsis} from 'react-ikonate'
import styled from 'styled-components'
import Colors from 'styles/colors'

export const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 2.5em;
  position: relative;
  cursor: pointer;
  display: flex;
  color: ${Colors.PRIMARY_25};
  width: auto;

  &:hover {
    color: ${Colors.PRIMARY};
  }
`

export const EllipsisIcon = styled(Ellypsis)``
