import styled from 'styled-components'

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;

  .list-item-title {
    margin-left: 12px;
  }
`

const OrderedList = styled.ol<{styleType?: string}>`
  list-style-type: ${({styleType}) => (styleType ? `${styleType}` : 'none')};
  margin: 0;
  padding: 0;
`

export default OrderedList
