import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'

import Card from 'components/Card'
import DragDrop from 'components/DragDrop'

export interface EditableNdaProps {
  disabled?: boolean
  onRemove: (files: any[], update?: boolean) => void
}

const EditableNda: React.FC<EditableNdaProps> = ({disabled, onRemove}) => {
  const {control, errors} = useFormContext()

  return (
    <Card
      padding
      title="Editable NDA"
      subtitle="Upload a document version of the NDA to allow users to download and edit for offline signing."
    >
      <Controller
        control={control}
        name="ndaFileDocument"
        defaultValue={[]}
        render={({onChange, value}) => (
          <DragDrop
            {...{control, onChange, onRemove, value, disabled}}
            name="ndaFileDocument"
            maxSize={10000000}
            error={{
              status: errors?.ndaFileDocument,
              message: errors?.ndaFileDocument?.message,
            }}
            accept=".docx"
          />
        )}
      />
    </Card>
  )
}

export default EditableNda
