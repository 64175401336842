import React, {Fragment} from 'react'
import {useHistory} from 'react-router-dom'
import {Cell} from 'react-table'

import {ROUTES} from 'config/routes.config'
import {DEAL_STATUS_TO_STRING} from 'config/data/deal.config'
import {formatFirebaseDate} from 'utils/date'
import {Deal} from 'types'

import {EditIcon, EyeIcon} from 'styles/icons'
import Badge, {dealStatusToBadgeStatus} from 'components/styles/Badge'
import Table from 'components/Table'

type AdminDealsProps = {
  deals: Deal[]
}

const AdminDeals = ({deals}: AdminDealsProps) => {
  const history = useHistory()

  const handleView = (slug: string) => history.push(`${ROUTES.DEAL}/${slug}`)

  const handleEdit = (id: string, slug: string) =>
    history.push(`${ROUTES.CREATE_DEAL}/${slug}`, {dealId: id})

  if (!Array.isArray(deals) || deals.length < 1) {
    return <span className="inline-block my-32">No deals found</span>
  }

  return (
    <Fragment>
      <Table
        columns={[
          {
            Header: 'Deal Name',
            accessor: 'name',
          },
          {
            Header: 'Company',
            accessor: 'company',
          },
          {
            Header: 'Deal Value',
            accessor: 'investmentSize',
          },
          {
            Header: 'Creation Date',
            accessor: 'publishedAt',
            Cell: (cell: Cell<Deal>) => {
              const {publishedAt} = cell.row.original

              return formatFirebaseDate(publishedAt)
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (cell: Cell<Deal>) => {
              const {status} = cell.row.original

              return status ? (
                <Badge size="small" status={dealStatusToBadgeStatus(status)}>
                  {DEAL_STATUS_TO_STRING[status]}
                </Badge>
              ) : null
            },
          },
          {
            id: 'edit-deal-column',
            disableSortBy: true,
            Cell: (cell: Cell<Deal>) => {
              const {id, slug} = cell.row.original

              return (
                <EditIcon size="small" onClick={() => handleEdit(id, slug)} />
              )
            },
          },
          {
            id: 'view-deal-column',
            disableSortBy: true,
            Cell: (cell: Cell<Deal>) => {
              const {slug} = cell.row.original

              return <EyeIcon size="small" onClick={() => handleView(slug)} />
            },
          },
        ]}
        data={deals}
      />
    </Fragment>
  )
}

export default AdminDeals
