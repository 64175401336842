import React, {useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {useHistory} from 'react-router-dom'

import {useAuth} from 'hooks/useAuth'
import {MessageProps, Sector, Size, Stage} from 'types'
import {reauthenticate, updatePassword} from 'lib/firebase/auth'
import {getUser, updateUserById} from 'services/users'
import {QUERY_STALE_TIME} from 'config/react-query.config'
import {DEFAULT_MESSAGES} from 'config/error.config'
import {ROUTES} from 'config/routes.config'

import GridLayout, {Col} from 'components/styles/GridLayout'
import Toast from 'components/Toast'
import Spinner from 'components/Spinner'
import SelfCertification from 'components/SelfCertification'
import {ErrorFallback} from 'components/utils/ErrorBoundary'
import ContentContainer from 'components/ContentContainer'
import Button from 'components/styles/Button'

import {Header} from './styles'
import Details from './components/Details'
import Interests from './components/Interests'
import Password from './components/Password'

type FormData = {
  firstName?: string
  lastName?: string
  company?: string
  country?: {
    value: string
    label: string
  }
  email?: string
  dealSectors?: Sector[]
  dealSizes?: Size[]
  dealStages?: Stage[]
}

type Profile = {
  user: {
    firstName: string
    lastName: string
    company: string
    country: string
    email: string
  }
  interests: {
    dealSectors: Sector[]
    dealSizes: Size[]
    dealStages: Stage[]
  }
}

const UserProfile: React.FC = () => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const {user, selfCertifiedAs, lastCertifiedAt} = useAuth()
  const userId = user?.uid

  const [toast, setToast] = useState<MessageProps>({value: '', type: 'error'})

  const [updating, setUpdating] = useState(false)

  const {
    data: profile,
    status,
    error,
  } = useQuery<Profile, Error>(
    ['user-profile', userId],
    async () => {
      const userData = await getUser(userId as string)
      const {
        firstName = '',
        lastName = '',
        company = '',
        country = '',
        email = '',
        dealSizes = [],
        dealSectors = [],
        dealStages = [],
      } = userData ?? {}

      return {
        user: {
          firstName,
          lastName,
          company,
          country,
          email,
        },
        interests: {
          dealSectors: Array.isArray(dealSectors) ? dealSectors : [],
          dealSizes: Array.isArray(dealSizes) ? dealSizes : [],
          dealStages: Array.isArray(dealStages) ? dealStages : [],
        },
      }
    },
    {
      enabled: Boolean(userId),
      staleTime: QUERY_STALE_TIME.MEDIUM,
      onError: error => {
        setToast({
          value: error.message,
          type: 'error',
        })
      },
    }
  )

  /**
   * Update details and interests!
   *
   * @param formData - user details and interests
   */
  const handleSubmit = async (formData: FormData) => {
    setUpdating(true)

    try {
      if (!userId) throw new Error('User not found.')

      let newData: any = {...formData}

      if (formData.country) {
        newData = {...newData, country: formData.country.label}
      }

      const {
        firstName,
        lastName,
        company,
        country,
        email,
        dealSizes,
        dealSectors,
        dealStages,
      } = await updateUserById(userId, newData)

      const profile = {
        user: {
          firstName,
          lastName,
          company,
          country,
          email,
        },
        interests: {
          dealSectors: dealSectors ?? [],
          dealSizes: dealSizes ?? [],
          dealStages: dealStages ?? [],
        },
      }

      queryClient.setQueryData(['user-profile', userId], profile)
    } catch (error) {
      setToast({
        value: error.response?.data?.title,
        type: 'error',
      })
    }

    setUpdating(false)
  }

  /**
   * Update password!
   *
   * @param formData - current and new password
   */
  const handlePassword = async (formData: {
    currentPassword: string
    password: string
  }) => {
    setUpdating(true)

    try {
      const {currentPassword, password} = formData

      const email = profile?.user.email

      if (!email) throw new Error('User not found.')
      if (!password || !currentPassword)
        throw new Error(`Current and new passpord can't be empty`)
      if (password === currentPassword)
        throw new Error('New Password must be different than your current one.')

      await reauthenticate(email, currentPassword)
      await updatePassword(password)
    } catch (_) {}

    setUpdating(false)
  }

  if (status === 'loading') {
    return <Spinner />
  }

  if ((status === 'error' && error) || !profile) {
    return (
      <ErrorFallback
        error={error ?? new Error(DEFAULT_MESSAGES.ERROR)}
        resetErrorBoundary={() => window.location.reload()}
      />
    )
  }

  return (
    <ContentContainer>
      <Header>
        <Button onClick={() => history.push(ROUTES.DEALS)}>
          Back to dashboard
        </Button>
      </Header>

      <GridLayout>
        <Col>
          <Interests
            data={{...profile.interests, loading: updating}}
            onSubmit={handleSubmit}
          />

          {selfCertifiedAs && lastCertifiedAt ? <SelfCertification /> : null}
        </Col>

        <Col>
          <Details
            data={{...profile.user, loading: updating}}
            onSubmit={handleSubmit}
          />

          <Password loading={updating} onSubmit={handlePassword} />
        </Col>
      </GridLayout>

      <Toast message={toast} action={setToast} />
    </ContentContainer>
  )
}

export default UserProfile
