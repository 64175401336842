import * as Sentry from '@sentry/react'

import {
  DealInterest,
  DealInterestStatus,
  InvitationStatus,
  Sector,
  Size,
  Stage,
  UserToInvite,
} from 'types'
import {DEAL_INTEREST_STATUS} from 'config/data/deal-interest.config'
import firebase from 'lib/firebase'
import axios from 'lib/axios'
import {DEALS_API, DEAL_INTEREST_API} from 'config/endpoints.config'
import {timeouts} from 'config/timeouts.config'

/**
 * Get a user's deal interest's status over the target deal.
 *
 * @param userId The ID of the user.
 * @param dealId The ID of the deal.
 *
 * @returns The status of the user's deal interest over the deal.
 */
export const getUserDealStatus = async (userId: string, dealId: string) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('deal-interests')
      .where('deal.id', '==', dealId)
      .where('user.id', '==', userId)
      .get()

    if (snapshot.docs?.length > 0) {
      const {status} = snapshot.docs[0]?.data() as DealInterest

      return status
    }

    return ''
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

/**
 * Gets the deal interests of the specified user.
 *
 * @param userId ID of the user.
 * @param filters Additional filters applied to the query.
 *
 * @returns An array of deal interests.
 */
export const getDealInterestsByUserId = async (
  userId: string,
  {dealId}: {dealId?: string} = {}
) => {
  try {
    let query = firebase
      .firestore()
      .collection('deal-interests')
      .where('user.id', '==', userId)

    if (dealId) {
      query = query.where('deal.id', '==', dealId)
    }

    const snapshot = await query.get()
    const dealInterests = snapshot.docs.map(doc => {
      const data = doc.data()

      return {
        id: doc.id,
        createdAt: data.createdAt?.toDate(),
        ...data,
      } as DealInterest
    })

    return dealInterests.filter(item => item?.deal?.status !== 'DRAFT')
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

/**
 * Request access to an open deal. Invited people should not need to
 * perform this step. Once the admin user approves the request,
 * the user should be able to sign the NDA document.
 *
 * @param dealId The ID of the target deal.
 *
 * @returns The user's created/updated deal interest.
 */
export const requestNdaAccess = async (dealId: string) => {
  const response = await axios.post(
    `${DEALS_API}/${dealId}/files/prerequisites`
  )

  return response.data
}

/**
 * Creates a DocuSign NDA link for the specified deal.
 *
 * If `isNDADownloadRequest` is passed, it returns a downloadable
 * NDA pdf document instead.
 *
 * @param dealInterestId The ID of the target deal interest.
 * @param params Params to the request.
 */
export const generateNda = async (
  dealInterestId: string,
  params: {
    html?: string
    isNDADownloadRequest?: boolean
    signer?: {
      name: string
      streetAddress: string
      city: string
      postCode: string
    }
    selfCertifyAs?: string
  }
) => {
  const {html, isNDADownloadRequest, ...rest} = params

  const response = await axios.post(
    `${DEAL_INTEREST_API}/${dealInterestId}/actions/generate-nda`,
    {
      html: `${html}`,
      isNDADownloadRequest: Boolean(isNDADownloadRequest),
      ...rest,
    },
    {
      ...(isNDADownloadRequest ? {responseType: 'blob'} : null),
    }
  )

  return response.data
}

/**
 * Request access to the deck files.
 *
 * This function should not need to be called, unless signing the NDA
 * fails to automatically request access to the deck files.
 *
 * @param dealInterestId The ID of the deal interest for the deal.
 */
export const requestDeckFilesAccess = async (dealInterestId: string) => {
  const response = await axios.post(
    `${DEAL_INTEREST_API}/${dealInterestId}/actions/deck-files`
  )

  return response.data
}

/**
 * Request access to the dataroom.
 *
 * @param dealInterestId The ID of the deal interest for the deal.
 *
 * @returns Updated deal interest.
 */
export const requestDataRoomAccess = async (dealInterestId: string) => {
  const response = await axios.patch<DealInterest>(
    `${DEAL_INTEREST_API}/${dealInterestId}`,
    {
      dataroomRequestStatus: 'REQUESTED',
    },
    {
      timeout: timeouts.long,
    }
  )

  return response.data
}

/**
 * Ask a question regarding a deal.
 *
 * @param dealInterestId The ID of the target deal's deal interest.
 * @param message The question.
 */
export const askQuestion = async (dealInterestId: string, message: string) => {
  const response = await axios.post(
    `${DEAL_INTEREST_API}/${dealInterestId}/actions/ask-question`,
    {
      question: message,
    }
  )

  return response.data
}

/**
 * Request a meeting regarding a deal.
 *
 * @param dealInterestId The ID of the target deal's deal interest.
 * @param message Message.
 */
export const requestMeeting = async (
  dealInterestId: string,
  message: string
) => {
  const response = await axios.post(
    `${DEAL_INTEREST_API}/${dealInterestId}/actions/request-meeting`,
    {
      message,
    }
  )

  return response.data
}

/**
 * Make a new offer regarding a deal.
 *
 * @param dealInterestId The ID of the deal's deal interest.
 * @param data ???
 */
export const makeNewOffer = async (dealInterestId: string, data: any) => {
  const response = await axios.post(
    `${DEAL_INTEREST_API}/${dealInterestId}/actions/new-offer`,
    {
      message: data.message,
      documents: data.offerFiles,
      questions: data.questions,
      type: 'INDICATIVE',
    }
  )

  return response.data
}

/**
 * Make a final offer regarding a deal.
 *
 * @param dealInterestId The ID of the deal's deal interest.
 * @param documents ???
 * @param confirmationAnswers ???
 */
export const makeFinalOffer = async (
  dealInterestId: string,
  documents: any,
  confirmationAnswers: boolean
) => {
  const response = await axios.post(
    `${DEAL_INTEREST_API}/${dealInterestId}/actions/new-offer`,
    {
      documents,
      confirmationAnswers,
      type: 'FINAL',
    }
  )

  return response.data
}

/**
 * Create a process letter for the deal.
 *
 * @param dealInterestId The ID of the deal's deal interest.
 */
export const createLetter = async (dealInterestId: string) => {
  const response = await axios.post(
    `${DEAL_INTEREST_API}/${dealInterestId}/actions/process-letter`,
    {},
    {
      responseType: 'blob',
    }
  )

  return response.data
}

/**
 * Determines whether the user is able to sign the NDA / has NDA access.
 *
 * @param dealInterestStatus The status of the user's deal interest.
 * @param isInvitedUser Whether the user was invited or not (can be read from the deal interest).
 *
 * @return If has NDA access or not.
 */
export const hasNdaAccess = (
  dealInterestStatus?: string,
  isInvitedUser?: boolean
) => {
  const hasDealInterest = Boolean(dealInterestStatus)

  return (
    hasDealInterest &&
    (isInvitedUser ||
      (dealInterestStatus !== DEAL_INTEREST_STATUS.TEASER_STAGE &&
        dealInterestStatus !== DEAL_INTEREST_STATUS.NDA_REQUESTED))
  )
}

/**
 * Determines whether (with the specified deal interest status),
 * the user is able to access the deck.
 *
 * @param status User's deal interest status.
 *
 * @returns If has deck access or not.
 */
export const hasDeckAccess = (status?: DealInterestStatus) => {
  if (!status) {
    return false
  }

  return (
    status !== DEAL_INTEREST_STATUS.TEASER_STAGE &&
    status !== DEAL_INTEREST_STATUS.NDA_REQUESTED &&
    status !== DEAL_INTEREST_STATUS.NDA_STAGE &&
    status !== DEAL_INTEREST_STATUS.DECK_REQUESTED
  )
}

/**
 * ADMIN
 * Get deal interests of a particular deal.
 *
 * @param dealId ID of the deal.
 * @param filters Filters applied to the query.
 *
 * @returns An array of deal interests.
 */
export const getDealInterests = async (
  dealId: string,
  {
    hasSignedNda,
    status,
  }: {hasSignedNda?: boolean; status?: DealInterestStatus} = {
    hasSignedNda: false,
  }
) => {
  try {
    let query = firebase
      .firestore()
      .collection('deal-interests')
      .where('deal.id', '==', dealId)
      .orderBy('createdAt', 'asc')

    if (hasSignedNda) {
      query = query.where('hasSignedNDA', '==', true)
    }
    if (status) {
      query = query.where('status', '==', status)
    }

    const snapshot = await query.get()
    const dealInterests = snapshot.docs.map(doc => {
      const data = doc.data()

      return {
        id: doc.id,
        createdAt: data.createdAt?.toDate(),
        ...data,
      } as DealInterest
    })

    return dealInterests
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

/**
 * ADMIN
 * Deletes a deal interest from a deal.
 *
 * @param dealInterestId The ID of the deal interest.
 */
export const deleteDealInterest = async (dealInterestId: string) => {
  await axios.delete(`${DEAL_INTEREST_API}/${dealInterestId}`)
}

/**
 * ADMIN
 * Update a user's deal interest status on a deal.
 *
 * @param dealInterestId The ID of the deal interest.
 * @param newStatus The new status for the deal interest.
 * @param signer ???
 *
 * @returns The updated deal interest.
 */
export const updateDealInterestStatus = async (
  dealInterestId: string,
  newStatus: string | null,
  signer?: any
) => {
  const response = await axios.patch<DealInterest>(
    `${DEAL_INTEREST_API}/${dealInterestId}`,
    {
      status: newStatus,
      ...(signer !== undefined
        ? {
            signer,
          }
        : null),
    },
    {
      timeout: timeouts.long,
    }
  )

  return response.data
}

/**
 * ADMIN
 * Update a user's deal interest invitation status on a deal.
 *
 * @param dealInterestId The ID of the deal interest.
 * @param newInvitationStatus The new status for the deal interest invite.
 *
 * @returns The updated deal interest.
 */
export const updateDealInterestInvitationStatus = async (
  dealInterestId: string,
  newInvitationStatus: InvitationStatus
) => {
  const response = await axios.patch<DealInterest>(
    `${DEAL_INTEREST_API}/${dealInterestId}`,
    {
      invitationStatus: newInvitationStatus,
    },
    {
      timeout: timeouts.long,
    }
  )

  return response.data
}

/**
 * ADMIN
 * Update a user's deck access status on a deal.
 *
 * @param dealInterestId The ID of the deal interest.
 * @param deckAccess Whether or not to allow deck access.
 *
 * @returns The updated deal interest.
 */
export const updateDeckAccessStatus = async (
  dealInterestId: string,
  hasDeckAccess: boolean
) => {
  const response = await axios.patch<DealInterest>(
    `${DEAL_INTEREST_API}/${dealInterestId}`,
    {
      approveDeckAccess: hasDeckAccess,
    },
    {
      timeout: timeouts.long,
    }
  )

  return response.data
}

/**
 * ADMIN
 * Update a user's dataroom request status on their deal interest.
 *
 * @param dealInterestId The ID of the deal interest.
 * @param newStatus The new status for the dataroom request.
 *
 * @returns The updated deal interest.
 */
export const updateDataRoomAccessStatus = async (
  dealInterestId: string,
  newStatus: string | null
) => {
  const response = await axios.patch<DealInterest>(
    `${DEAL_INTEREST_API}/${dealInterestId}`,
    {
      dataroomRequestStatus: newStatus,
    },
    {
      timeout: timeouts.long,
    }
  )

  return response.data
}

/**
 * ADMIN
 * Bypass the NDA stage completely for the target deal interest.
 * This can be used to move a user straight to the deck stage.
 *
 * E.g.: user signs NDA offline
 *
 * @param dealInterestId The ID of the deal interest.
 *
 * @returns The updated deal interest.
 */
export const bypassSignNdaForDealInterest = async (dealInterestId: string) => {
  const response = await axios.patch<DealInterest>(
    `${DEAL_INTEREST_API}/${dealInterestId}`,
    {
      status: 'DECK_STAGE',
    },
    {
      timeout: timeouts.long,
    }
  )

  return response.data
}

/**
 * Fetches the specified PDF file in a watermarked format.
 *
 * @param dealInterestId ID of the requesting user's deal interest.
 * @param fileName The name of the requested file.
 * @param fileLocation The location of the requested file.
 *
 * @returns A file blob of the requested PDF.
 */
export const fetchWatermarkedPdf = async (
  dealInterestId: string,
  fileName: string,
  fileLocation: string = 'DECK'
) => {
  const response = await axios.post<Blob>(
    `${DEAL_INTEREST_API}/${dealInterestId}/actions/watermark-file`,
    {
      fileName,
      fileLocation,
    },
    {
      responseType: 'blob',
    }
  )

  return response.data
}

/**
 * Fetch users (platform or Hubspot or both) based on their interests.
 *
 * @param dealId The deal ID
 * @param param1 User interests
 */
export const fetchUsersByInterest = async (
  dealId: string,
  {
    sector,
    size,
    stage,
  }: {
    sector?: Sector
    size?: Size
    stage?: Stage
  }
): Promise<UserToInvite[]> => {
  const response = await axios.post<UserToInvite[]>(
    `${DEAL_INTEREST_API}/invite`,
    null,
    {
      params: {
        dealId,
        sector,
        size,
        stage,
      },
    }
  )

  return response.data
}
