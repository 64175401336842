import React, {Fragment} from 'react'

import {CertificationType} from 'types'

interface CertificationStatementProps {
  certificationType: CertificationType
}

const CertificationStatement: React.FC<CertificationStatementProps> = ({
  certificationType,
}) => {
  if (certificationType === 'HIGH_NET_WORTH') {
    return (
      <Fragment>
        <p className="text-12 grey justify">
          <u>
            <b style={{color: 'black'}}>
              Statement for Certified High Net Worth Individual
            </b>
          </u>
        </p>

        <p className="text-12 grey justify">
          I declare that I am a certified high net worth individual for purposes
          of the Financial Services andMarkets Act 2000 (Financial Promotion)
          Order 2005.
        </p>

        <p className="text-12 grey justify">I understand that this means:</p>
        <p className="text-12 grey justify">
          (a) I can receive financial promotions that may not have been approved
          by a personauthorised by the Financial Services Authority;
        </p>
        <p className="text-12 grey justify">
          (b) the content of such financial promotions may not conform to rules
          issued by the FinancialServices Authority;{' '}
        </p>
        <p className="text-12 grey justify" style={{color: 'black'}}>
          (c){' '}
          <b style={{color: 'black'}}>
            by signing this statement I may lose significant rights;
          </b>
        </p>
        <p className="text-12 grey justify">
          (d) I may have no right to complain to either of the following -
        </p>
        <p className="text-12 grey justify">
          &nbsp;&nbsp;&nbsp; (i) the Financial Services Authority; or
        </p>
        <p className="text-12 grey justify">
          &nbsp;&nbsp;&nbsp; (ii) the Financial Ombudsman Scheme;
        </p>
        <p className="text-12 grey justify">
          (e) I may have no right to seek compensation from the Financial
          Services CompensationScheme.
        </p>
        <p className="text-12 grey justify">
          I am a certified high net worth individual because
          <b>at least one of the following applies -</b>
        </p>
        <p className="text-12 grey justify">
          (a) I had, during the financial year immediately preceding the date
          below, an annual income to the value of £100,000 or more;
        </p>
        <p className="text-12 grey justify">
          (b) I held, throughout the financial year immediately preceding the
          date below, net assets to the value of £250,000 or more. Net assets
          for these purposes do not include -{' '}
        </p>
        <p className="text-12 grey justify">
          &nbsp;&nbsp;&nbsp; (i) the property which is my primary residence or
          any loan secured on that residence;
        </p>
        <p className="text-12 grey justify">
          &nbsp;&nbsp;&nbsp; (ii) any rights of mine under a qualifying contract
          of insurance within the meaning of the Financial Services and Markets
          Act 2000 (Regulated Activities) Order 2001;{' '}
        </p>
        <p className="text-12 grey justify">
          <b>or</b>
        </p>
        <p className="text-12 grey justify">
          &nbsp;&nbsp;&nbsp; (iii) any benefits (in the form of pensions or
          otherwise) which are payable on the termination of my service or on my
          death or retirement and to which I am (or my dependants are), or may
          be, entitled.
        </p>
        <p className="text-12 grey justify">
          <b style={{color: 'black'}}>
            I accept that I can lose my property and other assets from making
            investment decisions based on financial promotions.
          </b>
        </p>
        <p className="text-12 grey justify">
          I am aware that it is open to me to seek advice from someone who
          specialises in advising on investments.
        </p>
      </Fragment>
    )
  }

  if (certificationType === 'SOPHISTICATED') {
    return (
      <Fragment>
        <p className="text-12 grey justify">
          <u>
            <b style={{color: 'black'}}>
              Statements for Certified Sophisticated Investor
            </b>
          </u>
        </p>

        <p className="text-12 grey justify">
          <b style={{color: 'black'}}>
            Part I - Statement to be signed by the investor:
          </b>
        </p>

        <p className="text-12 grey justify">
          I make this statement so that I am able to receive promotions which
          are exempt from therestrictions on financial promotion in the
          Financial Services and Markets Act 2000. Theexemption relates to
          certified sophisticated investors and I declare that I qualify as such
          inrelation to investments of the following kind:investments in
          unquoted private companies andunquoted public companies. I accept that
          the contents of promotions and other material that Ireceive may not
          have been approved by an authorised person and that their content may
          nottherefore be subject to controls which would apply if the promotion
          were made or approved by anauthorised person. I am aware that it is
          open to me to seek advice from someone whospecialises in advising on
          this kind of investment.
        </p>

        <p className="text-12 grey justify">
          <b style={{color: 'black'}}>
            Part 2 - Certificate to be signed by an Authorised Person advising
            the investor:
          </b>
        </p>

        <p className="text-12 grey justify">
          I am an authorised person in terms of the Financial Services and
          Markets Act 2000 and herebycertify that the person who has signed the
          above statement (in Part I above) is a sophisticatedinvestor in terms
          of the Financial Services and Markets Act (Financial Promotions) Order
          2005(as amended). Should you have any queries please do not hesitate
          to contact me.
        </p>
      </Fragment>
    )
  }

  if (certificationType === 'SELF_CERTIFIED_SOPHISTICATED') {
    return (
      <Fragment>
        <p className="text-12 grey justify">
          <u>
            <b style={{color: 'black'}}>
              Statement for Self-Certified Sophisticated Investor
            </b>
          </u>
        </p>

        <p className="text-12 grey justify">
          I declare that I am a self-certified sophisticated investor for the
          purposes of the FinancialServices and Markets Act (Financial
          Promotion) Order 2005.
        </p>
        <p className="text-12 grey justify">I understand that this means:</p>
        <p className="text-12 grey justify">
          (a) I can receive financial promotions that may not have been approved
          by a personauthorised by the Financial Services Authority;
        </p>
        <p className="text-12 grey justify">
          (b) the content of such financial promotions may not conform to rules
          issued by the FinancialServices Authority;
        </p>
        <p className="text-12 grey justify">
          (c){' '}
          <b style={{color: 'black'}}>
            by signing this statement I may lose significant rights;
          </b>
        </p>
        <p className="text-12 grey justify">
          (d)I may have no right to complain to either of the following -
        </p>
        <p className="text-12 grey justify">
          &nbsp;&nbsp;&nbsp; (i) the Financial Services Authority; or{' '}
        </p>
        <p className="text-12 grey justify">
          &nbsp;&nbsp;&nbsp; (ii) the Financial Ombudsman Scheme;
        </p>
        <p className="text-12 grey justify">
          (e) I may have no right to seek compensation from the Financial
          Services CompensationScheme.
        </p>
        <p className="text-12 grey justify">
          I am a self-certified sophisticated investor because at least one of
          the following applies -
        </p>
        <p className="text-12 grey justify">
          (a) I am a member of a network or syndicate of business angels and
          have been so for atleast the last six months prior to the date below;
        </p>
        <p className="text-12 grey justify">
          (b) I have made more than one investment in an unlisted company in the
          two years prior tothe date below;{' '}
        </p>
        <p className="text-12 grey justify">
          (c) I am working, or have worked in the two years prior to the date
          below, in a professionalcapacity in the private equity sector, or in
          the provision of finance for small and mediumenterprises;{' '}
        </p>
        <p className="text-12 grey justify">
          (d)I am currently, or have been in the two years prior to the date
          below, a director of acompany with an annual turnover of at least £1
          million.
        </p>
        <p className="text-12 grey justify">
          <b style={{color: 'black'}}>
            I accept that I can lose my property and other assets from making
            investment decisionsbased on financial promotions.
          </b>
        </p>
        <p className="text-12 grey justify">
          I am aware that it is open to me to seek advice from someone who
          specialises in advising oninvestments.
        </p>
      </Fragment>
    )
  }

  if (certificationType === 'INVESTMENT_PROFESSIONAL') {
    return (
      <Fragment>
        <p className="text-12 grey justify">
          As defined in Article 19 of the FPO
        </p>
      </Fragment>
    )
  }

  if (certificationType === 'HIGH_NET_WORTH_COMPANY') {
    return (
      <Fragment>
        <p className="text-12 grey justify">
          To whom Article 49 of the FPO applies
        </p>
      </Fragment>
    )
  }

  return null
}

export default CertificationStatement
