import React from 'react'
import {useHistory} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'
import {firebaseSignOut} from 'lib/firebase/auth'
import {useAuth} from 'hooks/useAuth'

import loginBackground from 'assets/images/login_background.jpg'
import registerBackground from 'assets/images/register_background.jpg'

import Button from 'components/styles/Button'

import Conditions from './Conditions'
import {GuestLayoutContainer, LogoutButtonContainer} from './styles'

interface GuestLayoutProps {
  register?: boolean
}

const GuestLayout: React.FC<GuestLayoutProps> = ({register, children}) => {
  const {isAuth} = useAuth()
  const history = useHistory()

  const handleLogout = async () => {
    await firebaseSignOut()
    history.push(ROUTES.HOME)
  }

  const coverImageSrc = register ? registerBackground : loginBackground

  return (
    <GuestLayoutContainer>
      <main className="content-left">
        {children}

        <Conditions />
      </main>

      <img src={coverImageSrc} alt="cover" loading="lazy" className="cover" />

      {isAuth && (
        <LogoutButtonContainer>
          <Button onClick={handleLogout}>Logout</Button>
        </LogoutButtonContainer>
      )}
    </GuestLayoutContainer>
  )
}

export default GuestLayout
