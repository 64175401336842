import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'

import Card from 'components/Card'
import DragDrop from 'components/DragDrop'

interface DeskProps {
  disabled?: boolean
  onRemove: (files: any[], update?: boolean) => void
}

const Desk: React.FC<DeskProps> = ({disabled, onRemove}) => {
  const {control, errors} = useFormContext()

  return (
    <Card padding title="Deal Deck" subtitle="Upload Deck documents below.">
      <Controller
        control={control}
        name="deskFiles"
        defaultValue={[]}
        render={({onChange, value}) => (
          <DragDrop
            {...{control, onChange, onRemove, value, disabled}}
            name="deskFiles"
            error={{
              status: errors?.deskFiles,
              message: errors?.deskFiles?.message,
            }}
            accept=".pdf, .xlsx, xlsm, .xlsb, .xltx" // accept pdf and excel file formats
          />
        )}
      />
    </Card>
  )
}

export default Desk
