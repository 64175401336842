import React, {Fragment} from 'react'

import Button from 'components/styles/Button'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'

type BlockProps = {
  show: boolean
  loading: boolean
  isSuspended: boolean
  name: string
  onBlock: () => void
  onClose: () => void
}

const Block = ({
  show,
  name,
  onBlock,
  onClose,
  loading,
  isSuspended,
}: BlockProps) => {
  return (
    <Modal isShowing={show} hide={onClose} maxWidth="600px">
      <Fragment>
        <h2>{isSuspended ? 'Unblock User' : 'Block User'}</h2>
        <p className="modal-inner-body">
          Do you want to {isSuspended ? 'unblock' : 'block'} this user?
          <br />
          <br />
          {name}
        </p>
        <div className="modal-inner-btn">
          {loading ? (
            <Spinner primary />
          ) : (
            <Fragment>
              <Button
                secondary
                className="modal-back-button"
                onClick={() => onClose()}
              >
                Go Back
              </Button>

              <Button onClick={onBlock}>
                {isSuspended ? 'Unblock' : 'Block'}
              </Button>
            </Fragment>
          )}
        </div>
      </Fragment>
    </Modal>
  )
}

export default Block
