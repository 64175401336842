import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

const titleStyling = css`
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.25rem;

  color: ${Colors.WHITE};
  text-align: center;
`

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 48px 24px;
  background-color: ${Colors.PRIMARY};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > :nth-child(n) {
    display: grid;
    justify-content: center;
    align-content: center;
    gap: 1em;
  }

  > :first-child {
    grid-template-columns: auto auto auto auto;
  }
  > :last-child {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: ${Breakpoints.md}) {
    > :nth-child(n) {
      grid-template-columns: 1fr;
    }
  }

  @media only screen and (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    justify-content: center;

    > :nth-child(n) {
      grid-template-columns: 1fr;
    }
    > :first-child {
      margin-bottom: 1em;
    }
  }
`

export const Logo = styled.img`
  height: 16.44px;
  width: 48px;
  position: relative;
  top: 4px;
  margin: 0 auto;
`

export const Title = styled.span`
  ${titleStyling};
`

export const TitleLink = styled(Link)`
  ${titleStyling};
  text-decoration: none;
`

export const TitleAnchor = styled.a`
  ${titleStyling};
  text-decoration: none;
`

export const Text = styled.div`
  color: ${Colors.DARK_GREY};
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
`
