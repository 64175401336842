import {createGlobalStyle, css} from 'styled-components'
import Breakpoints from './breakpoints'
import Colors from './colors'
import {NEUE_HAAS_GROTESK_DISPLAY, NEUE_HAAS_GROTESK_TEXT} from './fonts'

export const SLOW_TRANSITION = css`
  transition: 0.4s all ease-in-out;
`

export const FAST_TRANSITION = css`
  transition: 0.2s all ease-in-out;
`

const responsiveForm = css`
  flex-direction: column;

  > :nth-child(1) {
    margin-right: 0;
  }
`
const defaultForm = css`
  flex-direction: row;

  > :nth-child(1) {
    margin-right: 1.1em;
  }
`

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: 'antialiased';
    -moz-osx-font-smoothing: 'grayscale';
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: ${NEUE_HAAS_GROTESK_DISPLAY};
    color: ${Colors.BLACK}
  }

  #root {
    width: 100%;
    height: 100%;
  }

  body {
    h2 {
      font-size: 1.63rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.5rem;
      letter-spacing: 0px;
      text-align: left;
    }

    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.313rem;
      display: flex;
      align-items: center;
    }

    // Font family
    .neue-haas-grotesk-display {
      font-family: ${NEUE_HAAS_GROTESK_DISPLAY};
    }
    .neue-haas-grotesk-text {
      font-family: ${NEUE_HAAS_GROTESK_TEXT};
    }

    // Font weight
    .font-light {
      font-weight: 300 !important;
    }
    .font-normal {
      font-weight: 400 !important;
    }

    // Font size
    .text-base {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .text-sm	{
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    .text-xs {
      font-size: 0.75rem;
      line-height: 1rem;
    }
    .text-10 {
      font-size: 10px !important;
    }
    .text-12 {
      font-size: 12px !important;
    }
    .text-16 {
      font-size: 16px !important;
    }

    // Font utils
    .bold {
      font-weight: bold !important;
    }
    .italic {
      font-style: italic !important;
    }
    .underline {
     text-decoration: underline;
    }
    .text-center {
      text-align: center !important;
    }
    .justify {
      text-align: justify !important;
      text-justify: inter-word !important;
    }

    // Colors
    .primary {
      color: ${Colors.PRIMARY} !important;
    }
    .red {
      color: ${Colors.ERROR} !important;
    }
    .success {
      color: ${Colors.SUCCESS};
    }
    .warning {
      color: ${Colors.WARNING};
    }
    .grey {
      color: ${Colors.GREY};
    }
    .dark-grey {
      color: ${Colors.DARK_GREY};
    }
    .grey-50 {
      color: ${Colors.GREY_50};
    }
    .blue {
      color: ${Colors.BLUE};
    }
    .black {
      color: ${Colors.BLACK} !important;
    }
    .bg-light-grey {
      background-color: ${Colors.LIGHT_GREY} !important;
    }

    // Padding
    .padding-5 {
      padding: 5px;
    }
    .padding-top-16 {
      padding: 16px;
    }

    // Margin
    .my-1	{
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
    .my-2 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .my-3 {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
    .my-4 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .mx-1	{
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
    .mx-2 {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .m-16 {
      margin: 16px;
    }
    .my-16 {
      margin: 16px 0 16px 0;
    }
    .my-32 {
      margin: 32px 0;
    }
    .my-50 {
      margin: 50px 0;
    }
    .m-top-10 {
      margin-top: 10px;
    }
    .m-bottom-10 {
      margin-bottom: 10px;
    }
    .m-bottom-20 {
      margin-bottom: 20px;
    }
    .m-bottom-40 {
      margin-bottom: 40px;
    }

    // Utils
    .w-full {
      width: 100%;
    }
    .w-auto {
      width: auto;
    }

    .flex {
      display: flex;
    }
    .items-center {
      align-items: center;
    }
    .justify-center {
      justify-content: center;
    }

    .error {
      color: ${Colors.ERROR};
      font-size: 12px;
      font-weight: 300;
      margin-top: 2px;
    }

    .title-small {
      color: ${Colors.SECONDARY};
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
    }
    .title-primary {
      color: ${Colors.PRIMARY};
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0px;
    }
    .title-medium {
      font-size: 20px;
      color: ${Colors.PRIMARY};
      font-weight: bold;
      letter-spacing: 0px;
    }
    .title-big {
      color: ${Colors.SECONDARY};
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0px;
    }
    .text {
      color: ${Colors.BLACK};
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0px;
      text-align: left;
    }
    .text-label {
      color: ${Colors.BLACK};
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      text-align: left;
    }
    .text-label-bottom {
      color: ${Colors.SECONDARY_50};
      font-size: 8px;
      font-weight: 300;
      letter-spacing: 0px;
      margin-bottom: 1.5em;
    }
    .input-info {
      color: ${Colors.GREY};
      font-style: normal;
      text-align: left;
      margin-top: 10px;
      font-size: 12px;
      font-weight: 300;
    }

    .router-link-register {
      text-decoration: none;
      color: ${Colors.BLUE};
      font-weight: bold;

      &:hover {
        text-decoration: underline;
        color: ${Colors.LIGHT_BLUE};
      }
    }

    .link-router-dom {
      text-decoration: none;
      color: ${Colors.BLUE};
      font-weight: bold;

      &:hover {
        text-decoration: underline;
        color: ${Colors.LIGHT_BLUE};
      }
    }

    /** Display */
    .inline-block {
      display: inline-block;
    }

    fieldset {
      padding: 0;
      border: none;
    }

    .items-space-between {
      > :not(:first-child) {
        margin-top: 1.2em;
      }
    }

    .form {
      > :not(:first-child) {
        margin-top: 1.2em;
      }

      .row {
        display: flex;
        justify-content: space-between;

        ${defaultForm}

        > :nth-child(n) {
          flex: 1;
        }

        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: ${Breakpoints.sm}) {
          padding: 0;
          ${responsiveForm}
        }

        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (max-width: ${Breakpoints.md}) {
          ${responsiveForm}
        }

        /** Custom */
        @media only screen and (max-width: 1300px) {
          flex-direction: column !important;

          > :not(:first-child) {
            margin-top: 1em;
          }

          > :nth-child(1) {
            margin-right: 0 !important;
          }
        }
      }
    }

    form {
      &.app-form {

        fieldset {
          > :not(:first-child) {
            margin-top: 1.2em;
          }
        }

        button[type='submit'] {
          margin-top: 2.5em !important;
        }

        .row {
          display: flex;
          justify-content: space-between;

          ${defaultForm}

          > :nth-child(n) {
            flex: 1;
          }

          /* Extra small devices (phones, 600px and down) */
          @media only screen and (max-width: ${Breakpoints.sm}) {
            padding: 0;
            ${responsiveForm}
          }

          /* Medium devices (landscape tablets, 768px and up) */
          @media only screen and (max-width: ${Breakpoints.md}) {
            ${responsiveForm}
          }

          /** Custom */
          @media only screen and (max-width: 1300px) {
            flex-direction: column !important;

            > :not(:first-child) {
              margin-top: 1em;
            }

            > :nth-child(1) {
              margin-right: 0 !important;
            }
          }
        }
      }
    }

    .auth-container {
      display: flex;
      flex-direction: column;

      /** Sign Up */
      > :nth-child(2) {
        margin-top: 20%;
      }

      /** Form */
      > :nth-child(4) {
        margin-top: 8%;
      }
      /** Password Reset */
      > :nth-child(5) {
        margin-top: 8%;
      }
      /** Separator */
      > :nth-child(6) {
        margin-top: 8%;
      }
      /** Login */
      > :nth-child(7) {
        margin-top: 8%;
      }

      form {
        margin-top: 4em;
      }

      .page-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 100%;

        padding: 2em 0;

        @media only screen and (min-width: ${Breakpoints.sm}) {
          padding: 6em 0;
        }
      }

      @media only screen and (min-width: ${Breakpoints.sm}) {
        padding: 3em 1em;
      }
      @media only screen and (min-width: ${Breakpoints.md}) {
        padding: 4em 3em;
      }

      @media only screen and (min-width: ${Breakpoints.lg}) {
        padding: 4em 3em;
      }

      @media only screen and (min-width: ${Breakpoints.lg}) {
        padding: 4em 3em;
      }

      &.padding {
        padding: 4em 2em;
      }
    }

    .modal-inner-btn {
      display: grid;
      grid-template-columns: 0.5fr 1fr;
      grid-gap: 2rem;
    }

    /** Info icon css */
    .i {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${Colors.PRIMARY};

      font-weight: bold;

      margin: 0 0.7em;

      color: white;
      font-size: 1em;
      border: 1px solid transparent;
      width: 1.5em;
      height: 1.5em;
      text-align: center;

      border-radius: 100%;
    }

    .link {
      background:none;
      border:none;

      padding: 0;

      text-decoration:underline;
      display:inline-block;
      color: ${Colors.PRIMARY};
      font-size: 1em;

      cursor: pointer;
    }

    .tooltip {
      position: relative;
    }

    .tooltip .tooltiptext {
      visibility: hidden;
      width: 200px;
      background-color: ${Colors.WHITE};
      text-align: justify;
      border-radius: 6px;
      padding: 15px;

      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

      margin-bottom: 10px;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 50%;
      margin-left: -70px;

      @media only screen and (max-width: ${Breakpoints.md}) {
        margin-left: -200px;
      }
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }

    transition: all 0.5 linear;
  }
`

export default GlobalStyles
