import React from 'react'

import {ROUTES} from 'config/routes.config'

import logo from 'assets/images/logo_white.svg'

import {Container, Header, Logo, TitleLink, TitleAnchor, Text} from './styles'

const Footer: React.FC = () => {
  return (
    <Container className="footer">
      <Header>
        <div>
          <Logo src={logo} alt="NobleCo" loading="lazy" />

          <TitleLink className="mx-2.5" to={ROUTES.PRIVACY_POLICY}>
            Privacy Policy
          </TitleLink>

          <TitleLink className="mx-2.5" to={ROUTES.TERMS_OF_USE}>
            Terms & Conditions
          </TitleLink>

          <TitleLink className="mx-2.5" to={ROUTES.DISCLAIMER}>
            Disclaimer
          </TitleLink>
        </div>

        <div>
          <TitleAnchor href="tel:+4401316037680">
            (+44) 0131 603 7680
          </TitleAnchor>
          <TitleAnchor href="mailto:contactus@nobleandcompany.com">
            contactus@nobleandcompany.com
          </TitleAnchor>
        </div>
      </Header>

      <Text className="my-4 justify">
        Noble & Co is the trading name of Noble & Company (UK) Limited.
        Registered in Scotland No.SC420569. Vat N0.138407119. Authorised and
        regulated by the Financial Conduct Authority
      </Text>

      <Text className="justify">
        Disclaimer - The material contained on this website is for information
        purposes only, and not for the purposes of forming an investment
        opinion, nor does it constitute investment advice or counsel or
        solicitation for investment in any security. The Material is general
        information intended for recipients that understand the risks associated
        with such investments and who should conduct their own investigation,
        evaluation and analysis of the matters contained therein. The Material
        does not constitute an offer for the purchase or subscription of any
        securities and neither Noble & Company (UK) Limited nor any potential
        investee is under an obligation to make such an offer subsequently.
      </Text>
    </Container>
  )
}

export default Footer
