import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-gap: 1rem;
`

export const Section = styled.section<{show?: boolean}>`
  display: ${({show}) => (show ? 'grid' : 'none')};
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;

  h3 {
    margin: 0 !important;
  }

  .row {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;

    @media only screen and (max-width: ${Breakpoints.sm}) {
      grid-template-columns: 1fr;
    }
  }
`
