import React, {useState, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {useHistory, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {AxiosError} from 'axios'

import {Deal, MessageProps} from 'types'
import {ROUTES} from 'config/routes.config'
import {useAuth} from 'hooks/useAuth'
import {getPublicDeal} from 'services/deals'

import Contact from 'components/Contact'
import DealDetails from 'components/DealDetails'
import GridLayout, {Col} from 'components/styles/GridLayout'
import Toast from 'components/Toast'
import Button from 'components/styles/Button'
import Spinner from 'components/Spinner'
import Card from 'components/Card'
import ContentContainer from 'components/ContentContainer'

import {InterestedCard} from './styles'
import {ErrorFallback} from 'components/utils/ErrorBoundary'

type DealTeaserParams = {
  slug?: string
}

const DealTeaser: React.FC = () => {
  const {isAuth} = useAuth()

  const history = useHistory()
  const {slug = ''} = useParams<DealTeaserParams>()

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'success',
  })

  const {
    data: deal,
    status,
    error,
  } = useQuery<Deal, AxiosError>(
    ['public-deal', slug],
    () => getPublicDeal(slug),
    {
      enabled: Boolean(slug),
      onError: error => {
        setToast({
          value: error.response?.data?.title,
          type: 'error',
        })
      },
    }
  )

  if (status === 'loading') {
    return <Spinner />
  }

  if (status === 'error' && error) {
    return (
      <ErrorFallback
        error={error}
        resetErrorBoundary={() => window.location.reload()}
      />
    )
  }

  const content = (
    <Fragment>
      <GridLayout>
        <Col>
          {/** Deal's details */}
          {deal ? (
            <DealDetails
              deal={deal}
              optionalSlug={slug}
              showOverviewTitle
              showDealImage
            />
          ) : null}
        </Col>

        <Col>
          {/** Deal Interest Container - with links to register or sign in - hide if user signed in */}
          <InterestedCard title="Interested in this Deal?" hide={isAuth}>
            <div className="interested-create-account">
              <p className="interested-create-account-desc">
                If you would like more details on this deal, please create an
                account and register interest.
              </p>

              <Button onClick={() => history.push(ROUTES.REGISTER)} full>
                Create Account
              </Button>
            </div>

            <div className="interested-sign-in">
              {'Already have an account? '}
              <Link className="router-link-register" to={ROUTES.LOGIN}>
                Log In
              </Link>
            </div>
          </InterestedCard>

          {/** Deal's contact */}
          <Card title="Deal Contact" padding hide={!deal}>
            <Contact data={deal?.contact} />
          </Card>
        </Col>
      </GridLayout>
    </Fragment>
  )

  return (
    <ContentContainer>
      {content}

      <Toast message={toast} action={setToast} />
    </ContentContainer>
  )
}

export default DealTeaser
