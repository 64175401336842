import React from 'react'

import {PageTitleContainer, PageTitleText} from './styles'

interface PageTitleProps {
  title: string
  paddingTop?: boolean
}

const PageTitle: React.FC<PageTitleProps> = ({title, paddingTop = false}) => {
  return (
    <PageTitleContainer paddingTop={paddingTop}>
      <PageTitleText>{title}</PageTitleText>
    </PageTitleContainer>
  )
}

export default PageTitle
