import React, {Fragment} from 'react'

import {DealContact} from 'types'

import {ContactDetails} from './styles'

import Button from 'components/styles/Button'
import Card from 'components/Card'
import Spinner from 'components/Spinner'

type DealContactProps = {
  data: DealContact
  onAskQuestion: () => void
  onRequestMeeting: () => void
  loading: boolean
}

const Contact = ({
  data,
  onAskQuestion,
  onRequestMeeting,
  loading,
}: DealContactProps) => {
  const {name, phone, position, email} = data || {
    name: '',
    phone: '',
    position: '',
    email: '',
  }

  return (
    <Card padding>
      <h1 className="title-small">Deal Contact</h1>

      {loading ? (
        <Spinner primary />
      ) : (
        <Fragment>
          <span className="text">
            For queries or to arrange a meeting, please contact:
          </span>

          <ContactDetails>
            <div className="contact-item">
              <label>Point of Contact</label>
              <span className="text black">{name}</span>
            </div>

            <div className="contact-item">
              <label>Position</label>
              <span className="text black">{position}</span>
            </div>

            <div className="contact-item">
              <label>Phone</label>
              <span className="text black">{phone}</span>
            </div>

            <div className="contact-item">
              <label>Email</label>
              <span className="text black">{email}</span>
            </div>
          </ContactDetails>

          <div className="get-in-touch">
            <Button onClick={onAskQuestion} full>
              Ask a Question
            </Button>

            <Button onClick={onRequestMeeting} full>
              Request a meeting
            </Button>
          </div>
        </Fragment>
      )}
    </Card>
  )
}

export default Contact
