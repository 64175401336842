import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {ButtonType} from 'types'
import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'
import Constants from 'styles/constants'
import {FAST_TRANSITION, SLOW_TRANSITION} from 'styles/globalStyles'

import {InternalLinkProps} from './'

const noStyle = css`
  background: transparent;
  border: none;

  color: ${Colors.GREY_50};

  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0px;

  padding: 0;

  ${SLOW_TRANSITION};

  :hover {
    color: ${Colors.PRIMARY};
  }
`

const defaultStyle = css<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px 16px !important;

  background: ${({primary}) => (primary ? Colors.PRIMARY : Colors.WHITE)};
  color: ${({primary}) => (primary ? Colors.WHITE : Colors.PRIMARY)};

  margin: 1em 0;

  border: 1px solid ${Colors.PRIMARY};
  border-radius: ${Constants.BORDER_RADIUS};

  ${FAST_TRANSITION};

  :hover {
    background: ${({primary}) =>
      !primary ? Colors.PRIMARY : Colors.BTN_HOVER};
    color: ${Colors.WHITE};
  }
  :disabled {
    background-color: ${Colors.LIGHT_GREY};
    border: 1px solid ${Colors.GREY};
    color: ${Colors.GREY};
  }
`

export const StyledLink = styled(Link)<InternalLinkProps>`
  text-decoration: none;

  text-align: center;

  font-size: 12px;
  font-weight: bold;

  margin: 1em 0;

  cursor: pointer;

  .btn-ic {
    margin-right: 0.3em;
  }

  ${({center}) => center && `align-self: center;`};

  width: ${({full, size}) => size || (full ? '100%' : 'auto')};
  height: auto;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    width: auto !important;
  }

  ${({styleless}) => (styleless ? noStyle : defaultStyle)};
`
