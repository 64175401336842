import React from 'react'

import {ROUTES} from 'config/routes.config'

import InternalLink from 'components/InternalLink'

import {ConContainer, ConditionText} from './styles'

const Conditions: React.FC = () => {
  return (
    <ConContainer id="auth-footer">
      <ConditionText>
        Noble & Co is the trading name of Noble & Company (UK) Limited.
        Registered in Scotland No.SC420569. Vat N0.138407119. Authorised and
        regulated by the Financial Conduct Authority.
      </ConditionText>

      <ConditionText>
        <span>Telephone: 0131 603 7680&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>Email: capital@nobleandcompany.com&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <b>
          <InternalLink styleless size="100px" to={ROUTES.TERMS_OF_USE}>
            | Terms
          </InternalLink>
          &nbsp;
          <InternalLink styleless size="100px" to={ROUTES.DISCLAIMER}>
            | Disclaimer
          </InternalLink>
          &nbsp;
          <InternalLink styleless size="100px" to={ROUTES.PRIVACY_POLICY}>
            | Privacy Policy
          </InternalLink>
        </b>
      </ConditionText>
    </ConContainer>
  )
}

export default Conditions
