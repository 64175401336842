import React, {Fragment} from 'react'
import styled from 'styled-components'
import {useFormContext} from 'react-hook-form'

import {CERTIFICATIONS} from 'config/data/certifications.config'
import Colors from 'styles/colors'

import Separator from 'components/styles/Separator'
import Checkbox from 'components/Checkbox'
import Input from 'components/Input'

import {Section, StepSubtitle} from '../../styles'

import Statement1 from './components/Statement1'
import Statement2 from './components/Statement2'
import Statement3 from './components/Statement3'
import Statement4 from './components/Statement4'
import Statement5 from './components/Statement5'

const Line = styled(Separator)`
  background-color: ${Colors.LIGHT_GREY};
  margin: 0;
`

export const Statement = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'flex' : 'none')};
  flex-direction: column;

  padding: 1em;

  background-color: ${Colors.BACKGROUND};

  height: auto;
  max-height: 250px;

  overflow: scroll;
`

interface CertificationProps {
  formStep: number
  isCompany: boolean
  certificationType: string
}

const Certification: React.FC<CertificationProps> = ({
  formStep,
  isCompany,
  certificationType,
}) => {
  const {register, setValue, errors} = useFormContext()

  return (
    <Section show={formStep === 1}>
      <span className="text-lg grey">
        {isCompany
          ? 'Before we can generate your NDA, we need to capture a few details'
          : 'Let us know what type of investor you are'}
      </span>

      <Fragment>
        {isCompany && <StepSubtitle>Certification Type</StepSubtitle>}

        {/** The following boxes are only showing if user is not signing on behalf of a company */}
        {!isCompany && (
          <Fragment>
            <Checkbox
              name="certification"
              label="Certified High Net Worth Individual"
              value={CERTIFICATIONS.HIGH_NET_WORTH}
              type="radio"
              ref={register}
              bold
            />
            <Statement
              show={certificationType === CERTIFICATIONS.HIGH_NET_WORTH}
            >
              <Statement1 />
            </Statement>

            <Line />

            <Checkbox
              name="certification"
              label="Certified sophisticated investor"
              value={CERTIFICATIONS.SOPHISTICATED}
              type="radio"
              ref={register}
              bold
            />
            <Statement
              show={certificationType === CERTIFICATIONS.SOPHISTICATED}
            >
              <Statement2 />
            </Statement>

            <Line />

            <Checkbox
              name="certification"
              label="Self-certificated sophisticated investor"
              value={CERTIFICATIONS.SELF_CERTIFIED_SOPHISTICATED}
              type="radio"
              ref={register}
              bold
            />
            <Statement
              show={
                certificationType ===
                CERTIFICATIONS.SELF_CERTIFIED_SOPHISTICATED
              }
            >
              <Statement3 />
            </Statement>

            <Line />

            <Checkbox
              name="certification"
              label="Investment Professional"
              value={CERTIFICATIONS.INVESTMENT_PROFESSIONAL}
              type="radio"
              ref={register}
              bold
            />
            <Statement
              show={
                certificationType === CERTIFICATIONS.INVESTMENT_PROFESSIONAL
              }
            >
              <Statement4 />
            </Statement>
          </Fragment>
        )}

        <Line hide={isCompany} />

        <Checkbox
          name="certification"
          label="High net worth company, unincorporated association or trust"
          value={CERTIFICATIONS.HIGH_NET_WORTH_COMPANY}
          type="radio"
          defaultChecked={isCompany}
          ref={register}
          bold
          disabled={isCompany}
        />
        <Statement
          show={certificationType === CERTIFICATIONS.HIGH_NET_WORTH_COMPANY}
        >
          <Statement5 />
        </Statement>
      </Fragment>

      {isCompany && (
        <Fragment>
          <StepSubtitle>Company Details</StepSubtitle>

          <Input
            name="companyName"
            label="Company Name"
            type="text"
            placeholder="Company Name"
            onClear={() => setValue('companyName', '')}
            error={{
              status: errors.firstName,
              message: 'Comapny Name is required',
            }}
            ref={register({
              required: formStep === 1,
              maxLength: 80,
            })}
          />

          <div className="row">
            <Input
              name="registrationNumber"
              label="Company Registration Number"
              type="text"
              placeholder="SC123456"
              onClear={() => setValue('registrationNumber', '')}
              error={{
                status: errors.registrationNumber,
                message: 'Registration Number is required',
              }}
              ref={register({
                required: formStep === 1,
                maxLength: 20,
              })}
            />

            <Input
              name="country"
              label="Country of Registration"
              type="text"
              placeholder="SC123456"
              onClear={() => setValue('registrationNumber', '')}
              error={{
                status: errors.country,
                message: 'Country is required',
              }}
              ref={register({
                required: formStep === 1,
                maxLength: 35,
              })}
            />
          </div>

          <StepSubtitle>Company Address</StepSubtitle>

          <div className="row">
            <Input
              name="companyAddressLine1"
              label="Address Line 1"
              type="text"
              placeholder="Address Line 1"
              onClear={() => setValue('companyAddressLine1', '')}
              error={{
                status: errors.companyAddressLine1,
                message: 'Address Line 1 is required',
              }}
              ref={register({
                required: formStep === 1,
                maxLength: 200,
              })}
            />

            <Input
              name="companyAddressLine2"
              label="Address Line 2"
              type="text"
              placeholder="Address Line 2"
              onClear={() => setValue('registrationNumber', '')}
              error={{
                status: errors.companyAddressLine2,
                message: 'Address Line 2 has a maximum of 100 characters',
              }}
              ref={register({
                maxLength: 100,
              })}
            />
          </div>
          <div className="row">
            <Input
              name="companyCity"
              label="City"
              type="text"
              placeholder="City"
              onClear={() => setValue('companyCity', '')}
              error={{
                status: errors.companyCity,
                message: 'City is required',
              }}
              ref={register({
                required: formStep === 1,
                maxLength: 100,
              })}
            />

            <Input
              name="companyPostcode"
              label="Postcode"
              type="text"
              placeholder="Postcode"
              onClear={() => setValue('companyPostcode', '')}
              error={{
                status: errors.companyPostcode,
                message: 'Postcode is required',
              }}
              ref={register({
                required: formStep === 1,
                maxLength: 40,
              })}
            />
          </div>
        </Fragment>
      )}
    </Section>
  )
}

export default Certification
