import styled from 'styled-components'

import Colors from 'styles/colors'

import Card from 'components/Card'

export const InterestedCard = styled(Card)`
  .interested-create-account {
    padding: 20px 24px;
    border-bottom: 1px solid ${Colors.GREY_200};

    .interested-create-account-desc {
      margin: 0;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 28px;
      color: ${Colors.GREY};
    }
  }

  .interested-sign-in {
    padding: 16px 24px;
    text-align: center;
  }
`
