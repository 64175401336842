import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'
import {MessageProps} from 'types'
import {sendResetPasswordEmail} from 'services/password'
import {errorDefault} from 'utils/errors'

import GuestLayout from 'layouts/GuestLayout'
import Logo from 'components/Logo'
import Button from 'components/styles/Button'
import Toast from 'components/Toast'

import Form from './components/Form'

type SubmitResetPassword = {
  email: string
}

/**
 * Forgot Password Page
 *
 * -> input email address
 */
const Forgotten: React.FC = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  const redirect = () => {
    history.push(ROUTES.LOGIN)
  }

  const onSubmit = async (data: SubmitResetPassword) => {
    setLoading(true)

    try {
      const {email} = data
      const value = await sendResetPasswordEmail(email)

      setToast({
        value,
        type: 'success',
      })
    } catch (_) {
      setToast({
        value: errorDefault,
        type: 'error',
      })
    }

    setLoading(false)
  }

  return (
    <GuestLayout>
      <Toast message={toast} action={setToast} />

      <div className="auth-container">
        <Logo size="big" />

        <h1 className="text-center">Forgot Password</h1>

        <Form {...{onSubmit, loading}} />

        <Button secondary onClick={redirect} center>
          Back to Sign In
        </Button>
      </div>
    </GuestLayout>
  )
}

export default Forgotten
