import React, {useState, useEffect} from 'react'
import {useLocation, useHistory} from 'react-router-dom'

import {MessageProps} from 'types'
import {ROUTES} from 'config/routes.config'
import {handleResetPassword} from 'services/password'
import {errorDefault} from 'utils/errors'
import {timeouts} from 'config/timeouts.config'

import GuestLayout from 'layouts/GuestLayout'
import Logo from 'components/Logo'
import Toast from 'components/Toast'

import Form from './components/Form'
import Success from './components/Success'

type SubmitCreateNewPassword = {
  password: string
}

const defaultState = {
  submitted: false,
  loading: false,
}

/**
 * Reset Password Page
 *
 * -> input new password
 */
const Reset: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  const {actionCode} = (location.state as any) || {actionCode: ''}

  const [state, setState] = useState(defaultState)
  const {submitted, loading} = state

  const [toast, setToast] = useState<MessageProps>({value: '', type: 'info'})

  useEffect(() => {
    let timeout: number

    if (submitted) {
      timeout = setTimeout(() => {
        history.push(ROUTES.LOGIN)
      }, timeouts.small)
    }

    return () => clearTimeout(timeout)
  }, [submitted, history])

  const onSubmit = async (data: SubmitCreateNewPassword) => {
    const {password} = data

    setState({...state, loading: true})

    try {
      await handleResetPassword(password, actionCode)

      setState({submitted: true, loading: false})
    } catch (_) {
      setState({...state, loading: false})
      setToast({
        value: errorDefault,
        type: 'error',
      })
    }
  }

  return (
    <GuestLayout>
      <Toast message={toast} action={setToast} />
      <div className="auth-container">
        <Logo size="big" />

        <h1 className="text-center">
          {submitted ? 'Success!' : 'Reset Password'}
        </h1>

        {submitted ? <Success /> : <Form {...{onSubmit, loading}} />}
      </div>
    </GuestLayout>
  )
}

export default Reset
