export const Colors = {
  PRIMARY: '#212121',
  SECONDARY: '#212121',

  WHITE: '#ffffff',
  BLACK: '#212121',

  LIGHT_GREY: '#EDF0F2',
  GREY: '#757575',
  DARK_GREY: '#9E9E9E',
  GREYISH: '#839aa6',

  GREY_100: '#F8FAFB',
  GREY_200: '#E5E7EB',
  GREY_900: '#111827',

  GREEN: '#436518',
  BLUEISH: '#535e7c',
  LIGHT_BLUE: '#5eb7fe',
  BLUE: '#36A6FF',

  BUTTON_HOVER_BLUE: '#4AAFFF',
  BUTTON_CLICKED_BLUE: '#2674B3',

  LETTER_BORDERS: '#979797',
  BACKGROUND: '#f5f6f9',
  FOOTER: '#8c8d8c',
  BTN_HOVER: '#535e7c',
  CURRENCY: '#f8f9fa',
  PLACEHOLDER: '#eaeaea',

  BACKGROUND_FILE_BOX: '#fdfefe',
  BACKGROUND_DEAL_TYPE: '#ebf6ff',
  BACKGROUND_SUCCESS: '#EBFBF2',
  BACKGROUND_WARNING: '#FFF8EB',
  BACKGROUND_ERROR: '#FCECEC',

  SUCCESS: '#3AD47D',
  SUCCESS_600: '#299458',
  ERROR: '#DD3D3D',
  ERROR_LIGHT: '#e36363',
  ERROR_DARK: '#bf2121',
  ERROR_600: '#9B2B2B',
  WARNING: '#FFBB38',
  WARNING_700: '#664B16',
  DRAFT: '#974746',
  DISABLED: '#F3F5F7',
  INACTIVE: '#BDBDBD',

  // transparency
  PRIMARY_25: '#1b395f40',
  ERROR_50: '#f7565680',
  ERROR_05: '#f756560D',
  GREY_50: '#60606080',
  GREYISH_50: '#839aa680',
  SECONDARY_05: '#0000000D',
  SECONDARY_50: '#00000080',
  SECONDARY_25: '#00000040',
  SUCCESS_05: '#28c3970D',
  BLUEISH_05: `#535e7c0D`,
  BLACK_05: `#0000000D`,
  DRAFT_05: '#9747460D',
}

export default Colors
