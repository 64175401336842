import styled from 'styled-components'

import Textarea from 'components/styles/Textarea'

export const CardContent = styled.div`
  display: grid;
  gap: 16px;
`

export const CustomArea = styled(Textarea)`
  width: 100%;
  box-sizing: border-box;
`
