import React, {useEffect, useState, Fragment} from 'react'
import {useHistory} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'
import {
  FIREBASE_ACTION_CODE,
  FIREBASE_EMAIL,
  FIREBASE_MODE,
} from 'lib/firebase/auth'
import {MessageProps} from 'types'
import {getParameterByName} from 'utils/url'

import Loader from 'components/Loader'
import Toast from 'components/Toast'

/**
 * Handle redirection to
 * - reset password
 * - email verification
 */
const EmailRedirection: React.FC = () => {
  const history = useHistory()

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  useEffect(() => {
    const mode = getParameterByName(FIREBASE_MODE)
    const actionCode = getParameterByName(FIREBASE_ACTION_CODE)
    const email = getParameterByName(FIREBASE_EMAIL)

    // Handle the user management action.
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        history.push(ROUTES.PASSWORD_RESET_NEW, {actionCode})
        break
      case 'verifyEmail':
        // Display email verification handler and UI.
        history.push(ROUTES.EMAIL_VERIFICATION, {actionCode, email})
        break
      case 'teaserEmail':
        // Teaser Page
        // TODO: pass slug + id history.push(routes.TEASER, {id, slug})
        history.push(ROUTES.TEASER)
        break
      default:
        // Error: invalid mode.
        setToast({
          value: 'Something happened. Please try again',
          type: 'error',
          redirect: ROUTES.HOME,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Toast message={toast} action={setToast} />

      <Loader />
    </Fragment>
  )
}

export default EmailRedirection
