import styled from 'styled-components'
import Colors from 'styles/colors'
import Constants from 'styles/constants'

export const DropDownCardContainer = styled.div`
  position: absolute;
  right: 1em;
  color: ${Colors.PRIMARY};
  border-radius: ${Constants.BORDER_RADIUS};
  z-index: 5;
  border: 1px solid ${Colors.PRIMARY_25};

  ul {
    padding: 0 0.5em;
    list-style: none;
    margin: 0.5em;
    white-space: nowrap;
  }

  li {
    cursor: pointer;
    &:hover {
      color: ${Colors.PRIMARY_25};
    }
  }
`
