import React, {useEffect} from 'react'

import {useAuth} from 'hooks/useAuth'

import GridLayout, {Col} from 'components/styles/GridLayout'

import Welcome from './components/Welcome'
import Overview from './components/Overview'
import CompleteProfile from './components/CompleteProfile'
import YourDeals from './components/YourDeals'
import AvailableDeals from './components/AvailableDeals'
import Notifications from './components/Notifications'

interface UsersProps {
  updateRouter: (value: boolean) => void
}

const Users: React.FC<UsersProps> = ({updateRouter}) => {
  const {displayName, isAuthUserSelfCertified, hasAuthUserInterests} = useAuth()

  useEffect(() => {
    updateRouter(isAuthUserSelfCertified)
  }, [isAuthUserSelfCertified, updateRouter])

  return (
    <GridLayout>
      <Col>
        <Welcome
          displayName={displayName}
          certified={isAuthUserSelfCertified}
        />

        <Notifications />

        <YourDeals />
      </Col>

      <Col>
        <CompleteProfile
          hasInterests={hasAuthUserInterests}
          certified={isAuthUserSelfCertified}
        />

        <Overview />

        <AvailableDeals />
      </Col>
    </GridLayout>
  )
}

export default Users
