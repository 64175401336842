import React, {useState} from 'react'
import differenceInDays from 'date-fns/differenceInDays'
import addYears from 'date-fns/addYears'

import {useAuth} from 'hooks/useAuth'

import {CardProps} from 'components/Card'
import Badge from 'components/styles/Badge'

import {SelfCertificationCard} from './styles'

import CertificationStatus from './CertificationStatus'
import SelfCertificationFlow from './SelfCertificationFlow'

interface SelfCertificationProps {
  forceCertification?: boolean
  cardProps?: CardProps
}

const SelfCertification: React.FC<SelfCertificationProps> = ({
  forceCertification,
  cardProps,
}) => {
  const {selfCertifiedAs, lastCertifiedAt} = useAuth()

  const [showRenewUI, setShowRenewUI] = useState(false)
  const handleShowRenewUI = () => setShowRenewUI(true)
  const handleHideRenewUI = () => setShowRenewUI(false)

  const didUserEverCertify = Boolean(selfCertifiedAs && lastCertifiedAt)

  let mustRenewWithinDays = lastCertifiedAt
    ? differenceInDays(addYears(lastCertifiedAt.toDate(), 1), new Date())
    : 0
  if (mustRenewWithinDays < 0) {
    mustRenewWithinDays = 0
  }

  const showCertificationStatus =
    !forceCertification && didUserEverCertify && !showRenewUI

  return (
    <SelfCertificationCard
      title="Self Certification"
      titleRightComponent={
        <Badge
          status={
            mustRenewWithinDays < 31
              ? mustRenewWithinDays === 0
                ? 'error'
                : 'warning'
              : undefined
          }
        >
          {mustRenewWithinDays < 31
            ? mustRenewWithinDays === 0
              ? 'Please Renew'
              : 'Action Required Soon'
            : 'No Action Required'}
        </Badge>
      }
      padding
      {...cardProps}
    >
      {showCertificationStatus ? (
        // CERTIFICATION STATUS
        <CertificationStatus
          mustRenewWithinDays={mustRenewWithinDays}
          handleShowRenewUI={handleShowRenewUI}
        />
      ) : (
        // SELF-CERTIFY FLOW
        <SelfCertificationFlow
          showRenewUI={showRenewUI}
          handleHideRenewUI={handleHideRenewUI}
        />
      )}
    </SelfCertificationCard>
  )
}

export default SelfCertification
