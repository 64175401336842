import React, {Fragment, useState} from 'react'
import {ChevronUp, ChevronDown} from 'react-ikonate'
import {useQuery} from 'react-query'

import {Deal} from 'types'
import {getYourDeals} from 'services/deals'
import {useAuth} from 'hooks/useAuth'

import {Skeleton} from '@material-ui/lab'

import DealDetails from 'components/DealDetails'

import Empty from '../Empty'
import {DealListCard, ExpandButton} from './styles'

const YourDeals: React.FC = () => {
  const {user} = useAuth()
  const userId = user?.uid ?? ''

  const [expanded, setExpanded] = useState(false)
  const handleToggleExpanded = () => setExpanded(prev => !prev)

  const {data: yourDeals = [], status} = useQuery<Deal[], Error>(
    ['your-deals', userId],
    () => getYourDeals(userId),
    {
      enabled: Boolean(userId),
    }
  )

  const enableInteractions = status !== 'loading'

  return (
    <DealListCard
      title={'Your Deals'}
      padding
      bottomComponent={
        Array.isArray(yourDeals) && yourDeals.length > 1 ? (
          <ExpandButton
            styleless
            flex
            onClick={handleToggleExpanded}
            disabled={!enableInteractions}
          >
            {expanded ? 'Collapse section' : 'Expand section'}

            {expanded ? (
              <ChevronUp
                style={{
                  marginLeft: 6,
                }}
                fontSize="1.25rem"
                strokeWidth={3}
              />
            ) : (
              <ChevronDown
                style={{
                  marginLeft: 6,
                }}
                fontSize="1.25rem"
                strokeWidth={3}
              />
            )}
          </ExpandButton>
        ) : null
      }
    >
      {status === 'loading' ? (
        <Skeleton variant="rect" width="100%" height={357} />
      ) : (
        <Fragment>
          {Array.isArray(yourDeals) && yourDeals.length > 0 ? (
            yourDeals
              .sort((a, b) => {
                if (a.status === 'CLOSED') {
                  return 1
                } else if (b.status === 'CLOSED') {
                  return -1
                } else {
                  return 0
                }
              })
              .filter((_, index) => {
                if (expanded) {
                  return true
                }
                return index === 0
              })
              .map(deal => (
                <DealDetails
                  key={deal.id}
                  deal={deal}
                  showNotInterestedOption
                  showDealImage
                />
              ))
          ) : (
            <Empty />
          )}
        </Fragment>
      )}
    </DealListCard>
  )
}

export default YourDeals
