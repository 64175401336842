import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'

export const Container = styled.div`
  position: relative;
  height: 100%;

  .body {
    background-color: white;
    padding: 20px 40px 50px 40px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 488px;
    margin: 0;
    box-shadow: 0px 12px 15px 2px rgba(22, 28, 45, 0.1);

    @media (max-width: ${Breakpoints.sm}) {
      padding: 20px 15px 50px 15px;
      width: 90%;
    }
  }
`
