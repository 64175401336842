import {useEffect, useState} from 'react'
import {convertFromRaw, EditorState} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'

import {generateNda} from 'services/deal-interests'
import {selfCertifyUser} from 'services/users'
import {getContentRawNDA, getDeal} from 'services/deals'
import {useAuth} from 'hooks/useAuth'
import {getTodayDateFull} from 'utils/date'
import {downloadFileFromBlob} from 'utils/blob'
import {
  getAddressBlock,
  getSignatureBlock,
  replacePlaceholders,
} from 'utils/draftjs'
import {generateSigner} from 'utils/nda'
import {downloadFileFromUrl} from 'services/files'

const PLACEHOLDERS_NDA = [
  '{{name}}',
  '{{address}}',
  '{{signature}}',
  `{{date}}`,
]

type LOADING_REASON =
  | 'GENERATING_NDA_PDF'
  | 'GENERATING_NDA_EDITABLE'
  | 'GENERATE_DOCUSIGN_NDA_URL'
  | 'GENERATE_DOCUSIGN_SELF_CERTIFICATION_URL'
  | ''

const useGenerateNDA = (
  dealId: string,
  dealName: string,
  dealInterestId?: string
) => {
  const {user} = useAuth()

  const [loading, setLoading] = useState(true)
  const [loadingReason, setLoadingReason] = useState<LOADING_REASON>('')
  const [error, setError] = useState('')
  const [editor, setEditor] = useState<EditorState | null>(null)

  useEffect(() => {
    const getNDA = async () => {
      if (!dealId) {
        return
      }

      try {
        const nda = await getContentRawNDA(dealId)
        const editorState = EditorState.createWithContent(convertFromRaw(nda))

        setEditor(editorState)
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    getNDA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading) {
      setLoadingReason('')
    }
  }, [loading])

  /**
   * Generate NDA by updating the placeholders with the form's values
   *
   * @param values - form values
   * @returns NDA editor state with user's values
   */
  const generateNDA = (values?: any) => {
    if (values && editor) {
      const {
        isCompany = false,
        firstName = '',
        lastName = '',
        companyName = '',
      } = values

      const nameBlock = `${firstName} ${lastName}`

      const addressBlock = getAddressBlock({
        ...values,
        firstName,
        lastName,
      })
      const signatureBlock = getSignatureBlock({
        firstName,
        lastName,
        isCompany,
        companyName,
      })

      const placeholders = [
        {label: PLACEHOLDERS_NDA[0], value: nameBlock},
        {label: PLACEHOLDERS_NDA[1], value: addressBlock},
        {label: PLACEHOLDERS_NDA[2], value: signatureBlock},
        {label: PLACEHOLDERS_NDA[3], value: getTodayDateFull()},
      ]

      return replacePlaceholders(editor, placeholders)
    }

    return null
  }

  const downloadNdaPdf = async (values?: any) => {
    setLoading(true)
    setLoadingReason('GENERATING_NDA_PDF')

    try {
      if (!dealInterestId) {
        throw new Error('Cannot sign NDA without a deal interest')
      }
      if (!values) {
        throw new Error('Could not generate signer data')
      }
      if (!editor) {
        throw new Error('NDA document not found')
      }

      const editorState = generateNDA(values)
      if (!editorState) {
        throw new Error('Could not generate NDA document')
      }
      const html = stateToHTML(editorState.getCurrentContent())

      const signer = generateSigner(values)

      const blob = await generateNda(dealInterestId, {
        isNDADownloadRequest: true,
        html,
        signer,
        selfCertifyAs: values.certification ? values.certification : undefined,
      })

      const fileName = dealName?.toLocaleLowerCase()?.replace(/ /g, '-')
      downloadFileFromBlob(blob, {
        fileName: `${fileName}-NDA.pdf`,
        blobType: 'application/pdf',
      })
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const downloadEditableNda = async () => {
    setLoading(true)
    setLoadingReason('GENERATING_NDA_EDITABLE')

    try {
      if (!dealId) {
        throw new Error('Cannot sign NDA without a deal interest')
      }

      const deal = await getDeal(dealId)

      const {ndaFileDocument} = deal
      if (!ndaFileDocument || !ndaFileDocument.url) {
        return
      }

      const fileName = dealName?.toLocaleLowerCase()?.replace(/ /g, '-')

      await downloadFileFromUrl(ndaFileDocument.url, {
        fileName: `${fileName}-NDA.doc`,
        blobType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      })
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const generateNdaDocusignUrl = async (values?: any) => {
    setLoading(true)
    setLoadingReason('GENERATE_DOCUSIGN_NDA_URL')

    try {
      if (!dealInterestId) {
        throw new Error('Cannot sign NDA without a deal interest')
      }
      if (!values) {
        throw new Error('Could not generate signer data')
      }
      if (!editor) {
        throw new Error('NDA document not found')
      }

      const editorState = generateNDA(values)
      if (!editorState) {
        throw new Error('Could not generate NDA document')
      }
      const html = stateToHTML(editorState.getCurrentContent())

      const signer = generateSigner(values)

      const response = await generateNda(dealInterestId, {
        html,
        signer,
        selfCertifyAs: values.certification ? values.certification : undefined,
      })

      if (response.url) {
        // redirect user to DocuSign to complete sign nda & self-certification
        window.location.replace(response.url)
      } else {
        // no need to redirect to DocuSign
        window.location.reload()
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const generateSelfCertificationDocusignUrl = async (values: any) => {
    setLoading(true)
    setLoadingReason('GENERATE_DOCUSIGN_SELF_CERTIFICATION_URL')

    try {
      if (!user || !user.uid) {
        throw new Error('You must be logged in to self-certificate')
      }
      if (!values || !values.certification) {
        throw new Error('Could not determine self-certification type')
      }

      const response = await selfCertifyUser(user.uid, values.certification)

      if (response.url) {
        // redirect user to DocuSign to complete self-certification
        window.location.replace(response.url)
      } else {
        // no need to redirect to DocuSign
        window.location.reload()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    loadingReason,
    error,
    generateNDA,
    downloadNdaPdf,
    generateNdaDocusignUrl,
    generateSelfCertificationDocusignUrl,
    downloadEditableNda,
  }
}

export default useGenerateNDA
