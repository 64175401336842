import styled from 'styled-components'

import Colors from 'styles/colors'

export interface LabelProps {
  type: 'accept' | 'reject'
  checked?: boolean
  disabled?: boolean
  borderRadiusLeft?: boolean
  borderRadiusRight?: boolean
}

export const Label = styled.label<LabelProps>`
  padding: 8px 17px;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};

  background-color: ${({checked}) => (checked ? Colors.BLUE : Colors.WHITE)};
  color: ${({checked}) => (checked ? Colors.WHITE : '#616161')};

  font-size: 16px;
  line-height: 28px;

  border-top-left-radius: ${({borderRadiusLeft}) =>
    borderRadiusLeft ? '6px' : '0px'};
  border-bottom-left-radius: ${({borderRadiusLeft}) =>
    borderRadiusLeft ? '6px' : '0px'};

  border-top-right-radius: ${({borderRadiusRight}) =>
    borderRadiusRight ? '6px' : '0px'};
  border-bottom-right-radius: ${({borderRadiusRight}) =>
    borderRadiusRight ? '6px' : '0px'};

  border: 1px solid ${Colors.LIGHT_GREY};
`
