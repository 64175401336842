import React, {useState, Fragment} from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {ChevronDown, ChevronRight} from 'react-ikonate'

import {CertificationType, MessageProps} from 'types'
import {selfCertifyUser} from 'services/users'
import {
  CERTIFICATIONS,
  CERTIFICATIONS_TO_STRING,
} from 'config/data/certifications.config'
import {useAuth} from 'hooks/useAuth'

import Checkbox from 'components/Checkbox'
import Button from 'components/styles/Button'
import Toast from 'components/Toast'

import CertificationStatement from '../CertificationStatement'

interface SelfCertificationFlowProps {
  showRenewUI?: boolean
  handleHideRenewUI: () => void
}

const SelfCertificationFlow: React.FC<SelfCertificationFlowProps> = ({
  showRenewUI,
  handleHideRenewUI,
}) => {
  const {user} = useAuth()

  const [selectedCertificationType, setSelectedCertificationType] =
    useState<CertificationType>('')

  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  const handleSelfCertification = async () => {
    if (!user || !user.uid) {
      setToast({
        value:
          'Error: could not complete self-certification, user account not found',
        type: 'error',
      })
      return
    }

    setLoading(true)
    try {
      const response = await selfCertifyUser(
        user?.uid,
        selectedCertificationType
      )

      if (response.url) {
        // redirect user to DocuSign to complete self-certification
        window.location.replace(response.url)
      } else {
        // no need to redirect to DocuSign
        window.location.reload()
      }
    } catch (error) {
      setToast({
        value:
          error.response?.data?.title ??
          'Error: could not complete self-certification, please try again',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <p className="text-lg grey">
        Please confirm your classification status. You may be redirected to
        DocuSign to complete your self-certification.
      </p>

      {Object.values(CERTIFICATIONS).map(certificationType => (
        <div className="certification-types">
          <Checkbox
            name="certification"
            label={CERTIFICATIONS_TO_STRING[certificationType]}
            value={certificationType}
            checked={selectedCertificationType === certificationType}
            onClick={() => {
              if (selectedCertificationType === certificationType) {
                setSelectedCertificationType('')
              } else {
                setSelectedCertificationType(certificationType)
              }
            }}
            classes={{
              container: 'certification-type',
            }}
            disabled={loading}
          />

          <div className="certification-type-chevron">
            {selectedCertificationType &&
            selectedCertificationType === certificationType ? (
              <ChevronDown
                style={{
                  marginLeft: 6,
                }}
                fontSize="1.25rem"
                strokeWidth={3}
              />
            ) : (
              <ChevronRight
                style={{
                  marginLeft: 6,
                }}
                fontSize="1.25rem"
                strokeWidth={3}
              />
            )}
          </div>

          {selectedCertificationType &&
          selectedCertificationType === certificationType ? (
            <div className="certification-type-statement">
              <PerfectScrollbar>
                <div className="certification-type-statement-inner">
                  <CertificationStatement
                    certificationType={certificationType}
                  />
                </div>
              </PerfectScrollbar>
            </div>
          ) : null}
        </div>
      ))}

      <div className="actions">
        <Button
          onClick={handleSelfCertification}
          disabled={!selectedCertificationType || loading}
        >
          {selectedCertificationType
            ? `Certify as ${CERTIFICATIONS_TO_STRING[selectedCertificationType]}`
            : `Select a certification type`}
        </Button>

        {showRenewUI ? (
          <Button
            onClick={handleHideRenewUI}
            className="cancel-process"
            disabled={loading}
            secondary
          >
            Cancel renew process
          </Button>
        ) : null}
      </div>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default SelfCertificationFlow
