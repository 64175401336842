export const CERTIFICATIONS = Object.freeze({
  // DOCUSIGN
  HIGH_NET_WORTH: 'HIGH_NET_WORTH' as const,
  SELF_CERTIFIED_SOPHISTICATED: 'SELF_CERTIFIED_SOPHISTICATED' as const,
  // NO DOCUSIGN
  HIGH_NET_WORTH_COMPANY: 'HIGH_NET_WORTH_COMPANY' as const,
  SOPHISTICATED: 'SOPHISTICATED' as const,
  INVESTMENT_PROFESSIONAL: 'INVESTMENT_PROFESSIONAL' as const,
})

export const CERTIFICATIONS_TO_STRING = Object.freeze({
  HIGH_NET_WORTH: 'Certified High Net Worth Individual' as const,
  SELF_CERTIFIED_SOPHISTICATED:
    'Self-certificated sophisticated investor' as const,
  HIGH_NET_WORTH_COMPANY:
    'High net worth company, unincorporated association or trust' as const,
  SOPHISTICATED: 'Certified sophisticated investor' as const,
  INVESTMENT_PROFESSIONAL: 'Investment Professional' as const,
})
