import React, {Fragment} from 'react'

import Badge from 'components/styles/Badge'
import Button from 'components/styles/Button'

import {CERTIFICATIONS_TO_STRING} from 'config/data/certifications.config'
import {useAuth} from 'hooks/useAuth'

interface CertificationStatusProps {
  mustRenewWithinDays: number
  handleShowRenewUI: () => void
}

const CertificationStatus: React.FC<CertificationStatusProps> = ({
  mustRenewWithinDays,
  handleShowRenewUI,
}) => {
  const {selfCertifiedAs} = useAuth()

  return (
    <Fragment>
      <p className="grey">
        Certifications must be renewed every 365 days, and is required in order
        to access deals.
      </p>

      {mustRenewWithinDays > 0 ? (
        <Fragment>
          {selfCertifiedAs ? (
            <p>
              You are certified as{' '}
              <Badge>{CERTIFICATIONS_TO_STRING[selfCertifiedAs]}</Badge>
            </p>
          ) : null}

          <p>
            You must renew this certification within{' '}
            <Badge
              status={mustRenewWithinDays < 31 ? 'in_progress' : undefined}
            >
              {mustRenewWithinDays}
            </Badge>{' '}
            days.
          </p>
        </Fragment>
      ) : (
        <p>
          Your certification has expired, please renew it via the button below.
        </p>
      )}

      <Button onClick={handleShowRenewUI}>Renew Certification</Button>
    </Fragment>
  )
}

export default CertificationStatus
