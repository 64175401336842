import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import {autoScrollTop} from 'utils/pages'

import ContentContainer from 'components/ContentContainer'
import Button from 'components/styles/Button'

const Disclaimer: React.FC = () => {
  const history = useHistory()

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    autoScrollTop()
  }, [])

  return (
    <ContentContainer>
      <Button onClick={handleGoBack}>Back</Button>

      <p
        className="text-12 grey justify"
        style={{
          marginTop: '4em',
        }}
      >
        The material contained in this website (Material) is for information
        purposes only. This Material does not constitute a prospectus and is not
        intended as an offer or solicitation for the purchase or subscription of
        any security or any other action nor shall the Material (or any part of
        it), or the fact of its distribution, form the basis of, or be relied on
        in connection with or act as any inducement to enter into, any contract
        whatsoever relating to any securities. This website and the Material are
        not transferable and may not be distributed or published or reproduced,
        in whole or in part, in the press or elsewhere without the prior written
        permission of Noble & Company (UK) Limited. The Material is general
        information intended for recipients that understand the risks associated
        with such investments and who should conduct their own investigation,
        evaluation and analysis of the matters contained herein. Furthermore, it
        does not take account of whether an investment, course of action, or
        associated risks are suitable for the recipient nor should it be
        considered as a personalised recommendation. Neither Noble & Company
        (UK) Limited nor any of its associates or their respective directors,
        officers, employees, agents or advisers provides any legal, investment,
        financial or tax advice in relation to the matters contained in this
        website. Clients of Noble & Company (UK) Limited and readers of this
        website are strongly encouraged to consult their own professional
        advisers before making any investment decision.
      </p>

      <p className="text-12 grey justify">
        Noble & Company (UK) Limited will not be responsible for providing the
        protections afforded to customers or for providing advice in relation to
        any matters contained in this website or the Materials. Noble & Company
        (UK) Limited do not conduct investment business in the United Kingdom
        and the United States of America with private customers and accordingly
        services and products mentioned or referred to in this website or the
        Materials are not available to such persons.
      </p>
      <p className="text-12 grey justify">
        The provision of investment services may be restricted in certain
        jurisdictions. Users of this website and the Materials are required to
        acquaint themselves with any local laws and restrictions on the usage of
        this website, the Materials and the availability of any services
        described therein. The information within this website and the Materials
        is not intended for distribution to or use by any person or entity in
        any jurisdiction or country where such distribution would be unlawful or
        otherwise contrary to local laws or regulation and, in particular, is
        not for distribution in or into Australia, Canada, Japan or the United
        States.
      </p>
      <p className="text-12 grey justify">
        Information within this website is based on sources that Noble & Company
        (UK) Limited believes to be reliable but Noble & Company (UK) Limited
        gives no express or implied undertaking, representation or warranty or
        other assurance that it is accurate or complete and Noble & Company (UK)
        Limited cannot and does not guarantee the accuracy, validity, fairness,
        timeliness or completeness of any information, opinions or data made
        available to recipients of this website and the Materials. Save in the
        case of fraud, neither Noble & Company (UK) Limited nor any of its
        associates or their respective directors, officers, employees, agents or
        advisers will be responsible or have any liability for any errors or
        omissions or for the results obtained from the use of such materials,
        information or opinions contained herein or for any loss or damage that
        could result from any information made available to recipients via this
        website or the Materials. The Materials are subject to change without
        notice and Noble & Company (UK) Limited is under no obligation to
        report, update or keep such information accurate. Accordingly, no
        reliance may be placed for any purpose whatsoever on the information or
        opinions contained in this website or the Materials or on the
        completeness, accuracy or fairness of such information and opinions.
      </p>
      <p className="text-12 grey justify">
        Nothing in this website or the Materials is, or should be relied on as,
        a promise or representation as to the future. This website and the
        Materials may include certain statements, estimates and projections
        provided by Noble & Company (UK) Limited. Such statements, estimates and
        projections are based on various assumptions made by Noble & Company
        (UK) Limited concerning anticipated results or events which may or may
        not prove to be correct. No undertakings, representations or warranties
        are made by Noble & Company (UK) Limited as to the accuracy of such
        statements, estimates or projections.
      </p>
      <p className="text-12 grey justify">
        This website is strictly personal to the recipient and is being
        distributed only to and directed only at persons in member states of the
        European Economic Area who are qualified investors within the meaning of
        article 2(1)(e) of the Prospectus Directive (Qualified Investors). In
        addition, in the United Kingdom, this website is being distributed only
        to and directed only at (A) certified high net worth individuals within
        the meaning of Article 48(2) of the Financial Services and Markets Act
        2000 (Financial Promotion) Order 2005 (Order) and (B) Qualified
        Investors: (i) who have professional experience in matters relating to
        investments falling within Article 19(5) of the Financial Services and
        Markets Act 2000 (Financial Promotion) Order 2005 (Order) (investment
        professionals); or (ii) who fall within Article 49(2)(a) to (d) of the
        Order (high net worth companies, unincorporated associations etc.) or
        within another exemption in the Order, (all such persons referred to
        above being Relevant Persons). The term "Prospectus Directive" means
        Directive 2003/71/EC and includes any relevant implementing measures in
        each member state of the European Economic Area. Any investment or
        investment activity to which this website relates is available only to
        Relevant Persons and will be engaged in only with Relevant Persons. By
        accepting receipt of this website, each recipient is deemed to confirm,
        represent and warrant to Noble & Company (UK) Limited that they are a
        Relevant Person.
      </p>
      <p className="text-12 grey justify">
        Neither Noble & Company (UK) Limited nor any of its associates or their
        respective directors, officers, employees, agents or advisers will be
        responsible for any unlawful distribution of this website to any third
        parties.
      </p>
      <p className="text-12 grey justify">
        By accepting the use of this website and the Materials, recipients agree
        to be bound by the above conditions and limitations.
      </p>
    </ContentContainer>
  )
}

export default Disclaimer
