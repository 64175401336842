import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

type Props = {
  shadow?: boolean
  padding?: boolean
}

const isPadding = (padding?: boolean) => {
  if (padding === undefined) {
    return true
  }
  return padding
}

const Container = styled.div<Props>`
  border-radius: 5px;

  background-color: ${Colors.WHITE};

  padding: ${({padding}) => (isPadding(padding) ? `1.5em` : 0)};
  box-shadow: ${({shadow}) =>
    shadow ? `0 8px 10px 0 rgba(0, 0, 0, 0.2)` : `none`};

  @media only screen and (max-width: ${Breakpoints.sm}) {
    max-width: 100%;

    padding: ${({padding}) => (isPadding(padding) ? `1.5em 0.5em` : 0)};
  }
`

export default Container
