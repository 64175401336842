import React, {Fragment, useState} from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

import {
  Container,
  MainButton,
  ToggleDropdownButton,
  Dropdown,
  DropdownButton as DropdownButtonElement,
} from './styles'

export interface MainButtonAction {
  label: string
  onClick: (...params: any) => any
  selected: boolean
  disabled?: boolean
}

export interface DropdownAction {
  title: string
  description?: string
  onClick: (...params: any) => any
  selected: boolean
  disabled?: boolean
}

export interface DropdownButtonProps {
  mainButtonAction: MainButtonAction
  mainButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>
  dropdownActions: DropdownAction[]
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  mainButtonAction,
  mainButtonProps,
  dropdownActions,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleChangeSubmitActionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleChangeSubmitActionClose = () => {
    setAnchorEl(null)
  }

  const isChangeSubmitActionPopoverOpen = Boolean(anchorEl)

  return (
    <Fragment>
      <Container>
        <MainButton
          {...mainButtonProps}
          disabled={Boolean(mainButtonAction?.disabled)}
        >
          {mainButtonAction?.label}
        </MainButton>

        <ToggleDropdownButton
          onClick={handleChangeSubmitActionClick}
          disabled={Boolean(mainButtonAction?.disabled)}
        >
          <KeyboardArrowDown />
        </ToggleDropdownButton>
      </Container>

      <Dropdown
        open={isChangeSubmitActionPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleChangeSubmitActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {dropdownActions.map(da => (
          <DropdownButtonElement
            key={da.title}
            onClick={e => {
              handleChangeSubmitActionClose()

              da.onClick(e)
            }}
            disabled={Boolean(da.selected || da.disabled)}
            selected={Boolean(da.selected)}
          >
            <div className="submit-popover-button-title">{da.title}</div>
            <div className="submit-popover-button-description">
              {da.description}
            </div>
          </DropdownButtonElement>
        ))}
      </Dropdown>
    </Fragment>
  )
}

export default DropdownButton
