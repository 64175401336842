import React, {useState} from 'react'
import {Eye, EyeCrossed, Close} from 'react-ikonate'

import {ErrorType} from 'types'

import {Container, ikonateStyles} from './styles'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  info?: string
  btnText?: string
  btnDisabled?: boolean
  error?: ErrorType
  onClick?: (value: any) => void
  onClear?: (event: React.ChangeEvent<HTMLInputElement>) => void
  showPassword?: boolean
  inputRef?:
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined
}

const Input = React.forwardRef(
  (
    {
      label,
      info,
      error,
      btnText,
      btnDisabled,
      showPassword,
      onClick,
      onClear,
      ...props
    }: InputProps,
    forwardedRef: React.LegacyRef<HTMLInputElement> | undefined
  ) => {
    const [hide, setHide] = useState(true) // Handle eye icon

    const handleShow = () => {
      if (props.disabled) return

      const {id} = props

      if (id) {
        let input = document?.getElementById(id) as HTMLInputElement

        if (input) {
          switch (input.type) {
            case 'password':
              input.type = 'text'
              break
            case 'text':
              input.type = 'password'
              break
            default:
          }

          setHide(!hide)
        }
      }
    }

    const EyeIcon = ({className}: {className: string}) =>
      hide ? (
        <Eye {...ikonateStyles} {...{className}} onClick={handleShow} />
      ) : (
        <EyeCrossed {...ikonateStyles} {...{className}} onClick={handleShow} />
      )

    const handleClick = () => {
      const {id} = props

      if (onClick) {
        const elem: any = id ? document?.getElementById(id) : null
        onClick(elem?.value)
      }
    }

    const hasButton = Boolean(btnText && onClick)

    return (
      <Container
        btn={hasButton}
        error={!!error?.status}
        icon={showPassword || !!onClear}
      >
        {/** Label and Input */}

        {label && (
          <label className="text-label" htmlFor={props.name}>
            {label}
          </label>
        )}

        <div className="input-wrapper">
          <input
            ref={forwardedRef}
            {...props}
            data-lpignore={showPassword || !!onClear}
          />

          {/** Optionals items: button or icons with actions */}

          {hasButton && (
            <button type="button" onClick={handleClick} disabled={btnDisabled}>
              {btnText}
            </button>
          )}

          {showPassword && <EyeIcon className="eye" />}

          {onClear && (
            <Close
              {...ikonateStyles}
              className="clear"
              onClick={() => {
                const event = {
                  target: {value: '', name: props.name},
                } as React.ChangeEvent<HTMLInputElement>
                onClear(event)
              }}
            />
          )}
        </div>

        {/** Error and Bottom info message optional */}

        {!error?.status && info && <label className="input-info">{info}</label>}

        {error?.status && error?.message && (
          <span className="error">{error.message}</span>
        )}
      </Container>
    )
  }
)

export default Input
