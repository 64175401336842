import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

import background from 'assets/images/background.jpg'

const AuthLayout = styled.div`
  background-image: ${`url(${background})`};
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;

  min-height: 100vh;

  .content {
    flex: 1 0 auto;
    width: auto;
    max-width: 700px;
  }

  .footer {
    flex-shrink: 0;
  }

  @media only screen and (max-width: ${Breakpoints.md}) {
    background-image: none;
    width: 100%;

    .content {
      border-radius: 0;
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
  }
`

export default AuthLayout
