import React from 'react'

import {useAuth} from 'hooks/useAuth'

import ContentContainer from 'components/ContentContainer'

import Users from './users'
import Admin from './admin'

interface DealsProps {
  setVerified: (value: boolean) => void
}

const Deals: React.FC<DealsProps> = ({setVerified}) => {
  const {isAdmin, isAuthUserSelfCertified: isCertification} = useAuth()

  // If on sign in certification is false but the value has been updated in between,
  // we need to update the value at the router level to allow some routes to be available
  const updateRouter = (value: boolean) => {
    if (!isCertification && value) {
      setVerified(value)
    }
  }

  return (
    <ContentContainer>
      {isAdmin ? <Admin /> : <Users {...{updateRouter}} />}
    </ContentContainer>
  )
}

export default Deals
