import * as Sentry from '@sentry/react'

import firebase from 'lib/firebase'
import axios from 'lib/axios'
import {EMAILS_API} from 'config/endpoints.config'

export const sendResetPasswordEmail = async (email: string) => {
  const response = await axios.post(`${EMAILS_API}?type=reset-password`, {
    to: email,
  })

  return response.data?.message
}

/**
 * For more details see doc:
 * https://firebase.google.com/docs/auth/custom-email-handler
 * @param newPassword - user new password
 * @param actionCode - code required to reset your password
 */
export const handleResetPassword = async (
  newPassword: string,
  actionCode: string
) => {
  try {
    const accountEmail = await firebase
      .auth()
      .verifyPasswordResetCode(actionCode)

    await firebase.auth().confirmPasswordReset(actionCode, newPassword)

    return accountEmail
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}
