import styled from 'styled-components'

import Colors from 'styles/colors'
import Constants from 'styles/constants'
import {DealStatus} from 'types'

const handleStatusColor = (status: DealStatus) => {
  switch (status?.toLocaleLowerCase()) {
    case 'draft':
      return Colors.DRAFT
    case 'published':
      return Colors.SUCCESS
    default:
      return Colors.PRIMARY
  }
}

export const StatusContainer = styled.div<{
  status: DealStatus
  small?: boolean
}>`
  border-radius: ${Constants.BORDER_RADIUS};
  background-color: ${Colors.WHITE};
  max-width: 80px;

  height: max-content;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5em 0;

  label {
    color: ${({status}) => handleStatusColor(status)};
    font-size: ${({small}) => (small ? '8pt' : '12pt')};
  }
`
