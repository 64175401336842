import React from 'react'
import {Column, useTable, usePagination, useSortBy} from 'react-table'
import clsx from 'clsx'

import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import {IconButton} from '@material-ui/core'

import {TableContainer, Dots} from './styles'

export interface TableProps<Data extends Record<string, any>> {
  columns: Column<Data>[]
  data: Data[]
  showPerPage?: number
}

function MorePagesIndicator() {
  return (
    <Dots>
      <span />
      <span />
      <span />
    </Dots>
  )
}

function Table<Data extends Record<string, any>>(props: TableProps<Data>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // pagination
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: {pageIndex},
    pageOptions,
    gotoPage,
  } = useTable<Data>(
    {
      columns: props.columns,
      data: props.data,
      initialState: {
        pageIndex: 0,
        pageSize: props.showPerPage ?? 10,
      },
    },
    useSortBy,
    usePagination
  )

  if (
    !Array.isArray(props.columns) ||
    !Array.isArray(props.data) ||
    props.columns.length < 1 ||
    props.data.length < 1
  ) {
    return null
  }

  return (
    <TableContainer>
      <div className="outer">
        <div className="inner">
          <div className="inner-inner">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <span className="column-inner">
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDropDown
                                fontSize="small"
                                className="sortIcon"
                              />
                            ) : (
                              <ArrowDropUp
                                fontSize="small"
                                className="sortIcon"
                              />
                            )
                          ) : null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)

                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={props.columns.length}>
                    <div className="pagination">
                      <IconButton
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className="goBackwardButton"
                        size="small"
                      >
                        <ChevronLeft />
                      </IconButton>

                      <div className="pages">
                        {/* goto first page button */}
                        <button
                          onClick={() => gotoPage(0)}
                          disabled={pageIndex === 0}
                          className={clsx(pageIndex === 0 && 'active')}
                        >
                          {1}
                        </button>

                        {/* shows if there are unshown buttons between page buttons */}
                        {pageIndex - 3 > 0 && <MorePagesIndicator />}

                        {/* goto buttons (except goto first page and goto last page) */}
                        {pageOptions.map(pageOption => {
                          const displayOption =
                            Math.abs(pageOption - pageIndex) < 3 &&
                            pageOption !== pageOptions.length - 1 &&
                            pageOption !== 0

                          if (!displayOption) {
                            return null
                          }

                          return (
                            <button
                              key={pageOption}
                              onClick={() => gotoPage(pageOption)}
                              disabled={pageIndex === pageOption}
                              className={clsx(
                                pageIndex === pageOption && 'active'
                              )}
                            >
                              {pageOption + 1}
                            </button>
                          )
                        })}

                        {/* shows if there are unshown buttons between page buttons */}
                        {pageOptions.length - 1 > pageIndex + 3 && (
                          <MorePagesIndicator />
                        )}

                        {/* goto last page button */}
                        {pageOptions.length > 1 && (
                          <button
                            onClick={() => gotoPage(pageOptions.length - 1)}
                            disabled={pageIndex === pageOptions.length - 1}
                            className={clsx(
                              pageIndex === pageOptions.length - 1 && 'active'
                            )}
                          >
                            {pageOptions.length}
                          </button>
                        )}
                      </div>

                      <IconButton
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className="goForwardButton"
                        size="small"
                      >
                        <ChevronRight />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </TableContainer>
  )
}

export default React.memo(Table) as typeof Table
