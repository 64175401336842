import React, {useEffect, useState, Fragment} from 'react'
import * as Sentry from '@sentry/react'
import {useHistory, Link} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'
import {useAuth} from 'hooks/useAuth'
import {signInWithEmailAndPassword, firebaseSignOut} from 'lib/firebase/auth'
import {FormLogin, MessageProps} from 'types'
import {
  errorAccountBlocked,
  errorVerifyEmail,
  errorWrongPassword,
  ERROR_CODE,
} from 'utils/errors'
import {autoScrollBottom} from 'utils/pages'

import GuestLayout from 'layouts/GuestLayout'
import Logo from 'components/Logo'
import Separator from 'components/styles/Separator'
import Toast from 'components/Toast'

import Form from './components/Form'

const Login: React.FC = () => {
  const {isAuth, isAuthUserSelfCertified: isCertification, error} = useAuth()

  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'info',
  })

  const onSubmit = async ({email, password}: FormLogin) => {
    setLoading(true)

    try {
      const userCreds = await signInWithEmailAndPassword(email, password)

      if (!userCreds.user?.emailVerified) {
        await firebaseSignOut()
        setToast({
          value: errorVerifyEmail,
          type: 'error',
        })
      }
    } catch (error) {
      Sentry.captureException(error)

      setLoading(false)

      let errorMsg: any = ''

      switch (error?.code) {
        case ERROR_CODE.WRONG_PASSWORD:
          errorMsg = errorWrongPassword
          break
        case ERROR_CODE.USER_DISABLED:
          errorMsg = errorAccountBlocked
          break
        case ERROR_CODE.USER_NOT_FOUND:
          errorMsg = (
            <Fragment>
              Sorry, no account was found under this email address. Please{' '}
              <Link className="link" to={ROUTES.REGISTER}>
                Register
              </Link>{' '}
              or{' '}
              <button className="link" onClick={autoScrollBottom}>
                Contact Us
              </button>
            </Fragment>
          )
          break
        default:
          errorMsg = error?.message
      }

      setToast({
        value: errorMsg,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    switch (isAuth) {
      case true:
        history.replace(ROUTES.HOME)
        break
      default:
        setLoading(false)
    }

    // Handle firebase auth error
    if (error) {
      setToast({value: error, type: 'error'})
    }
  }, [isCertification, isAuth, error, history])

  return (
    <GuestLayout>
      <Toast message={toast} action={setToast} duration={5000} />

      <div className="auth-container">
        <Logo size="big" />

        <h1 className="text-center">Sign In</h1>

        <Form {...{onSubmit, loading}} />

        <span className="text text-center">
          Forgotten your password?{' '}
          <Link className="link-router-dom" to={ROUTES.PASSWORD_RESET_EMAIL}>
            Reset
          </Link>
        </span>

        <Separator />

        <span className="text bold black text-center">
          {`Don't have an account? `}
          <Link className="link-router-dom" to={ROUTES.REGISTER}>
            Register Today
          </Link>
        </span>
      </div>
    </GuestLayout>
  )
}

export default Login
