import {format} from 'date-fns'
import firebase from 'firebase/app'

export const formatFirebaseDate = (
  date?: firebase.firestore.Timestamp | null
) => {
  if (!date) {
    return ''
  }

  try {
    let formattedDate
    if (date.seconds) {
      formattedDate = format(new Date(date.seconds * 1000), 'dd/MM/yy')
    } else {
      formattedDate = format(date.toDate(), 'dd/MM/yy')
    }

    return formattedDate
  } catch (_) {
    return ''
  }
}

// i.e Monday, 1 February 2021
export const getTodayDateFull = () => {
  const d = new Date()

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const date = d.getDate()
  const day = days[d.getDay()]
  const month = months[d.getMonth()]
  const year = d.getFullYear()

  return `${day}, ${date} ${month} ${year}`
}
