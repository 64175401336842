import React, {Fragment} from 'react'

const Statement2 = () => {
  return (
    <Fragment>
      <p className="text-12 grey justify">
        <u>
          <b style={{color: 'black'}}>
            Statements for Certified Sophisticated Investor
          </b>
        </u>
      </p>

      <p className="text-12 grey justify">
        <b style={{color: 'black'}}>
          Part I - Statement to be signed by the investor:
        </b>
      </p>

      <p className="text-12 grey justify">
        I make this statement so that I am able to receive promotions which are
        exempt from therestrictions on financial promotion in the Financial
        Services and Markets Act 2000. Theexemption relates to certified
        sophisticated investors and I declare that I qualify as such inrelation
        to investments of the following kind:investments in unquoted private
        companies andunquoted public companies. I accept that the contents of
        promotions and other material that Ireceive may not have been approved
        by an authorised person and that their content may nottherefore be
        subject to controls which would apply if the promotion were made or
        approved by anauthorised person. I am aware that it is open to me to
        seek advice from someone whospecialises in advising on this kind of
        investment.
      </p>

      <p className="text-12 grey justify">
        <b style={{color: 'black'}}>
          Part 2 - Certificate to be signed by an Authorised Person advising the
          investor:
        </b>
      </p>

      <p className="text-12 grey justify">
        I am an authorised person in terms of the Financial Services and Markets
        Act 2000 and herebycertify that the person who has signed the above
        statement (in Part I above) is a sophisticatedinvestor in terms of the
        Financial Services and Markets Act (Financial Promotions) Order 2005(as
        amended). Should you have any queries please do not hesitate to contact
        me.
      </p>
    </Fragment>
  )
}

export default Statement2
