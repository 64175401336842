import React from 'react'

import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

import {
  faCheckCircle,
  faArrowCircleRight,
  faPhone,
  faEye,
  faLockOpen,
  faLock,
} from '@fortawesome/free-solid-svg-icons'
import {
  faEnvelope,
  faQuestionCircle,
  faCalendarAlt,
  faFilePdf,
  faFileExcel,
  faFileVideo,
  faUser,
  faComments,
} from '@fortawesome/free-regular-svg-icons'
import {faCheck, faExclamation} from '@fortawesome/pro-regular-svg-icons'

export type IconName =
  | 'checkmark-circle'
  | 'arrow-circle-right'
  | 'phone'
  | 'envelope'
  | 'question-circle'
  | 'calendar'
  | 'eye-solid'
  | 'file-pdf'
  | 'file-excel'
  | 'file-video'
  | 'lock-close'
  | 'lock-open'
  | 'checkmark-regular'
  | 'exclamation-regular'
  | 'user-regular'
  | 'comments-regular'

type IconMap = {
  [key in IconName]: IconDefinition
}

const iconMap: IconMap = {
  'checkmark-circle': faCheckCircle,
  'arrow-circle-right': faArrowCircleRight,
  phone: faPhone,
  envelope: faEnvelope,
  'question-circle': faQuestionCircle,
  calendar: faCalendarAlt,
  'eye-solid': faEye,
  'file-pdf': faFilePdf,
  'file-excel': faFileExcel,
  'file-video': faFileVideo,
  'lock-open': faLockOpen,
  'lock-close': faLock,
  'checkmark-regular': faCheck,
  'exclamation-regular': faExclamation,
  'user-regular': faUser,
  'comments-regular': faComments,
}

export interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  name: IconName
}

const Icon: React.FC<IconProps> = ({name, ...rest}) => {
  return <FontAwesomeIcon {...rest} icon={iconMap[name]} />
}

export default Icon
