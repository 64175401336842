import React, {useState, memo, useRef, useEffect} from 'react'
import {makeStyles} from '@material-ui/core'
import {Document, Page} from 'react-pdf'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {Grid, Fab, LinearProgress, Tooltip} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'
import NavigateNext from '@material-ui/icons/NavigateNext'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import GetApp from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'

import Spinner from 'components/Spinner'

const isMobile = window.innerWidth < 700
const pagePerRender = 11

const createPages = (numPages: number, scale: number, page: number) => {
  const pages = []

  for (
    let i = page * pagePerRender + 1;
    i <= page * pagePerRender + pagePerRender && i <= numPages;
    i++
  ) {
    pages.push(
      <Page
        loading={<Spinner />}
        pageNumber={i}
        width={scale}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    )
  }

  return pages
}

interface Config {
  fullLoading: boolean
  scale: number
  pageNumber: number
  page: number
  minScale: number
  maxScale: number
  scalePoint: number
  numPages: number
}

interface ZoomNavigatorProps {
  setConfig: Function
  config: Config
}

const ZoomNavigator: React.FC<ZoomNavigatorProps> = ({config, setConfig}) => {
  let {minScale, maxScale} = config

  const scalePoint: number =
    typeof config.scalePoint === 'string'
      ? parseInt(config.scalePoint)
      : config.scalePoint
  const scale: number =
    typeof config.scale === 'string' ? parseInt(config.scale) : config.scale

  const handleScale = (type: 'max' | 'min') => () => {
    const shouldResize = type === 'max' ? scale < maxScale : scale > minScale
    const newScale = type === 'max' ? scale + scalePoint : scale - scalePoint

    if (shouldResize) {
      setConfig({...config, scale: newScale})
    }
  }

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="flex-end"
    >
      <Grid item>
        <Tooltip title="Zoom in" placement="left">
          <Fab
            disableRipple
            color="primary"
            aria-label="add"
            onClick={handleScale('max')}
          >
            <Add />
          </Fab>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title="Zoom out" placement="left">
          <Fab
            disableRipple
            color="primary"
            aria-label="add"
            onClick={handleScale('min')}
          >
            <Remove />
          </Fab>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

interface PagesNavigatorProps {
  fileOrBase64: string
  setConfig: Function
  config: Config
  onClose?: Function
  onDownload?: Function
  isDownloading?: boolean
}

const PagesNavigator: React.FC<PagesNavigatorProps> = ({
  fileOrBase64,
  config,
  setConfig,
  onClose,
  onDownload,
  isDownloading,
}) => {
  const classes = useStyles()

  const {fullLoading, numPages, page} = config
  const hasPagination = fullLoading && numPages > pagePerRender

  const handlePage = (type: 'previous' | 'next') => () => {
    const changePage =
      type === 'next' ? page < numPages / pagePerRender - 1 : page > 0
    const newPage = type === 'next' ? page + 1 : page - 1

    if (changePage) {
      setConfig({...config, page: newPage})
    }
  }

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.header}
      spacing={2}
    >
      {hasPagination && (
        <Grid item xs={12} md={6} className={classes.title}>
          {`Pages: ${page * pagePerRender + 1} - ${
            numPages <= pagePerRender
              ? numPages
              : (page + 1) * pagePerRender > numPages
              ? numPages
              : (page + 1) * pagePerRender
          } from ${numPages}`}
        </Grid>
      )}

      <Grid item xs={12} md={hasPagination ? 3 : 12}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.navigation}
        >
          {numPages > 10 && (
            <div>
              <Tooltip
                title={`Previous ${pagePerRender} pages`}
                placement="bottom"
              >
                <Fab disableRipple onClick={handlePage('previous')}>
                  <NavigateBefore />
                </Fab>
              </Tooltip>

              <Tooltip title={`Next ${pagePerRender} pages`} placement="bottom">
                <Fab disableRipple onClick={handlePage('next')}>
                  <NavigateNext />
                </Fab>
              </Tooltip>
            </div>
          )}

          {onDownload ? (
            <Tooltip title="Download the file" placement="bottom">
              <Fab
                disableRipple
                onClick={() => onDownload()}
                disabled={isDownloading}
              >
                {isDownloading ? <Spinner size="xs" white /> : <GetApp />}
              </Fab>
            </Tooltip>
          ) : null}

          {onClose ? (
            <Tooltip title="Close PDF Viewer" placement="bottom">
              <Fab
                disableRipple
                onClick={() => onClose()}
                className={classes.close}
              >
                <CloseIcon />
              </Fab>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export interface ReactPdfViewerProps {
  file: string
  onClose?: Function
  onDownload?: Function
  disableMargin?: boolean
  height?: number | string
  isDownloading?: boolean
}

const PDFViewer: React.FC<ReactPdfViewerProps> = ({
  file,
  onClose,
  onDownload,
  disableMargin,
  height,
  isDownloading,
}) => {
  const classes = useStyles({disableMargin, height})

  const scrollViewEl = useRef<HTMLDivElement | null>(null)

  const [config, setConfig] = useState<Config>({
    fullLoading: false,
    numPages: 0,
    scale: isMobile ? window.innerWidth - 50 : 700,
    pageNumber: 10,
    page: 0,
    minScale: 500,
    maxScale: window.innerWidth - 50,
    scalePoint: 40,
  })

  useEffect(() => {
    if (scrollViewEl && scrollViewEl.current && config.page > 0) {
      scrollViewEl?.current?.scrollIntoView(false)
    }
  }, [config.page])

  const onDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
    setConfig({...config, numPages, fullLoading: true})
  }

  const fileOrBase64 = file.toLowerCase().endsWith('.pdf')
    ? file
    : `data:application/pdf;base64,${file}`

  return (
    <div className={classes.root}>
      <PagesNavigator
        fileOrBase64={fileOrBase64}
        config={config}
        setConfig={setConfig}
        onClose={onClose}
        onDownload={onDownload}
        isDownloading={isDownloading}
      />

      <PerfectScrollbar className={classes.scrollbarContainer}>
        <div ref={scrollViewEl} className={classes.documentContainer}>
          <Document
            file={fileOrBase64}
            onLoadSuccess={onDocumentLoadSuccess}
            // @ts-ignore
            renderTextLayer={false}
            loading={<LinearProgress color="primary" />}
          >
            {createPages(config.numPages, config.scale, config.page).map(
              (page, index) => (
                <div key={`pdf-viewer-key-${index}`}>{page}</div>
              )
            )}
          </Document>
        </div>
      </PerfectScrollbar>

      {!isMobile && (
        <div className={classes.zoom}>
          <ZoomNavigator config={config} setConfig={setConfig} />
        </div>
      )}
    </div>
  )
}

type StyleProps =
  | {
      disableMargin?: boolean
      height?: number | string
    }
  | undefined

const useStyles = makeStyles(theme => ({
  root: (props: StyleProps) => ({
    position: 'relative',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.7)',
    overflow: 'hidden',
    marginBottom: props?.disableMargin ? 0 : theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: props?.disableMargin ? 0 : theme.spacing(4),
    },
    ...(props?.height
      ? {
          height: props.height,
        }
      : null),
  }),
  scrollbarContainer: (props: StyleProps) => ({
    ...(props?.disableMargin
      ? {
          height: 'calc(100vh - 52px)',
          [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 52px)',
          },
        }
      : null),
  }),
  documentContainer: {
    width: '100%',
    height: 'calc(100vh - 75px - 88px)', // top navigation and pdf viewer header
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 75px - 52px)',
    },
    '& canvas': {
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.7) !important',
      margin: '10px auto !important',
    },
  },
  title: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  navigation: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  header: {
    width: '100%',
    background: 'rgb(50, 54, 57)',
    boxShadow:
      '0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)',
    color: '#fff',
    top: 0,
    margin: 0,
    zIndex: 1,
    padding: '0px 15px',
    '& a': {
      textDecoration: 'none !important',
    },
    '& button': {
      width: 25,
      height: 25,
      color: '#fff !important',
      margin: '0 5px',
      background: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        background: 'transparent !important',
        color: '#fff !important',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: 'transparent !important',
        color: '#fff !important',
        boxShadow: 'none',
      },
      '& .MuiIcon-root': {
        width: '20px !important',
      },
    },
  },
  zoom: {
    position: 'absolute',
    right: 30,
    bottom: 30,
    '& button': {
      width: 36,
      height: 36,
      marginBottom: 10,
      background: '#fff',
      color: 'rgb(97, 97, 97)',
      '&:hover': {
        width: 36,
        height: 36,
        marginBottom: 10,
        background: '#fff',
        color: 'rgb(97, 97, 97)',
      },
      '&:disable': {
        width: 36,
        height: 36,
        marginBottom: 10,
      },
    },
  },
  close: {
    marginLeft: `${theme.spacing(4)}px !important`,
  },
}))

export default memo(PDFViewer)
