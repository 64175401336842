import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (min-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${Breakpoints.lg}) {
    grid-gap: 2rem;
  }
`

export const Col = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row-gap: 1rem;

  @media (min-width: ${Breakpoints.lg}) {
    grid-row-gap: 2rem;
  }
`

export default GridLayout
