import React from 'react'
import {useFormContext} from 'react-hook-form'

import Card from 'components/Card'
import Checkbox from 'components/Checkbox'

import {CardContent} from './styles'

interface DealSettingsProps {}

const DealSettings: React.FC<DealSettingsProps> = () => {
  const {register} = useFormContext()

  return (
    <Card
      padding
      title="Deal Settings"
      subtitle="Configure what should be shown and to whom."
    >
      <CardContent>
        <Checkbox
          label="Anonymous Deal"
          description="Some information is hidden on the deal card by default"
          name="isAnoynamousDeal"
          defaultValue={true as any}
          ref={register}
        />

        <Checkbox
          label="Private Deal"
          description="Deal will only be visible to invited users. Will not appear in Open Deals for other users"
          name="isPrivate"
          ref={register}
        />
      </CardContent>
    </Card>
  )
}

export default DealSettings
