import React from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'

import {
  DATAROOM_STATUS,
  DEAL_INTEREST_STATUS,
  DEAL_INTEREST_STATUS_TO_STRING,
} from 'config/data/deal-interest.config'
import {ROUTES} from 'config/routes.config'
import Colors from 'styles/colors'
import {useAuth} from 'hooks/useAuth'
import {DealInterest} from 'types'
import {formatFirebaseDate} from 'utils/date'

import {DealInterestStatusUI} from 'pages/User/components/UserDeals/styles'
import Icon from 'components/Icon'

export const AdminTable = styled.table`
  border: transparent;
  border-top: 0px;
  border-radius: 3px;
  background-color: ${Colors.WHITE};

  text-align: center;
  border-collapse: collapse;
  width: 100%;

  .sort-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  th {
    font-size: 16px;
    border-top: transparent;
    cursor: pointer;

    padding-top: 12px;
    padding-bottom: 12px;
    color: ${Colors.SECONDARY};

    > :not(:first-child) {
      text-align: center;
    }
  }
  th:first-child {
    width: 25%;
  }

  tr {
    border-bottom: 1px solid ${Colors.SECONDARY_50};
  }
  tr:last-child {
    border-bottom: none !important;
  }

  td {
    font-size: 16px;
  }
  td:first-child {
    width: 25%;
  }
`

type Props = {
  dealInterests: DealInterest[]
}

/**
 * Show admin table of all the deal interests existing
 * for this deal
 *
 * @returns Deal interests Table!
 */
const Admin = ({dealInterests}: Props) => {
  const {isAdmin} = useAuth()
  const history = useHistory()

  if (!isAdmin) return null

  const renderTableHeader = () => {
    return [
      'User',
      'Email',
      'Status',
      'Pending',
      'Date Interested',
      'View',
    ].map((hd, key) => (
      <th align={key <= 1 ? 'left' : 'center'} {...{key}}>
        {hd}
      </th>
    ))
  }

  const handleView = (slug: string) => {
    history.push(`${ROUTES.USER}/${slug}`)
  }

  const renderTableData = () => {
    return dealInterests.map(data => {
      const {id, user, createdAt, status, dataroomRequestStatus} = data
      const {id: userId, slug, firstName, lastName, email} = user ?? {}

      const statusStr = status ? DEAL_INTEREST_STATUS_TO_STRING[status] : ''

      const hasPendingRequest =
        status === DEAL_INTEREST_STATUS.NDA_REQUESTED ||
        status === DEAL_INTEREST_STATUS.DECK_REQUESTED ||
        dataroomRequestStatus === DATAROOM_STATUS.REQUESTED

      return (
        <tr key={id}>
          <td align="left">
            {firstName} {lastName}
          </td>

          <td align="left">{email}</td>

          <td>
            <DealInterestStatusUI>{statusStr}</DealInterestStatusUI>
          </td>

          <td className={hasPendingRequest ? 'pending' : ''}>
            {hasPendingRequest ? 'Yes' : 'No'}
          </td>

          <td>{formatFirebaseDate(createdAt)}</td>

          <td align="center">
            {userId && slug && (
              <Icon name="eye-solid" onClick={() => handleView(slug)} />
            )}
          </td>
        </tr>
      )
    })
  }

  return (
    <AdminTable id="table-deal-interests" cellSpacing="0" cellPadding="15">
      <tbody>
        <tr>{renderTableHeader()}</tr>
        {renderTableData()}
      </tbody>
    </AdminTable>
  )
}

export default Admin
