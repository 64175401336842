const Breakpoints = {
  xsm: '575.98px',
  sm: '767.98px',
  md: '991.98px',
  lg: '1199.98px',
  xl: '1399.98px',
  '2xl': '1499.98px',
}

export default Breakpoints
