import React from 'react'

import {StyledContentContainer} from './styles'

export interface ContentContainerProps {}

const ContentContainer: React.FC<ContentContainerProps> = ({children}) => {
  return <StyledContentContainer>{children}</StyledContentContainer>
}

export default ContentContainer
