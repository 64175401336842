import React, {Fragment} from 'react'
import {useHistory} from 'react-router-dom'
import {Cell} from 'react-table'

import {User} from 'types'
import {ROUTES} from 'config/routes.config'
import {CERTIFICATIONS} from 'config/data/certifications.config'
import {
  DATAROOM_STATUS,
  DEAL_INTEREST_STATUS,
} from 'config/data/deal-interest.config'
import {formatFirebaseDate} from 'utils/date'

import Table from 'components/Table'
import Badge from 'components/styles/Badge'
import {EyeIcon} from 'styles/icons'

import certificateIcon from 'assets/icons/file-certificate.png'

import {CertificationIcon} from './styles'

interface UsersTableProps {
  users: User[]
}

const UsersTable = ({users}: UsersTableProps) => {
  const history = useHistory()

  const getFPOCategorisation = (certification: string) => {
    switch (certification) {
      case CERTIFICATIONS.HIGH_NET_WORTH:
        return 'FPO48'
      case CERTIFICATIONS.SOPHISTICATED:
        return 'FPO50'
      case CERTIFICATIONS.SELF_CERTIFIED_SOPHISTICATED:
        return 'FPO50A'
      case CERTIFICATIONS.INVESTMENT_PROFESSIONAL:
        return 'FPO19'
      case CERTIFICATIONS.HIGH_NET_WORTH_COMPANY:
        return 'FPO49'
      default:
        return ''
    }
  }

  const handleView = (slug: string) => {
    history.push({
      pathname: `${ROUTES.USER}/${slug}`,
    })
  }

  if (!Array.isArray(users) || users.length < 1) {
    return <span className="inline-block my-32">No users found</span>
  }

  return (
    <Fragment>
      <Table
        columns={[
          {
            Header: 'Name',
            accessor: ({firstName, lastName}: User) =>
              `${firstName} ${lastName}`,
            Cell: (cell: Cell<User>) => {
              const {firstName, lastName, email} = cell.row.original

              return (
                <div>
                  {firstName || lastName ? (
                    <div className="bold black">
                      {`${firstName ?? ''} ${lastName ?? ''}`}
                    </div>
                  ) : null}

                  <div className="my-1">{email}</div>
                </div>
              )
            },
          },
          {
            Header: 'Details',
            accessor: 'company',
            Cell: (cell: Cell<User>) => {
              const {company, selfCertifiedAs} = cell.row.original

              return (
                <div>
                  <div className="black">{company}</div>

                  {selfCertifiedAs && (
                    <div className="dark-grey my-1 flex items-center">
                      <CertificationIcon
                        src={certificateIcon}
                        alt="certification"
                        width="16"
                        height="16"
                      />

                      {getFPOCategorisation(selfCertifiedAs ?? '')}
                    </div>
                  )}
                </div>
              )
            },
          },
          {
            Header: 'Deal(s)',
            accessor: ({dealInterests}: User) => dealInterests?.length ?? 0,
          },
          {
            Header: 'Pending deal(s)',
            accessor: ({dealInterests}: User) => {
              if (!dealInterests) {
                return 0
              }

              const totalDeckWithNDARequests =
                (dealInterests?.length > 0 &&
                  dealInterests.filter(
                    d => d.status === DEAL_INTEREST_STATUS.NDA_REQUESTED
                  ).length) ||
                0

              const totalDeckRequests =
                (dealInterests?.length > 0 &&
                  dealInterests.filter(
                    d => d.status === DEAL_INTEREST_STATUS.DECK_REQUESTED
                  ).length) ||
                0

              const totalDataroomRequests =
                (dealInterests?.length > 0 &&
                  dealInterests.filter(
                    d => d.dataroomRequestStatus === DATAROOM_STATUS.REQUESTED
                  ).length) ||
                0

              const totalPendingRequests =
                totalDeckRequests +
                totalDeckWithNDARequests +
                totalDataroomRequests

              return totalPendingRequests
            },
          },
          {
            Header: 'Last access',
            accessor: 'lastActiveAt',
            Cell: (cell: Cell<User>) => {
              const {lastActiveAt} = cell.row.original

              return (
                <Badge size="small" status="grey">
                  {formatFirebaseDate(lastActiveAt)}
                </Badge>
              )
            },
          },
          {
            id: 'view-user-column',
            disableSortBy: true,
            Cell: (cell: Cell<User>) => {
              const {id, slug} = cell.row.original

              return (
                <>
                  {id && slug && (
                    <EyeIcon size="small" onClick={() => handleView(slug)} />
                  )}
                </>
              )
            },
          },
        ]}
        data={users}
      />
    </Fragment>
  )
}

export default UsersTable
