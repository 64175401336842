import React, {Fragment} from 'react'

import Button from 'components/styles/Button'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'

type DeleteModalProps = {
  show: boolean
  email: string
  isArchived: boolean | undefined
  loading: boolean
  onDelete: () => void
  onClose: () => void
}

const DeleteModal = ({
  show,
  email,
  loading,
  isArchived,
  onDelete,
  onClose,
}: DeleteModalProps) => {
  return (
    <Modal isShowing={show} hide={onClose} maxWidth="600px">
      <Fragment>
        <h2>{isArchived ? 'Delete User' : 'Archive User'}</h2>
        <p className="modal-inner-body">
          {isArchived
            ? `Are you sure you want to delete ${email}?`
            : `Are you sure you want to archive ${email}?`}
        </p>
        <div className="modal-inner-btn">
          {!loading ? (
            <Fragment>
              <Button
                secondary
                className="modal-back-button"
                onClick={() => onClose()}
              >
                Go Back
              </Button>

              <Button onClick={onDelete} disabled={loading}>
                {isArchived ? 'Delete' : 'Archive'}
              </Button>
            </Fragment>
          ) : (
            <Spinner primary />
          )}
        </div>
      </Fragment>
    </Modal>
  )
}

export default DeleteModal
