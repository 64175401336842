import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'

import {COUNTRIES} from 'config/data/options.config'

import Card from 'components/Card'
import Button from 'components/styles/Button'
import Input from 'components/Input'
import Select from 'components/Select'

import {Form} from './styles'

interface DetailsProps {
  data: {
    firstName: string
    lastName: string
    company?: string
    country: string
    loading: boolean
  }
  onSubmit: (data: any) => void
}

const Details: React.FC<DetailsProps> = ({data, onSubmit}) => {
  const {loading, ...user} = data
  const defaultCountry = {value: user.country, label: user.country}

  const {register, handleSubmit, errors, control, reset} = useForm()

  useEffect(() => {
    if (!loading) {
      reset() // clear unsaved data
    }
  }, [loading, reset])

  return (
    <Card padding title="Your Details">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={loading} aria-busy={loading}>
          <div className="section">
            <Input
              label="First Name"
              name="firstName"
              placeholder="First name"
              type="text"
              error={{
                status: errors?.firstName,
                message: 'First Name is required',
              }}
              ref={register({
                required: true,
                maxLength: 80,
              })}
              defaultValue={user.firstName}
            />

            <Input
              label="Last Name"
              name="lastName"
              placeholder="Last Name"
              type="text"
              error={{
                status: errors.lastName,
                message: 'Last name is required',
              }}
              ref={register({
                required: true,
                maxLength: 100,
              })}
              defaultValue={user.lastName}
            />
          </div>

          <div className="section">
            <Input
              label="Company"
              name="company"
              placeholder="Company Name"
              type="text"
              error={{
                status: errors.company,
                message: 'Company name is required',
              }}
              ref={register({
                required: true,
              })}
              defaultValue={user.company}
            />

            <Controller
              control={control}
              rules={{required: true}}
              name="country"
              defaultValue={defaultCountry}
              render={props => (
                <Select
                  label="Country of Residence"
                  placeholder="Country"
                  defaultValue={defaultCountry}
                  options={COUNTRIES}
                  isSearchable
                  {...props}
                />
              )}
            />
          </div>

          <Button type="submit">
            {loading ? 'Please wait...' : 'Update Details'}
          </Button>
        </fieldset>
      </Form>
    </Card>
  )
}

export default Details
