import React, {useState, useEffect, Fragment} from 'react'
import {useForm} from 'react-hook-form'

import {MessageProps} from 'types'

import Button from 'components/styles/Button'
import Input from 'components/Input'
import Card from 'components/Card'
import Toast from 'components/Toast'

import {Form} from './styles'

export type PasswordForm = {
  currentPassword: string
  password: string
}

interface PasswordProps {
  loading: boolean
  onSubmit: (values: PasswordForm) => void
}

const Password: React.FC<PasswordProps> = ({loading, onSubmit}) => {
  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  const {register, handleSubmit, errors, reset} = useForm()

  useEffect(() => {
    if (!loading) {
      reset()
    }
  }, [loading, reset])

  return (
    <Fragment>
      <Card padding title="Your Details">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={loading} aria-busy={loading}>
            <Input
              id="password-old" // `id` needed for the showPassword
              name="currentPassword"
              label="Current Password"
              type="password"
              placeholder="****"
              autoComplete="password"
              error={{
                status: errors.currentPassword,
                message: 'This is not a valid password',
              }}
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 16,
              })}
              showPassword
            />
            <Input
              id="password-new" // `id` needed for the showPassword
              name="password"
              label="New Password"
              type="password"
              placeholder="****"
              autoComplete="password"
              error={{
                status: errors.password,
                message: 'This is not a valid password',
              }}
              ref={register({
                minLength: 8,
                maxLength: 16,
              })}
              showPassword
            />

            <Button type="submit">
              {loading ? 'Please wait...' : 'Update Password'}
            </Button>
          </fieldset>
        </Form>
      </Card>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default Password
