import React, {Fragment, useState} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import {useAuth} from 'hooks/useAuth'

import AuthLayout from 'layouts/AuthLayout'

import Register from 'pages/Auth/Register'
import Login from 'pages/Auth/Login'
import Forgotten from 'pages/Auth/Forgotten'
import Reset from 'pages/Auth/Reset'
import EmailRedirection from 'pages/Auth/EmailRedirection'
import EmailVerification from 'pages/Auth/EmailVerification'
import Teaser from 'pages/Teaser'
import Disclaimer from 'pages/Disclaimer'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import TermsOfUse from 'pages/TermsOfUse'

import UserSettings from 'pages/UserProfile'
import Users from 'pages/Users'
import User from 'pages/User'
import Deals from 'pages/Deals'
import CreateDeal from 'pages/CreateDeal'
import Deal from 'pages/Deal'
import Analytics from 'pages/Analytics'
import Deck from 'pages/Deck'

import PageNotFound from 'pages/NotFound'

import CookieBanner from 'components/CookieBanner'

const MainRoutes: React.FC = () => {
  const {isAuth, isAdmin, user, role, isAuthUserSelfCertified} = useAuth()

  const [verified, setVerified] = useState(isAuthUserSelfCertified || isAdmin)

  // Detect if the user has been partially created (i.e: Firebase User but no system user)
  const partialUser = Boolean(user && !role)

  /* Google Analytics */
  // https://raptis.wtf/blog/custom-hook-to-connect-google-analytics-in-react/
  useGoogleAnalytics()

  const redirectPath = isAuth
    ? isAdmin
      ? ROUTES.USERS
      : ROUTES.DEALS
    : ROUTES.LOGIN

  return (
    <Fragment>
      <CookieBanner />

      <Switch>
        <Route exact path={ROUTES.HOME}>
          <Redirect to={redirectPath} />
        </Route>

        {partialUser && (
          <Route exact path={[ROUTES.HOME, ROUTES.LOGIN]}>
            <Redirect to={ROUTES.REGISTER} />
          </Route>
        )}

        {/* GUEST ROUTES */}
        <Route path={ROUTES.REGISTER}>
          {isAuth ? (
            <Redirect to={ROUTES.HOME} />
          ) : (
            <Register partialUser={partialUser} />
          )}
        </Route>

        <Route path={ROUTES.LOGIN}>
          {isAuth ? <Redirect to={ROUTES.HOME} /> : <Login />}
        </Route>

        <Route path={ROUTES.PASSWORD_RESET_EMAIL}>
          {isAuth ? <Redirect to={ROUTES.HOME} /> : <Forgotten />}
        </Route>

        <Route path={ROUTES.PASSWORD_RESET_NEW}>
          {isAuth ? <Redirect to={ROUTES.HOME} /> : <Reset />}
        </Route>

        <Route path={ROUTES.EMAIL_REDIRECTION}>
          {isAuth ? <Redirect to={ROUTES.HOME} /> : <EmailRedirection />}
        </Route>

        <Route path={ROUTES.EMAIL_VERIFICATION}>
          {isAuth ? <Redirect to={ROUTES.HOME} /> : <EmailVerification />}
        </Route>

        <Route path={ROUTES.TEASER}>
          <AuthLayout>
            <Teaser />
          </AuthLayout>
        </Route>

        {/* --- */}

        <Route exact path={ROUTES.DISCLAIMER}>
          <AuthLayout>
            <Disclaimer />
          </AuthLayout>
        </Route>

        <Route exact path={ROUTES.PRIVACY_POLICY}>
          <AuthLayout>
            <PrivacyPolicy />
          </AuthLayout>
        </Route>

        <Route exact path={ROUTES.TERMS_OF_USE}>
          <AuthLayout>
            <TermsOfUse />
          </AuthLayout>
        </Route>
        {/* END OF GUEST ROUTES */}

        {/* PROTECTED ROUTES */}
        <Route path={ROUTES.USER_SETTINGS}>
          {isAuth && !isAdmin ? (
            <AuthLayout>
              <UserSettings />
            </AuthLayout>
          ) : (
            <Redirect to={ROUTES.HOME} />
          )}
        </Route>

        <Route exact path={[ROUTES.DEALS, ROUTES.DEALS_PARAM]}>
          {isAuth ? (
            <AuthLayout>
              <Deals setVerified={setVerified} />
            </AuthLayout>
          ) : (
            <Redirect to={ROUTES.HOME} />
          )}
        </Route>

        <Route exact path={ROUTES.DEAL_PARAM}>
          {isAuth ? (
            <AuthLayout>
              <Deal />
            </AuthLayout>
          ) : (
            <Redirect to={ROUTES.HOME} />
          )}
        </Route>

        {/* --- */}

        <Route exact path={[ROUTES.USERS, ROUTES.USERS_PARAM]}>
          {isAuth && isAdmin ? (
            <AuthLayout>
              <Users />
            </AuthLayout>
          ) : (
            <Redirect to={ROUTES.HOME} />
          )}
        </Route>

        <Route path={ROUTES.USER_PARAM}>
          {isAuth && isAdmin ? (
            <AuthLayout>
              <User />
            </AuthLayout>
          ) : (
            <Redirect to={ROUTES.HOME} />
          )}
        </Route>

        <Route path={[ROUTES.CREATE_DEAL_PARAM, ROUTES.CREATE_DEAL]}>
          {isAuth && isAdmin ? (
            <AuthLayout>
              <CreateDeal />
            </AuthLayout>
          ) : (
            <Redirect to={ROUTES.HOME} />
          )}
        </Route>

        <Route path={ROUTES.ANALYTICS}>
          {isAuth && isAdmin ? (
            <AuthLayout>
              <Analytics />
            </AuthLayout>
          ) : (
            <Redirect to={ROUTES.HOME} />
          )}
        </Route>

        <Route exact path={ROUTES.DECK}>
          {verified ? (
            <AuthLayout>
              <Deck />
            </AuthLayout>
          ) : (
            <Redirect to={ROUTES.HOME} />
          )}
        </Route>
        {/* END OF PROTECTED ROUTES */}

        <PageNotFound />
      </Switch>
    </Fragment>
  )
}

export default MainRoutes
