import React, {useState, Fragment} from 'react'
import {useHistory} from 'react-router-dom'
import {ArrowRightCircle} from 'react-ikonate'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import {useMutation} from 'react-query'
import * as yup from 'yup'
import {Controller, useForm} from 'react-hook-form'
import {AxiosError} from 'axios'

import imagePlaceholder from 'assets/images/placeholder.png'

import {Deal, MessageProps} from 'types'
import {ROUTES} from 'config/routes.config'
import {DISMISS_DEAL_FEEDBACK_OPTIONS} from 'config/data/options.config'
import {queryClient} from 'lib/react-query'
import {dismissDeal} from 'services/deals'
import {useAuth} from 'hooks/useAuth'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import {formatFirebaseDate} from 'utils/date'

import Badge from 'components/styles/Badge'
import Button from 'components/styles/Button'
import Modal from 'components/Modal'
import Toast from 'components/Toast'
import Spinner from 'components/Spinner'
import Checkbox from 'components/Checkbox'
import Textarea from 'components/styles/Textarea'

import {
  Details,
  Grid,
  DismissModalText,
  DismissModalButtons,
  DismissModalFeedback,
} from './styles'

const getStatus = (dealStatus: string) => {
  switch (dealStatus) {
    case 'draft':
      return 'Draft'
    case 'published':
      return 'Open'
    case 'closed':
      return 'Archived'
    default:
      return ''
  }
}

type FeedbackFormInputs = {
  feedbackItems: string[]
  otherFeedback: string
}

const feedbackFormSchema = yup.object().shape({
  feedbackItems: yup.array().of(yup.string()),
  otherFeedback: yup.string().max(300, 'Max allowed character limit is 300'),
})

export interface DealDetailsProps {
  deal: Deal
  neat?: boolean
  optionalSlug?: string
  showOverviewTitle?: boolean
  showDealImage?: boolean
  hideViewButton?: boolean
  showDismissOption?: boolean
  showNotInterestedOption?: boolean
}

const DealDetails: React.FC<DealDetailsProps> = ({
  deal,
  neat,
  optionalSlug,
  showOverviewTitle,
  showDealImage,
  hideViewButton,
  showDismissOption,
  showNotInterestedOption,
}) => {
  const {isAuth, dismissedDeals} = useAuth()
  const history = useHistory()

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  const [showDismissModal, setShowDismissModal] = useState(false)
  const handleDismissModalOpen = () => setShowDismissModal(true)
  const handleDismissModalClose = () => setShowDismissModal(false)

  const feedbackForm = useForm<FeedbackFormInputs>({
    resolver: useYupValidationResolver(feedbackFormSchema),
    defaultValues: {
      feedbackItems: [],
      otherFeedback: '',
    },
    shouldUnregister: false,
  })

  const dismissDealMutation = useMutation<Deal, AxiosError>(
    () => {
      const {feedbackItems, otherFeedback} = feedbackForm.getValues()

      return dismissDeal(deal?.id, {
        feedbackItems,
        otherFeedback,
      })
    },
    {
      onError: error => {
        setToast({
          value: error.response?.data?.title,
          type: 'error',
        })
      },
      onSuccess: () => {
        handleDismissModalClose()
        queryClient.invalidateQueries('your-deals')
        queryClient.invalidateQueries('overview-deals')
        queryClient.invalidateQueries('available-deals')
      },
    }
  )

  const handleDismissModalSubmit = async (_: FeedbackFormInputs) => {
    await dismissDealMutation.mutateAsync()
  }

  if (!deal) {
    return null
  }

  const {
    name,
    company,
    sector,
    type,
    description,
    status,
    investmentSize,
    publishedAt,
    slug,
    showDealDetails,
  } = deal

  const dealStatus = status?.toLocaleLowerCase()
  const disabledView = dealStatus === 'draft' || dealStatus === 'closed'
  const readableStatus = getStatus(dealStatus)

  const canUserViewDeal = isAuth && !neat && !hideViewButton
  const canUserDismissDeal =
    !dismissedDeals.includes(deal.id) &&
    canUserViewDeal &&
    (showDismissOption || showNotInterestedOption)

  return (
    <Fragment>
      <Details neat={neat}>
        {showOverviewTitle ? (
          <div className="overview">
            <h1 className="overview-title">Overview</h1>
          </div>
        ) : null}

        <div className="deal-info">
          {showDealImage ? (
            <img
              src={showDealDetails ? deal.featuredImage?.url : imagePlaceholder}
              alt={deal.name}
              className="image"
            />
          ) : null}

          <div className="header">
            <div className="name">
              <h2 className="deal-name">{name}</h2>
              <h3 className="grey deal-company">{company}</h3>
            </div>

            <Badge status={dealStatus}>{readableStatus}</Badge>
          </div>

          <div className="content">
            <div className="badges">
              {type ? <Badge>{type}</Badge> : null}
              {sector ? <Badge status="grey">{sector}</Badge> : null}
            </div>

            <p className="deal-content-description">{description}</p>

            <Grid>
              <div>
                <span className="text-base grey">Investment Size</span>
                <span className="bold black">{investmentSize}</span>
              </div>

              <div>
                <span className="text-base  grey">Deal Live</span>
                <span className="bold black">
                  {typeof publishedAt === 'string'
                    ? format(parseISO(publishedAt), 'dd/MM/yy')
                    : formatFirebaseDate(publishedAt)}
                </span>
              </div>

              {/* <div> */}
              {/* TODO: 'stage' we dont have it yet in the backend
            <span className="text-base  grey">Stage</span>
            <span className="bold black">{''}</span> */}
              {/* </div> */}
            </Grid>

            <div className="buttons">
              {canUserViewDeal && (
                <Button
                  disabled={disabledView}
                  onClick={() =>
                    history.push(`${ROUTES.DEAL}/${slug ?? optionalSlug}`)
                  }
                  flex
                >
                  View Deal
                  <ArrowRightCircle
                    style={{
                      marginLeft: 12,
                    }}
                    strokeWidth={3}
                    fontSize="1.25rem"
                  />
                </Button>
              )}

              {canUserDismissDeal && (
                <Button
                  secondary
                  disabled={disabledView}
                  onClick={handleDismissModalOpen}
                >
                  {showNotInterestedOption ? 'Not interested' : 'Dismiss'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Details>

      <Modal
        isShowing={showDismissModal}
        hide={handleDismissModalClose}
        title="Dismiss Deal"
        maxWidth="600px"
      >
        <DismissModalText>
          Dismissing this deal will remove it from the list of open deals.
          Please let us know why you don’t like about this opportunity:
        </DismissModalText>

        <form onSubmit={feedbackForm.handleSubmit(handleDismissModalSubmit)}>
          <fieldset
            disabled={feedbackForm.formState.isSubmitting}
            aria-disabled={feedbackForm.formState.isSubmitting}
            aria-busy={feedbackForm.formState.isSubmitting}
          >
            <DismissModalFeedback>
              <Controller
                control={feedbackForm.control}
                name="feedbackItems"
                render={props => (
                  <>
                    {DISMISS_DEAL_FEEDBACK_OPTIONS.map(option => (
                      <Checkbox
                        name={option.label}
                        label={option.label}
                        value={option.value}
                        checked={
                          Array.isArray(props.value) &&
                          props.value.includes(option.value)
                        }
                        onChange={e => {
                          if (!Array.isArray(props.value)) {
                            return
                          }

                          if (e.target.checked) {
                            props.onChange([...props.value, option.value])
                          } else {
                            props.onChange(
                              props.value.filter(val => val !== option.value)
                            )
                          }
                        }}
                      />
                    ))}
                  </>
                )}
              />

              <Controller
                control={feedbackForm.control}
                name="otherFeedback"
                render={props => (
                  <div className="dismiss-deal-feedback-other">
                    <label className="text-label" htmlFor="other-feedback">
                      Other
                    </label>
                    <Textarea
                      name="other-feedback"
                      placeholder="Provide any specific feedback here"
                      rows={3}
                      error={Boolean(feedbackForm.errors.otherFeedback)}
                      {...props}
                    />
                    {Boolean(feedbackForm.errors.otherFeedback) && (
                      <span className="error">
                        {feedbackForm.errors.otherFeedback?.message}
                      </span>
                    )}
                  </div>
                )}
              />
            </DismissModalFeedback>

            <DismissModalButtons>
              <Button
                secondary
                onClick={handleDismissModalClose}
                disabled={feedbackForm.formState.isSubmitting}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={feedbackForm.formState.isSubmitting}
                flex
              >
                Dismiss Deal
                {feedbackForm.formState.isSubmitting ? (
                  <span style={{marginLeft: 12}}>
                    <Spinner size="xs" margin="none" />
                  </span>
                ) : null}
              </Button>
            </DismissModalButtons>
          </fieldset>
        </form>
      </Modal>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default DealDetails
