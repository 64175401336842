import React, {useEffect, Fragment} from 'react'
import {useSpring, useTransition} from 'react-spring'
import {useHistory} from 'react-router-dom'

import {MessageProps} from 'types'
import {ErrorIcon, InfoIcon, SuccessIcon} from 'styles/icons'

import {Message, MessageHeader, Timer, ToastContainer} from './styles'

export interface ToastProps {
  action: React.Dispatch<any>
  message: MessageProps
  duration?: number
}

const Toast: React.FC<ToastProps> = ({
  message: {value = '', type = 'success', redirect, callback},
  action,
  duration = 4000,
}) => {
  const history = useHistory()

  const fade = useTransition(value, null, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })
  const timer = useSpring({
    from: {width: '100%'},
    to: {width: '0%'},
    config: {duration},
    onRest: () => {
      if (redirect) {
        const pushTimer = setTimeout(() => {
          history.push(redirect)
          clearTimeout(pushTimer)
        }, 2000)
      } else if (callback) {
        callback()
      }
    },
  })

  useEffect(() => {
    let timeoutToast = 0
    if (value) {
      timeoutToast = setTimeout(() => {
        action({value: '', type: ''})
      }, duration)
    }

    return () => {
      timeoutToast && clearTimeout(timeoutToast)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const SelectIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorIcon pos="absolute" />
      case 'info':
        return <InfoIcon pos="absolute" />
      case 'success':
        return <SuccessIcon pos="absolute" />

      default:
        return <p />
    }
  }

  return (
    <Fragment>
      {fade.map(
        ({item, key, props}) =>
          item && (
            <ToastContainer key={key} type={type} style={props}>
              <SelectIcon />
              <MessageHeader type={type}>{type}</MessageHeader>
              <Message>{value}</Message>
              <Timer style={timer} />
            </ToastContainer>
          )
      )}
    </Fragment>
  )
}

export default Toast
