import React from 'react'
import {useForm} from 'react-hook-form'

import Button from 'components/styles/Button'
import Input from 'components/Input'

interface FormProps {
  onSubmit: (data: any) => void
  loading?: boolean
}

const Form: React.FC<FormProps> = ({onSubmit, loading}) => {
  const {register, handleSubmit, errors} = useForm()

  return (
    <form className="app-form" onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={loading} aria-busy={loading}>
        <Input
          id="email-address"
          label="Email Address"
          type="email"
          placeholder="Email"
          error={{
            status: errors.email,
            message: 'This is not a valid email address',
          }}
          name="email"
          ref={register({
            required: true,
            pattern: /^\S+@\S+$/i,
          })}
        />

        <Input
          id="login-password"
          label=" Password"
          type="password"
          placeholder="Password"
          error={{
            status: errors.password,
            message: 'This is not a valid password',
          }}
          name="password"
          showPassword
          ref={register({
            required: true,
          })}
          autoComplete="Password"
        />

        <Button full type="submit" {...{loading}}>
          Sign In
        </Button>
      </fieldset>
    </form>
  )
}

export default Form
