import {useQuery} from 'react-query'

import {Deal} from 'types'
import {QUERY_STALE_TIME} from 'config/react-query.config'
import {useAuth} from 'hooks/useAuth'
import {getDealBySlug, getShowDealDetails} from 'services/deals'
import {hasDeckAccess} from 'services/deal-interests'

// export const defaultDeal: Partial<Deal> = {
//   id: '',
//   name: '',
//   company: '',
//   sector: '',
//   slug: '',
//   status: '',
//   featuredImage: {
//     url: '',
//     reference: '',
//     altText: '',
//   },
//   investmentSize: '',
//   publishedAt: null,
//   dealInterests: [],
//   teaserFile: undefined,
//   deskFiles: [],
//   selfCertifiedAs: undefined,
//   lastCertifiedAt: '',
//   isPrivate: false,
//   caplinkedWorkspaceId: 0,
//   caplinkedWorkspaceName: '',
//   type: '',
//   video: undefined,
//   description: '',
//   isAnoynamousDeal: true,
// };

type UserStatus = {
  hasSignedNDA: boolean
  hasDeckAccess: boolean
}
type QueryResult = {
  deal: Deal
  userStatus: UserStatus
}

const useDeal = (slug: string) => {
  const {user, isAdmin} = useAuth()
  const userId = user?.uid

  const {data, status, error, refetch} = useQuery<QueryResult, Error>(
    ['deal', slug],
    async () => {
      const deal = await getDealBySlug(slug, userId as string, isAdmin)

      const {dealInterests} = deal
      const currentDealInterest =
        Array.isArray(dealInterests) && dealInterests.length > 0
          ? dealInterests[0]
          : undefined

      const hasSignedNDA = currentDealInterest?.hasSignedNDA ?? false
      const {status} = currentDealInterest ?? {}

      return {
        deal: {
          ...deal,
          dealInterest: currentDealInterest,
        },
        userStatus: {
          hasSignedNDA,
          hasDeckAccess: hasDeckAccess(status),
        },
      }
    },
    {
      enabled: Boolean(slug) && Boolean(userId),
      staleTime: QUERY_STALE_TIME.SHORT,
    }
  )

  const showDealDetails =
    data &&
    getShowDealDetails(data.deal, {
      isAdmin,
      hasUserSignedNDA: data.userStatus.hasSignedNDA,
    })
  const showFiles = Boolean(isAdmin || data?.userStatus.hasDeckAccess)

  return {
    deal: data?.deal,
    loading: status === 'loading',
    error,
    showDealDetails,
    showFiles,
    refetchDeal: refetch,
  }
}

export default useDeal
