export const ROUTES = Object.freeze({
  HOME: '/' as const,

  LOGIN: '/login' as const,
  REGISTER: '/register' as const,
  SELF_CERTIFICATION: '/self-certification' as const,
  VERIFY_CERTIFICATION: '/verification' as const,

  INTERESTS: '/interests' as const,

  PASSWORD_RESET_EMAIL: '/forgot-password' as const,
  PASSWORD_RESET_NEW: '/reset-password' as const,
  EMAIL_VERIFICATION: '/email-verification' as const,
  EMAIL_REDIRECTION: '/email-redirection' as const,

  TERMS_OF_USE: '/terms-of-use' as const,
  DISCLAIMER: '/disclaimer' as const,
  PRIVACY_POLICY: '/privacy-policy' as const,
  TEASER: '/deal/:slug/teaser' as const,
  DECK: '/deal/:slug/deck/:name' as const,

  USERS: '/users' as const,
  USERS_PARAM: '/users/:currentPage?' as const,
  USER: '/user' as const,
  USER_PARAM: `/user/:slug` as const,
  USER_SETTINGS: '/user/settings' as const,

  DEALS: '/deals' as const,
  DEALS_PARAM: '/deals/:currentPage?' as const,
  DEAL: '/deal' as const,

  CREATE_DEAL: '/create-deal' as const,
  CREATE_DEAL_PARAM: '/create-deal/:slug' as const,
  DEAL_PARAM: `/deal/:slug` as const,

  ANALYTICS: `/analytics` as const,
})
