import React, {useState, Fragment} from 'react'
import Dropzone, {DropzoneProps, FileRejection} from 'react-dropzone'
import {Close} from 'react-ikonate'

import {File} from 'types'
import {trimString} from 'utils/strings'

import {Section, Wrapper} from './styles'

export interface DragDropProps extends DropzoneProps {
  name: string
  value: File[]
  maxSize?: number
  disabled?: boolean
  labelTop?: boolean
  placeholder?: string
  asInput?: boolean
  label?: string
  labelId?: string
  labelZone?: string
  onRemove?: (files: any[]) => void
  onChange: (files: any) => void
  error?: {
    status?: boolean
    message?: string
  }
}

const DragDrop: React.FC<DragDropProps> = ({
  onChange,
  onRemove,
  asInput,
  name,
  label,
  labelId,
  labelTop,
  placeholder = '',
  labelZone = 'Drag and Drop Files',
  error,
  value,
  disabled,
  ...props
}) => {
  const isError = error?.status

  const [validationError, setValidationError] = useState<string | null>(null)

  const handleDrop = (data: any[]) => {
    const existingValues =
      value && value.length > 0 ? (asInput ? [value] : value) : []

    const newData = [...data, ...existingValues]?.reduce((results, file) => {
      const exist = results.some((item: File) => item.name === file.name)
      return exist ? [...results] : [...results, file]
    }, [])

    asInput && onRemove && onRemove(value)
    onChange(newData)
  }

  const handleRemove = async (file: File) => {
    const newData = value?.filter(f => f.name !== file.name)
    // callbackChange(newData)

    if (onRemove && file?.url && file?.reference) {
      onRemove([file])
      onChange(newData)
    } else {
      onChange(newData)
    }
  }

  /**
   * Hanlde error on file based on the initial props defined
   * i.e: maxSize, minSize, maxFiles etc..
   * @param data - files drag and drop
   */
  const handleValidationError = (data: FileRejection[]) => {
    const {maxSize} = props

    // Finding 1st error catched
    try {
      const {errors} = data[0]
      const {code, message} = errors[0]

      switch (code) {
        case 'file-too-large':
          const fileError = maxSize && data.find(d => d?.file.size > maxSize)
          fileError &&
            setValidationError(
              data.length > 1
                ? `${fileError.file.name} is too large`
                : 'Your file is too large'
            )
          break
        default:
          // "file-too-small" | "too-many-files" | "file-invalid-type"
          setValidationError(message)
      }
    } catch (_) {
      // Avoid any crash, in case the first error is not defined data[0] or errors[0]
    }
  }

  const resetValidationError = () => validationError && setValidationError(null)

  return (
    <Wrapper {...{labelTop}}>
      {label && <label className="text-label">{label}</label>}

      <Section {...{asInput, isError, disabled}}>
        <Dropzone
          maxSize={props.maxSize || 10000000}
          onDropAccepted={resetValidationError}
          onDropRejected={handleValidationError}
          maxFiles={asInput ? 1 : undefined}
          onDrop={data => handleDrop(data)}
          {...props}
          {...{disabled}}
        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} name={name} multiple={!asInput} />

              {asInput && (
                <Fragment>
                  {value && value[0]?.name ? (
                    <label
                      id={labelId || 'label-file-name-zone'}
                      className="file-name-zone"
                    >
                      {trimString(value[0].name, 40)}
                    </label>
                  ) : (
                    <label className="file-name-zone placeholder">
                      {placeholder}
                    </label>
                  )}
                </Fragment>
              )}

              <label className="label">{labelZone}</label>
              <em>or click here</em>
            </div>
          )}
        </Dropzone>

        {!asInput && value && value.length > 0 && (
          <ul>
            {<h4>Files</h4>}
            {value.map((file: any, index: number) => (
              <li className="item" key={`file_${index}`}>
                <span>
                  {file?.name || file?.reference || 'file name not found'}
                </span>

                {onRemove && (
                  <Close
                    className="close-btn"
                    onClick={() => handleRemove(file)}
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </Section>

      {/**
       * `validationError` is the internal component error management from the props passed to the component
       * `error.status` is the external error management coming for a form for example
       */}
      {(validationError || error?.status) && (
        <span className="error">{validationError || error?.message}</span>
      )}
    </Wrapper>
  )
}

export default DragDrop
