import React from 'react'
import {Route} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'

import InternalLink from 'components/InternalLink'
import ContentContainer from 'components/ContentContainer'

import {Container} from './styles'

const PageNotFound: React.FC = () => (
  <Route path="*">
    <ContentContainer>
      <Container>
        <h1 className="title-primary">404 Page Not Found</h1>
        <InternalLink to={ROUTES.HOME}>Home</InternalLink>
      </Container>
    </ContentContainer>
  </Route>
)

export default PageNotFound
