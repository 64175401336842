import styled, {css} from 'styled-components'
import {Bin, Close, Edit, Eye, Info, Ok, OkCircle} from 'react-ikonate'

import {IkoIcon, IkoIconSize} from 'types'

import Colors from './colors'

/**
 * Default Ikonate icon style
 * see doc: https://github.com/danielkov/react-ikonate
 */
const handleIconSize = (size: IkoIconSize) => {
  switch (size) {
    case 'small':
      return css`
        width: 1.5rem;
        height: 1.5rem;
      `
    case 'medium':
      return css`
        width: 2.5rem;
        height: 2.5rem;
      `
    case 'big':
      return css`
        width: 3rem;
        height: 3rem;
      `
    default:
      return css`
        width: 2rem;
        height: 2rem;
      `
  }
}

const iconStyle = css<IkoIcon>`
  position: ${({pos}) => pos || 'unset'};
  right: 1.5em;

  ${({size}) => handleIconSize(size)};
`

export const InfoIcon = styled(Info)<IkoIcon>`
  ${iconStyle};
  stroke: ${Colors.WARNING};
`

export const SuccessIcon = styled(Ok)<IkoIcon>`
  ${iconStyle};
  stroke: ${Colors.SUCCESS};
`

export const SuccessCircleIcon = styled(OkCircle)<IkoIcon>`
  ${iconStyle};
  stroke: ${Colors.SUCCESS};
`

export const ErrorIcon = styled(Close)<IkoIcon>`
  ${iconStyle};
  stroke: ${Colors.ERROR};
`

export const EyeIcon = styled(Eye)<IkoIcon>`
  ${iconStyle};
  stroke: ${Colors.PRIMARY};
  cursor: pointer;
`

export const EditIcon = styled(Edit)<IkoIcon>`
  ${iconStyle};
  stroke: ${Colors.PRIMARY};
  cursor: pointer;
`

export const BinIcon = styled(Bin)<IkoIcon>`
  ${iconStyle};
  stroke: ${Colors.PRIMARY};
  cursor: pointer;
`
