import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

export const CardContent = styled.div`
  display: grid;
  gap: 16px;

  @media only screen and (min-width: ${Breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
`
