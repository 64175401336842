import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'
import {BinIcon} from 'styles/icons'

export const EmailUsersContainer = styled.div`
  display: grid;
  grid-gap: 3em;

  @media (min-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr 0.8fr;
    column-gap: 3em;
  }
`

export const Recipients = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

export const Recipient = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.LIGHT_GREY};
  }
`

export const InputContainer = styled.div`
  margin-top: 24px;
  display: block;
`

export const SubmitButtonContainer = styled.div`
  margin-top: 32px;
`

export const DeleteIcon = styled(BinIcon)`
  &:hover {
    stroke: ${Colors.ERROR};
  }
`
