import React, {createContext, useContext, useState, useEffect} from 'react'

import {AuthUser, MaintenanceMode, User} from 'types'
import {ROLES} from 'config/data/roles.config'
import firebase from 'lib/firebase'
import {firebaseSignOut} from 'lib/firebase/auth'
import axios from 'lib/axios'
import {
  getUser,
  updateUserById,
  isUserCertified,
  hasUserInterests,
} from 'services/users'
import {timeouts} from 'config/timeouts.config'

interface AuthContextValue extends AuthUser {
  loading: boolean
  error?: string | null
  maintenanceMode?: boolean
}

const AuthContext = createContext<AuthContextValue | undefined>({
  user: undefined,
  role: undefined,
  displayName: '',
  isAdmin: false,
  isAuth: false,
  isAuthUserSelfCertified: false,
  hasAuthUserInterests: false,
  dismissedDeals: [],
  loading: true,
  error: null,
})

const AuthProvider: React.FC = ({children}) => {
  let sessionTimeout: number
  let unsubscribeUserDataPolling: firebase.Unsubscribe
  let unsubscribeMaintenanceModePolling: firebase.Unsubscribe

  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const [authUser, setAuthUser] = useState<AuthUser>({
    user: undefined,
    role: undefined,
    displayName: '',
    isAuth: false,
    isAdmin: false,
    isAuthUserSelfCertified: false,
    hasAuthUserInterests: false,
    dismissedDeals: [],
  })
  const [maintenanceMode, setMaintenanceMode] = useState(false)

  const stopUserDataPolling = () => {
    if (unsubscribeUserDataPolling) {
      unsubscribeUserDataPolling()
    }
  }
  const stopSessionDurationMeasuring = () => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout)
    }
  }
  const stopMaintenanceModePolling = () => {
    if (unsubscribeMaintenanceModePolling) {
      unsubscribeMaintenanceModePolling()
    }
  }

  const handleUserDataPolling = (user?: firebase.User | null) => {
    if (user) {
      unsubscribeUserDataPolling = firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .onSnapshot(snapshot => {
          const userData = snapshot.data() as User

          if (userData) {
            const {
              isArchived,
              isSuspended,
              selfCertifiedAs,
              lastCertifiedAt,
              dismissedDeals,
            } = userData

            if (isArchived || isSuspended) {
              firebaseSignOut()
            }

            setAuthUser(prev => ({
              ...prev,
              selfCertifiedAs,
              lastCertifiedAt,
              isAuthUserSelfCertified: isUserCertified(userData),
              hasAuthUserInterests: hasUserInterests(userData),
              dismissedDeals: Array.isArray(dismissedDeals)
                ? dismissedDeals
                : [],
            }))
          }
        })
    } else {
      stopUserDataPolling()
    }
  }

  const handleSessionDurationMeasuring = async (
    user?: firebase.User | null
  ) => {
    if (user) {
      sessionTimeout = setTimeout(() => {
        firebaseSignOut()
      }, timeouts.logout)

      await updateUserById(user.uid, {
        lastActiveAt: new Date(),
      }).catch(_ => {
        // silent
      })
    } else {
      stopSessionDurationMeasuring()
    }
  }

  const handleMaintenanceModePolling = () => {
    unsubscribeMaintenanceModePolling = firebase
      .firestore()
      .collection('system')
      .doc('maintenance')
      .onSnapshot(snapshot => {
        if (snapshot && snapshot.data()) {
          const {enabled} = (snapshot.data() as MaintenanceMode) ?? {
            enabled: undefined,
          }

          setMaintenanceMode(Boolean(enabled))
        }
      })
  }

  const setAxiosAuthHeaders = async (user?: firebase.User | null) => {
    if (user) {
      const token = await firebase.auth().currentUser?.getIdToken()

      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      delete axios.defaults.headers.common.Authorization
    }
  }

  useEffect(() => {
    const unsubscribeAuthStatePolling = firebase
      .auth()
      .onAuthStateChanged(async user => {
        try {
          if (user) {
            if (!user.emailVerified) {
              setLoading(false)
              return
            }

            const userData = await getUser(user.uid, false)
            const {
              role,
              firstName,
              selfCertifiedAs,
              lastCertifiedAt,
              dismissedDeals,
            } = userData

            setAuthUser({
              user,
              role,
              displayName: firstName,
              isAuth: Boolean(role),
              isAdmin: role === ROLES.ADMIN,
              selfCertifiedAs,
              lastCertifiedAt,
              isAuthUserSelfCertified: isUserCertified(userData),
              hasAuthUserInterests: hasUserInterests(userData),
              dismissedDeals: Array.isArray(dismissedDeals)
                ? dismissedDeals
                : [],
            })
          } else {
            setAuthUser({
              user: undefined,
              role: undefined,
              displayName: '',
              isAuth: false,
              isAdmin: false,
              isAuthUserSelfCertified: false,
              hasAuthUserInterests: false,
              dismissedDeals: [],
            })
          }
        } catch (error) {
          setError(error.message)
        }

        // set axios headers to avoid having to include token on each request
        await setAxiosAuthHeaders(user)

        // events that need update whenever auth state changes
        handleUserDataPolling(user)
        handleSessionDurationMeasuring(user)

        setLoading(false)
      })

    // events that DO NOT need update whenever auth state changes
    handleMaintenanceModePolling()

    return () => {
      unsubscribeAuthStatePolling()
      stopUserDataPolling()
      stopSessionDurationMeasuring()
      stopMaintenanceModePolling()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...authUser,
        loading,
        error,
        maintenanceMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export {AuthProvider, useAuth}
