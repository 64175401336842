import React from 'react'

import {DealInterestStatus} from 'types'

import {ButtonsContainer} from '../../styles'
import AccessBtn from '../AccessBtn'
import {hasNdaAccess} from 'services/deal-interests'
import {DEAL_INTEREST_STATUS} from 'config/data/deal-interest.config'

interface NdaAccessButtonsProps {
  dealInterestId: string
  dealInterestStatus?: DealInterestStatus
  isInvitedUser?: boolean
  handleNdaAccessRequest: (e: any, id: string) => void
}

const NdaAccessButtons: React.FC<NdaAccessButtonsProps> = ({
  dealInterestId,
  dealInterestStatus,
  isInvitedUser,
  handleNdaAccessRequest,
}) => {
  const isUserAskingForNdaAccess =
    dealInterestStatus === DEAL_INTEREST_STATUS.NDA_REQUESTED
  const hasUserNdaAccess = hasNdaAccess(dealInterestStatus, isInvitedUser)

  return (
    <ButtonsContainer>
      <AccessBtn
        id={`radioAcceptNdaRequest-${dealInterestId}`}
        type="accept"
        value="APPROVED"
        onClick={e => handleNdaAccessRequest(e, dealInterestId)}
        checked={hasUserNdaAccess}
        disabled={!isUserAskingForNdaAccess || hasUserNdaAccess}
        borderRadiusLeft
        borderRadiusRight
      />
    </ButtonsContainer>
  )
}

export default NdaAccessButtons
