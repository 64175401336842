import styled from 'styled-components'

import Colors from 'styles/colors'

import Icon from 'components/Icon'
import Button from 'components/styles/Button'

interface IconStateProps {
  active?: boolean
  current?: boolean
}

export const IconState = styled(Icon)<IconStateProps>`
  color: ${({active}) => (active ? Colors.SUCCESS : Colors.BLACK)};
`

export const CompleteProfileTitle = styled.p`
  margin-top: 0;
  margin-bottom: 22px;
  color: ${Colors.GREY};
  font-size: 18px;
  line-height: 28px;
`

export const ActionButton = styled(Button)`
  height: fit-content;
  width: 100px;
`
