import {ErrorAPI} from 'types'

export const ERROR_CODE = {
  WRONG_PASSWORD: 'auth/wrong-password',
  USER_DISABLED: 'auth/user-disabled',
  USER_NOT_FOUND: 'auth/user-not-found',
}

export const errorDefault = 'Please get in touch.'
export const errorAccountBlocked =
  'Get in touch, your access to the platform has been revoked.'
export const errorWrongPassword =
  'The email or password you entered is incorrect. Please try again or contact us.'

export const errorVerifyEmail =
  'Your email address is not verified yet. Please try again or contact us.'

/**
 * Manage API error (not firebase - but gearedap API)
 */
export const handleErrors = (error: ErrorAPI) => {
  const status = error.status

  switch (status) {
    case 422:
      if (error?.validationErrors?.registrationCode?.msg)
        return error?.validationErrors?.registrationCode?.msg
      return (
        error.detail ||
        'Something happened. Please check if you input valid data.'
      )
    default:
      return (
        error.detail ||
        'Something happened. Please check if you input valid data.'
      )
  }
}
