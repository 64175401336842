import React, {Fragment} from 'react'

import Button from 'components/styles/Button'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'

type VerifyEmailModalProps = {
  show: boolean
  loading: boolean
  onVerifyEmail: () => void
  onClose: () => void
}

const VerifyEmailModal = ({
  show,
  onVerifyEmail,
  loading,
  onClose,
}: VerifyEmailModalProps) => {
  return (
    <Modal
      {...{
        isShowing: show,
        hide: onClose,
      }}
      maxWidth="600px"
    >
      <Fragment>
        <h2>Verify User Email?</h2>

        <p className="modal-inner-body">Yes, I want to verify this user.</p>
        <div className="modal-inner-btn">
          {!loading ? (
            <Fragment>
              <Button
                secondary
                className="modal-back-button"
                onClick={() => onClose()}
              >
                Go Back
              </Button>

              <Button onClick={onVerifyEmail} disabled={loading}>
                Verify
              </Button>
            </Fragment>
          ) : (
            <Spinner primary />
          )}
        </div>
      </Fragment>
    </Modal>
  )
}

export default VerifyEmailModal
