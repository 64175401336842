import styled from 'styled-components'

import Container from 'components/styles/Container'
import Separator from 'components/styles/Separator'

export const Line = styled(Separator)`
  margin: 30px 0 !important;
`

export const FormHeader = styled.div``

export const FormFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img`
  height: 51.3px;
  width: 150px;
  left: 25px;
  top: 24px;
  position: relative;
`

export const Card = styled(Container)`
  margin: 70px 0 70px 50px;
`
