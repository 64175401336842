export const DEAL_INTEREST_STATUS = Object.freeze({
  /** The user has access to the teaser */
  TEASER_STAGE: 'TEASER_STAGE' as const,
  /** The user has requested to see the NDA for an open deal */
  NDA_REQUESTED: 'NDA_REQUESTED' as const,
  /** The user can see the NDA of an open deal */
  NDA_STAGE: 'NDA_STAGE' as const,
  /** The user has requested access to the deck */
  DECK_REQUESTED: 'DECK_REQUESTED' as const,
  /** The user has been granted access to the deck */
  DECK_STAGE: 'DECK_STAGE' as const,
  /** The user has made an indicative offer for the deal */
  INDICATIVE_OFFER_PROPOSED: 'INDICATIVE_OFFER_PROPOSED' as const,
  /**  The user has access to the dataroom */
  DATAROOM_STAGE: 'DATAROOM_STAGE' as const,
  /** The user has made a final offer for the deal */
  FINAL_OFFER_PROPOSED: 'FINAL_OFFER_PROPOSED' as const,
  /** The user has invested in an offer for the deal */
  INVESTED: 'INVESTED' as const,
  /** The user has not invested in an offer for the deal */
  NOT_INVESTED: 'NOT_INVESTED' as const,
})

export const DEAL_INTEREST_STATUS_TO_STRING = Object.freeze({
  TEASER_STAGE: 'Teaser Stage' as const,
  NDA_REQUESTED: 'NDA Requested' as const,
  NDA_STAGE: 'NDA Stage' as const,
  DECK_REQUESTED: 'Deck Requested' as const,
  DECK_STAGE: 'Deck Stage' as const,
  INDICATIVE_OFFER_PROPOSED: 'Indicative Offer Proposed' as const,
  DATAROOM_STAGE: 'Dataroom Stage' as const,
  FINAL_OFFER_PROPOSED: 'Final Offer Proposed' as const,
  INVESTED: 'Invested' as const,
  NOT_INVESTED: 'Not Invested' as const,
})

export const INVITATION_STATUS = Object.freeze({
  /** The invitation is waiting to be sent */
  PENDING: 'PENDING' as const,
  /** The invitation has been sent */
  SENT: 'SENT' as const,
  /** The user has joined the platform */
  JOINED: 'JOINED' as const,
})

export const EMAIL_DELIVERY_STATUS = Object.freeze({
  NOT_SENT: 'NOT_SENT' as const,
  BOUNCED: 'BOUNCED' as const,
  DEFERRED: 'DEFERRED' as const,
  DELIVERED: 'DELIVERED' as const,
  DROPPED: 'DROPPED' as const,
  SENDING: 'SENDING' as const,
  OPENED: 'OPENED' as const,
})

export const EMAIL_DELIVERY_STATUS_TO_STRING = Object.freeze({
  NOT_SENT: 'Not sent' as const,
  BOUNCED: 'Bounced' as const,
  DEFERRED: 'Deferred' as const,
  DELIVERED: 'Delivered' as const,
  DROPPED: 'Dropped' as const,
  SENDING: 'Sending' as const,
  OPENED: 'Opened' as const,
})

export const DATAROOM_STATUS = Object.freeze({
  REQUESTED: 'REQUESTED' as const,
  APPROVED: 'APPROVED' as const,
  MANUAL_ADD_PENDING: 'MANUAL_ADD_PENDING' as const,
  MANUAL_REMOVE_PENDING: 'MANUAL_REMOVE_PENDING' as const,
  DENIED: 'DENIED' as const,
  REVOKED: 'REVOKED' as const,
})

export const DATAROOM_STATUS_TO_STRING = Object.freeze({
  REQUESTED: 'Requested' as const,
  APPROVED: 'Approved' as const,
  MANUAL_ADD_PENDING: 'Manual Add Pending' as const,
  MANUAL_REMOVE_PENDING: 'Manual Remove Pending' as const,
  DENIED: 'Denied' as const,
  REVOKED: 'Revoked' as const,
})
