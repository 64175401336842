import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'
import {FAST_TRANSITION} from 'styles/globalStyles'

export const Container = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (min-width: ${Breakpoints.sm}) {
    width: auto;
  }
`

export const MainButton = styled.button`
  background-color: ${Colors.BLUE};
  border: 2px solid ${Colors.BLUE};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: ${Colors.WHITE};
  border-right: 1px solid ${Colors.BUTTON_CLICKED_BLUE};

  width: 100%;

  ${FAST_TRANSITION};

  padding: 6px 16px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  cursor: pointer;
  &:disabled {
    cursor: unset;
  }

  &:hover {
    background-color: ${Colors.BUTTON_HOVER_BLUE};
    border-color: ${Colors.BUTTON_HOVER_BLUE};
    border-right-color: ${Colors.BUTTON_CLICKED_BLUE};
    color: ${Colors.WHITE};
  }

  &:focus {
    background-color: ${Colors.BUTTON_HOVER_BLUE};
    border-color: ${Colors.BUTTON_HOVER_BLUE};
    border-right-color: ${Colors.BUTTON_CLICKED_BLUE};
    color: ${Colors.WHITE};
  }

  &:disabled {
    color: ${Colors.DARK_GREY};
    background-color: ${Colors.LIGHT_GREY};
    border: 2px solid ${Colors.LIGHT_GREY};
    border-right: 1px solid ${Colors.GREY};
  }
`

export const ToggleDropdownButton = styled.button`
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;

  background-color: ${Colors.BLUE};
  border: 2px solid ${Colors.BLUE};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${Colors.WHITE};

  ${FAST_TRANSITION};

  padding: 6px 16px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  cursor: pointer;
  &:disabled {
    cursor: unset;
  }

  &:hover {
    background-color: ${Colors.BUTTON_HOVER_BLUE};
    border-color: ${Colors.BUTTON_HOVER_BLUE};
    color: ${Colors.WHITE};
  }

  &:focus {
    background-color: ${Colors.BUTTON_HOVER_BLUE};
    border-color: ${Colors.BUTTON_HOVER_BLUE};
    color: ${Colors.WHITE};
  }

  &:disabled {
    color: ${Colors.DARK_GREY};
    background-color: ${Colors.LIGHT_GREY};
    border: 2px solid ${Colors.LIGHT_GREY};
  }
`

export const Dropdown = styled(Popover)`
  margin-top: 10px;

  .MuiPopover-paper {
    width: 288px !important;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  }
`

export const DropdownButton = styled.button<{selected?: boolean}>`
  width: 100%;

  background-color: ${({selected}) => (selected ? Colors.BLUE : Colors.WHITE)};
  color: ${({selected}) => (selected ? Colors.WHITE : Colors.BLACK)};
  border: none;

  ${FAST_TRANSITION};

  padding: 16px;

  font-style: normal;
  text-align: left;

  cursor: pointer;
  &:disabled {
    cursor: unset;
  }

  .submit-popover-button-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;

    color: #212121;
  }
  .submit-popover-button-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    margin-top: 8px;
    color: #757575;
  }

  &:hover {
    background-color: ${Colors.BUTTON_HOVER_BLUE};

    .submit-popover-button-title {
      color: ${Colors.WHITE};
    }
    .submit-popover-button-description {
      color: ${Colors.WHITE};
    }
  }

  &:focus {
    background-color: ${Colors.BUTTON_HOVER_BLUE};

    .submit-popover-button-title {
      color: ${Colors.WHITE};
    }
    .submit-popover-button-description {
      color: ${Colors.WHITE};
    }
  }

  &:disabled {
    background-color: ${({selected}) =>
      selected ? Colors.BLUE : Colors.LIGHT_GREY};

    .submit-popover-button-title {
      color: ${({selected}) => (selected ? Colors.WHITE : Colors.DARK_GREY)};
    }
    .submit-popover-button-description {
      color: ${({selected}) => (selected ? Colors.WHITE : Colors.DARK_GREY)};
    }
  }
`
