import React, {useState, useEffect, Fragment} from 'react'

import useForm from 'hooks/useForm'
import useModal from 'hooks/useModal'
import {makeFinalOffer} from 'services/deal-interests'
import {makeOfferFilesUpload} from 'services/files'

import Button from 'components/styles/Button'
import Checkbox from 'components/Checkbox'
import ErrorMessage from 'components/ErrorMessage'
import Modal from 'components/Modal'
import Dropzone from 'components/Dropzone'

import {Actions, ConfirmList, Boxes} from './styles'

interface FinalOfferProps {
  label: string
  type: string
  id?: string
  updateFinalOfferStatus: () => void
  buttonDisabled?: boolean
}

const FinalOffer: React.FC<FinalOfferProps> = ({
  label,
  id,
  updateFinalOfferStatus,
  buttonDisabled,
}) => {
  const {isShowing, toggle} = useModal()

  const [makeOffer, setMakeOffer] = useState(false) // API make offer loading
  const [error, setError] = useState(null)

  const {inputs, handleChange, clearForm} = useForm({}, isShowing)

  const enableBoxes = !!inputs?.documents?.length
  const enableSubmit = enableBoxes && inputs?.checkbox

  /**
   * Submit offer
   */
  useEffect(() => {
    // If loading set to true and submit was enabled
    if (isShowing && makeOffer) {
      const submitFinalOffer = async () => {
        try {
          if (!id) {
            throw new Error('No deal interest found. Please contact us.')
          }

          let documents = null
          // Check if some documents exist
          if (!!inputs.documents.length) {
            documents = await makeOfferFilesUpload(inputs.documents, id)
          }

          await makeFinalOffer(id, documents, inputs?.checkbox === 'YES')

          toggle()
          clearForm()
          updateFinalOfferStatus() // update indicative offers count on success! +1
        } catch (error) {
          setError(error)
        }

        setMakeOffer(!makeOffer)
      }

      submitFinalOffer()
    }
  }, [
    clearForm,
    id,
    inputs,
    isShowing,
    makeOffer,
    toggle,
    updateFinalOfferStatus,
  ])

  if (!id) {
    return null
  }

  return (
    <Fragment>
      <Button onClick={toggle} disabled={buttonDisabled}>
        {label}
      </Button>

      {/** Arrange a meeting Modal */}
      <Modal hide={toggle} {...{isShowing}} neat>
        <h2>Submit Final Offer</h2>

        <span className="text-lg grey">Please upload your offer below:</span>

        <span className="text-base black italic">
          Please ensure that you also attach your comments on the Terms Sheet
          from your review of the transaction SPA.
        </span>

        <form
          onSubmit={async (e: any) => {
            e.preventDefault()
            setMakeOffer(!makeOffer)
          }}
        >
          <ErrorMessage {...{error}} />

          <fieldset disabled={makeOffer} aria-busy={makeOffer}>
            <Dropzone
              name="documents"
              onChange={handleChange}
              values={inputs.documents}
            />

            <strong className="underline italic">We confirm:</strong>
            <ConfirmList>
              <li>
                that we have completed our due diligence enquiries and SPA
                review, and
              </li>

              <li>
                that we anticipate no further material changes being proposed to
                the SPA other than those included in our attached Terms Sheet
              </li>
            </ConfirmList>

            <Boxes>
              <div />
              <div />

              <Checkbox
                label="Yes"
                name="checkbox"
                value="YES"
                type="radio"
                disabled={!enableBoxes}
                onChange={handleChange}
              />
              <Checkbox
                label="No"
                name="checkbox"
                value="NO"
                type="radio"
                disabled={!enableBoxes}
                onChange={handleChange}
              />
            </Boxes>

            <Actions>
              <Button onClick={toggle} secondary>
                Cancel
              </Button>

              <Button type="submit" disabled={!enableSubmit}>
                {makeOffer ? 'Please wait...' : 'Submit'}
              </Button>
            </Actions>
          </fieldset>
        </form>
      </Modal>
    </Fragment>
  )
}

export default FinalOffer
