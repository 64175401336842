import styled from 'styled-components'

import Card from 'components/Card'

export const DealListCard = styled(Card)`
  .card-content {
    > div:not(:first-child) {
      margin-top: 24px;
    }
  }
`
