import React, {useState, Fragment, useMemo, useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import useCreateDeal from 'hooks/useCreateDeal'
import {ROUTES} from 'config/routes.config'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import useModal from 'hooks/useModal'
import {archiveDeal, deleteDeal} from 'services/admin'

import {createDealValidationSchema} from './validations'
import {DealCreationHeaderButtons, ArchiveModalOptions} from './styles'

import Breadcrumbs from 'components/Header/Breadcrumbs'
import PageTitle from 'components/PageTitle'
import Button from 'components/styles/Button'
import ErrorButton from 'components/styles/ErrorButton'
import Spinner from 'components/Spinner'
import Toast from 'components/Toast'
import {Tab, TabContent, Tabs} from 'components/styles/Tabs'
import ContentContainer from 'components/ContentContainer'
import Modal from 'components/Modal'
import DropdownButton, {
  MainButtonAction,
  DropdownAction,
} from 'components/DropdownButton'

import DealForm from './components/DealForm'
import EditNDA from './components/EditNDA'
import EditLetter from './components/EditLetter'
import InviteUsers from './components/InviteUsers'
import EmailUsers from './components/EmailUsers'

type TabId =
  | '1-deal-details'
  | '2-deal-nda'
  | '3-deal-process-letter'
  | '4-deal-invites'
  | '5-deal-emails'

type CreateDealSubmitActionType =
  | 'save-and-publish'
  | 'save-as-draft'
  | 'archive'

const CreateDeal: React.FC = () => {
  const history = useHistory()

  const [activeTab, setActiveTab] = useState<TabId>('1-deal-details')
  const handleTabClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    const clickedTabId = (e.target as HTMLButtonElement).id as TabId
    if (clickedTabId !== activeTab) {
      setActiveTab(clickedTabId)
    }
  }

  /**
   * modals logic start
   */
  const {
    isShowing: isArchiveModalShowing,
    setIsShowing: setIsArchiveModalShowing,
  } = useModal()
  const [isArchiving, setIsArchiving] = useState(false)
  const {
    isShowing: isDeleteModalShowing,
    setIsShowing: setIsDeleteModalShowing,
  } = useModal()
  const [isDeleting, setIsDeleting] = useState(false)
  /**
   * modals logic end
   */

  const formOptions = useForm({
    resolver: useYupValidationResolver(createDealValidationSchema),
  })
  const {handleSubmit, getValues, setValue, errors} = formOptions

  const {
    caplinkedWorkspaces,
    state,
    toast,
    setToast,
    onRemoveFiles,
    handleSaveDeal,
    refetchDealData,
  } = useCreateDeal(getValues, setValue)
  const {loading, dealId, status, slug} = state

  const published = status === 'PUBLISHED'
  const draft = status === 'DRAFT'
  const archived = status === 'CLOSED'
  const existingDeal = draft || published || archived

  /**
   * current submit action logic start
   */
  const [selectedSubmitActionType, setSelectedSubmitActionType] =
    useState<CreateDealSubmitActionType>('save-as-draft')

  useEffect(() => {
    if (loading) {
      return
    }

    setSelectedSubmitActionType(() => {
      // determine default submit action
      if (!existingDeal) {
        return 'save-as-draft'
      }

      if (archived) {
        return 'archive'
      } else if (draft) {
        return 'save-as-draft'
      } else if (published) {
        return 'save-and-publish'
      }

      return 'save-as-draft'
    })
  }, [loading, existingDeal, archived, draft, published])

  // all possible submit actions
  const submitActions = useMemo<MainButtonAction[]>(() => {
    return [
      {
        label: 'Save changes',
        onClick: (formData: any) => handleSaveDeal(formData, 'PUBLISHED'),
        selected: selectedSubmitActionType === 'save-and-publish',
        disabled: archived,
      },
      {
        label: 'Save draft',
        onClick: (formData: any) => handleSaveDeal(formData, 'DRAFT'),
        selected: selectedSubmitActionType === 'save-as-draft',
        disabled: archived || published,
      },
      {
        label: 'Archived',
        onClick: () => {
          // deal archivation process is handled via a separate submit function triggered by the
          // deal archive modal
        },
        selected: selectedSubmitActionType === 'archive',
        disabled: archived || !existingDeal,
      },
    ]
  }, [
    handleSaveDeal,
    selectedSubmitActionType,
    archived,
    published,
    existingDeal,
  ])
  const currentSubmitAction = submitActions.find(action => action.selected)
  /**
   * current submit action logic end
   */

  /**
   * dropdown actions logic start
   */
  const dropdownActions = useMemo<DropdownAction[]>(() => {
    return [
      {
        title: 'Published',
        description:
          'Deal is published and live on the platform for users to interact with',
        onClick: async () => {
          const formData = getValues()
          await handleSaveDeal(formData, 'PUBLISHED')

          setSelectedSubmitActionType('save-and-publish')
        },
        selected: selectedSubmitActionType === 'save-and-publish',
        disabled: archived,
      },
      {
        title: 'Draft',
        description:
          'Deal is not published, Admins can continue to edit the deal.',
        onClick: async () => {
          const formData = getValues()
          await handleSaveDeal(formData, 'DRAFT')

          setSelectedSubmitActionType('save-as-draft')
        },
        selected: selectedSubmitActionType === 'save-as-draft',
        disabled: archived || published,
      },
      {
        title: 'Archived',
        description:
          'This will close the deal, users will no longer be able to interact with the deal. Analytics will no longer be available',
        onClick: () => {
          setIsArchiveModalShowing(true)
        },
        selected: selectedSubmitActionType === 'archive',
        disabled: archived || !existingDeal,
      },
    ]
  }, [
    selectedSubmitActionType,
    archived,
    published,
    setIsArchiveModalShowing,
    existingDeal,
    handleSaveDeal,
    getValues,
  ])
  /**
   * dropdown actions logic end
   */

  const onSubmit = (formData: any) => {
    // submit logic depends on currently selected action
    return currentSubmitAction?.onClick(formData)
  }

  const navigateToDealPage = () => {
    history.push(`${ROUTES.DEAL}/${slug}`)
  }

  const onArchiveDealModalSubmit = async () => {
    setIsArchiving(true)

    try {
      if (!state.dealId) {
        throw new Error(`This deal doesn't exist anymore.`)
      }

      await archiveDeal(state.dealId)
      await refetchDealData()

      setToast({
        value: 'Deal archived successfully.',
        type: 'success',
      })
      setIsArchiveModalShowing(false)
    } catch (error) {
      setToast({
        value: error.response?.data?.title ?? error.message,
        type: 'error',
      })
    }

    setIsArchiving(false)
  }

  const onDeleteDealModalSubmit = async () => {
    setIsDeleting(true)

    try {
      if (!state.dealId) {
        throw new Error(`This deal doesn't exist anymore.`)
      }

      await deleteDeal(state.dealId)

      setToast({
        value: 'Deal deleted successfully.',
        type: 'success',
      })
      setIsArchiveModalShowing(false)

      history.push(ROUTES.DEALS)
    } catch (error) {
      setToast({
        value: error.response?.data?.title,
        type: 'error',
      })
    }

    setIsDeleting(false)
  }

  return (
    <Fragment>
      <Breadcrumbs
        breadcrumbs={[
          {
            type: 'link',
            to: '/deals',
            label: 'Deals',
          },
          {
            type: 'text',
            label: dealId ? 'Edit Deal' : 'New Deal',
          },
        ]}
      />
      <PageTitle title={dealId ? 'Edit Deal' : 'Create New Deal'} />

      <Tabs>
        <Tab
          id="1-deal-details"
          onClick={handleTabClick}
          active={activeTab === '1-deal-details'}
          disabled={loading}
        >
          {'Deal Details'}
        </Tab>

        <Tab
          id="2-deal-nda"
          onClick={handleTabClick}
          active={activeTab === '2-deal-nda'}
          error={errors?.ndaFile}
          disabled={loading}
        >
          {'Deal NDA'}
        </Tab>

        <Tab
          id="3-deal-process-letter"
          onClick={handleTabClick}
          active={activeTab === '3-deal-process-letter'}
          error={errors?.processLetter}
          disabled={loading}
        >
          {'Process Letter'}
        </Tab>

        <Tab
          id="4-deal-invites"
          onClick={e => {
            if (existingDeal) {
              handleTabClick(e)
            } else {
              e.preventDefault()
            }
          }}
          active={activeTab === '4-deal-invites'}
          disabled={loading}
          data-tip
          data-for="deal-invites-tooltip"
        >
          {'Invite users'}
        </Tab>
        {!existingDeal && (
          <ReactTooltip id="deal-invites-tooltip" place="top" effect="solid">
            {'To invite users, publish a deal or save it as a draft'}
          </ReactTooltip>
        )}

        <Tab
          id="5-deal-emails"
          onClick={e => {
            if (existingDeal) {
              handleTabClick(e)
            } else {
              e.preventDefault()
            }
          }}
          active={activeTab === '5-deal-emails'}
          disabled={loading}
          data-tip
          data-for="deal-emails-tooltip"
        >
          {'Emails'}
        </Tab>
        {!existingDeal && (
          <ReactTooltip id="deal-emails-tooltip" place="top" effect="solid">
            {'To email users, publish a deal or save it as a draft'}
          </ReactTooltip>
        )}
      </Tabs>

      <ContentContainer>
        <FormProvider {...formOptions}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {activeTab === '1-deal-details' ||
            activeTab === '2-deal-nda' ||
            activeTab === '3-deal-process-letter' ? (
              <DealCreationHeaderButtons>
                {loading ? (
                  <Spinner size="small" />
                ) : (
                  <Fragment>
                    {existingDeal && (
                      <Button secondary onClick={navigateToDealPage}>
                        {'View Deal'}
                      </Button>
                    )}

                    {existingDeal && archived && (
                      <ErrorButton
                        onClick={() => setIsDeleteModalShowing(true)}
                      >
                        {'Delete Deal'}
                      </ErrorButton>
                    )}

                    {currentSubmitAction ? (
                      <DropdownButton
                        mainButtonAction={currentSubmitAction}
                        mainButtonProps={{
                          type: 'submit',
                        }}
                        dropdownActions={dropdownActions}
                      />
                    ) : null}
                  </Fragment>
                )}
              </DealCreationHeaderButtons>
            ) : null}

            <TabContent
              id="1-deal-details-content"
              active={activeTab === '1-deal-details'}
            >
              <DealForm
                loading={loading}
                status={status}
                onRemoveFiles={onRemoveFiles}
                caplinkedWorkspaces={caplinkedWorkspaces}
              />
            </TabContent>

            <TabContent
              id="2-deal-nda-content"
              active={activeTab === '2-deal-nda'}
            >
              <EditNDA />
            </TabContent>

            <TabContent
              id="3-deal-process-letter-content"
              active={activeTab === '3-deal-process-letter'}
            >
              <EditLetter />
            </TabContent>
          </form>
        </FormProvider>

        <TabContent
          id="4-deal-invites-content"
          active={activeTab === '4-deal-invites'}
        >
          {dealId ? <InviteUsers dealId={dealId} setToast={setToast} /> : null}
        </TabContent>

        <TabContent
          id="5-deal-emails-content"
          active={activeTab === '5-deal-emails'}
        >
          {dealId ? <EmailUsers dealId={dealId} setToast={setToast} /> : null}
        </TabContent>
      </ContentContainer>

      <Modal
        hide={() => setIsArchiveModalShowing(false)}
        isShowing={isArchiveModalShowing}
        maxWidth="600px"
      >
        <h2>Archive Deal</h2>
        <span className="text-lg grey">
          {`Closing this deal will mean users are no longer able to register interest, make offers or otherwise interact with the deal. Deal Analytics will no longer be available.`}
        </span>

        <ArchiveModalOptions>
          <Button
            secondary
            onClick={() => setIsArchiveModalShowing(false)}
            disabled={isArchiving}
          >
            Cancel
          </Button>
          <Button onClick={onArchiveDealModalSubmit} disabled={isArchiving}>
            {isArchiving ? 'Please wait...' : `Archive`}
          </Button>
        </ArchiveModalOptions>
      </Modal>

      <Modal
        hide={() => setIsDeleteModalShowing(false)}
        isShowing={isDeleteModalShowing}
        maxWidth="600px"
      >
        <h2>Delete Deal</h2>
        <span className="text-lg grey">
          {`Are you sure you want to delete this deal?`}
        </span>

        <ArchiveModalOptions>
          <Button
            secondary
            onClick={() => setIsDeleteModalShowing(false)}
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button onClick={onDeleteDealModalSubmit} disabled={isDeleting}>
            {isDeleting ? 'Please wait...' : `Delete`}
          </Button>
        </ArchiveModalOptions>
      </Modal>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default CreateDeal
