import React from 'react'

import {Container} from './styles'

const Maintenance: React.FC = () => {
  return (
    <Container>
      <div className="body">
        <h1 className="title-primary">Maintenance</h1>
        <span className="text">
          Our system is currently being updated and will be available again
          shortly.
        </span>
      </div>
    </Container>
  )
}

export default Maintenance
