import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

export const CardContent = styled.div`
  display: grid;
  gap: 16px;

  @media only screen and (min-width: ${Breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const DatePickerContainer = styled.div<{error?: boolean}>`
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
      > input {
        width: 100%;
        padding: 8px 56px 8px 16px;

        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: ${({error}) => (error ? Colors.ERROR : Colors.BLACK)};

        border-radius: 4px;
        border: ${({error}) =>
          error
            ? `2px solid ${Colors.ERROR}`
            : `2px solid ${Colors.LIGHT_GREY}`};

        ::placeholder {
          color: ${Colors.DARK_GREY};
        }
      }
    }
  }

  .react-datepicker__current-month {
    color: ${Colors.PRIMARY} !important;
  }

  .react-datepicker__day--selected {
    background-color: ${Colors.PRIMARY} !important;
    color: ${Colors.WHITE} !important;
  }
`
