import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'

export const DealCreationHeaderButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  @media only screen and (min-width: ${Breakpoints.sm}) {
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
  }

  > :not(:first-child) {
    margin-top: 12px;

    @media only screen and (min-width: ${Breakpoints.sm}) {
      margin-top: 0;
      margin-left: 12px;
    }
  }
`

export const ArchiveModalOptions = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 0.5fr 1fr;
`
