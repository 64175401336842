import React, {useState, useEffect, Fragment} from 'react'

import useForm from 'hooks/useForm'
import useModal from 'hooks/useModal'
import {OfferQuestion} from 'types'
import {getOfferQuestions} from 'services/deals'
import {makeNewOffer} from 'services/deal-interests'
import {makeOfferFilesUpload} from 'services/files'

import Button from 'components/styles/Button'
import Textarea from 'components/styles/Textarea'
import Spinner from 'components/Spinner'
import ErrorMessage from 'components/ErrorMessage'
import Modal from 'components/Modal'
import Dropzone from 'components/Dropzone'

import {Actions, QuestionsGrid, Section} from './styles'

interface IndicativeOfferProps {
  label: string
  type: string
  id?: string
  updateIndicativeOffersCount: () => void
  buttonDisabled?: boolean
}

const IndicativeOffer: React.FC<IndicativeOfferProps> = ({
  label,
  id,
  type,
  updateIndicativeOffersCount,
  buttonDisabled,
}) => {
  const {isShowing, toggle} = useModal()

  const [questions, setQuestions] = useState<OfferQuestion[]>([])
  const [loading, setLoading] = useState(true) // API fetch questions loading
  const [makeOffer, setMakeOffer] = useState(false) // API make offer loading
  const [error, setError] = useState(null)

  const {inputs, handleChange, clearForm} = useForm({}, isShowing)

  const enableSubmit = questions.every(q => inputs[q.id] || false)

  useEffect(() => {
    if (isShowing && loading && questions.length === 0) {
      const fetchOfferQuestions = async () => {
        try {
          const data = await getOfferQuestions(type)
          setQuestions(data)
        } catch (error) {
          setError(error)
        }

        setLoading(!loading)
      }

      fetchOfferQuestions()
    }
  }, [isShowing, loading, questions.length, type])

  /**
   * Submit offer
   */
  useEffect(() => {
    // If loading set to true and submit was enabled
    if (isShowing && makeOffer) {
      const submitIndicativeOffer = async () => {
        try {
          if (!id) {
            throw new Error('No deal interest found. Please contact us.')
          }

          const newQuestions = questions.map(({question, id}) => {
            return {question, answer: inputs[id]}
          })

          let documents = null

          // Check if some documents exist
          if (!!inputs.documents.length) {
            documents = await makeOfferFilesUpload(inputs.documents, id)
          }

          await makeNewOffer(id, {
            questions: newQuestions,
            documents,
            message: null,
          })

          toggle()
          clearForm()
          updateIndicativeOffersCount() // update indicative offers count on success! +1
        } catch (error) {
          setError(error)
        }

        setMakeOffer(!makeOffer)
      }

      submitIndicativeOffer()
    }
  }, [
    clearForm,
    isShowing,
    inputs,
    makeOffer,
    questions,
    toggle,
    id,
    updateIndicativeOffersCount,
  ])

  if (!id) {
    return null
  }

  return (
    <Fragment>
      <Button
        onClick={toggle}
        secondary={isShowing && loading}
        disabled={buttonDisabled}
      >
        {isShowing && loading ? <Spinner size="xs" margin="none" /> : label}
      </Button>

      {/** Arrange a meeting Modal */}
      <Modal hide={toggle} {...{isShowing}}>
        <h2>Submit Indicative Offer</h2>

        <form
          onSubmit={async (e: any) => {
            e.preventDefault()
            setMakeOffer(!makeOffer)
          }}
        >
          <ErrorMessage {...{error}} />

          <fieldset
            disabled={loading || makeOffer}
            aria-busy={loading || makeOffer}
          >
            <QuestionsGrid>
              {questions.map(({question, ...others}, index) => {
                return (
                  <Section key={index}>
                    <label htmlFor={others.id} style={{height: '30px'}}>
                      {question}
                    </label>
                    <Textarea
                      name={others.id}
                      onChange={handleChange}
                      placeholder="Please provide your answer here."
                      className="text-base black"
                      rows={2}
                    />
                  </Section>
                )
              })}
            </QuestionsGrid>

            <Dropzone
              name="documents"
              onChange={handleChange}
              values={inputs.documents}
            />

            <Actions>
              <Button onClick={toggle} secondary>
                Cancel
              </Button>

              <Button type="submit" disabled={!enableSubmit}>
                {makeOffer ? 'Please wait...' : 'Submit'}
              </Button>
            </Actions>
          </fieldset>
        </form>
      </Modal>
    </Fragment>
  )
}

export default IndicativeOffer
