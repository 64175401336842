import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'

import {ModalLayout} from './styles'

export const Close = ({onClick}: {onClick: () => void}) => (
  <span className="close" onClick={onClick}>
    &times;
  </span>
)

export interface ModalProps {
  hide?: () => void
  isShowing: boolean
  neat?: boolean
  title?: string
  headerComponent?: React.ReactNode
  maxWidth?: string
}

const Modal: React.FC<ModalProps> = ({
  hide,
  isShowing,
  neat,
  title,
  headerComponent,
  maxWidth,
  children,
}) => {
  if (!isShowing) {
    return null
  }

  return ReactDOM.createPortal(
    <Fragment>
      <ModalLayout show={isShowing} neat={neat} maxWidth={maxWidth}>
        <div className="modal-container">
          {headerComponent ? (
            <div className="modal-header">{headerComponent}</div>
          ) : null}

          <div className="modal-body">
            {title ? <h4 className="modal-title">{title}</h4> : null}

            {hide ? <Close onClick={hide} /> : null}

            <div className="modal-content">{children}</div>
          </div>
        </div>
      </ModalLayout>
    </Fragment>,
    document.body
  )
}

export default Modal
