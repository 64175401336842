import {useState} from 'react'

import {DATAROOM_STATUS} from 'config/data/deal-interest.config'
import {DealInterest} from 'types'
import {hasNdaAccess} from 'services/deal-interests'
import {useAuth} from 'hooks/useAuth'

const STEPS = Object.freeze({
  TO_REQUEST_NDA_ACCESS: 1 as const,
  TO_SIGN_NDA: 2 as const,
  TO_WAIT_FOR_DECK_ACCESS: 3 as const,
  TO_SUBMIT_INDICATIVE_OFFERS: 4 as const,
  TO_SUBMIT_FINAL_OFFER: 5 as const,
})

const getSignNdaStepTitle = (
  hasSignedNDA: boolean,
  isUserCertified: boolean,
  short?: boolean
) => {
  switch (hasSignedNDA) {
    case true:
      return isUserCertified ? 'Signed NDA' : short ? 'Certify' : 'Self-certify'
    case false:
      return isUserCertified
        ? 'Sign NDA'
        : short
        ? 'Sign NDA & Certify'
        : 'Sign NDA & Self-certify'
    default:
      return ''
  }
}

const getSignNdaStepDescription = (
  hasSignedNDA: boolean,
  isUserCertified: boolean
) => {
  switch (hasSignedNDA) {
    case true:
      return isUserCertified
        ? 'Please follow the steps to review and sign the Non-Disclosure Agreement and confirm your classification status if applicable.'
        : 'Please proceed to confirm your classification status and self-certify if applicable.'
    case false:
      return isUserCertified
        ? 'Please follow the steps to review and sign the Non-Disclosure Agreement.'
        : 'Please follow the steps to review and sign the Non-Disclosure Agreement and confirm your classification status if applicable.'
    default:
      return ''
  }
}

export type DealProgressionStepId =
  | 'request_nda_access'
  | 'sign_nda'
  | 'wait_for_deck_access'
  | 'submit_indicative_offer'
  | 'submit_final_offer'

export type DealProgressionStatus = 'need_user_action' | 'need_approve' | 'done'

export type DealProgressionStep = {
  id: DealProgressionStepId
  title: string
  titleShort: string
  description?: string
  current: boolean
  status: DealProgressionStatus
  statusText?: string
}

const useDealProgression = (dealInterest?: DealInterest) => {
  const {isAuthUserSelfCertified} = useAuth()

  const {
    isInvitedUser = false,
    hasSignedNDA = false,
    nbIndicativeOffers = 0,
    dataroomRequestStatus = null,
    hasMadeFinalOffer = false,
    status,
  } = dealInterest ?? {}

  const hasUserNdaAccess = hasNdaAccess(status, isInvitedUser)
  const hasDataroomAccess = dataroomRequestStatus === DATAROOM_STATUS.APPROVED

  const [countIndicativeOffers, setCountIndicativeOffers] =
    useState(nbIndicativeOffers)
  const [finalOfferStatus, setFinalOfferStatus] = useState(hasMadeFinalOffer)

  let currentStep: number
  // 1. need to request NDA access
  if (!hasUserNdaAccess) {
    currentStep = STEPS.TO_REQUEST_NDA_ACCESS
  } else if (!hasSignedNDA || !isAuthUserSelfCertified) {
    // 2. need to sign NDA or self-certify (or both)
    currentStep = STEPS.TO_SIGN_NDA
  } else if (status === 'DECK_REQUESTED') {
    // 3. has to wait for deck access
    currentStep = STEPS.TO_WAIT_FOR_DECK_ACCESS
  } else if (!hasDataroomAccess) {
    // 4. need to submit indicative offers
    currentStep = STEPS.TO_SUBMIT_INDICATIVE_OFFERS
  } else if (hasDataroomAccess) {
    // 5. need to submit final offer
    currentStep = STEPS.TO_SUBMIT_FINAL_OFFER
  } else {
    currentStep = -1
  }

  const updateIndicativeOffersCount = () => {
    setCountIndicativeOffers(prev => prev + 1)
  }
  const updateFinalOfferStatus = () => {
    setFinalOfferStatus(true)
  }

  const steps: DealProgressionStep[] = [
    {
      id: 'request_nda_access',
      title: 'Request NDA access',
      titleShort: 'NDA Access',
      description:
        status === 'NDA_REQUESTED'
          ? `Your interest has been registered. You will recieve an email once you've been given access to this deal.`
          : `To learn more about this opportunity, please proceed to register your interest.`,
      current: currentStep === STEPS.TO_REQUEST_NDA_ACCESS,
      status:
        currentStep > STEPS.TO_REQUEST_NDA_ACCESS
          ? 'done'
          : status === 'NDA_REQUESTED'
          ? 'need_approve'
          : 'need_user_action',
      statusText:
        currentStep > STEPS.TO_REQUEST_NDA_ACCESS
          ? 'Approved'
          : status === 'NDA_REQUESTED'
          ? 'Awaiting Approval'
          : 'Request Access',
    },
    {
      id: 'sign_nda',
      title: getSignNdaStepTitle(hasSignedNDA, isAuthUserSelfCertified),
      titleShort: getSignNdaStepTitle(
        hasSignedNDA,
        isAuthUserSelfCertified,
        true
      ),
      description: getSignNdaStepDescription(
        hasSignedNDA,
        isAuthUserSelfCertified
      ),
      current: currentStep === STEPS.TO_SIGN_NDA,
      status: currentStep > STEPS.TO_SIGN_NDA ? 'done' : 'need_user_action',
      statusText: currentStep > STEPS.TO_SIGN_NDA ? 'Signed' : 'Sign NDA',
    },
    {
      id: 'wait_for_deck_access',
      title: 'Request Deck Access',
      titleShort: 'Deck Access',
      description:
        status === 'DECK_REQUESTED'
          ? `Pending approval. You will receive an email when access has been granted.`
          : `Request access to the Information Memorandum or Deck for this deal.`,
      current: currentStep === STEPS.TO_WAIT_FOR_DECK_ACCESS,
      status:
        currentStep > STEPS.TO_WAIT_FOR_DECK_ACCESS ? 'done' : 'need_approve',
      statusText:
        currentStep > STEPS.TO_WAIT_FOR_DECK_ACCESS
          ? 'Approved'
          : 'Awaiting Approval',
    },
    {
      id: 'submit_indicative_offer',
      title: `Submit Indicative Offer (${countIndicativeOffers}/2)`,
      titleShort: 'Indicative Offer',
      description:
        countIndicativeOffers < 1
          ? `Click on the 'Submit Offer' button to upload your Letter of Intent for this opportunity.`
          : `Submit another LOI or re-submit an earlier indicative offer.`,
      current: currentStep === STEPS.TO_SUBMIT_INDICATIVE_OFFERS,
      status:
        currentStep > STEPS.TO_SUBMIT_INDICATIVE_OFFERS
          ? 'done'
          : countIndicativeOffers < 2
          ? 'need_user_action'
          : 'need_approve',
      statusText:
        currentStep > STEPS.TO_SUBMIT_INDICATIVE_OFFERS
          ? 'Submitted'
          : countIndicativeOffers < 2
          ? 'Submit Offer'
          : 'Awaiting Approval',
    },
    {
      id: 'submit_final_offer',
      title: `Submit Final Offer (${finalOfferStatus ? '1' : '0'}/1)`,
      titleShort: 'Final Offer',
      description: `Click on the 'Submit Final Offer' button to submit your offer.`,
      current: currentStep === STEPS.TO_SUBMIT_FINAL_OFFER,
      status:
        currentStep > STEPS.TO_SUBMIT_FINAL_OFFER
          ? 'done'
          : finalOfferStatus
          ? 'need_approve'
          : 'need_user_action',
      statusText:
        currentStep > STEPS.TO_SUBMIT_FINAL_OFFER
          ? 'Submitted'
          : finalOfferStatus
          ? 'Submitted'
          : 'Submit Final Offer',
    },
  ]

  return {
    steps,
    updateIndicativeOffersCount,
    updateFinalOfferStatus,
    hasSignedNDA,
  }
}

export default useDealProgression
