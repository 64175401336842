import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'

import Card from 'components/Card'
import DragDrop from 'components/DragDrop'

interface TeaserProps {
  disabled?: boolean
  onRemove: (files: any[], update?: boolean) => void
}

const Teaser: React.FC<TeaserProps> = ({disabled, onRemove}) => {
  const {control, errors} = useFormContext()

  return (
    <Card padding title="Deal Teaser" subtitle="Upload Teaser documents below.">
      <Controller
        control={control}
        name="teaserFile"
        defaultValue={[]}
        render={({onChange, value}) => (
          <DragDrop
            {...{control, onChange, onRemove, value, disabled}}
            name="teaserFile"
            maxSize={10000000}
            error={{
              status: errors?.teaserFile,
              message: errors?.teaserFile?.message,
            }}
            accept=".pdf"
          />
        )}
      />
    </Card>
  )
}

export default Teaser
