import React from 'react'
import styled from 'styled-components'

import Colors from 'styles/colors'

const EmptyDeals = styled.div`
  padding: 1.8rem;

  border: 1px solid ${Colors.LIGHT_GREY};
  box-sizing: border-box;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Empty: React.FC = () => {
  return (
    <EmptyDeals>
      <h2>You don't have any deals</h2>

      <span className="text-base  grey">
        Complete your profile to receive deal invitations, or view our open
        deals to register interest.
      </span>
    </EmptyDeals>
  )
}

export default Empty
