import React, {useState, Fragment, useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'

import {MessageProps} from 'types'
import useStepper from 'hooks/useStepper'
import {generateSigner} from 'utils/nda'

import Modal from 'components/Modal'
import Button from 'components/styles/Button'
import Toast from 'components/Toast'

import Step1 from './components/Step1'
import Step2 from './components/Step2'

import {Actions} from './styles'

type FormData = {
  firstName: string
  lastName: string
  isCompany: boolean
  addressLine1: string
  addressLine2: string
  city: string
  postcode: string
  companyName: string
  registrationNumber: string
  country: string
  companyAddressLine1: string
  companyAddressLine2: string
  companyCity: string
  companyPostcode: string
  date: Date
  [x: string]: any
}

type Props = {
  show: boolean
  onClose: () => void
  onProceed: (data: any) => void
}

const OverrideDeck = ({show, onClose, onProceed}: Props) => {
  const {
    step: formStep,
    nextStep,
    previousStep,
    setStep: setFormStep,
  } = useStepper()

  const formProps = useForm()
  const {
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: {isSubmitting},
  } = formProps

  const isCompany = watch('isCompany')
  useEffect(() => {
    if (isCompany) {
      setValue('addressLine1', '')
      setValue('addressLine2', '')
      setValue('city', '')
      setValue('postcode', '')
    }
  }, [isCompany, setValue])

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'success',
  })

  const handleClose = () => {
    reset()
    onClose()
    setFormStep(0)
  }

  const isLastStep =
    (isCompany && formStep === 1) || (!isCompany && formStep === 0)

  const onSubmit = (formValues: FormData) => {
    if (isLastStep) {
      const signer = {
        ...generateSigner(formValues),
        signedNdaOn: formValues.date,
      }

      onProceed(signer)
      handleClose()
    } else {
      nextStep()
    }
  }

  return (
    <Fragment>
      {/** Sign NDA and Self certification */}
      {show && (
        <Modal hide={handleClose} isShowing maxWidth="700px">
          <FormProvider {...formProps}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                {/** STEP 1 Your Details */}
                <Step1 {...{formStep, isCompany}} />

                {/** STEP 2 Additional Company's Details */}
                <Step2 {...{formStep}} />

                <Actions>
                  <Button
                    type="button"
                    onClick={() => {
                      if (formStep < 1) {
                        handleClose()
                      } else {
                        previousStep()
                      }
                    }}
                    secondary
                  >
                    {formStep >= 1 ? 'Back' : 'Cancel'}
                  </Button>

                  <Button type="submit">
                    {isCompany && !isLastStep ? 'Continue' : 'Sign'}
                  </Button>
                </Actions>
              </fieldset>
            </form>
          </FormProvider>
        </Modal>
      )}

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default OverrideDeck
