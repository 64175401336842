import React, {useEffect, Fragment} from 'react'
import {useDropzone, DropzoneInputProps} from 'react-dropzone'

import {trimString} from 'utils/strings'

import ErrorMessage from 'components/ErrorMessage'

import {Container, FileResults} from './styles'

export interface DropzoneProps extends DropzoneInputProps {
  values?: any[]
  maxFiles?: number
  maxSize?: number
}

const showError = (rejection: any) => {
  const name = rejection?.file.name
  const message = rejection?.errors[0].message
  const error = {message: `${name} - ${message}`}

  return <ErrorMessage {...{error}} />
}

const Close = ({onClick}: {onClick: () => void}) => (
  <span onClick={onClick}>&times;</span>
)

const showFile = (file: any, callback: (f: any) => void) => {
  return (
    <li className="text-base" key={file?.path}>
      {trimString(file?.name, 30)}
      <Close onClick={() => callback(file)} />
    </li>
  )
}

const Dropzone: React.FC<DropzoneProps> = ({
  className,
  onChange,
  values = [],
  maxFiles = 5,
  maxSize = 10000000,
  name,
  accept = '.pdf',
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    // onDropAccepted: onChange,
    // getFilesFromEvent: onChange,
  })

  useEffect(() => {
    if (acceptedFiles && onChange && name) {
      const newUpdate = {
        target: {name, type: 'file', files: acceptedFiles},
      } as any
      onChange(newUpdate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  const update = (file: any) => {
    if (onChange && name) {
      const newUpdate = {
        target: {name, type: 'file'},
        removeFile: file,
      } as any // React.ChangeEvent<HTMLInputElement>
      onChange(newUpdate)
    }
  }

  return (
    <div {...{className}}>
      <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <input {...getInputProps()} {...{name}} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </Container>

      <FileResults>
        {Array.isArray(values) && values.length > 0 && (
          <Fragment>
            <h4>Accepted Files</h4>

            <div className="results">
              {values.map((file: any) => showFile(file, update))}
            </div>
          </Fragment>
        )}

        {Array.isArray(fileRejections) && fileRejections.length > 0 && (
          <Fragment>
            <h4>Rejected Files</h4>
            <div className="results">
              {fileRejections.map(rejection => showError(rejection))}
            </div>
          </Fragment>
        )}
      </FileResults>
    </div>
  )
}

export default Dropzone
