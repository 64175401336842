import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core'
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary'

import {Container, Typography, ButtonBase} from '@material-ui/core'
import SadIcon from '@material-ui/icons/SentimentDissatisfied'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'

import Button from 'components/styles/Button'

export const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const classes = useStyles()

  const [showDetailedError, setShowDetailedError] = useState(false)
  const toggleDetailedError = () => setShowDetailedError(!showDetailedError)

  return (
    <div className={classes.errorFallbackContainer}>
      <Container maxWidth="md" className={classes.errorFallbackContainer}>
        <SadIcon className={classes.errorFallbackIcon} />

        <Typography
          variant="h5"
          component="h1"
          className={classes.errorFallbackTitle}
        >
          {'There has been an error while trying to fulfill your request.'}
        </Typography>

        <div className={classes.errorFallbackDetails}>
          <ButtonBase
            onClick={toggleDetailedError}
            className={classes.errorFallbackToggleDetails}
            disableRipple
            disableTouchRipple
          >
            {showDetailedError ? <ArrowDownIcon /> : <ArrowRightIcon />}

            <Typography
              component="span"
              className={classes.errorFallbackShowMoreTitle}
            >
              {showDetailedError
                ? 'Hide detailed error message'
                : 'Show detailed error message'}
            </Typography>
          </ButtonBase>

          {showDetailedError ? (
            <Typography
              component="p"
              className={classes.errorFallbackErrorMessage}
            >
              {error.message}
            </Typography>
          ) : null}
        </div>

        <Button onClick={resetErrorBoundary}>{'Retry'}</Button>
      </Container>
    </div>
  )
}

const ErrorBoundary: React.FC = ({children}) => {
  const handleReset = () => {
    window.location.reload()
  }

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={handleReset}>
      {children}
    </ReactErrorBoundary>
  )
}

const useStyles = makeStyles(theme => ({
  errorFallbackRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  errorFallbackContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  errorFallbackIcon: {
    marginBottom: theme.spacing(4),
    fontSize: 72,
    [theme.breakpoints.down('sm')]: {
      fontSize: 58,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 48,
    },
  },
  errorFallbackTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 24,
  },
  errorFallbackDetails: {
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
  },
  errorFallbackToggleDetails: {
    padding: theme.spacing(1),
    textAlign: 'left',
    width: '100%',
  },
  errorFallbackShowMoreTitle: {
    marginLeft: theme.spacing(1),
    fontSize: '0.9rem',
  },
  errorFallbackErrorMessage: {
    padding: theme.spacing(2, 4),
    fontSize: '0.9rem',
    textAlign: 'left',
    fontStyle: 'italic',
  },
}))

export default ErrorBoundary
