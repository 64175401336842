import React from 'react'
import {useFormContext} from 'react-hook-form'

import Checkbox from 'components/Checkbox'
import Input from 'components/Input'

import {Section} from '../../styles'

import {StepSubtitle} from '../../styles'

interface YourDetailsProps {
  formStep: number
  isCompany: boolean
}

const YourDetails: React.FC<YourDetailsProps> = ({formStep, isCompany}) => {
  const {register, setValue, errors} = useFormContext()

  return (
    <Section show={formStep === 0}>
      <span className="text-lg grey">
        Before we can generate your NDA, we need to capture a few details
      </span>

      <StepSubtitle>Your Details</StepSubtitle>

      <div className="row">
        <Input
          name="firstName"
          label="First Name"
          type="text"
          placeholder="First name"
          onClear={() => setValue('firstName', '')}
          error={{
            status: errors.firstName,
            message: 'First name is required',
          }}
          ref={register({
            required: true,
            maxLength: 80,
          })}
        />

        <Input
          name="lastName"
          label="Last Name"
          type="text"
          placeholder="Last name"
          onClear={() => setValue('lastName', '')}
          error={{
            status: errors.firstName,
            message: 'First name is required',
          }}
          ref={register({
            required: true,
            maxLength: 80,
          })}
        />
      </div>

      <Checkbox
        name="isCompany"
        label="Are you signing NDA on behalf of a company?"
        ref={register}
      />

      <StepSubtitle>Your Address</StepSubtitle>

      <div className="row">
        <Input
          name="addressLine1"
          label="Address Line 1"
          type="text"
          placeholder="Address Line 1"
          onClear={() => setValue('addressLine1', '')}
          error={{
            status: errors.addressLine1,
            message: 'Address Line 1 is required',
          }}
          ref={register({
            required: !isCompany,
            maxLength: 200,
          })}
          disabled={isCompany}
        />

        <Input
          name="addressLine2"
          label="Address Line 2"
          type="text"
          placeholder="Address Line 2"
          onClear={() => setValue('addressLine2', '')}
          error={{
            status: errors.addressLine2,
            message: 'Address Line 2 requires text only',
          }}
          ref={register({
            maxLength: 100,
          })}
          disabled={isCompany}
        />
      </div>

      <div className="row">
        <Input
          name="city"
          label="City"
          type="text"
          placeholder="City"
          onClear={() => setValue('city', '')}
          error={{
            status: errors.city,
            message: 'City is required',
          }}
          ref={register({
            required: !isCompany,
            maxLength: 32,
          })}
          disabled={isCompany}
        />

        <Input
          name="postcode"
          label="Postcode"
          type="text"
          placeholder="Postcode"
          onClear={() => setValue('postcode', '')}
          error={{
            status: errors.postcode,
            message: 'Postcode is required',
          }}
          ref={register({
            required: !isCompany,
            maxLength: 32,
          })}
          disabled={isCompany}
        />
      </div>
    </Section>
  )
}

export default YourDetails
