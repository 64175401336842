import React from 'react'
import {useFormContext, Controller} from 'react-hook-form'
import DatePicker from 'react-datepicker'

import Card from 'components/Card'

import {CardContent, DatePickerContainer} from './styles'

const Deadline: React.FC = () => {
  const {control, errors} = useFormContext()

  return (
    <Card
      padding
      title="Deal Deadline"
      subtitle="Set a deadline for each stage of the deal. These are text boxes, and will not have material effect beyond displaying data to the user. If left empty, no text will display"
    >
      <CardContent>
        <div>
          <label className="text-label" htmlFor="indicativeOfferDeadline">
            Indicative Offers
          </label>
          <Controller
            name="indicativeOfferDeadline"
            control={control}
            render={({value, ...rest}) => (
              <>
                <DatePickerContainer
                  error={Boolean(errors.indicativeOfferDeadline)}
                >
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={value}
                    placeholderText="Click to select a date"
                    disabledKeyboardNavigation
                    {...rest}
                  />
                </DatePickerContainer>
                {Boolean(errors.indicativeOfferDeadline) && (
                  <span className="error">
                    {errors.indicativeOfferDeadline?.message}
                  </span>
                )}
              </>
            )}
          />
        </div>

        <div>
          <label className="text-label" htmlFor="indicativeOfferDeadline">
            Final Offers
          </label>
          <Controller
            name="finalOfferDeadline"
            control={control}
            render={({value, ...rest}) => (
              <>
                <DatePickerContainer error={Boolean(errors.finalOfferDeadline)}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={value}
                    placeholderText="Click to select a date"
                    disabledKeyboardNavigation
                    {...rest}
                  />
                </DatePickerContainer>
                {Boolean(errors.finalOfferDeadline) && (
                  <span className="error">
                    {errors.finalOfferDeadline?.message}
                  </span>
                )}
              </>
            )}
          />
        </div>
      </CardContent>
    </Card>
  )
}

export default Deadline
