import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import {pdfjs} from 'react-pdf'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-datepicker/dist/react-datepicker.css'

import App from './App'

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://5521dde584f142d3a2f38f261c1fc20a@o390735.ingest.sentry.io/5404134',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 1.0,
    environment: process.env.REACT_APP_ENV,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
