import styled from 'styled-components'

import Colors from 'styles/colors'

import GridLayout from 'components/styles/GridLayout'
import Breakpoints from 'styles/breakpoints'

export const Image = styled.img`
  width: 100%;
  height: 369px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid ${Colors.LIGHT_GREY};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
`

export const DealGrid = styled(GridLayout)`
  align-items: self-start;

  @media (min-width: ${Breakpoints.md}) {
    grid-template-columns: 45% 1fr;
  }
`

export const Header = styled.div<{showOptions: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > .options {
    display: ${({showOptions}) => (showOptions ? 'grid' : 'none')};
    grid-gap: 1em;
    grid-template-columns: auto auto auto;
  }
`
