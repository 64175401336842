import styled from 'styled-components'

import Colors from 'styles/colors'

import Card from 'components/Card'
import Button from 'components/styles/Button'

export const DealListCard = styled(Card)`
  .card-content {
    > div:not(:first-child) {
      margin-top: 24px;
    }
  }
`

export const ExpandButton = styled(Button)`
  margin: 0 auto;
  border: 1px solid ${Colors.LIGHT_GREY};
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
`
