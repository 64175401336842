import React, {Fragment} from 'react'

import Button from 'components/styles/Button'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'

type ExportModalProps = {
  show: boolean
  loading: boolean
  onExport: () => void
  onClose: () => void
}

const ExportModal = ({show, onExport, loading, onClose}: ExportModalProps) => {
  return (
    <Modal isShowing={show} hide={onClose} maxWidth="600px">
      <Fragment>
        <h2>Export User Data</h2>
        <p className="modal-inner-body">Export User Data?</p>
        <div className="modal-inner-btn">
          {!loading ? (
            <Fragment>
              <Button
                secondary
                className="modal-back-button"
                onClick={() => onClose()}
              >
                Go Back
              </Button>

              <Button onClick={onExport} disabled={loading}>
                Export Data
              </Button>
            </Fragment>
          ) : (
            <Spinner primary />
          )}
        </div>
      </Fragment>
    </Modal>
  )
}

export default ExportModal
