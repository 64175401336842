import React, {useEffect, useState} from 'react'

import {ROUTES} from 'config/routes.config'
import {
  localStorageSet,
  localStorageGet,
  localStorageKeys,
} from 'utils/localStorage'

import Button from 'components/styles/Button'
import InternalLink from 'components/InternalLink'

import {Container} from './styles'

const CookieBanner: React.FC = () => {
  const [visible, setVisible] = useState(false)

  const handleVisibility = () => {
    localStorageSet(localStorageKeys.COOKIE, 'false')
    setVisible(false)
  }

  useEffect(() => {
    const isDone = localStorageGet(localStorageKeys.COOKIE) || false

    if (!isDone) {
      setVisible(true)
    }
  }, [])

  return (
    <Container {...{visible}} className="banner">
      <h3>Cookies Consent</h3>

      <p>
        We use cookies to ensure that we provide you with the best possible
        experience. By using our website, you agree that we may store and access
        cookies on your device. You can learn more on cookies and how to mange
        your preferences in our{' '}
        <InternalLink styleless to={ROUTES.PRIVACY_POLICY}>
          Privacy & Cookies Policy.
        </InternalLink>
      </p>

      <Button onClick={handleVisibility}>Accept</Button>
    </Container>
  )
}

export default CookieBanner
