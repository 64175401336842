import * as Sentry from '@sentry/react'

import firebase from 'lib/firebase'
import axios from 'lib/axios'
import {EMAILS_API, EMAIL_VERIFICATION} from 'config/endpoints.config'

export const sendVerificationEmail = async (email: string) => {
  const token = await firebase.auth().currentUser?.getIdToken()

  await axios.post(
    `${EMAILS_API}?type=send-verification`,
    {
      to: email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const verifyEmail = async (email: string) => {
  await axios.put(`${EMAIL_VERIFICATION}`, {
    email,
  })
}

export const handleVerifyEmail = async (actionCode: string) => {
  try {
    await firebase.auth().applyActionCode(actionCode)
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}
