export const generateSigner = (values: any) => {
  const {
    isCompany = false,
    firstName = '',
    lastName = '',
    addressLine1 = '',
    addressLine2 = '',
    postcode = '',
    city = '',
    companyCity = '',
    companyPostcode = '',
    companyAddressLine1 = '',
    companyAddressLine2 = '',
  } = values ?? {}

  const signer = {
    name: `${firstName} ${lastName}`,
    streetAddress: '',
    city: '',
    postCode: '',
  }

  if (isCompany) {
    // Signed on behalf of a company
    const address = companyAddressLine2
      ? `${companyAddressLine1}, ${companyAddressLine2}`
      : `${companyAddressLine1}`

    signer.streetAddress = address
    signer.city = companyCity
    signer.postCode = companyPostcode
  } else {
    // Signed as a user
    const address = addressLine2
      ? `${addressLine1}, ${addressLine2}`
      : `${addressLine1}`

    signer.streetAddress = address
    signer.city = city
    signer.postCode = postcode
  }

  return signer
}
