import React from 'react'

import noblesLogo from 'assets/images/logo.svg'

import {LogoSize, LogoContainer} from './styles'

interface LogoProps {
  size?: LogoSize
  className?: string
  onClick?: () => void
}

const Logo: React.FC<LogoProps> = ({size = 'normal', ...props}) => (
  <LogoContainer {...{size, ...props}}>
    <img className="nobles-logo" src={noblesLogo} alt="Nobles & Co" />
  </LogoContainer>
)

export default Logo
