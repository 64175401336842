import styled from 'styled-components'

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-gap: 2rem;
`

export const ConfirmList = styled.ol`
  list-style-type: upper-roman;
  font-style: italic;

  margin-top: 0;

  padding: 0 3em;

  li:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const Boxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 120px 120px;
`
