import React, {Fragment, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {ValueType} from 'react-select'
import Spinner from 'components/Spinner'
import {AxiosError} from 'axios'

import {Deal, DealInterest, MessageProps} from 'types'
import {addUserToDeal, getDealsWithoutDealInterest} from 'services/deals'
import {queryClient} from 'lib/react-query'

import {Skeleton} from '@material-ui/lab'

import Button from 'components/styles/Button'
import Toast from 'components/Toast'
import Select, {Option} from 'components/Select'

import {
  AddToDealContainer,
  AddToDealDescription,
  AddToDealTitle,
} from './styles'

interface AdduserToDealProps {
  userId: string
  userEmail: string
}

const AdduserToDeal: React.FC<AdduserToDealProps> = ({userId, userEmail}) => {
  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  const [selectedDeal, setSelectedDeal] = useState<Option | null>(null)

  const {data: dealsWithoutDealInterest = [], status} = useQuery<Deal[], Error>(
    ['deals-without-deal-interest', userId],
    () => getDealsWithoutDealInterest(userId),
    {
      enabled: Boolean(userId),
    }
  )

  const addUserToDealMutation = useMutation<DealInterest, AxiosError>(
    () => addUserToDeal(userEmail, selectedDeal?.value as string),
    {
      onError: error => {
        setToast({
          value: error.response?.data?.title,
          type: 'error',
        })
      },
      onSuccess: () => {
        setSelectedDeal(null)
        setToast({
          value: 'User has been successfully added to deal',
          type: 'success',
        })
        queryClient.invalidateQueries(['deals-without-deal-interest', userId])
      },
    }
  )

  const dealOptions: Option[] = dealsWithoutDealInterest.map(deal => ({
    label: deal.name,
    value: deal.id,
  }))

  return (
    <Fragment>
      {status === 'loading' ? (
        <Skeleton variant="rect" height={40} width="100%" />
      ) : (
        <Fragment>
          <AddToDealTitle>Assign user to a Deal</AddToDealTitle>

          <AddToDealDescription>
            Manually assign user to a deal
          </AddToDealDescription>

          <AddToDealContainer>
            <div
              style={{
                flex: 1,
              }}
            >
              <Select
                options={dealOptions}
                value={selectedDeal}
                onChange={(selected: ValueType<Option>) => {
                  setSelectedDeal(selected as Option)
                }}
                full
              />
            </div>

            <Button
              onClick={() => {
                addUserToDealMutation.mutate()
              }}
              disabled={!selectedDeal || addUserToDealMutation.isLoading}
              flex
            >
              Assign to Deal
              {addUserToDealMutation.isLoading ? (
                <span style={{marginLeft: 12}}>
                  <Spinner size="xs" margin="none" />
                </span>
              ) : null}
            </Button>
          </AddToDealContainer>
        </Fragment>
      )}

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default AdduserToDeal
