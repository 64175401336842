import React from 'react'
import ReactSelect, {Props as ReactSelectProps, components} from 'react-select'

import Colors from 'styles/colors'

import {SelectContainer} from './styles'

export type Option = {
  value: string
  label: string
}

export interface SelectProps extends ReactSelectProps {
  className?: string
  label?: string
  info?: string
  full?: boolean
  labelTop?: boolean
  labelLeft?: boolean
  error?: {
    status?: boolean
    message?: string
  }
}

const Select: React.FC<SelectProps> = ({
  className,
  label,
  info,
  error,
  labelTop,
  labelLeft,
  full,
  ...props
}) => {
  const SELECT_STYLING = {
    option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
      return {
        ...styles,
        color: isSelected ? Colors.WHITE : data.color,
        backgroundColor: isSelected ? Colors.BLACK : data.backgroundColor,
      }
    },
    input: (base: any) => ({
      ...base,
    }),
    control: (base: any) => ({
      ...base,
      paddingLeft: '5px',
      color: error?.status ? Colors.ERROR : Colors.SECONDARY,
      border: error?.status
        ? `2px solid ${Colors.ERROR}`
        : `2px solid ${Colors.LIGHT_GREY}`,
      '&:hover': {
        border: error?.status
          ? `2px solid ${Colors.ERROR}`
          : `2px solid ${Colors.LIGHT_GREY}`,
      },
      borderRadius: '4px',
      boxShadow: 'none',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: Colors.SECONDARY_25,
    }),
  }

  // Override auto complete rule (i.e: disabled last pass proposition)
  const Input = ({autoComplete, ...props}: any) => (
    <components.Input {...props} autoComplete="new-password" />
  )

  return (
    <SelectContainer {...{className, full, labelTop, labelLeft}}>
      {label && <label className="text-label">{label}</label>}

      <ReactSelect
        id="dropdown"
        {...props}
        styles={SELECT_STYLING}
        components={{
          Input,
        }}
      />

      {!error?.status && info && <label className="input-info">{info}</label>}
      {error?.status && error?.message && (
        <span className="error">{error.message}</span>
      )}
    </SelectContainer>
  )
}

export default Select
