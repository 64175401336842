import React from 'react'
import styled, {css} from 'styled-components'

import Colors from 'styles/colors'
import {FAST_TRANSITION} from 'styles/globalStyles'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  secondary?: boolean
  styleless?: boolean
  center?: boolean
  full?: boolean
  flex?: boolean
}

const stylelessStyling = css`
  background-color: transparent;
  border: none;
  color: ${Colors.BLACK};

  &:disabled {
    color: ${Colors.DARK_GREY};
  }
`

const defaultStyling = css<ButtonProps>`
  background-color: ${({secondary}) =>
    secondary ? 'transparent' : Colors.BLACK};
  border: 2px solid ${Colors.BLACK};
  border-radius: 4px;
  color: ${({secondary}) => (secondary ? Colors.BLACK : Colors.WHITE)};

  &:hover {
    background-color: ${({secondary}) =>
      secondary ? 'transparent' : Colors.BUTTON_HOVER_BLUE};
    border-color: ${Colors.BUTTON_HOVER_BLUE};
    color: ${({secondary}) =>
      secondary ? Colors.BUTTON_HOVER_BLUE : Colors.WHITE};
  }

  &:focus {
    background-color: ${({secondary}) =>
      secondary ? 'transparent' : Colors.BUTTON_CLICKED_BLUE};
    border-color: ${Colors.BUTTON_CLICKED_BLUE};
    color: ${({secondary}) =>
      secondary ? Colors.BUTTON_CLICKED_BLUE : Colors.WHITE};
  }

  &:disabled {
    color: ${Colors.DARK_GREY};
    background-color: ${Colors.LIGHT_GREY};
    border: 2px solid ${Colors.LIGHT_GREY};
  }
`

const flexStyling = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled.button<ButtonProps>`
  ${({styleless}) => (styleless ? stylelessStyling : defaultStyling)};
  ${({center}) => center && `align-self: center;`};

  ${FAST_TRANSITION};

  padding: 6px 16px;

  width: ${({full}) => (full ? `100%` : `auto`)};

  ${({flex}) => (flex ? flexStyling : undefined)};

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  cursor: pointer;
  &:disabled {
    cursor: unset;
  }
`

export default Button
