import React, {Fragment} from 'react'

const Statement4 = () => {
  return (
    <Fragment>
      <p className="text-12 grey justify">
        As defined in Article 19 of the FPO
      </p>
    </Fragment>
  )
}

export default Statement4
