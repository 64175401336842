import * as yup from 'yup'

// const MAX_FILE_SIZE = 10000000 // 10 mb
// const MAX_IMAGE_SIZE = 4000000 // 4mb

// let fileName = ''

/**
 * Create Deal - Form input validations
 */
export const createDealValidationSchema = yup.object().shape({
  // // // // // //
  // OVERVIEW
  // // // // // //
  type: yup.mixed().required('Required'),
  name: yup.string().required('Required'),
  company: yup.string().required('Required'),
  sector: yup.mixed().required('Required'),
  featuredImage: yup
    .array()
    // .test('fileSize', 'Your image is too large', (files: any) => {
    //   return files[0] && files[0].size <= MAX_IMAGE_SIZE
    // })
    .min(1, 'Required')
    .max(1, 'Only a maximum of 1 file is allowed'),
  caplinkedWorkspaceId: yup.mixed().required('Required'),

  // // // // // //
  // DETAILS
  // // // // // //
  investmentSize: yup.mixed().required('Required'),
  contactName: yup.string().required('Required'),
  phone: yup
    .string()
    .required('Required')
    .matches(/^$|[0-9\s]{8,}/, 'You need to provide a valid phone number'),
  email: yup
    .string()
    .required('Required')
    .email('You need to provide a valid email '),
  position: yup.string().required('Required'),

  // // // // // //
  // TEASER
  // // // // // //
  teaserFile: yup
    .array()
    // .test('fileSize', 'Your file is too large', (files: any) => {
    //   return files[0] && files[0].size <= MAX_FILE_SIZE
    // })
    .min(1, 'Required')
    .max(1, 'Only a maximum of 1 file is allowed'),

  // // // // // //
  // DECK - we are missing file size in each file to make size control possible
  // // // // // //
  deskFiles: yup
    .array()
    // .test(
    //   'fileSize',
    //   () => `${fileName || 'File'} is too large`,
    //   (files: any) =>
    //     files.every((f: File) => {
    //       if (f.size > MAX_FILE_SIZE) fileName = f.name
    //       return f.size <= MAX_FILE_SIZE
    //     })
    // )
    // .max(5, 'Only a maximum of 5 files are allowed')
    .min(1, 'Required')
    .max(5, 'Only a maximum of 5 files are allowed'),

  // // // // // //
  // NDA document (editable docx)
  // // // // // //
  ndaFileDocument: yup
    .array()
    .min(1, 'Required')
    .max(1, 'Only a maximum of 1 file is allowed'),

  // // // // // //
  // NDA
  // // // // // //
  ndaFile: yup.mixed().test('content', 'NDA is required', value => {
    return value && value.getCurrentContent().getPlainText() !== ''
  }),

  // // // // // //
  // Video
  // // // // // //
  vimeo: yup.string().matches(
    // eslint-disable-next-line
    /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)|(^$)/,
    'You need to provide a valid vimeo url'
  ),

  // // // // // //
  // Description
  // // // // // //
  description: yup
    .string()
    .required('Description is required')
    .max(200, 'Only a maximum of 200 characters is allowed'),

  // // // // // //
  // Deadlines
  // // // // // //
  indicativeOfferDeadline: yup
    .date()
    .required('Indicative offer deadline is required'),
  finalOfferDeadline: yup
    .date()
    .min(
      yup.ref('indicativeOfferDeadline'),
      'Final offer deadline should be after the indicative offer deadline'
    ),
})
