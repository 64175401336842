import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

import Colors from 'styles/colors'

export const PageTitleContainer = styled.div<{paddingTop: boolean}>`
  width: 100%;
  background-color: ${Colors.WHITE};
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  padding-top: ${({paddingTop}) => (paddingTop ? `16px` : `0`)};
  @media only screen and (max-width: ${Breakpoints.sm}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const PageTitleText = styled.h2`
  font-size: 21px;
  line-height: 32px;
  color: ${Colors.BLACK};
  margin: 0;
  font-weight: 600;
`
