import React from 'react'

import {Label} from './styles'

interface AccessBtnProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: 'accept' | 'reject'
  borderRadiusLeft?: boolean
  borderRadiusRight?: boolean
}

const AccessBtn: React.FC<AccessBtnProps> = ({
  id,
  value,
  onClick,
  checked,
  disabled,
  type,
  borderRadiusLeft,
  borderRadiusRight,
}) => {
  return (
    <div>
      <input
        id={id}
        name={id}
        type="radio"
        checked={checked}
        value={value}
        onClick={onClick}
        disabled={disabled}
      />

      <Label
        htmlFor={id}
        type={type}
        checked={checked}
        disabled={disabled}
        borderRadiusLeft={borderRadiusLeft}
        borderRadiusRight={borderRadiusRight}
      >
        {type === 'accept' && (checked ? 'Accepted' : 'Accept')}
        {type === 'reject' && (checked ? 'Rejected' : 'Reject')}
      </Label>
    </div>
  )
}

export default AccessBtn
