import React, {useEffect, useState, useRef} from 'react'
import {Link} from 'react-router-dom'
import Lottie from 'react-lottie'

import {ROUTES} from 'config/routes.config'
import {MessageProps} from 'types'
import {sendVerificationEmail} from 'services/email'

import Button from 'components/styles/Button'
import Spinner from 'components/Spinner'

import * as animationData from './email.json'
import {Container} from './styles'

const DEFAULT_TIMER = 60

interface EmailProps {
  email: string
  setToast: (value: React.SetStateAction<MessageProps>) => void
}

const Email: React.FC<EmailProps> = ({email, setToast}) => {
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = React.useState(DEFAULT_TIMER)

  const timer = useRef(-1)

  const isDisabled = loading || counter !== 0

  useEffect(() => {
    if (loading) {
      const triggerEmail = async () => {
        try {
          await sendVerificationEmail(email)
        } catch (error) {
          setToast({
            value: error.response?.data?.title,
            type: 'error',
          })
        }

        setLoading(false)
      }

      triggerEmail()
    }
  }, [email, loading, setToast])

  useEffect(() => {
    if (counter > 0) {
      timer.current = setTimeout(() => setCounter(counter - 1), 1000)
    }

    return () => clearTimeout(timer?.current)
  }, [counter])

  const handleResendEmail = () => {
    if (counter === 0) {
      setCounter(DEFAULT_TIMER)
    }

    setLoading(!loading)
  }

  return (
    <Container>
      <div>
        <Lottie
          options={{
            // @ts-ignore
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
            loop: true,
            autoplay: true,
          }}
          width={200}
          height={200}
        />
      </div>

      <h2 className="text-center">We’ve sent you an email.</h2>

      <span className="grey text-lg text-center">
        Please follow the link inside so that we can verify your email address.
        Once verified, you’ll be able to login.
      </span>

      <Button
        type="button"
        onClick={handleResendEmail}
        disabled={isDisabled}
        full
      >
        {loading ? (
          <Spinner size="xs" margin="none" />
        ) : (
          `Resend Verification Email ${counter !== 0 ? `(${counter}s)` : ''}`
        )}
      </Button>

      <Link className="router-link-register text-center" to={ROUTES.HOME}>
        Home
      </Link>
    </Container>
  )
}

export default Email
