import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import ReactGA from 'react-ga'

// https://raptis.wtf/blog/custom-hook-to-connect-google-analytics-in-react/
const useGoogleAnalytics = () => {
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS
  // Enable debug mode on the local development environment
  //   const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  TRACKING_ID && ReactGA.initialize(TRACKING_ID)

  const sendPageview = (path: any) => {
    ReactGA.set({page: path})
    ReactGA.pageview(path)
  }

  const location = useLocation()
  useEffect(() => {
    if (location) {
      const currentPath = location.pathname + location.search
      sendPageview(currentPath)
    }
  }, [location])
}

export default useGoogleAnalytics
