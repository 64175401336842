import React from 'react'
import {useQuery} from 'react-query'

import {Deal} from 'types'
import {getOverviewDeals} from 'services/deals'
import {useAuth} from 'hooks/useAuth'

import {Skeleton} from '@material-ui/lab'

import DealProgress from 'components/DealProgress'

import {DealListCard} from './styles'

const Overview: React.FC = () => {
  const {user} = useAuth()
  const userId = user?.uid ?? ''

  const {data: yourDeals = [], status} = useQuery<Deal[], Error>(
    ['overview-deals', userId],
    () => getOverviewDeals(userId),
    {
      enabled: Boolean(userId),
    }
  )

  if (status === 'loading') {
    return <Skeleton variant="rect" width="100%" height={445} />
  }

  if (!Array.isArray(yourDeals) || yourDeals.length < 1) {
    return null
  }

  return (
    <DealListCard padding title="Overview">
      {yourDeals.map(deal => (
        <DealProgress key={deal.id} deal={deal} showDealTitle />
      ))}
    </DealListCard>
  )
}

export default Overview
