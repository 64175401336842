import {animated} from 'react-spring'
import styled from 'styled-components'

import Colors from 'styles/colors'
import {ToastType} from 'types'

type ToastContainerProps = {
  type: ToastType
}

const selectMessageBackground = (type: ToastType) => {
  switch (type) {
    case 'error':
      return Colors.ERROR
    case 'info':
      return Colors.WARNING
    default:
      return Colors.SUCCESS
  }
}

export const ToastContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 12px;
  right: 12px;
  width: 100%;
  background-color: ${Colors.WHITE};
  border-left: 4px solid
    ${({type}: ToastContainerProps) => selectMessageBackground(type)};
  padding: 20px;
  z-index: 20;
  width: 300px;
  box-shadow: 0px 12px 15px 2px rgba(22, 28, 45, 0.1);
`
export const Message = styled.div`
  color: ${Colors.GREY};
  font-size: 1.2em;
  margin-bottom: 0.5em;
`
export const MessageHeader = styled.h1<ToastContainerProps>`
  font-size: 1.2em;
  margin-bottom: 0.5em;
  text-transform: capitalize;
  color: ${({type}: ToastContainerProps) => selectMessageBackground(type)};
`

export const Timer = styled(animated.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
`
