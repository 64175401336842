import styled from 'styled-components'

import Colors from 'styles/colors'

export const InterestsTitle = styled.p`
  margin-top: 0;
  margin-bottom: 22px;
  color: ${Colors.GREY};
  font-size: 18px;
  line-height: 28px;
`

export const Form = styled.form`
  fieldset {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;

    .section {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  }
`

export const FormSectionTitle = styled.p`
  margin: 0;
  font-size: 21px;
  line-height: 32px;
  font-weight: 600;
`
