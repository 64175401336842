import React, {useEffect, useState, Fragment, useRef} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {Ok} from 'react-ikonate'

import {MessageProps} from 'types'
import useGenerateNDA from 'hooks/useGenerateNDA'
import useStepper from 'hooks/useStepper'

import Button from 'components/styles/Button'
import Modal from 'components/Modal'
import Toast from 'components/Toast'
import Spinner from 'components/Spinner'

import YourDetails from './components/YourDetails'
import Certification from './components/Certification'
import Sign from './components/Sign'

import {
  Actions,
  DownloadEditableNdaButton,
  ModalSteps,
  Step,
  StepCount,
  StepCountText,
  StepTitle,
} from './styles'

export type FormData = {
  // your details
  firstName: string
  lastName: string
  isCompany: boolean
  addressLine1: string
  addressLine2: string
  city: string
  postcode: string
  // company details
  companyName: string
  registrationNumber: string
  country: string
  companyAddressLine1: string
  companyAddressLine2: string
  companyCity: string
  companyPostcode: string
  // anything else
  [key: string]: any
}

interface SignNdaProps {
  label: string
  dealId: string
  dealInterestId?: string
  dealName: string
  skipSignNda?: boolean
  isAuthUserSelfCertified?: boolean
  hasNdaFileDocument?: boolean
  isSigningNdaInProgress?: boolean
  buttonDisabled?: boolean
}

const SignNda: React.FC<SignNdaProps> = ({
  label,
  dealId,
  dealName,
  dealInterestId,
  skipSignNda,
  isAuthUserSelfCertified,
  hasNdaFileDocument,
  isSigningNdaInProgress,
  buttonDisabled,
}) => {
  const {
    loading,
    loadingReason,
    error,
    generateNDA,
    downloadNdaPdf,
    generateNdaDocusignUrl,
    generateSelfCertificationDocusignUrl,
    downloadEditableNda,
  } = useGenerateNDA(dealId, dealName, dealInterestId)

  const backButtonRef = useRef<HTMLButtonElement>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'success',
  })
  useEffect(() => {
    if (error) {
      setToast({
        type: 'error',
        value: error,
      })
    }
  }, [error])

  const [showSignNdaModal, setShowSignNdaModal] = useState(false)
  const handleSignNdaModalOpen = () => setShowSignNdaModal(true)
  const handleSignNdaModalClose = () => setShowSignNdaModal(false)

  const {
    step: formStep,
    nextStep,
    previousStep,
    setStep: setFormStep,
  } = useStepper()

  const formMethods = useForm()
  const {setValue, watch, handleSubmit} = formMethods

  const formValues = watch()
  const certificationType = formValues.certification
  const isCompany = formValues.isCompany

  const skipSelfCertification = isAuthUserSelfCertified && !isCompany

  useEffect(() => {
    // reset sign nda flow when modal closes
    const resetSignNdaProcess = () => {
      setFormStep(0)
    }

    if (!showSignNdaModal) {
      resetSignNdaProcess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSignNdaModal])

  useEffect(() => {
    if (isCompany) {
      setValue('addressLine1', '')
      setValue('addressLine2', '')
      setValue('city', '')
      setValue('postcode', '')
    }
  }, [isCompany, setValue])

  const onBack = () => {
    if (formStep < 1) {
      handleSignNdaModalClose()
    } else {
      if (formStep === 2 && skipSelfCertification) {
        setFormStep(0)
      } else {
        previousStep()
      }
    }
  }

  const onDownloadNdaPdf = () => {
    downloadNdaPdf(formValues)
  }

  const onDownloadEditableNda = () => {
    downloadEditableNda()
  }

  const onSubmit = async () => {
    if (formStep > 2) {
      return
    }
    if (skipSignNda && skipSelfCertification) {
      return
    }

    if (formStep === 0) {
      // your details
      if (skipSelfCertification) {
        setFormStep(2)
      } else {
        nextStep()
      }
    }
    if (formStep === 1) {
      // select self-certification type
      if (certificationType) {
        nextStep()
      }
    }
    if (formStep === 2) {
      // sign nda & self-certify
      if (skipSignNda) {
        await generateSelfCertificationDocusignUrl(formValues)
      } else {
        await generateNdaDocusignUrl(formValues)
      }
    }

    // remove focus from the buttons (in case this wasn't last step)
    backButtonRef?.current?.blur()
    submitButtonRef?.current?.blur()
  }

  const showDownloadNdaButton = formStep >= 2

  const steps = [
    {label: 'Your details'},
    {label: 'Additional Details'},
    {label: 'Sign'},
  ]

  const getModalTitle = () => {
    switch (formStep) {
      case 0:
        return 'Sign Non-Disclosure Agreement (NDA)'
      case 1:
        return isCompany ? 'Company Details' : 'Certification Type'
      case 2:
        return 'Sign Non-Disclosure Agreement (NDA) & Self Certification'
      default:
        return ''
    }
  }

  return (
    <Fragment>
      <Button
        onClick={handleSignNdaModalOpen}
        disabled={loading || isSigningNdaInProgress || buttonDisabled}
      >
        {isSigningNdaInProgress ? 'Processing' : label}
      </Button>

      <Modal
        isShowing={showSignNdaModal}
        hide={handleSignNdaModalClose}
        title={getModalTitle()}
        maxWidth="800px"
        headerComponent={
          <ModalSteps>
            {steps.map((step, index) => (
              <Step>
                <StepCount current={formStep === index} done={formStep > index}>
                  <StepCountText
                    current={formStep === index}
                    done={formStep > index}
                  >
                    {`${index + 1}`.padStart(2, '0')}
                  </StepCountText>

                  {formStep > index ? <Ok color="white" /> : null}
                </StepCount>

                <StepTitle current={formStep === index} done={formStep > index}>
                  {step.label}
                </StepTitle>
              </Step>
            ))}
          </ModalSteps>
        }
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={loading} aria-busy={loading}>
              {/* STEP 0 - Your Details */}
              <YourDetails formStep={formStep} isCompany={isCompany} />

              {/* STEP 1 - Additional Details */}
              <Certification
                formStep={formStep}
                isCompany={isCompany}
                certificationType={certificationType}
              />

              {/* STEP 2 - NDA viewer + Sign NDA + download NDA docx */}
              <Sign formStep={formStep} editorState={generateNDA(formValues)} />

              {loading && (
                <>
                  <Spinner size="xs" />

                  {loadingReason === 'GENERATING_NDA_PDF' && (
                    <p className="text-center">
                      Preparing your document, this will only take a moment.
                    </p>
                  )}
                </>
              )}

              <Actions showExtraButton={showDownloadNdaButton}>
                <Button
                  ref={backButtonRef}
                  type="button"
                  onClick={onBack}
                  secondary
                >
                  {formStep >= 1 ? 'Back' : 'Cancel'}
                </Button>

                {showDownloadNdaButton && (
                  <Button type="button" onClick={onDownloadNdaPdf}>
                    {'Download agreement PDF'}
                  </Button>
                )}

                <Button ref={submitButtonRef} type="submit">
                  {formStep <= 1 ? 'Continue' : 'Proceed to Sign on DocuSign'}
                </Button>
              </Actions>

              {showDownloadNdaButton && hasNdaFileDocument && (
                <div>
                  <p className="text-sm">
                    To discuss NDA terms, please contact the deal manager.
                    Alternatively,{' '}
                    <DownloadEditableNdaButton
                      type="button"
                      onClick={onDownloadEditableNda}
                      disabled={loading}
                    >
                      download editable version
                    </DownloadEditableNdaButton>{' '}
                    for offline signing.
                  </p>
                </div>
              )}
            </fieldset>
          </form>
        </FormProvider>
      </Modal>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default SignNda
