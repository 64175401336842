import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

export const StyledContentContainer = styled.div`
  padding: 32px;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    padding: 16px 0;
  }
`
