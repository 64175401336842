import React, {Fragment} from 'react'
import {QueryClientProvider} from 'react-query'

import GlobalStyles from 'styles/globalStyles'
import {AuthProvider} from 'hooks/useAuth'
import {queryClient} from 'lib/react-query'

import Routes from 'routes'

const App: React.FC = () => {
  return (
    <Fragment>
      <GlobalStyles />

      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </QueryClientProvider>
    </Fragment>
  )
}

export default App
