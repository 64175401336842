import React from 'react'
import styled, {css} from 'styled-components'
import Colors from 'styles/colors'

interface Props extends React.LinkHTMLAttributes<HTMLLinkElement> {
  secondary?: boolean
  loading?: boolean
  disabled?: boolean
  full?: boolean
}

const disabledStyling = css`
  color: ${Colors.DARK_GREY} !important;
  background-color: ${Colors.LIGHT_GREY} !important;
  border: 2px solid ${Colors.LIGHT_GREY} !important;
`

const Link = styled.a<Props>`
  background-color: ${({secondary}) =>
    secondary ? 'transparent' : Colors.BLACK};

  border: ${({secondary}) =>
    secondary ? `2px solid ${Colors.PRIMARY}` : `2px solid ${Colors.BLACK}`};
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  color: ${({secondary}) => (secondary ? Colors.BLACK : Colors.WHITE)};

  width: ${({full}) => (full ? `100%` : `auto`)};

  text-decoration: none;

  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;

  cursor: pointer;

  ${({disabled}) => disabled && disabledStyling};
`

export default Link
