import React, {useState, Fragment} from 'react'

import {DealInterestStatus} from 'types'
import {DEAL_INTEREST_STATUS} from 'config/data/deal-interest.config'
import {hasDeckAccess} from 'services/deal-interests'

import {ButtonsContainer} from '../../styles'
import AccessBtn from '../AccessBtn'

import OverrideDeck from './components/OverrideDeck'

interface DeckButtonsProps {
  dealInterestId: string
  dealInterestStatus?: DealInterestStatus
  hasSignedNDA?: boolean
  handleDeckRequest: (evt: any, id: string, signer?: any) => void
}

const DeckButtons: React.FC<DeckButtonsProps> = ({
  dealInterestId,
  dealInterestStatus,
  hasSignedNDA,
  handleDeckRequest,
}) => {
  const [showModal, setShowModal] = useState(false)

  const isUserAskingForDeckAccess =
    dealInterestStatus === DEAL_INTEREST_STATUS.DECK_REQUESTED
  const hasUserDeckAccess = hasDeckAccess(dealInterestStatus)

  const renderAcceptAndRejectButtons = () => {
    return (
      <ButtonsContainer>
        <AccessBtn
          id={`radioAcceptDeck-${dealInterestId}`}
          type="accept"
          value="APPROVED"
          onClick={e => handleDeckRequest(e, dealInterestId)}
          checked={hasUserDeckAccess}
          disabled={hasUserDeckAccess}
          borderRadiusLeft
        />
        <AccessBtn
          id={`radioRejectDeck-${dealInterestId}`}
          type="reject"
          value="DENIED"
          onClick={e => handleDeckRequest(e, dealInterestId)}
          checked={!isUserAskingForDeckAccess && !hasUserDeckAccess}
          disabled={!isUserAskingForDeckAccess && !hasUserDeckAccess}
          borderRadiusRight
        />
      </ButtonsContainer>
    )
  }

  const renderOverrideAccessButtons = () => {
    return (
      <ButtonsContainer>
        <AccessBtn
          id={`radioAcceptDeck-${dealInterestId}`}
          type="accept"
          value={DEAL_INTEREST_STATUS.DECK_STAGE}
          onClick={() => setShowModal(true)}
          checked={hasUserDeckAccess}
          disabled={hasUserDeckAccess}
          borderRadiusLeft
          borderRadiusRight
        />
      </ButtonsContainer>
    )
  }

  /**
   * Seems to be deprecated, but have to make 100% sure.
   */
  // const renderManualAcceptNDA = () => {
  //   return (
  //     <ButtonsContainer>
  //       <div data-tip data-for={`radioManualAcceptNDADeck-${dealInterestId}`}>
  //         <AccessBtn
  //           id={`radioManualAcceptNDADeck-${dealInterestId}`}
  //           type="accept"
  //           value="CUSTOM"
  //           onClick={e => handleDeckRequest(e, dealInterestId)}
  //           disabled={hasUserDeckAccess}
  //         />
  //       </div>

  //       <ReactTooltip
  //         id={`radioManualAcceptNDADeck-${dealInterestId}`}
  //         place="top"
  //         effect="solid"
  //       >
  //         {'Custom NDA needs reviewed and manually approved.'}
  //       </ReactTooltip>
  //     </ButtonsContainer>
  //   );
  // };

  const renderButtons = () => {
    switch (dealInterestStatus) {
      case DEAL_INTEREST_STATUS.TEASER_STAGE:
      case DEAL_INTEREST_STATUS.NDA_REQUESTED:
      case DEAL_INTEREST_STATUS.NDA_STAGE:
      case DEAL_INTEREST_STATUS.DECK_REQUESTED:
        return hasSignedNDA
          ? renderAcceptAndRejectButtons()
          : renderOverrideAccessButtons()
      case DEAL_INTEREST_STATUS.DECK_STAGE:
      case DEAL_INTEREST_STATUS.INDICATIVE_OFFER_PROPOSED:
      case DEAL_INTEREST_STATUS.DATAROOM_STAGE:
      case DEAL_INTEREST_STATUS.FINAL_OFFER_PROPOSED:
        return renderAcceptAndRejectButtons()

      case DEAL_INTEREST_STATUS.INVESTED:
      case DEAL_INTEREST_STATUS.NOT_INVESTED:
        return null

      default:
        return null
    }
  }

  return (
    <Fragment>
      {renderButtons()}

      <OverrideDeck
        show={showModal}
        onClose={() => setShowModal(false)}
        onProceed={(data: any) => {
          handleDeckRequest(
            {target: {value: DEAL_INTEREST_STATUS.DECK_STAGE}},
            dealInterestId,
            data
          )
        }}
      />
    </Fragment>
  )
}

export default DeckButtons
