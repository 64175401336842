import React, {useEffect, useState, Fragment} from 'react'
import {useHistory} from 'react-router-dom'
import {Cell} from 'react-table'

import {
  DealInterest,
  DealInterestStatus,
  DataroomStatus,
  MessageProps,
} from 'types'
import {
  DATAROOM_STATUS,
  DEAL_INTEREST_STATUS,
  DEAL_INTEREST_STATUS_TO_STRING,
} from 'config/data/deal-interest.config'
import {ROUTES} from 'config/routes.config'
import {
  updateDealInterestStatus,
  updateDeckAccessStatus,
  updateDataRoomAccessStatus,
} from 'services/deal-interests'

import Toast from 'components/Toast'
import Toggle from 'components/Toggle'
import {EllipsisIcon} from 'components/Toggle/styles'
import Table from 'components/Table'
import Badge, {dealInterestStatusToBadgeStatus} from 'components/styles/Badge'

import NdaAccessButtons from './components/NdaAccessButtons'
import DataroomButtons from './components/DataroomButtons'
import DeckButtons from './components/DeckButtons'
import UpdateModal from './components/UpdateModal'

interface UserDealsProps {
  dealInterests: DealInterest[]
  onUpdateNdaAccess: (updatedDealInterest: DealInterest) => void
  onUpdateDeckAccess: (
    id: string,
    status: DealInterestStatus | undefined
  ) => void
  onUpdateDataroomAccess: (
    id: string,
    value: DataroomStatus | undefined,
    status: DealInterestStatus | undefined
  ) => void
  onInvesting: (id: string, value: DealInterestStatus | undefined) => void
}

type State = {
  dealInterestId: string
  value: DataroomStatus | DealInterestStatus | string // uses string for deck manual nda approval 'custom'
  ndaAccessLoading: boolean
  deckLoading: boolean
  dataroomLoading: boolean
  signer: any
  investingLoading: boolean
}

const defaultState: State = {
  dealInterestId: '',
  value: null,
  ndaAccessLoading: false,
  deckLoading: false,
  dataroomLoading: false,
  signer: null,
  investingLoading: false,
}

const UserDeals: React.FC<UserDealsProps> = ({
  dealInterests,
  onUpdateNdaAccess,
  onUpdateDeckAccess,
  onUpdateDataroomAccess,
  onInvesting,
}) => {
  const history = useHistory()

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: '',
  })

  const [state, setState] = useState<State>(defaultState)
  const {
    ndaAccessLoading,
    deckLoading,
    dataroomLoading,
    investingLoading: investing,
    value,
    dealInterestId,
    signer,
  } = state

  const handleNdaAccessRequest = (e: any, dealInterestId: string) => {
    setState({
      ...state,
      ndaAccessLoading: true,
      dealInterestId,
      value: e.target.value,
    })
  }

  const handleDeckRequest = (e: any, dealInterestId: string, signer = null) => {
    setState({
      ...state,
      deckLoading: true,
      dealInterestId,
      value: e.target.value,
      signer,
    })
  }

  const handleDataroomRequest = (e: any, dealInterestId: string) => {
    setState({
      ...state,
      dataroomLoading: true,
      dealInterestId,
      value: e.target.value,
    })
  }

  const markInvestedOrNot = (dealInterestId: string, invest: boolean) => {
    setState({
      ...state,
      investingLoading: true,
      dealInterestId,
      value: invest
        ? DEAL_INTEREST_STATUS.INVESTED
        : DEAL_INTEREST_STATUS.NOT_INVESTED,
    })
  }

  const handleViewDeal = (slug: string) => {
    history.push(`${ROUTES.DEAL}/${slug}`)
  }

  /**
   * Handle NDA access update.
   */
  useEffect(() => {
    if (ndaAccessLoading) {
      const updateNdaAccess = async () => {
        try {
          const newDealInterestStatus =
            value === 'APPROVED' ? 'NDA_STAGE' : 'TEASER_STAGE'

          const updatedDealInterest = await updateDealInterestStatus(
            dealInterestId,
            newDealInterestStatus
          )

          onUpdateNdaAccess(updatedDealInterest)
        } catch (error) {
          setToast({
            value: error.response?.data?.title,
            type: 'error',
          })
        } finally {
          setState(defaultState)
        }
      }

      updateNdaAccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ndaAccessLoading])

  /**
   * Handle deck access update.
   */
  useEffect(() => {
    if (deckLoading) {
      const updateDeck = async () => {
        try {
          let result
          if (signer) {
            result = await updateDealInterestStatus(
              dealInterestId,
              value,
              signer
            )
          } else {
            const deckAccess = value === 'APPROVED'
            result = await updateDeckAccessStatus(dealInterestId, deckAccess)
          }

          onUpdateDeckAccess(dealInterestId, result?.status)
        } catch (error) {
          setToast({
            value: error.response?.data?.title,
            type: 'error',
          })
        } finally {
          setState(defaultState)
        }
      }

      updateDeck()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deckLoading])

  /**
   * Handle dataroom access update.
   */
  useEffect(() => {
    if (dataroomLoading) {
      const updateDataroom = async () => {
        try {
          const result = await updateDataRoomAccessStatus(dealInterestId, value)

          onUpdateDataroomAccess(
            dealInterestId,
            result.dataroomRequestStatus,
            result.status
          )
        } catch (error) {
          if (
            error.message ===
            'The user has an account not created by us, they must be manually removed via the caplinked dashboard'
          ) {
            onUpdateDataroomAccess(
              dealInterestId,
              DATAROOM_STATUS.MANUAL_REMOVE_PENDING,
              DEAL_INTEREST_STATUS.DATAROOM_STAGE
            )
          }

          if (
            error.message ===
            'The user has a caplinked account that we cannot administer - the user needs to be manually added to the workspace'
          ) {
            onUpdateDataroomAccess(
              dealInterestId,
              DATAROOM_STATUS.MANUAL_ADD_PENDING,
              DEAL_INTEREST_STATUS.DATAROOM_STAGE
            )
          }

          setToast({
            value: error.response?.data?.title,
            type: 'error',
          })
        } finally {
          setState(defaultState)
        }
      }

      updateDataroom()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataroomLoading])

  /**
   * Handle invested/not-invested status update.
   */
  useEffect(() => {
    if (investing) {
      const updateInvestmentStatus = async () => {
        try {
          const result = await updateDealInterestStatus(dealInterestId, value)
          onInvesting(dealInterestId, result.status)
        } catch (error) {
          setToast({
            value: error.response?.data?.title,
            type: 'error',
          })
        } finally {
          setState(defaultState)
        }
      }

      updateInvestmentStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investing])

  return (
    <Fragment>
      <Toast message={toast} action={setToast} />

      <Table
        columns={[
          {
            Header: 'Deal',
            accessor: ({deal}: DealInterest) => deal?.name,
            Cell: (cell: Cell<DealInterest>) => {
              const {deal: {name, slug} = {}} = cell.row.original

              return name && slug ? (
                <span
                  className="bold black"
                  onClick={() => handleViewDeal(slug)}
                >
                  {name}
                </span>
              ) : null
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (cell: Cell<DealInterest>) => {
              const {status} = cell.row.original

              return status ? (
                <Badge
                  size="small"
                  status={dealInterestStatusToBadgeStatus(status)}
                >
                  {DEAL_INTEREST_STATUS_TO_STRING[status]}
                </Badge>
              ) : (
                ''
              )
            },
          },
          {
            Header: 'NDA Access',
            id: 'nda-access-buttons-column',
            disableSortBy: true,
            Cell: (cell: Cell<DealInterest>) => {
              const {id, status, isInvitedUser} = cell.row.original

              return (
                <NdaAccessButtons
                  dealInterestId={id}
                  dealInterestStatus={status}
                  isInvitedUser={isInvitedUser}
                  handleNdaAccessRequest={handleNdaAccessRequest}
                />
              )
            },
          },
          {
            Header: 'Deck Access',
            id: 'deck-access-buttons-column',
            disableSortBy: true,
            Cell: (cell: Cell<DealInterest>) => {
              const {id, status, hasSignedNDA} = cell.row.original

              return (
                <DeckButtons
                  dealInterestId={id}
                  dealInterestStatus={status}
                  hasSignedNDA={hasSignedNDA}
                  handleDeckRequest={handleDeckRequest}
                />
              )
            },
          },
          {
            Header: 'Caplinked Access',
            id: 'caplinked-access-buttons-column',
            disableSortBy: true,
            Cell: (cell: Cell<DealInterest>) => {
              const {id, status, dataroomRequestStatus} = cell.row.original

              return (
                <DataroomButtons
                  dealInterestId={id}
                  status={status}
                  dataroomRequestStatus={dataroomRequestStatus}
                  handleDataroomRequest={handleDataroomRequest}
                />
              )
            },
          },
          {
            id: 'more-options-column',
            disableSortBy: true,
            Cell: (cell: Cell<DealInterest>) => {
              const {id, status} = cell.row.original

              return (
                <Toggle
                  data={
                    status === DEAL_INTEREST_STATUS.INVESTED
                      ? ['Mark as not Invested']
                      : ['Mark as Invested']
                  }
                  dealInterestId={id}
                  onClick={() =>
                    status === DEAL_INTEREST_STATUS.INVESTED
                      ? markInvestedOrNot(id, false)
                      : markInvestedOrNot(id, true)
                  }
                >
                  <EllipsisIcon />
                </Toggle>
              )
            },
          },
        ]}
        data={dealInterests}
      />

      <UpdateModal show={dataroomLoading || deckLoading || investing} />
    </Fragment>
  )
}

export default UserDeals
