import React, {useState, useEffect, Fragment} from 'react'

import {createLetter, fetchWatermarkedPdf} from 'services/deal-interests'
import {downloadFileFromUrl} from 'services/files'
import {downloadFileFromBlob} from 'utils/blob'
import {trimString} from 'utils/strings'
import useVimeoIframe from 'hooks/useVimeoIframe'
import {MessageProps, File} from 'types'

import Spinner from 'components/Spinner'
import Badge from 'components/styles/Badge'
import LightBox from 'components/styles/LightBox'
import Button from 'components/styles/Button'
import Link from 'components/styles/Link'
import Toast from 'components/Toast'
import {Close} from 'components/Modal'

import {
  FilesGrid,
  FileBox,
  PDFIcon,
  ExcelIcon,
  VideoIcon,
  LockIcon,
  Type,
} from './styles'
import PdfPreviewButton from './PdfPreviewButton'

interface FilesProps {
  files: Partial<File>[] // array of files for the user
  dealInterestId?: string // deal-interest id
  enableCaplinked: boolean // enable or not caplinked link
  showFiles: boolean // show or hide files who need some access level => i.e: self certify + NDA signed
}

const Files = ({
  files,
  dealInterestId,
  showFiles,
  enableCaplinked,
}: FilesProps) => {
  const [currentlyDownloadedFile, setCurrentlyDownloadedFile] =
    useState<string | null>(null)

  const [video, setVideo] = useState(false)

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  // FIXME: 😂
  // this code means that since you're finding the first element
  // that is a video in the files array, no matter which file will the user
  // choose to preview, they'll always see the first video file in the array
  // as a preview...
  const {videoIframe, videoError} = useVimeoIframe(
    files?.find(f => f.type === 'video')?.url
  )
  useEffect(() => {
    if (videoError) {
      setToast({
        value: videoError.message,
        type: 'error',
      })
    }
  }, [videoError])

  const downloadExcelFile = async (url: string, name: string) => {
    setCurrentlyDownloadedFile(name)

    try {
      await downloadFileFromUrl(url, {
        fileName: name,
      })
    } catch (error) {
      setToast({
        value: error.message,
        type: 'error',
      })
    }

    setCurrentlyDownloadedFile(null)
  }

  const downloadProcessLetter = async (name: string) => {
    setCurrentlyDownloadedFile(name)

    try {
      if (!dealInterestId) {
        throw new Error(`Can't find your deal interest. Please contact us.`)
      }

      const letterBlob = await createLetter(dealInterestId)

      downloadFileFromBlob(letterBlob, {
        fileName: name.endsWith('.pdf') ? name : `${name}.pdf`,
        blobType: 'application/pdf',
      })
    } catch (error) {
      setToast({
        value: error.message,
        type: 'error',
      })
    }

    setCurrentlyDownloadedFile(null)
  }

  const downloadWatermarkedPdf = async (fileName: string) => {
    setCurrentlyDownloadedFile(fileName)

    try {
      if (!dealInterestId) {
        throw new Error(`Can't find your deal interest. Please contact us.`)
      }

      const watermarkedPdfBlob = await fetchWatermarkedPdf(
        dealInterestId,
        fileName
      )

      downloadFileFromBlob(watermarkedPdfBlob, {
        fileName: fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`,
        blobType: 'application/pdf',
      })
    } catch (error) {
      setToast({
        value: error.message,
        type: 'error',
      })
    }

    setCurrentlyDownloadedFile(null)
  }

  const downloadTeaserdPdf = async (fileName: string, url: string) => {
    setCurrentlyDownloadedFile(fileName)

    try {
      await downloadFileFromUrl(url, {
        fileName: fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`,
        blobType: 'application/pdf',
      })
    } catch (error) {
      setToast({
        value: error.message,
        type: 'error',
      })
    }

    setCurrentlyDownloadedFile(null)
  }

  const content = (file: Partial<File>) => {
    const {name = '', url = '', type, label} = file

    const extension = name?.split('.').reverse()[0] || ''
    const isLetter = label === 'Process Letter'
    const currentName = name?.split('.')[0] || 'name-not-found'
    const fileName = trimString(currentName, 35)

    const fileIcon = () => {
      switch (type) {
        case 'video':
          return <VideoIcon name="file-video" />
        case 'caplinked':
          return (
            <LockIcon
              isLock={!enableCaplinked}
              name={enableCaplinked ? 'lock-open' : 'lock-close'}
            />
          )
        default:
          return extension === 'pdf' ? (
            <PDFIcon name="file-pdf" />
          ) : (
            <ExcelIcon name="file-excel" />
          )
      }
    }

    const fileButton = () => {
      const isDisabled = Boolean(currentlyDownloadedFile)

      const disabledCaplinked = isDisabled || !showFiles || !enableCaplinked
      const disabledVideo = isDisabled || !showFiles || !videoIframe
      const disabledButton = isDisabled || !showFiles

      const isBeingDownloaded =
        currentlyDownloadedFile === name || currentlyDownloadedFile === fileName

      switch (type) {
        case 'caplinked':
          return (
            <Link
              disabled={disabledCaplinked}
              aria-disabled={disabledCaplinked}
              target="_blank"
              rel="noopener noreferrer"
              {...(!isDisabled && enableCaplinked
                ? {
                    href: url,
                  }
                : null)}
            >
              View Document
            </Link>
          )

        case 'video':
          return (
            <Button disabled={disabledVideo} onClick={() => setVideo(!video)}>
              View Document
            </Button>
          )

        case 'teaser':
          return (
            <PdfPreviewButton
              fileUrl={url.endsWith('.pdf') ? url : `${url}.pdf`}
              disabled={isDisabled}
              onDownload={() => downloadTeaserdPdf(name, url)}
              isDownloading={isBeingDownloaded}
            >
              View Document
            </PdfPreviewButton>
          )

        default:
          // Excel file
          if (extension !== 'pdf') {
            return (
              <Button
                disabled={disabledButton}
                onClick={() => downloadExcelFile(url, name)}
              >
                {isBeingDownloaded ? (
                  <Spinner size="xs" margin="none" />
                ) : (
                  'View Document'
                )}
              </Button>
            )
          }
          // PDF file
          return isLetter ? (
            <Button
              disabled={disabledButton}
              onClick={() => downloadProcessLetter(fileName)}
            >
              {isBeingDownloaded ? (
                <Spinner size="xs" margin="none" />
              ) : (
                'View Document'
              )}
            </Button>
          ) : (
            <PdfPreviewButton
              fileUrl={url.endsWith('.pdf') ? url : `${url}.pdf`}
              disabled={disabledButton}
              onDownload={() => downloadWatermarkedPdf(name)}
              isDownloading={isBeingDownloaded}
            >
              View Document
            </PdfPreviewButton>
          )
      }
    }

    return (
      <Fragment>
        <Type>
          <Badge status="grey">{label || 'Deck'}</Badge>{' '}
        </Type>

        <div className="box-content">
          {fileIcon()}

          <span className="text-base text-center black">{fileName}</span>

          {fileButton()}
        </div>
      </Fragment>
    )
  }

  if (!Array.isArray(files) || files.length < 1) {
    return null
  }

  return (
    <Fragment>
      <FilesGrid>
        {files.map(file => (
          <FileBox key={file.reference}>{content({...file})}</FileBox>
        ))}
      </FilesGrid>

      {video && (
        <LightBox show>
          <Close onClick={() => setVideo(false)} />

          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: videoIframe ?? '',
            }}
          />
        </LightBox>
      )}

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default Files
