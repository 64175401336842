import React from 'react'

import Card from 'components/Card'

import {User} from 'types'
import {isUserCertified} from 'services/users'
import {formatFirebaseDate} from 'utils/date'

import AdduserToDeal from '../AddUserToDeal'

import {UserDetailsGrid, UserDetail} from './styles'

interface UserCardProps {
  user: User
}

const UserCard: React.FC<UserCardProps> = ({user}) => {
  const {email, firstName, lastName, country, createdAt, id} = user

  const isCertified = isUserCertified(user)

  return (
    <Card
      padding
      title="User information"
      subtitle="Personal details and status"
      bottomComponent={
        <div>
          <AdduserToDeal userId={id} userEmail={email} />
        </div>
      }
    >
      <UserDetailsGrid>
        <UserDetail>
          <p className="label">Email Address</p>
          <p className="value">{email}</p>
        </UserDetail>

        <UserDetail>
          <p className="label">Full Name</p>
          <p className="value">
            {firstName} {lastName}
          </p>
        </UserDetail>

        <UserDetail>
          <p className="label">Country of Residence</p>
          <p className="value">{country}</p>
        </UserDetail>

        <UserDetail>
          <p className="label">Join Date</p>
          <p className="value">{formatFirebaseDate(createdAt)}</p>
        </UserDetail>

        <UserDetail>
          <p className="label">Self Certified</p>
          <p className="value">{isCertified ? 'Yes' : 'No'}</p>
        </UserDetail>
      </UserDetailsGrid>
    </Card>
  )
}

export default UserCard
