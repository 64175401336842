import React from 'react'
import {SpinnerContainer} from './styles'

export type MarginType = 'big' | 'none' | undefined
export type SpinnerSize = 'small' | 'big' | 'xs' | undefined

export interface SpinnerProps {
  primary?: boolean
  margin?: MarginType
  size?: SpinnerSize
  label?: string
  right?: boolean // move label to the right and container direction as a row
  white?: boolean
}

const Spinner: React.FC<SpinnerProps> = ({
  primary,
  label,
  margin,
  right,
  size,
  white,
}) => {
  return (
    <SpinnerContainer {...{primary, margin, right, size, white}}>
      <div className="sc-circle">
        <div className="sc-circle1 sc-child"></div>
        <div className="sc-circle2 sc-child"></div>
        <div className="sc-circle3 sc-child"></div>
        <div className="sc-circle4 sc-child"></div>
        <div className="sc-circle5 sc-child"></div>
        <div className="sc-circle6 sc-child"></div>
        <div className="sc-circle7 sc-child"></div>
        <div className="sc-circle8 sc-child"></div>
        <div className="sc-circle9 sc-child"></div>
        <div className="sc-circle10 sc-child"></div>
        <div className="sc-circle11 sc-child"></div>
        <div className="sc-circle12 sc-child"></div>
      </div>
      {label && <span className="text text-center">{label}</span>}
    </SpinnerContainer>
  )
}

export default Spinner
