import styled from 'styled-components'

import Colors from 'styles/colors'

export const TermsContainer = styled.div`
  margin-top: 4em;

  .header {
    font-weight: bold;
  }

  .data {
    text-decoration: underline;
  }

  .footer {
    font-size: 0.8em;
  }

  ul {
    color: ${Colors.GREY};
  }
`
