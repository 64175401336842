import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

import Colors from 'styles/colors'

export interface ModalLayoutProps {
  show?: boolean
  neat?: boolean
  maxWidth?: string
}

export const ModalLayout = styled.div<ModalLayoutProps>`
  display: ${({show}) => (show ? 'flex' : 'none')}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding: 4rem 0; /* Location of the box [top - bottom] */

  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  width: 100%; /* Full width */
  max-height: 100%; /* Full height */

  /* overflow-y: scroll; */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  /* Modal */
  .modal-container {
    position: relative;

    background-color: ${Colors.WHITE};
    margin: auto;
    padding: 20px;
    border: 1px solid ${Colors.LIGHT_GREY};
    border-radius: 5px;

    width: auto;
    max-width: ${({maxWidth}) => (maxWidth ? maxWidth : '100%')};
    min-width: 100%;

    @media only screen and (min-width: ${Breakpoints.sm}) {
      min-width: 450px;
    }
    @media only screen and (min-width: ${Breakpoints.md}) {
      min-width: 500px;
    }

    .modal-header {
      margin-bottom: 24px;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      position: relative;

      .modal-title {
        font-size: 26px;
        line-height: 40px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 24px;
      }

      .close {
        position: absolute;
        right: 0;
        top: 0;

        color: ${Colors.GREY};
        font-size: 28px;
        font-weight: bold;
      }

      .close:hover,
      .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }

      .modal-content {
        /** if you pass neat you will have a classic🥖 gap of 1 rem  */
        > :not(:first-child) {
          margin-top: ${({neat}) => (neat ? '1rem' : '2.2rem')};
        }

        /** If you have a form */
        form {
          display: grid;
          grid-template-columns: 1fr;
          gap: 1rem;

          & > fieldset {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
          }
        }

        h2 {
          margin-top: 0;
        }
      }
    }
  }
`
