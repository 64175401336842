import React, {Fragment} from 'react'

const Statement5 = () => (
  <Fragment>
    <p className="text-12 grey justify">
      To whom Article 49 of the FPO applies
    </p>
  </Fragment>
)

export default Statement5
