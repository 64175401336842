import React, {useEffect, useState, useRef, Fragment} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import Lottie from 'react-lottie'

import {ROUTES} from 'config/routes.config'
import {MessageProps} from 'types'
import {handleVerifyEmail, verifyEmail} from 'services/email'
import {errorDefault} from 'utils/errors'
import {firebaseSignOut} from 'lib/firebase/auth'

import RegistrationLayout from 'components/styles/RegistrationLayout'
import Button from 'components/styles/Button'
import Footer from 'components/Footer'
import Toast from 'components/Toast'
import Spinner from 'components/Spinner'
import Card from 'components/Card'

import * as animationData from './success.json'

/**
 * User email verification screen: Firebase+Backend
 *
 * Flow:
 * 1/ user registration ->
 * 2/ user has clicked on the email verification link ->
 * 3/ redirect here ->
 * 4/ processing user's email verification ->
 * 5/ Redirection to sign in screen
 */
const EmailVerification: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  const timer = useRef(-6)

  const [loading, setLoading] = useState(true)
  const [verified, setVerified] = useState(false)

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'success',
  })

  useEffect(() => {
    if (loading) {
      const verifyUserEmail = async () => {
        const {actionCode, email} = (location.state as any) || {
          actionCode: '',
          email: '',
        }

        try {
          if (!actionCode) {
            throw new Error(
              'No verification code found. Please click again in your email link or contact us if the problem persists.'
            )
          }

          // Firebase
          await handleVerifyEmail(actionCode)
          // Backend
          await verifyEmail(email)

          setVerified(true)
        } catch (_) {
          setToast({
            value: errorDefault,
            type: 'error',
            redirect: ROUTES.HOME,
          })
        }

        setLoading(false)
      }

      firebaseSignOut()
      verifyUserEmail()
    }
  }, [loading, location.state])

  useEffect(() => {
    if (verified) {
      // On email verification success automatically redirect to LOGIN
      timer.current = setTimeout(() => history.push(ROUTES.LOGIN), 3000)
    }

    return () => clearTimeout(timer?.current)
  }, [history, verified])

  return (
    <Fragment>
      <RegistrationLayout>
        <Card className="content" padding shadow fullHeight centerContent>
          {!verified ? (
            <Spinner />
          ) : (
            <Lottie
              width={200}
              height={200}
              options={{
                // @ts-ignore
                animationData: animationData.default,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
                loop: false,
                autoplay: true,
              }}
            />
          )}

          <h2 className="text-center">
            {!verified
              ? 'Pending Approval...'
              : 'Thanks! Your account is confirmed.'}
          </h2>

          <span className="grey text-lg text-center m-bottom-20">
            {!verified
              ? 'You will be able to continue to the platform in just a second.'
              : 'You may now continue to the platform.'}
          </span>

          <Button
            type="button"
            onClick={() => history.push(ROUTES.LOGIN)}
            disabled={loading}
            full
          >
            Sign In
          </Button>
        </Card>
      </RegistrationLayout>

      <Footer />

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default EmailVerification
