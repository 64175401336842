import * as Sentry from '@sentry/react'

import firebase from 'lib/firebase'
import {Notification} from 'types'

/**
 * Gets the most recent notifications for the currently
 * signed in user.
 *
 * Returns the 10 most recent notifications by default.
 *
 * @param userId The ID of the signed in user.
 * @param optionalParams Optional parameters.
 *
 * @returns The notifications.
 */
export const getRecentNotifications = async (
  userId: string,
  {limit = 10}: {limit?: number} = {}
) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get()

    const notifications = await Promise.all(
      snapshot.docs.map(async notification => {
        const data = notification.data()

        return {
          ...data,
          id: notification.id,
        } as Notification
      })
    )

    return notifications
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}
