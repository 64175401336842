import styled, {css} from 'styled-components'

import Colors from 'styles/colors'

const centerContentStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export interface CardContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  padding?: boolean
  shadow?: boolean
  hide?: boolean
  disableTitleUnderline?: boolean
  hideOutline?: boolean
  fullHeight?: boolean
  centerContent?: boolean
}

export const CardContainer = styled.div<CardContainerProps>`
  background-color: ${Colors.WHITE};
  width: 100%;

  height: ${({fullHeight}) => (fullHeight ? '100%' : 'auto')};
  display: ${({hide, fullHeight}) =>
    hide ? 'none' : fullHeight ? 'flex' : 'block'};
  flex-direction: column;

  border-radius: ${({hideOutline}) => (hideOutline ? 0 : '5px')};
  border: ${({hideOutline}) =>
    hideOutline ? 'none' : `1px solid ${Colors.LIGHT_GREY}`};
  box-shadow: ${({shadow}) =>
    shadow ? `0 8px 10px 0 rgba(0, 0, 0, 0.2)` : `none`};

  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 20px 24px;
    border-bottom: ${({disableTitleUnderline}) =>
      !disableTitleUnderline ? `1px solid ${Colors.GREY_200}` : `none`};
    ${({disableTitleUnderline}) =>
      disableTitleUnderline ? 'padding-bottom: 0;' : undefined};

    .card-title-text {
      margin: 0;
      font-size: 26px;
      line-height: 40px;
      font-weight: 600;
    }
  }

  .card-subtitle {
    display: flex;
    align-items: center;

    padding: 20px 24px;
    border-bottom: 1px solid ${Colors.GREY_200};

    .card-subtitle-text {
      margin: 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: #616161;
    }
  }

  .card-content {
    padding: ${({padding}) => (padding ? `20px 24px` : 0)};
    flex: ${({fullHeight}) => (fullHeight ? '1' : 'initial')};
    ${({centerContent}) => (centerContent ? centerContentStyles : undefined)};
  }

  .card-bottom {
    border-top: 1px solid ${Colors.GREY_200};
    padding: ${({padding}) => (padding ? `20px 24px` : 0)};
  }
`
