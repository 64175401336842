import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJET_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

export const emailAuthCredentials = (email: string, currentPassword: string) =>
  firebase.auth.EmailAuthProvider.credential(email, currentPassword)

const app = firebase.initializeApp(config)
const database = firebase.firestore(app)

database.settings({
  experimentalForceLongPolling: true,
})
database.enablePersistence()

export {database}

export default firebase
