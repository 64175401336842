import React from 'react'

import Spinner from 'components/Spinner'

import {LoaderContainer} from './styles'

const Loader: React.FC = () => {
  return (
    <LoaderContainer>
      <Spinner primary />
    </LoaderContainer>
  )
}

export default Loader
