import React, {Fragment, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import * as yup from 'yup'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import Papa from 'papaparse'
import clsx from 'clsx'
import {AxiosError} from 'axios'
import {Cell} from 'react-table'

import useYupValidationResolver from 'hooks/useYupValidationResolver'
import {DEAL_SECTORS, DEAL_STAGES, DEAL_SIZES} from 'config/data/options.config'
import {
  DEAL_INTEREST_STATUS_TO_STRING,
  EMAIL_DELIVERY_STATUS_TO_STRING,
} from 'config/data/deal-interest.config'
import {
  DealInterest,
  ErrorType,
  MessageProps,
  Sector,
  Size,
  Stage,
  UserToInvite,
} from 'types'
import {
  deleteDealInterest,
  fetchUsersByInterest,
  getDealInterests,
} from 'services/deal-interests'
import {inviteUsersToDealByEmail, resendInviteEmails} from 'services/deals'
import {formatFirebaseDate} from 'utils/date'
import {validateEmail} from 'utils/email'

import Select, {Option} from 'components/Select'
import Input from 'components/Input'
import Button from 'components/styles/Button'
import Spinner from 'components/Spinner'
import Card from 'components/Card'
import DragDrop from 'components/DragDrop'
import ErrorButton from 'components/styles/ErrorButton'
import Table from 'components/Table'
import Badge from 'components/styles/Badge'

import {InviteUsersContainer, DeleteIcon} from './styles'
import {
  dealInterestStatusToBadgeStatus,
  emailDeliveryStatusToBadgeStatus,
} from 'components/styles/Badge'

type TabId = '1-invite-list' | '2-invited-to-deal'

type InviteListItem = {
  email: string
  firstName?: string
  lastName?: string
  company?: string
  country?: string
}

type InviteViaInterestInputs = {
  sector: Option | null
  stage: Option | null
  size: Option | null
}

type InviteManuallyInputs = {
  email: string
}

const inviteViaInterestSchema = yup.object().shape({
  sector: yup.string().nullable(),
  stage: yup.string().nullable(),
  size: yup.string().nullable(),
})

const inviteManuallySchema = yup.object().shape({
  email: yup
    .string()
    .email('Needs to be a valid email')
    .required('Email recipient is required'),
})

interface InviteUsersProps {
  dealId: string
  setToast: React.Dispatch<React.SetStateAction<MessageProps>>
}

const InviteUsers: React.FC<InviteUsersProps> = ({dealId, setToast}) => {
  const queryClient = useQueryClient()

  const [tabId, setTabId] = useState<TabId>('1-invite-list')

  const [inviteList, setInviteList] = useState<InviteListItem[]>([])
  const [resendInvitesToList, setResendInvitesToList] = useState<string[]>([])

  const [errorCSV, setErrorCSV] = useState<ErrorType>({
    status: false,
    message: '',
  })
  // CSV file name
  const [valueCSV, setValueCSV] = useState<any>([])

  const inviteViaInterestForm = useForm<InviteViaInterestInputs>({
    resolver: useYupValidationResolver(inviteViaInterestSchema),
    defaultValues: {
      sector: null,
      stage: null,
      size: null,
    },
    shouldUnregister: false,
  })
  const inviteManuallyForm = useForm<InviteManuallyInputs>({
    resolver: useYupValidationResolver(inviteManuallySchema),
    defaultValues: {
      email: '',
    },
    shouldUnregister: false,
  })

  /** useQuery to fetch deal interests (users who are added to the deal) */
  const {data: dealInterests, status: dealInterestsStatus} = useQuery<
    DealInterest[]
  >(['deal-interests', dealId], async () => getDealInterests(dealId), {
    enabled: Boolean(dealId),
  })

  /** useMutation to fetch users based on their interests */
  const getUsersToInviteFromInterests = useMutation<UserToInvite[], AxiosError>(
    async () => {
      const {sector, stage, size} = inviteViaInterestForm.getValues()

      const usersToInvite = await fetchUsersByInterest(dealId, {
        sector: sector ? (sector.value as Sector) : undefined,
        stage: stage ? (stage.value as Stage) : undefined,
        size: size ? (size.value as Size) : undefined,
      })

      return usersToInvite
    },
    {
      onError: error => {
        setToast({
          value: 'Failed to fetch user(s) from Hubspot',
          type: 'error',
        })
      },
    }
  )

  /** useMutation to handle adding new users to the deal */
  const inviteUsersToDeal = useMutation<void, AxiosError>(
    async () => {
      await inviteUsersToDealByEmail(
        dealId,
        inviteList.map(item => ({
          email: item.email,
          firstName: item.firstName ?? null,
          lastName: item.lastName ?? null,
        }))
      )
    },
    {
      onSuccess: () => {
        setToast({
          value: 'User(s) added to the deal',
          type: 'success',
        })
        queryClient.invalidateQueries(['deal-interests', dealId])

        // clear invite list
        removeAllItemsFromInviteList()
        // go to 2nd tab automatically
        setTabId('2-invited-to-deal')
      },
      onError: error => {
        setToast({
          value: 'Failed to add user(s) to the deal',
          type: 'error',
        })
      },
    }
  )

  /** useMutation to handle email re-sending to selected users */
  const resendInviteToUsers = useMutation<void, AxiosError>(
    async () => {
      await resendInviteEmails(dealId, resendInvitesToList)
    },
    {
      onSuccess: () => {
        setToast({
          value: 'User(s) have been sent a new invite email',
          type: 'success',
        })
        queryClient.invalidateQueries(['deal-interests', dealId])

        // clear resend list
        removeAllItemsFromResendInvitesToList()
      },
      onError: error => {
        setToast({
          value: 'Failed to send email to user(s)',
          type: 'error',
        })
      },
    }
  )

  /** useMutation to handle email re-sending to selected users */
  const deleteDealInterestMutation = useMutation<void, AxiosError, string>(
    async (dealInterestId: string) => deleteDealInterest(dealInterestId),
    {
      onSuccess: () => {
        setToast({
          value: 'User have been successfully removed from deal',
          type: 'success',
        })
        queryClient.invalidateQueries(['deal-interests', dealId])
      },
      onError: error => {
        setToast({
          value: 'Failed to remove user from deal interest',
          type: 'error',
        })
      },
    }
  )

  /**
   * Invite from CRM form submit handler.
   *
   * @param data Form data
   */
  const inviteViaInterestSubmit = async (data: InviteViaInterestInputs) => {
    try {
      const usersToInvite = await getUsersToInviteFromInterests.mutateAsync()

      const usersToInviteTransformed = usersToInvite.map(
        userToInvite =>
          ({
            email: userToInvite.email,
            firstName: userToInvite.firstName ?? undefined,
            lastName: userToInvite.lastName ?? undefined,
            company: userToInvite.company ?? undefined,
          } as InviteListItem)
      )
      // avoid duplicates
      const newInvites = usersToInviteTransformed.filter(
        userToInvite =>
          !inviteList.some(item => item.email === userToInvite.email)
      )
      setInviteList([...inviteList, ...newInvites])

      setToast({
        type: 'success',
        value: 'User(s) added to invite list',
      })

      inviteViaInterestForm.reset()
    } catch (error) {
      setToast({
        type: 'error',
        value: 'Failed to add user(s) to invite list',
      })
    }
  }

  /**
   * Invite Manually form submit handler.
   *
   * @param data Form data
   */
  const inviteManuallySubmit = (data: InviteManuallyInputs) => {
    const {email} = data

    addItemToInviteList({email})

    setToast({
      type: 'success',
      value: 'User(s) added to invite list',
    })

    inviteManuallyForm.reset()
  }

  const handleTabClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    const clickedTabId = (e.target as HTMLButtonElement).id as TabId
    if (clickedTabId !== tabId) {
      setTabId(clickedTabId)
    }
  }

  // invite list functions start //
  const removeItemFromInviteList = (email: string) => {
    const newInviteList = inviteList.filter(item => item.email !== email)
    setInviteList(newInviteList)
  }
  const addItemToInviteList = (newItem: InviteListItem) => {
    if (inviteList.some(item => item.email === newItem.email)) {
      // avoid duplicates
      return
    }

    const newInviteList = [...inviteList, newItem]
    setInviteList(newInviteList)
  }
  const removeAllItemsFromInviteList = () => {
    setInviteList([])
  }
  const addInviteListToDeal = async () => {
    await inviteUsersToDeal.mutateAsync()
  }
  // invite list functions end //

  // resend list functions start //
  const removeEmailFromResendInvitesToList = (emailToRemove: string) => {
    const newResendInvitesTo = resendInvitesToList.filter(
      email => email !== emailToRemove
    )
    setResendInvitesToList(newResendInvitesTo)
  }
  const addEmailToResendInvitesToList = (newEmail: string) => {
    if (resendInvitesToList.includes(newEmail)) {
      // avoid duplicates
      return
    }

    const newResendInvitesTo = [...resendInvitesToList, newEmail]
    setResendInvitesToList(newResendInvitesTo)
  }
  const removeAllItemsFromResendInvitesToList = () => {
    setResendInvitesToList([])
  }
  const resendInvitesToSelected = async () => {
    await resendInviteToUsers.mutateAsync()
  }
  // resend list functions end //

  /**
   * DragDrop component event handler that
   * adds to the invite list from a CSV file.
   *
   * @param files Files read by DragDrop component
   */
  const handleInviteByCSV = async (files: any) => {
    const usersFile: any = files[0]

    try {
      setValueCSV([{name: usersFile.name}])

      if (errorCSV.status) {
        setErrorCSV({...errorCSV, status: false})
      }

      const reader = new FileReader()

      reader.onload = () => {
        const text: any = reader?.result // csv as text
        const results = Papa.parse(text, {header: true}) // object with { data, errors, meta }
        const rows: any[] = results.data // array of objects

        let validationError = ''
        const validated = rows.every((r: {email?: string}, index) => {
          const isValid = validateEmail(r.email)
          if (!isValid) {
            validationError = `The row number ${
              index + 1
            } has an invalid email ${r?.email}`
          }
          return isValid
        })

        if (validated) {
          const allCsvInvites = rows.map(
            row =>
              ({
                email: row.email,
                firstName: row.firstname,
                lastName: row.lastname,
                company: row.company,
                country: row.country,
              } as InviteListItem)
          )
          // avoid duplicates
          const newCsvInvites = allCsvInvites.filter(
            invite => !inviteList.some(item => item.email === invite.email)
          )
          setInviteList([...inviteList, ...newCsvInvites])
        } else {
          setErrorCSV({
            status: true,
            message: validationError,
          })
        }
      }

      reader.readAsText(usersFile)
    } catch (error) {
      setErrorCSV({
        status: true,
        message: 'This is not a valid csv file.',
      })
    }
  }

  return (
    <InviteUsersContainer>
      <div className="left">
        {/* Add from CRM */}
        <Card
          padding
          title="Add via interest"
          subtitle="Invite users via the CRM based on their interests and location."
          className="add-via-interest-card"
        >
          <form
            onSubmit={inviteViaInterestForm.handleSubmit(
              inviteViaInterestSubmit
            )}
          >
            <fieldset
              disabled={inviteViaInterestForm.formState.isSubmitting}
              aria-disabled={inviteViaInterestForm.formState.isSubmitting}
              aria-busy={inviteViaInterestForm.formState.isSubmitting}
              className="form-grid"
            >
              <Controller
                control={inviteViaInterestForm.control}
                name="sector"
                render={props => (
                  <div>
                    <span className="text-label">Sector</span>
                    <Select
                      placeholder="Select Sector"
                      options={DEAL_SECTORS}
                      isSearchable
                      isClearable
                      error={{
                        status: Boolean(inviteViaInterestForm.errors.sector),
                        message: inviteViaInterestForm.errors.sector?.message,
                      }}
                      isDisabled={inviteViaInterestForm.formState.isSubmitting}
                      {...props}
                    />
                  </div>
                )}
              />

              <Controller
                control={inviteViaInterestForm.control}
                name="stage"
                render={props => (
                  <div>
                    <span className="text-label">Stage</span>
                    <Select
                      placeholder="Select Stage"
                      options={DEAL_STAGES}
                      isSearchable
                      isClearable
                      error={{
                        status: Boolean(inviteViaInterestForm.errors.stage),
                        message: inviteViaInterestForm.errors.stage?.message,
                      }}
                      isDisabled={inviteViaInterestForm.formState.isSubmitting}
                      {...props}
                    />
                  </div>
                )}
              />

              <Controller
                control={inviteViaInterestForm.control}
                name="size"
                render={props => (
                  <div>
                    <span className="text-label">Size</span>
                    <Select
                      placeholder="Select Size"
                      options={DEAL_SIZES}
                      isSearchable
                      isClearable
                      error={{
                        status: Boolean(inviteViaInterestForm.errors.size),
                        message: inviteViaInterestForm.errors.size?.message,
                      }}
                      isDisabled={inviteViaInterestForm.formState.isSubmitting}
                      {...props}
                    />
                  </div>
                )}
              />

              <Button
                type="submit"
                full
                disabled={
                  !inviteViaInterestForm.formState.isDirty ||
                  inviteViaInterestForm.formState.isSubmitting
                }
              >
                Add to Invite List
              </Button>
            </fieldset>
          </form>
        </Card>

        {/* Add manually */}
        <Card
          padding
          title="Manually add users"
          subtitle="Invite specific users via email using the bulk CSV upload or invite individually."
          className="add-manually-card"
        >
          <form
            onSubmit={inviteManuallyForm.handleSubmit(inviteManuallySubmit)}
          >
            <fieldset
              disabled={inviteManuallyForm.formState.isSubmitting}
              aria-disabled={inviteManuallyForm.formState.isSubmitting}
              aria-busy={inviteManuallyForm.formState.isSubmitting}
              className="form-grid"
            >
              <div className="add-manually-card-form-email">
                <Input
                  ref={inviteManuallyForm.register}
                  type="text"
                  name="email"
                  label="Add additional recipient"
                  placeholder="Enter recipient email"
                  error={{
                    status: Boolean(inviteManuallyForm.errors.email),
                    message: inviteManuallyForm.errors.email?.message,
                  }}
                />

                <Button
                  type="submit"
                  full
                  disabled={
                    !inviteManuallyForm.formState.isDirty ||
                    inviteManuallyForm.formState.isSubmitting
                  }
                >
                  Add Recipient
                </Button>
              </div>

              <DragDrop
                error={errorCSV}
                value={valueCSV}
                accept=".csv"
                name="csv-invite"
                labelZone="Upload CSV"
                label="Upload via CSV"
                labelId="label-upload-csv"
                placeholder="Select CSV"
                onChange={handleInviteByCSV}
              />
            </fieldset>
          </form>
        </Card>
      </div>

      <div className="right">
        <div className="tabs">
          {/* Tabs */}
          <div className="tabs-buttons">
            <button
              id="1-invite-list"
              onClick={handleTabClick}
              disabled={
                inviteUsersToDeal.isLoading || resendInviteToUsers.isLoading
              }
              className={clsx(
                'tab-button',
                tabId === '1-invite-list' && 'tab-button--active'
              )}
            >
              Invite List
            </button>
            <button
              id="2-invited-to-deal"
              onClick={handleTabClick}
              disabled={
                inviteUsersToDeal.isLoading || resendInviteToUsers.isLoading
              }
              className={clsx(
                'tab-button',
                tabId === '2-invited-to-deal' && 'tab-button--active'
              )}
            >
              Invited to Deal
            </button>
          </div>

          {/* Extra tab actions */}
          <div className="tabs-extra-actions">
            {tabId === '1-invite-list' && (
              <Fragment>
                <ErrorButton
                  secondary
                  onClick={removeAllItemsFromInviteList}
                  disabled={
                    inviteList.length < 1 || inviteUsersToDeal.isLoading
                  }
                >
                  Clear List
                </ErrorButton>
                <Button
                  onClick={addInviteListToDeal}
                  disabled={
                    inviteList.length < 1 || inviteUsersToDeal.isLoading
                  }
                >
                  Invite to Deal
                </Button>
              </Fragment>
            )}

            {tabId === '2-invited-to-deal' && (
              <Fragment>
                <Button
                  onClick={resendInvitesToSelected}
                  disabled={
                    dealInterestsStatus === 'loading' ||
                    resendInvitesToList.length < 1 ||
                    resendInviteToUsers.isLoading
                  }
                >
                  Resend Invitation
                </Button>
              </Fragment>
            )}
          </div>
        </div>

        <div className="tab-content">
          {/* Tab content */}
          {tabId === '1-invite-list' && (
            <div>
              {inviteList.length < 1 ? (
                <Card
                  title="Adding Users"
                  subtitle="Use the Invite List tab to select the users you’d like to add to this deal. Once you’ve added users and you’re happy to invite, press the Add to Deal button which will send the users an email invitation and add them to the deal. Once a user has been invited, they will be displayed in the Invited to Deal tab."
                >
                  <p className="tab-content-card-text">
                    No new users have been added to this deal.
                  </p>
                </Card>
              ) : (
                <Table
                  columns={[
                    {
                      Header: 'Name',
                      accessor: ({firstName, lastName}: InviteListItem) =>
                        `${firstName} ${lastName}`,
                      Cell: (cell: Cell<InviteListItem>) => {
                        const {firstName, lastName, email} = cell.row.original

                        return (
                          <div>
                            {firstName || lastName ? (
                              <div className="bold black">
                                {`${firstName ?? ''} ${lastName ?? ''}`}
                              </div>
                            ) : null}

                            <div>{email}</div>
                          </div>
                        )
                      },
                    },
                    {
                      Header: 'Company',
                      accessor: 'company',
                    },
                    {
                      Header: 'Country',
                      accessor: 'country',
                    },
                    {
                      id: 'delete-invite-item-column',
                      disableSortBy: false,
                      Cell: (cell: Cell<InviteListItem>) => {
                        const {email} = cell.row.original

                        return (
                          <DeleteIcon
                            onClick={() => removeItemFromInviteList(email)}
                          />
                        )
                      },
                    },
                  ]}
                  data={inviteList}
                  showPerPage={50}
                />
              )}
            </div>
          )}

          {tabId === '2-invited-to-deal' && (
            <div>
              {dealInterestsStatus === 'loading' || !dealInterests ? (
                <Spinner primary />
              ) : dealInterests.length < 1 ? (
                <Card
                  title="Users invited to deal"
                  subtitle="Users invited to this deal will show up here. You can invite people to the deal via the Invite List tab."
                >
                  <p className="tab-content-card-text">
                    No users have been added to this deal yet.
                  </p>
                </Card>
              ) : (
                <Table
                  columns={[
                    {
                      id: 'add-user-to-resend-invite-list',
                      disableSortBy: true,
                      Header: () => {
                        return (
                          <input
                            type="checkbox"
                            checked={dealInterests
                              .filter(di => di.user && di.user.email)
                              .every(di =>
                                resendInvitesToList.includes(
                                  di.user!.email as string
                                )
                              )}
                            onChange={e => {
                              if (e.target.checked) {
                                const allUserEmails = dealInterests
                                  .filter(di => di.user && di.user.email)
                                  .map(di => di.user!.email as string)
                                setResendInvitesToList(allUserEmails)
                              } else {
                                removeAllItemsFromResendInvitesToList()
                              }
                            }}
                          />
                        )
                      },
                      Cell: (cell: Cell<DealInterest>) => {
                        const {user} = cell.row.original

                        return (
                          <input
                            type="checkbox"
                            disabled={!user || !user.email}
                            value={user?.email}
                            checked={Boolean(
                              user?.email &&
                                resendInvitesToList.includes(user.email)
                            )}
                            onChange={e => {
                              if (!user || !user.email) return

                              if (e.target.checked) {
                                addEmailToResendInvitesToList(user.email)
                              } else {
                                removeEmailFromResendInvitesToList(user.email)
                              }
                            }}
                          />
                        )
                      },
                    },
                    {
                      Header: 'Name',
                      accessor: ({
                        user: {firstName, lastName, email} = {},
                      }: DealInterest) => `${firstName} ${lastName}`,
                      Cell: (cell: Cell<DealInterest>) => {
                        const {user: {firstName, lastName, email} = {}} =
                          cell.row.original

                        return (
                          <div>
                            {firstName || lastName ? (
                              <div className="bold black">
                                {`${firstName ?? ''} ${lastName ?? ''}`}
                              </div>
                            ) : null}

                            <div>{email}</div>
                          </div>
                        )
                      },
                    },
                    {
                      Header: 'Date Invited',
                      accessor: 'createdAt',
                      Cell: (cell: Cell<DealInterest>) => {
                        const {createdAt} = cell.row.original

                        return formatFirebaseDate(createdAt)
                      },
                    },
                    {
                      Header: 'Country',
                      accessor: ({user: {country} = {}}: DealInterest) =>
                        country,
                    },
                    {
                      Header: 'Deal Status',
                      accessor: 'status',
                      Cell: (cell: Cell<DealInterest>) => {
                        const {status} = cell.row.original

                        return status ? (
                          <Badge
                            status={dealInterestStatusToBadgeStatus(status)}
                            size="small"
                          >
                            {DEAL_INTEREST_STATUS_TO_STRING[status]}
                          </Badge>
                        ) : (
                          ''
                        )
                      },
                    },
                    {
                      Header: 'Email Status',
                      accessor: ({inviteEmail}: DealInterest) =>
                        inviteEmail?.status,
                      Cell: (cell: Cell<DealInterest>) => {
                        const {inviteEmail} = cell.row.original

                        return inviteEmail?.status ? (
                          <Badge
                            status={emailDeliveryStatusToBadgeStatus(
                              inviteEmail.status
                            )}
                            size="small"
                          >
                            {
                              EMAIL_DELIVERY_STATUS_TO_STRING[
                                inviteEmail.status
                              ]
                            }
                          </Badge>
                        ) : (
                          ''
                        )
                      },
                    },
                    {
                      id: 'delete-deal-interest-column',
                      disableSortBy: true,
                      Cell: (cell: Cell<DealInterest>) => {
                        const {id: dealInterestId} = cell.row.original

                        return (
                          <DeleteIcon
                            onClick={() =>
                              deleteDealInterestMutation.mutate(dealInterestId)
                            }
                          />
                        )
                      },
                    },
                  ]}
                  data={dealInterests}
                  showPerPage={50}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </InviteUsersContainer>
  )
}

export default InviteUsers
