import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'

import {useAuth} from 'hooks/useAuth'

import Loader from 'components/Loader'
import ScrollReset from 'components/utils/ScrollReset'

import MainRoutes from './components/MainRoutes'
import MaintenanceRoutes from './components/MaintenanceRoutes'

const Routes: React.FC = () => {
  const {loading, maintenanceMode} = useAuth()

  if (loading) {
    return <Loader />
  }

  const routes = maintenanceMode ? <MaintenanceRoutes /> : <MainRoutes />

  return (
    <Router>
      <ScrollReset />

      {routes}
    </Router>
  )
}

export default Routes
