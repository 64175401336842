import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'

import {DEAL_SECTORS, DEAL_TYPES} from 'config/data/options.config'
import {DealStatus as DealStatusType} from 'types'

import Card from 'components/Card'
import DealStatus from 'components/DealStatus'
import DragDrop from 'components/DragDrop'
import Input from 'components/Input'
import Select, {Option} from 'components/Select'

import {CardContent, CustomArea} from './styles'

interface OverviewProps {
  disabled?: boolean
  status?: DealStatusType
  onRemove: (file?: any) => void
  caplinkedWorkspaces: Option[]
}

const Overview: React.FC<OverviewProps> = ({
  disabled,
  status,
  onRemove,
  caplinkedWorkspaces,
}) => {
  const {register, control, errors} = useFormContext()

  return (
    <Card
      padding
      title="Deal Overview"
      subtitle="Enter deal details below."
      titleRightComponent={status && <DealStatus {...{status}} />}
    >
      <CardContent>
        <Controller
          control={control}
          name="type"
          render={props => (
            <Select
              label="Deal Type"
              placeholder="Select Deal Type"
              options={DEAL_TYPES}
              isSearchable
              error={{
                status: errors?.type,
                message: errors?.type?.message,
              }}
              {...props}
              {...{isDisabled: disabled}}
            />
          )}
        />

        <Input
          id="deal-title"
          label="Deal Title"
          type="text"
          error={{
            status: errors?.name,
            message: errors?.name?.message,
          }}
          name="name"
          ref={register}
          {...{disabled}}
        />

        <Input
          id="deal-company"
          label="Company"
          type="text"
          error={{
            status: errors?.company,
            message: errors?.company?.message,
          }}
          name="company"
          ref={register}
          {...{disabled}}
        />

        <Controller
          control={control}
          name="sector"
          render={props => (
            <Select
              labelTop
              label="Sector"
              placeholder="Select sector"
              options={DEAL_SECTORS}
              isSearchable
              error={{
                status: errors?.sector,
                message: errors?.sector?.message,
              }}
              {...props}
              {...{isDisabled: disabled}}
            />
          )}
        />

        <Input
          id="deal-size"
          label="Deal Size"
          type="text"
          error={{
            status: errors?.investmentSize,
            message: errors?.investmentSize?.message,
          }}
          name="investmentSize"
          ref={register}
          {...{disabled}}
        />

        <div>
          <label className="text-label" htmlFor="description">
            Deal Description
          </label>
          <CustomArea
            name="description"
            placeholder="Add your description here"
            ref={register}
            rows={3}
            error={Boolean(errors && errors.description)}
          />
          {Boolean(errors && errors.description) && (
            <span className="error">{errors?.description?.message}</span>
          )}
        </div>

        <Controller
          control={control}
          name="caplinkedWorkspaceId"
          render={props => (
            <Select
              labelTop
              label="Caplinked Workspace"
              placeholder="Select Caplinked Workspace"
              options={caplinkedWorkspaces}
              isSearchable
              error={{
                status: errors?.caplinkedWorkspaceId,
                message: errors?.caplinkedWorkspaceId?.message,
              }}
              {...props}
              {...{isDisabled: disabled || status === 'PUBLISHED'}}
            />
          )}
        />

        <Controller
          control={control}
          name="featuredImage"
          defaultValue={[]}
          render={({onChange, value}) => {
            return (
              <DragDrop
                label="Deal Image"
                {...{control, onChange, onRemove, value, disabled}}
                maxSize={4000000}
                name="featuredImage"
                accept="image/*"
                error={{
                  status: errors?.featuredImage,
                  message: errors?.featuredImage?.message,
                }}
              />
            )
          }}
        />

        <Input
          id="deal-video"
          defaultValue=""
          label="Deal Video URL"
          type="url"
          error={{
            status: errors?.vimeo,
            message: errors?.vimeo?.message,
          }}
          name="vimeo"
          ref={register}
          {...{disabled}}
        />
      </CardContent>
    </Card>
  )
}

export default Overview
