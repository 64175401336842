import axios from 'axios'
import * as Sentry from '@sentry/react'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL ?? '',
})

client.interceptors.response.use(
  response => response,
  error => {
    Sentry.captureException(error)

    return Promise.reject(error)
  }
)

export default client
