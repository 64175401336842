import axios from 'lib/axios'

import {
  CAPLINKED,
  DATA_REQUESTS,
  DEALS_API,
  EMAIL_VERIFICATION,
  USERS_API,
} from 'config/endpoints.config'

export const archiveUser = async (userId: string) => {
  const response = await axios.delete(`${USERS_API}/${userId}`, {
    params: {
      action: 'ARCHIVE',
    },
  })

  return response.data
}

export const deleteUser = async (userId: string) => {
  const response = await axios.delete(`${USERS_API}/${userId}`, {
    params: {
      action: 'DELETE',
    },
  })

  return response.data
}

export const exportUserData = async (userId: string) => {
  const response = await axios.get(`${DATA_REQUESTS}`, {
    responseType: 'blob',
    params: {
      ids: [userId],
      dataType: 'USERS',
    },
  })

  const blob = new Blob([response.data], {type: 'text/csv;charset=utf-8;'})
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `user-data-${userId}.csv`)
  document.body.appendChild(link)
  link.click()

  return response.data
}

export const archiveDeal = async (dealId: string) => {
  const response = await axios.delete(`${DEALS_API}/${dealId}`, {
    params: {
      action: 'CLOSE',
    },
  })

  return response.data
}

export const deleteDeal = async (dealId: string) => {
  const response = await axios.delete(`${DEALS_API}/${dealId}`, {
    params: {
      action: 'DELETE',
    },
  })

  return response.data
}

export const exportDealData = async (dealId: string) => {
  const response = await axios.get(`${DATA_REQUESTS}`, {
    responseType: 'blob',
    params: {
      ids: [dealId],
      dataType: 'DEALS',
    },
  })

  const blob = new Blob([response.data], {type: 'text/csv;charset=utf-8;'})
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `deal-data-${dealId}.csv`)
  document.body.appendChild(link)
  link.click()

  return response.data
}

// Only available for admin
export const blockUser = async (userId: string, isSuspended: boolean) => {
  const response = await axios.patch(`${USERS_API}/${userId}`, {
    lastActiveAt: new Date(),
    isSuspended,
  })

  return response.data
}

export const fetchCaplinkedWorkspaces = async () => {
  const response = await axios.get(CAPLINKED)

  return response.data
}

export const overrideVerifyEmail = async (email: string) => {
  const response = await axios.patch(EMAIL_VERIFICATION, {
    email,
  })

  return response.data
}
