import firebase, {emailAuthCredentials} from 'lib/firebase'

const FIREBASE_ACTION_CODE = 'oobCode'
const FIREBASE_MODE = 'mode'
const FIREBASE_EMAIL = 'email'

const signInWithEmailAndPassword = (email: string, password: string) => {
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

const createUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const currentUser = firebase.auth().currentUser

  if (currentUser) {
    await firebaseSignOut()
  }

  await firebase.auth().createUserWithEmailAndPassword(email, password)
}

const reauthenticate = async (email: string, currentPassword: string) => {
  const currentUser = firebase.auth().currentUser

  if (currentUser) {
    const cred = emailAuthCredentials(email, currentPassword)
    await currentUser.reauthenticateWithCredential(cred)
  }
}

const updatePassword = async (password: string) => {
  const currentUser = firebase.auth().currentUser

  if (currentUser) {
    await currentUser.updatePassword(password)
  }
}

const firebaseSignOut = () => {
  return firebase.auth().signOut()
}

const refreshTokenResults = async () => {
  await firebase.auth().currentUser?.getIdTokenResult(true)
  const newTokenResults = await firebase.auth().currentUser?.getIdTokenResult()

  return {
    user: firebase.auth().currentUser,
    role: newTokenResults && newTokenResults.claims.role,
  }
}

export {
  refreshTokenResults,
  firebaseSignOut,
  updatePassword,
  reauthenticate,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  FIREBASE_ACTION_CODE,
  FIREBASE_MODE,
  FIREBASE_EMAIL,
}
