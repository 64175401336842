import {Link} from 'react-router-dom'
import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

import Colors from 'styles/colors'

export interface SubHeaderContainerProps {}

export const BreadcrumbsContainer = styled.div<SubHeaderContainerProps>`
  background-color: ${Colors.WHITE};
  padding: 16px 32px;
  @media only screen and (max-width: ${Breakpoints.sm}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;

  color: ${Colors.BLACK};

  :hover {
    color: ${Colors.BLUE};
    text-decoration: underline;
  }
`

export const BreadcrumbText = styled.span`
  font-size: 14px;
  line-height: 20px;

  color: ${Colors.BLACK};
`
