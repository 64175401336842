import React, {Fragment} from 'react'
import {ValueType} from 'react-select'

import useAnalytics from 'hooks/useAnalytics'
import {SelectDeal} from 'hooks/useAnalytics'

import Select from 'components/Select'
import Spinner from 'components/Spinner'
import Toast from 'components/Toast'
import ContentContainer from 'components/ContentContainer'
import Card from 'components/Card'
import PageTitle from 'components/PageTitle'

import {SelectDealCard, StatisticsCards, StatisticsCard} from './styles'

const Analytics: React.FC = () => {
  const {analytics, deals, error, loading, setError, setDeal} = useAnalytics()

  const handleSearch = (item: ValueType<SelectDeal>) => {
    setDeal(item as SelectDeal)
  }

  return (
    <Fragment>
      <PageTitle title="Analytics" paddingTop />

      <ContentContainer>
        <Toast message={error} action={setError} />

        <SelectDealCard
          padding
          title="Select Deal"
          subtitle="Using the below input, select a deal"
        >
          <Select
            placeholder="Your Deal"
            options={deals}
            onChange={handleSearch}
            full
            isDisabled={loading}
            isSearchable
            isClearable
          />
        </SelectDealCard>

        <div
          style={{
            marginTop: 48,
          }}
        >
          <Card padding title="Deal Statistics">
            <div>
              {loading ? (
                <Spinner primary />
              ) : (
                <Fragment>
                  {analytics.length ? (
                    <StatisticsCards>
                      {analytics.map(({value, label}) => (
                        <StatisticsCard padding>
                          <p className="value">{value}</p>
                          <p className="label">{label}</p>
                        </StatisticsCard>
                      ))}
                    </StatisticsCards>
                  ) : (
                    <span>No deal selected</span>
                  )}
                </Fragment>
              )}
            </div>
          </Card>
        </div>
      </ContentContainer>
    </Fragment>
  )
}

export default Analytics
