/* eslint-disable no-useless-escape */
export const getParameterByName = (name: string, url?: string) => {
  if (!url) {
    url = window.location.href
  }

  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')

  const regexS = '[\\?&]' + name + '=([^&#]*)'
  const regex = new RegExp(regexS)
  const results = regex.exec(url)

  return results == null ? null : results[1]
}

/**
 * Open a new tab
 * @param href string
 */
export const openUrlInNewTab = (href: string) => {
  Object.assign(window.document?.createElement('a'), {
    target: '_blank',
    href,
  }).click()
}

/**
 * Open a new tab - window ipen
 * @param url string
 */
export const windowOpen = (url: string) => {
  let page = window?.open(url, '_blank')

  if (page) {
    page.document.write('Loading document...')
    page.location.href = url
  }
}

/**
 * Reload current window with new url
 * @param url string
 */
export const openUrlInWindow = (url: string) => {
  window.location.replace(url)
}
