import React, {Fragment, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'

import {useAuth} from 'hooks/useAuth'
import Breakpoints from 'styles/breakpoints'
import {ROUTES} from 'config/routes.config'
import {Deal, MessageProps} from 'types'
import {getDeals} from 'services/deals'
import {errorDefault} from 'utils/errors'

import Input from 'components/Input'
import Toast from 'components/Toast'
import Button from 'components/styles/Button'
import Spinner from 'components/Spinner'

import AdminDeals from './components/AdminDeals'

const SearchBar = styled.div`
  margin-bottom: 32px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 140px;
  gap: 32px;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`

type StateProps = {
  yourDeals: Deal[]
  openDeals: Deal[]
}

type FilterType = 'name'

type FiltersType = {
  values: {
    slug?: string
    name?: string
  }
  data: Deal[]
}

const defaultState: StateProps = {
  yourDeals: [],
  openDeals: [],
}
const defaultFilters: FiltersType = {
  values: {},
  data: [],
}

const Admin = () => {
  const history = useHistory()
  const {isAdmin} = useAuth()

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  const [loading, setLoading] = useState(true)
  const [deals, setDeals] = useState(defaultState)

  const {yourDeals} = deals

  /**
   * Filters
   */
  const [filters, setFilters] = useState(defaultFilters)
  // Is fitlers enabled? At least 1 active values should be there
  const activeFilters = Object.keys(filters.values).length > 0

  useEffect(() => {
    if (loading) {
      const fetchAdminDeals = async () => {
        try {
          const yourDeals = await getDeals()

          setDeals({...deals, yourDeals})
        } catch (_) {
          setToast({
            value: errorDefault,
            type: 'error',
          })
        }

        setLoading(!loading)
      }
      fetchAdminDeals()
    }
  }, [deals, isAdmin, loading])

  /**
   * On Filters change
   */
  useEffect(() => {
    const {values} = filters

    switch (activeFilters) {
      case true: // Apply filter(s)
        let results = yourDeals

        const name = values?.name?.toLowerCase()

        if (name) {
          results = results.filter(deal =>
            deal.name.toLowerCase().startsWith(name)
          )
        }

        setFilters({
          ...filters,
          data: results,
        })

        break
      default:
        // Clear filters
        setFilters(defaultFilters)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.values])

  const clearFilters = (type: 'name') => {
    let newValues = {...filters.values}
    delete newValues[type]

    setFilters({
      ...filters,
      values: newValues,
    })
  }

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name as FilterType

    switch (value) {
      case '':
        clearFilters(name)
        break
      default:
        setFilters({
          ...filters,
          values: {
            ...filters.values,
            [name]: value,
          },
        })
    }
  }

  const dealsData = activeFilters ? filters.data : yourDeals

  if (loading) {
    return <Spinner />
  }

  return (
    <Fragment>
      <SearchBar>
        <div />
        <div />

        <Input
          id="search-input"
          type="text"
          placeholder="Search Deals"
          name="name"
          value={filters.values?.name || ''}
          onChange={handleSearchByName}
          onClear={handleSearchByName}
        />

        <Button type="button" onClick={() => history.push(ROUTES.CREATE_DEAL)}>
          Create Deal
        </Button>
      </SearchBar>

      <AdminDeals deals={dealsData} />

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default Admin
