import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-gap: 2rem;
`

export const QuestionsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  max-height: 270px !important;
  overflow-y: scroll;

  @media only screen and (max-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr;
    max-height: 100%;
  }
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.8rem;
`
