import styled from 'styled-components'
import Colors from 'styles/colors'

const LightBox = styled.div<{show?: boolean}>`
  display: ${({show}) => (show ? 'block' : 'none')}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  .content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* The Close Button */
  .close {
    color: ${Colors.BLACK};
    float: right;
    font-size: 3rem;
    font-weight: bold;
    margin: 1rem 1rem 0 0;
  }

  .close:hover,
  .close:focus {
    color: ${Colors.WHITE};
    text-decoration: none;
    cursor: pointer;
  }
`

export default LightBox
