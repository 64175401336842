import styled, {css} from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

import Icon from 'components/Icon'

export const FilesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;

  @media only screen and (max-width: 1590px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
  }
  @media only screen and (max-width: ${Breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 2em;
  }
  @media only screen and (max-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
  }
  @media only screen and (max-width: ${Breakpoints.sm}) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }
`

export const FileBox = styled.section`
  display: flex;
  flex-direction: column;

  background: ${Colors.BACKGROUND_FILE_BOX};
  border: 1px solid ${Colors.LIGHT_GREY};
  box-sizing: border-box;
  border-radius: 5px;

  .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
`

const iconStyling = css`
  width: 2.5rem !important;
  height: 2.5rem !important;
`

export const PDFIcon = styled(Icon)`
  ${iconStyling};

  path {
    fill: red;
  }
`

export const ExcelIcon = styled(Icon)`
  ${iconStyling};

  path {
    fill: green;
  }
`

export const VideoIcon = styled(Icon)`
  ${iconStyling};

  path {
    fill: ${Colors.BLUE};
  }
`

export const LockIcon = styled(Icon)<{isLock: boolean}>`
  ${iconStyling};

  path {
    fill: ${({isLock}) => (isLock ? Colors.WARNING : Colors.SUCCESS)};
  }
`

export const Type = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 1rem 1rem 0 0;
`
