import styled from 'styled-components'

import Colors from 'styles/colors'

import Card from 'components/Card'

export const SelfCertificationCard = styled(Card)`
  .certification-types {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;

    .certification-type-chevron {
      position: absolute;
      top: 20px;
      right: 0;
    }

    .certification-type {
      margin: 0;
      padding: 16px 12px;
      text-align: left;
      border: none;
      background: transparent;
      color: inherit;
      font: inherit;
    }

    .certification-type-statement {
      background-color: ${Colors.GREY_100};
      border-radius: 5px;
      border: 1px solid ${Colors.LIGHT_GREY};
      margin-bottom: 12px;

      .certification-type-statement-inner {
        max-height: 225px;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .cancel-process {
    margin-top: 18px;
  }
`
