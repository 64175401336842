import React, {useEffect} from 'react'

import {useForm} from 'react-hook-form'

import Card from 'components/Card'
import Button from 'components/styles/Button'
import Checkbox from 'components/Checkbox'
import Badge from 'components/styles/Badge'

import {Form, InterestsTitle, FormSectionTitle} from './styles'

interface InterestsProps {
  data: {
    loading: boolean
    dealSectors: string[]
    dealSizes: string[]
    dealStages: string[]
  }
  onSubmit: (data: any) => void
}

const Interests: React.FC<InterestsProps> = ({data, onSubmit}) => {
  const {register, handleSubmit, reset} = useForm()

  const {dealSizes, dealSectors, dealStages, loading} = data

  /**
   * Define the default box value
   *
   * @param currentBox - current `value` of the checkbox (i.e: "<£5m", "Infrastructure", etc... )
   * @param type - section type either stage or size or sector
   * @returns default checkbox value as tru or false!
   */
  const defaultValue = (
    currentBox: string,
    type: 'stage' | 'size' | 'sector'
  ) => {
    const getValues = () => {
      switch (type) {
        case 'stage':
          return dealStages
        case 'size':
          return dealSizes
        case 'sector':
          return dealSectors
        default:
          return []
      }
    }

    const values: string[] = getValues()
    if (!!values.length) {
      const valueFound = values.find(
        (item: string) => item.toLowerCase() === currentBox.toLowerCase()
      )
      return !!valueFound
    }

    return false
  }

  useEffect(() => {
    if (!loading) {
      reset() // clear unsaved data
    }
  }, [loading, reset])

  const isInProgress =
    dealSectors.length < 1 || dealSizes.length < 1 || dealStages.length < 1

  return (
    <Card
      padding
      title="Your Interests"
      titleRightComponent={
        isInProgress ? <Badge status="in_progress">In Progress</Badge> : null
      }
    >
      <InterestsTitle>
        Please provide us with some indactives times you would be available.
      </InterestsTitle>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={loading} aria-busy={loading}>
          <FormSectionTitle>Size</FormSectionTitle>

          <div className="section">
            <Checkbox
              ref={register}
              value="<£5m"
              name="dealSizes"
              defaultChecked={defaultValue('<£5m', 'size')}
            />
            <Checkbox
              ref={register}
              value="£5m-£10m"
              name="dealSizes"
              defaultChecked={defaultValue('£5m-£10m', 'size')}
            />
            <Checkbox
              ref={register}
              value="£10m-£50m"
              name="dealSizes"
              defaultChecked={defaultValue(`£10m-£50m`, 'size')}
            />
            <Checkbox
              ref={register}
              value="£50m-£100m"
              name="dealSizes"
              defaultChecked={defaultValue(`£50m-£100m`, 'size')}
            />
            <Checkbox
              ref={register}
              value=">£100m"
              name="dealSizes"
              defaultChecked={defaultValue(`>£100m`, 'size')}
            />
          </div>

          <FormSectionTitle>Sector</FormSectionTitle>

          <div className="section">
            <Checkbox
              ref={register}
              value={`Food & Drink`}
              name="dealSectors"
              defaultChecked={defaultValue(`Food & Drink`, 'sector')}
            />
            <Checkbox
              ref={register}
              value="Tech"
              name="dealSectors"
              defaultChecked={defaultValue('Tech', 'sector')}
            />
            <Checkbox
              ref={register}
              name="dealSectors"
              value="Infrastructure"
              defaultChecked={defaultValue('Infrastructure', 'sector')}
            />
            <Checkbox
              ref={register}
              value="Energy"
              name="dealSectors"
              defaultChecked={defaultValue('Energy', 'sector')}
            />
            <Checkbox
              ref={register}
              value="Financial Services"
              name="dealSectors"
              defaultChecked={defaultValue('Financial Services', 'sector')}
            />
          </div>

          <FormSectionTitle>Stage</FormSectionTitle>

          <div className="section">
            <Checkbox
              ref={register}
              value="Early stage/seed"
              name="dealStages"
              defaultChecked={defaultValue('Early stage/seed', 'stage')}
            />
            <Checkbox
              ref={register}
              value="Venture capital"
              name="dealStages"
              defaultChecked={defaultValue('Venture capital', 'stage')}
            />
            <Checkbox
              ref={register}
              value="Growth capital"
              name="dealStages"
              defaultChecked={defaultValue('Growth capital', 'stage')}
            />
            <Checkbox
              value={`Buyout/M&A`}
              ref={register}
              name="dealStages"
              defaultChecked={defaultValue(`Buyout/M&A`, 'stage')}
            />
          </div>

          <Button type="submit">
            {loading ? 'Please wait...' : 'Confirm Preferences'}
          </Button>
        </fieldset>
      </Form>
    </Card>
  )
}

export default Interests
