import {useEffect, useState} from 'react'

import {getVideoFromVimeo} from 'services/deals'

const useVimeoIframe = (url?: string) => {
  const [videoIframe, set] = useState<string | null>(null)
  const [videoError, setError] = useState<any>(null)

  useEffect(() => {
    if (url && !videoIframe) {
      const getVideoIframe = async () => {
        try {
          const video = await getVideoFromVimeo(url)
          set(video.html)
        } catch (error) {
          setError(error)
        }
      }

      getVideoIframe()
    }
  }, [url, videoIframe])

  return {videoIframe, videoError}
}

export default useVimeoIframe
