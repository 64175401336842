import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

import Colors from 'styles/colors'
import {NotificationIconType} from 'types'

import Button from 'components/styles/Button'
import Breakpoints from 'styles/breakpoints'

export const ExpandButton = styled(Button)`
  margin: 0 auto;
  border: 1px solid ${Colors.LIGHT_GREY};
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
`

export const NotificationsContainer = styled.div`
  @media (min-width: ${Breakpoints.sm}) {
    position: relative;

    ::before {
      position: absolute;
      content: '';
      width: 2px;
      height: 100%;
      left: 16px;
      background-color: ${Colors.LIGHT_GREY};
    }
  }
`

export const Notification = styled.div`
  background-color: ${Colors.WHITE};
  position: relative;

  :not(:first-child) {
    margin-top: 32px;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  @media (min-width: ${Breakpoints.sm}) {
    grid-template-columns: 1fr 100px;
  }
`

export const NotificationCoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  @media (min-width: ${Breakpoints.sm}) {
    display: grid;
    grid-template-columns: 50px 1fr;
  }
`

export const NotificationAltInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const notificationIconInfoStyle = css`
  background-color: #ebf6ff;
  color: #36a6ff;
`
const notificationIconWarningStyle = css`
  background-color: #fff8eb;
  color: #ffbb38;
`
const notificationIconSuccessStyle = css`
  background-color: #ebfbf2;
  color: #3ad57d;
`
const notificationIconGreyStyle = css`
  background-color: #e8ebef;
  color: #1b395f;
`
export interface NotificationIconProps {
  type: NotificationIconType
}
export const NotificationIcon = styled.div<NotificationIconProps>`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 6px;
  margin-bottom: 6px;

  ${({type}) =>
    type === 'PROFILE_ACTION' ? notificationIconInfoStyle : undefined};
  ${({type}) => (type === 'DENIED' ? notificationIconWarningStyle : undefined)};
  ${({type}) =>
    type === 'CONFIRMED' ? notificationIconSuccessStyle : undefined};
  ${({type}) => (type === 'MESSAGE' ? notificationIconGreyStyle : undefined)};
`

export const NotificationTitle = styled.span`
  font-size: 16px;
  line-height: 28px;
`

export const NotificationLink = styled(Link)`
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
  color: inherit;

  :hover {
    text-decoration: underline;
    color: ${Colors.BUTTON_HOVER_BLUE};
  }
`

export const NotificationDate = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
`

export const NoNotificationsContainer = styled.div`
  padding: 1rem;

  border: 1px solid ${Colors.LIGHT_GREY};
  box-sizing: border-box;
  border-radius: 5px;
`
