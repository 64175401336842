import React, {useState, Fragment} from 'react'

import {makeStyles, Dialog, DialogContent, Slide} from '@material-ui/core'
import {TransitionProps} from '@material-ui/core/transitions'

import Button from 'components/styles/Button'
import ReactPdfViewer from 'components/ReactPdfViewer'

const Transition = React.forwardRef(
  (
    props: TransitionProps & {children?: React.ReactElement},
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />
  }
)

interface PdfPreviewButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fileUrl: string
  onDownload?: Function
  isDownloading?: boolean
}

const PdfPreviewButton: React.FC<PdfPreviewButtonProps> = ({
  fileUrl,
  children,
  onDownload,
  isDownloading,
  ...rest
}) => {
  const classes = useStyles()

  const [showPreview, setShowPreview] = useState(false)
  const openPreview = () => setShowPreview(true)
  const closePreview = () => setShowPreview(false)

  return (
    <Fragment>
      <Button onClick={openPreview} {...rest}>
        {children}
      </Button>

      <Dialog
        open={showPreview}
        onClose={closePreview}
        fullScreen
        TransitionComponent={Transition}
        scroll="body"
      >
        <DialogContent className={classes.dialogContent}>
          <ReactPdfViewer
            file={fileUrl}
            onClose={closePreview}
            onDownload={onDownload}
            disableMargin
            height="100%"
            isDownloading={isDownloading}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: '0 !important',
    margin: '0 !important',
    height: '100%',
  },
}))

export default PdfPreviewButton
