import React from 'react'

import Header from 'components/Header'
import Footer from 'components/Footer'

import {AuthLayoutContainer} from './styles'

const AuthLayout: React.FC = ({children}) => {
  return (
    <AuthLayoutContainer>
      <Header />

      <main className="content">{children}</main>

      <Footer />
    </AuthLayoutContainer>
  )
}

export default AuthLayout
