import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

import Colors from 'styles/colors'

export const TimelineContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

export interface TimelineItemProps {
  filled?: boolean
}

export const TimelineItem = styled.div<TimelineItemProps>`
  padding: 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: ${Breakpoints.sm}) {
    flex-direction: row;
    text-align: left;
  }
  @media (min-width: ${Breakpoints.md}) {
    flex-direction: column;
    text-align: center;
  }
  @media (min-width: ${Breakpoints.lg}) {
    flex-direction: row;
    text-align: left;
  }

  border-bottom: 4px solid;
  border-bottom-color: ${({filled}) =>
    filled ? Colors.BLUE : Colors.LIGHT_GREY};

  :not(:first-child) {
    border-left: 1px solid ${Colors.LIGHT_GREY};

    ::before {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 1px solid ${Colors.LIGHT_GREY};
      border-right: 1px solid ${Colors.LIGHT_GREY};
      transform: rotate(45deg);
      position: absolute;
      left: -5px;
      top: 44%;
      background-color: ${Colors.WHITE};
    }
  }
`

export interface TimelineItemCountProps {
  filled?: boolean
}
export const TimelineItemCount = styled.div<TimelineItemCountProps>`
  border: 2px solid;
  border-radius: 50%;
  border-color: ${({filled}) => (filled ? Colors.BLUE : Colors.LIGHT_GREY)};

  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 12px;
  @media (min-width: ${Breakpoints.sm}) {
    margin-bottom: 0;
    margin-right: 12px;
  }
  @media (min-width: ${Breakpoints.md}) {
    margin-bottom: 12px;
    margin-right: 0;
  }
  @media (min-width: ${Breakpoints.lg}) {
    margin-bottom: 0;
    margin-right: 12px;
  }
`

export interface TimelineItemCountTextProps {
  filled?: boolean
}
export const TimelineItemCountText = styled.span<TimelineItemCountTextProps>`
  font-size: 14px;
  line-height: 16px;

  color: ${({filled}) => (filled ? Colors.BLUE : Colors.DARK_GREY)};
`

export interface TimelineItemTitleProps {
  filled?: boolean
}
export const TimelineItemTitle = styled.p<TimelineItemTitleProps>`
  margin: 0;
  color: ${({filled}) => (filled ? Colors.BLUE : Colors.GREY)};
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
`

export const TimelineItemDate = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  color: ${Colors.DARK_GREY};
`
