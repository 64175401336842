import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

export const SearchBar = styled.div`
  display: grid;
  grid-gap: 2em;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  width: 100%;

  @media only screen and (max-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }

  margin-bottom: 1.5em;
`
