import React, {useEffect, useState, Fragment} from 'react'
import {useHistory, useParams, useLocation} from 'react-router-dom'

import {useAuth} from 'hooks/useAuth'
import useDeal from 'hooks/useDeal'
import {MessageProps, File} from 'types'
import {DATAROOM_STATUS} from 'config/data/deal-interest.config'
import {ROUTES} from 'config/routes.config'
import {timeouts} from 'config/timeouts.config'
import {DEFAULT_MESSAGES} from 'config/error.config'

import imagePlaceholder from 'assets/images/placeholder.png'

import Breadcrumbs from 'components/Header/Breadcrumbs'
import {Col} from 'components/styles/GridLayout'
import Toast from 'components/Toast'
import Card from 'components/Card'
import Button from 'components/styles/Button'
import Spinner from 'components/Spinner'

import DealDetails from 'components/DealDetails'
import Contact from 'components/Contact'
import DealProgress from 'components/DealProgress'
import DealTimeline from 'components/DealTimeline'
import {ErrorFallback} from 'components/utils/ErrorBoundary'
import ContentContainer from 'components/ContentContainer'
import SelfCertification from 'components/SelfCertification'

import {Image, Content, DealGrid, Header} from './styles'
import Files from './components/Files'
import Admin from './components/Admin'
import ExportButton from './components/ExportButton'
// import ArchiveButton from './components/ArchiveButton';

type DealParams = {
  slug: string
}

const Deal: React.FC = () => {
  const history = useHistory()
  const {slug} = useParams<DealParams>()
  const {isAdmin, selfCertifiedAs, lastCertifiedAt} = useAuth()

  const {deal, loading, error, showDealDetails, showFiles, refetchDeal} =
    useDeal(slug)

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })
  useEffect(() => {
    if (error) {
      setToast({
        value: error,
        type: 'error',
      })
    }
  }, [error])

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const dealActionParam = query.get('action')
  const dealEventParam = query.get('event')
  const listenForDealProgress =
    dealActionParam === 'nda' && dealEventParam === 'signing_complete'

  /**
   * It may be necessary to update the deal in case we
   * expect the deal progress to change.
   * This happens when the user is redirected back from
   * signing the NDA on DocuSign.
   */
  useEffect(() => {
    let refetchDealTimeout: number
    if (listenForDealProgress) {
      refetchDealTimeout = setTimeout(() => {
        refetchDeal()
      }, timeouts.minute)
    }

    return () => {
      if (refetchDealTimeout) {
        clearTimeout(refetchDealTimeout)
      }
    }
  }, [listenForDealProgress, refetchDeal])

  const dealInterests = deal?.dealInterests || []
  const deskFiles = deal?.deskFiles || []
  const enableCaplinked =
    isAdmin ||
    deal?.dealInterest?.dataroomRequestStatus === DATAROOM_STATUS.APPROVED

  const userFiles: Partial<File>[] = [
    {...deal?.teaserFile, label: 'Deal Teaser', type: 'teaser'},
    ...deskFiles,
    {
      name: `CapLinked Data Room.caplinked`,
      reference: `data-room-${deal?.name}`,
      url: 'https://secure.caplinked.com/users/sign-in',
      type: 'caplinked',
    },
  ]
  // Setup files with process letter included
  if (deal?.processLetter) {
    userFiles.push({
      name: `Process Letter - ${deal.name}.pdf`,
      reference: `process-letter-${deal.name}`,
      url: '',
      label: 'Process Letter',
      type: 'letter',
    })
  }
  // Setup files with video included
  if (deal?.video?.url) {
    userFiles.push({
      name: `Video - ${deal.name}.video`,
      reference: 'video',
      url: deal.video.url,
      type: 'video',
    })
  }

  if (loading) {
    return <Spinner />
  }
  if (error || !deal) {
    return (
      <ErrorFallback
        error={error ?? new Error(DEFAULT_MESSAGES.ERROR)}
        resetErrorBoundary={() => window.location.reload()}
      />
    )
  }

  return (
    <Fragment>
      {isAdmin && (
        <Breadcrumbs
          breadcrumbs={[
            {
              type: 'link',
              to: '/deals',
              label: 'Deals',
            },
            {
              type: 'text',
              label: deal.name,
            },
          ]}
        />
      )}

      <ContentContainer>
        <Content>
          {/** Show options only for Admin user */}
          <Header showOptions={isAdmin}>
            <Button onClick={() => history.push(ROUTES.DEALS)}>
              Back to Deals
            </Button>

            {/** Admin options */}
            <div className="options">
              <ExportButton id={deal.id} />

              <Button
                secondary
                onClick={() =>
                  history.push(`${ROUTES.CREATE_DEAL}/${deal.name}`, {
                    dealId: deal.id,
                  })
                }
              >
                Edit
              </Button>

              {/** Handle archive/delete deal */}
              {/* <ArchiveButton
                id={deal.id}
                status={deal.status ?? ''}
                name={deal.name ?? ''}
              /> */}
            </div>
          </Header>

          <DealGrid>
            <Col>
              <Image
                src={
                  showDealDetails ? deal?.featuredImage?.url : imagePlaceholder
                }
                alt={deal?.name}
              />

              <DealDetails deal={deal} showOverviewTitle hideViewButton />

              <Card padding title="Contact">
                <Contact data={deal.contact} id={deal.dealInterest?.id} />
              </Card>

              {selfCertifiedAs && lastCertifiedAt ? (
                <SelfCertification />
              ) : null}
            </Col>

            <Col>
              <DealTimeline deal={deal} />

              <DealProgress
                deal={deal}
                refetchDeal={refetchDeal}
                isSigningNdaInProgress={listenForDealProgress}
              />

              <Card padding title="Deal Files">
                <Files
                  files={userFiles}
                  dealInterestId={deal.dealInterest?.id}
                  enableCaplinked={enableCaplinked}
                  showFiles={showFiles}
                />
              </Card>
            </Col>
          </DealGrid>

          {/* Show for Admin the deal interests table for this deal */}
          <Admin dealInterests={dealInterests} />
        </Content>
      </ContentContainer>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default Deal
