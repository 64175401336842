import React, {Fragment, useState} from 'react'
import {ChevronDown, ChevronUp} from 'react-ikonate'
import {useQuery} from 'react-query'

import {NotificationIconType} from 'types'
import {useAuth} from 'hooks/useAuth'
import {getRecentNotifications} from 'services/notifications'
import {formatFirebaseDate} from 'utils/date'
import {ROUTES} from 'config/routes.config'

import {Skeleton} from '@material-ui/lab'

import Card from 'components/Card'

import {
  ExpandButton,
  NotificationsContainer,
  Notification,
  NotificationCoreInfo,
  NotificationAltInfo,
  NotificationIcon,
  NotificationTitle,
  NotificationLink,
  NotificationDate,
  NoNotificationsContainer,
} from './styles'
import Icon, {IconName} from 'components/Icon'

type IconNameType = {
  [key in NotificationIconType]: IconName
}

const iconName: IconNameType = {
  CONFIRMED: 'checkmark-regular',
  DENIED: 'exclamation-regular',
  PROFILE_ACTION: 'user-regular',
  MESSAGE: 'comments-regular',
}

const Notifications: React.FC = () => {
  const {user} = useAuth()
  const userId = user?.uid

  const [expanded, setExpanded] = useState(false)
  const handleToggleExpanded = () => setExpanded(prev => !prev)

  const {data: notifications = [], status} = useQuery(
    ['notifications', userId],
    () => getRecentNotifications(userId as string),
    {
      enabled: Boolean(userId),
      refetchInterval: 1000 * 30, // 30 seconds
      refetchOnWindowFocus: true,
    }
  )

  return (
    <Card
      title="Notifications"
      padding
      bottomComponent={
        Array.isArray(notifications) && notifications.length > 3 ? (
          <ExpandButton styleless flex onClick={handleToggleExpanded}>
            {expanded ? 'Collapse section' : 'Expand section'}

            {expanded ? (
              <ChevronUp
                style={{
                  marginLeft: 6,
                }}
                fontSize="1.25rem"
                strokeWidth={3}
              />
            ) : (
              <ChevronDown
                style={{
                  marginLeft: 6,
                }}
                fontSize="1.25rem"
                strokeWidth={3}
              />
            )}
          </ExpandButton>
        ) : null
      }
    >
      {status === 'loading' ? (
        <Fragment>
          <Skeleton variant="text" width="100%" height={50} />
          <Skeleton variant="text" width="100%" height={50} />
          <Skeleton variant="text" width="100%" height={50} />
        </Fragment>
      ) : Array.isArray(notifications) && notifications.length > 0 ? (
        <NotificationsContainer>
          {notifications
            .filter((_, index) => {
              if (expanded) {
                return true
              }
              return index < 3 // display 3 by default
            })
            .map(notification => (
              <Notification key={notification.id}>
                <NotificationCoreInfo>
                  {notification.iconType ? (
                    <NotificationIcon type={notification.iconType}>
                      <Icon name={iconName[notification.iconType]} />
                    </NotificationIcon>
                  ) : null}

                  <NotificationTitle>
                    {notification.notification}
                    {notification.dealName && notification.dealSlug ? (
                      <Fragment>
                        {' for '}
                        <NotificationLink
                          to={`${ROUTES.DEAL}/${notification.dealSlug}`}
                        >
                          {notification.dealName}
                        </NotificationLink>
                      </Fragment>
                    ) : null}
                  </NotificationTitle>
                </NotificationCoreInfo>

                <NotificationAltInfo>
                  <NotificationDate>
                    {formatFirebaseDate(notification.createdAt)}
                  </NotificationDate>
                </NotificationAltInfo>
              </Notification>
            ))}
        </NotificationsContainer>
      ) : (
        <NoNotificationsContainer>
          <h4>You have no notifications as of now.</h4>
        </NoNotificationsContainer>
      )}
    </Card>
  )
}

export default Notifications
