import React from 'react'

import {CardContainerProps, CardContainer} from './styles'

export interface CardProps extends CardContainerProps {
  title?: string
  subtitle?: string
  titleRightComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
  className?: string
}

const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  titleRightComponent,
  bottomComponent,
  children,
  ...rest
}) => {
  return (
    <CardContainer {...rest}>
      {title ? (
        <div className="card-title">
          <h2 className="card-title-text">{title}</h2>

          {titleRightComponent}
        </div>
      ) : null}

      {subtitle ? (
        <div className="card-subtitle">
          <p className="card-subtitle-text">{subtitle}</p>
        </div>
      ) : null}

      <div className="card-content">{children}</div>

      {bottomComponent ? (
        <div className="card-bottom">{bottomComponent}</div>
      ) : null}
    </CardContainer>
  )
}

export default Card
