import React from 'react'
import DropDownCard from './components/DropdownCard'
import {ToggleButton} from './styles'

export interface ToggleProps {
  children: any
  data: any[]
  dealInterestId: string
  onClick: (id: string) => void
}

const Toggle: React.FC<ToggleProps> = ({
  children,
  data,
  dealInterestId,
  onClick,
}) => {
  const [open, setOpen] = React.useState(false)
  const drop: any = React.useRef(null)

  function handleClick(e: any) {
    if (
      drop &&
      drop.current &&
      !e.target.closest(`.${drop.current.className}`) &&
      open
    ) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  return (
    <div
      className="dropdown"
      ref={drop}
      style={{
        position: 'relative',
        margin: '16px',
        width: 'auto',
        display: 'inline-block',
      }}
    >
      <ToggleButton onClick={() => setOpen(open => !open)}>
        {children}
      </ToggleButton>

      {open && (
        <DropDownCard
          data={data}
          setOpen={setOpen}
          dealInterestId={dealInterestId}
          onClick={onClick}
        />
      )}
    </div>
  )
}

export default Toggle
