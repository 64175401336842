import React, {Fragment} from 'react'

import Modal from 'components/Modal'
import Spinner from 'components/Spinner'

type UpdateModalProps = {
  show: boolean
}

const UpdateModal = ({show}: UpdateModalProps) => {
  return (
    <Modal isShowing={show} maxWidth="600px">
      <Fragment>
        <h2>Access Update</h2>
        <p className="modal-inner-body">
          Please wait while the user access is updating. <br />
          <br />
          It may take a few seconds.
        </p>

        <div className="modal-inner-btn">
          <Spinner primary />
        </div>
      </Fragment>
    </Modal>
  )
}

export default UpdateModal
