import styled from 'styled-components'
import Colors from 'styles/colors'

const Separator = styled.hr<{hide?: boolean}>`
  display: ${({hide}) => (hide ? 'none' : 'block')};
  width: 100%;
  border: none;
  height: 1px;
  color: ${Colors.SECONDARY_25};
  background-color: ${Colors.SECONDARY_25};
`
export default Separator
