import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin-top: 15%;
  }

  padding: 2em 0;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: ${Breakpoints.sm}) {
    padding: 6em 0.5em;
  }
`
