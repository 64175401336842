import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

export const Form = styled.form`
  fieldset {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;

    .section {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5rem;

      @media (min-width: ${Breakpoints.sm}) {
        grid-template-columns: 1fr 1fr;
      }
      @media (min-width: ${Breakpoints.md}) {
        grid-template-columns: 1fr;
      }
      @media (min-width: ${Breakpoints.lg}) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`
