import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'
import {BinIcon} from 'styles/icons'

export const InviteUsersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @media (min-width: ${Breakpoints.lg}) {
    grid-template-columns: 35% 1fr;
  }

  @media (min-width: ${Breakpoints.xl}) {
    grid-template-columns: 30% 1fr;
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .left {
    @media (min-width: ${Breakpoints.lg}) {
      margin-top: 71px;
    }

    .add-via-interest-card {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .add-manually-card {
      border-top: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      .add-manually-card-form-email {
        display: flex;
        flex-direction: column;

        @media (min-width: ${Breakpoints['2xl']}) {
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
        }

        > div {
          @media (min-width: ${Breakpoints['2xl']}) {
            flex: 1;
          }
        }

        > button {
          margin-top: 16px;

          @media (min-width: ${Breakpoints['2xl']}) {
            margin-top: 0;
            margin-left: 16px;
            max-width: 160px;
            min-width: 144px;
          }
        }
      }
    }
  }

  .right {
    padding: 0 12px;
    overflow: hidden;

    @media only screen and (min-width: ${Breakpoints.sm}) {
      padding: 0;
    }

    .tabs {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: ${Breakpoints.sm}) {
        flex-direction: row;
        justify-content: space-between;
      }

      .tabs-buttons {
        display: flex;
        justify-content: center;

        @media only screen and (min-width: ${Breakpoints.sm}) {
          justify-content: flex-start;
        }

        button {
          &:not(:first-child) {
            margin-left: 12px;
          }
        }

        .tab-button {
          cursor: pointer;
          background: none;
          font: inherit;
          outline: inherit;
          border: none;

          padding-bottom: 16px;
          position: relative;

          font-size: 16px;
          line-height: 28px;
          font-weight: 400;
          color: ${Colors.GREY};

          &:disabled {
          }

          &.tab-button--active {
            color: ${Colors.BUTTON_CLICKED_BLUE};

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: ${Colors.BLUE};
            }
          }
        }
      }

      .tabs-extra-actions {
        margin-top: 32px;
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: ${Breakpoints.sm}) {
          margin-top: 0;
          flex-direction: row;
        }

        button {
          &:not(:first-child) {
            margin-top: 12px;

            @media only screen and (min-width: ${Breakpoints.sm}) {
              margin-top: 0;
              margin-left: 12px;
            }
          }
        }
      }
    }

    .tab-content {
      margin-top: 26px;

      .tab-content-card-text {
        text-align: center;
        color: ${Colors.INACTIVE};
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
`

export const DeleteIcon = styled(BinIcon)`
  width: 20px;
  height: 20px;

  stroke: ${Colors.DARK_GREY};

  &:hover {
    stroke: ${Colors.ERROR};
  }
`
