import React from 'react'
import ReactTooltip from 'react-tooltip'

import {
  DATAROOM_STATUS,
  DEAL_INTEREST_STATUS,
} from 'config/data/deal-interest.config'
import {DataroomStatus, DealInterestStatus} from 'types'

import {ButtonsContainer} from '../../styles'
import AccessBtn from '../AccessBtn'
import {hasDeckAccess} from 'services/deal-interests'

interface DataroomButtonsProps {
  dealInterestId: string
  status: DealInterestStatus | undefined
  dataroomRequestStatus: DataroomStatus | undefined
  handleDataroomRequest: any
}

const DataroomButtons: React.FC<DataroomButtonsProps> = ({
  dealInterestId,
  status,
  dataroomRequestStatus,
  handleDataroomRequest,
}) => {
  const renderAcceptAndRejectButtons = () => {
    return (
      <ButtonsContainer>
        <AccessBtn
          id={`radioAcceptDataroom-${dealInterestId}`}
          type="accept"
          value="APPROVED"
          onClick={e => handleDataroomRequest(e, dealInterestId)}
          checked={dataroomRequestStatus === DATAROOM_STATUS.APPROVED}
          disabled={dataroomRequestStatus === DATAROOM_STATUS.APPROVED}
          borderRadiusLeft
          borderRadiusRight={!dataroomRequestStatus}
        />

        {dataroomRequestStatus && (
          <AccessBtn
            id={`radioRejectDataroom-${dealInterestId}`}
            type="reject"
            value="REVOKED"
            onClick={e => handleDataroomRequest(e, dealInterestId)}
            checked={dataroomRequestStatus === DATAROOM_STATUS.REVOKED}
            disabled={dataroomRequestStatus === DATAROOM_STATUS.REVOKED}
            borderRadiusRight
          />
        )}
      </ButtonsContainer>
    )
  }

  const renderManualAdd = () => {
    return (
      <ButtonsContainer>
        <div data-tip data-for={`manualAddDataroom-${dealInterestId}`}>
          <AccessBtn
            id={`radioManualAddDataroom-${dealInterestId}`}
            type="accept"
            value="APPROVED"
            onClick={e => handleDataroomRequest(e, dealInterestId)}
            checked={dataroomRequestStatus === DATAROOM_STATUS.APPROVED}
            disabled={dataroomRequestStatus === DATAROOM_STATUS.APPROVED}
            borderRadiusLeft
            borderRadiusRight
          />
        </div>

        <ReactTooltip
          id={`manualAddDataroom-${dealInterestId}`}
          place="top"
          effect="solid"
        >
          {'This user needs to be manually added to the Caplinked Workspace.'}
        </ReactTooltip>
      </ButtonsContainer>
    )
  }

  const renderManualRemove = () => {
    return (
      <ButtonsContainer>
        <div>
          <div data-tip data-for={`manualRemoveDataroom-${dealInterestId}`}>
            <AccessBtn
              id={`radioManualRemoveDataroom-${dealInterestId}`}
              type="reject"
              value="REVOKED"
              onClick={e => handleDataroomRequest(e, dealInterestId)}
              checked={dataroomRequestStatus === DATAROOM_STATUS.REVOKED}
              disabled={dataroomRequestStatus === DATAROOM_STATUS.REVOKED}
              borderRadiusLeft
              borderRadiusRight
            />
          </div>

          <ReactTooltip
            id={`manualRemoveDataroom-${dealInterestId}`}
            place="top"
            effect="solid"
          >
            {
              'This user needs to be manually removed to the Caplinked Workspace.'
            }
          </ReactTooltip>
        </div>
      </ButtonsContainer>
    )
  }

  const renderButtons = () => {
    switch (dataroomRequestStatus) {
      case DATAROOM_STATUS.REVOKED:
      case DATAROOM_STATUS.APPROVED:
        return renderAcceptAndRejectButtons()

      case DATAROOM_STATUS.MANUAL_ADD_PENDING:
        return renderManualAdd()

      case DATAROOM_STATUS.MANUAL_REMOVE_PENDING:
        return renderManualRemove()

      default:
        return renderAcceptAndRejectButtons()
    }
  }

  const showDataroomButtons =
    hasDeckAccess(status) &&
    status !== DEAL_INTEREST_STATUS.INVESTED &&
    status !== DEAL_INTEREST_STATUS.NOT_INVESTED

  if (!showDataroomButtons) {
    return null
  }

  return renderButtons()
}
export default DataroomButtons
