import styled from 'styled-components'

export type LogoSize = 'small' | 'normal' | 'medium' | 'big' | ''

const handleWidthSize = (size: LogoSize) => {
  switch (size) {
    case 'small':
      return '5em'
    case 'normal':
      return '8em'
    case 'medium':
      return '10em'
    case 'big':
      return '15em'
    default:
      return '8em'
  }
}

export interface LogoContainerProps {
  size: LogoSize
}

export const LogoContainer = styled.div<LogoContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  .nobles-logo {
    width: ${({size}) => handleWidthSize(size)};
    height: auto;
  }
`
