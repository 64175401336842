import React, {useState} from 'react'
import {ValueType} from 'react-select'
import {useFormContext, Controller} from 'react-hook-form'
import {Editor} from 'react-draft-wysiwyg'
import {Modifier, EditorState} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import Card from 'components/Card'
import Select, {Option} from 'components/Select'

import {EditorWrapper} from './styles'

const toolbar = {
  options: [
    'blockType',
    'fontSize',
    'inline',
    'link',
    'textAlign',
    'list',
    'image',
  ],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
  },
  image: {
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: false,
    uploadCallback: undefined,
    previewImage: true,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: {present: false, mandatory: false},
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
}

const data: Option[] = [
  {label: '{{Name}}', value: '{{name}}'},
  {label: '{{Address}}', value: '{{address}}'},
  {label: '{{Date}}', value: '{{date}}'},
  {label: '{{Date-deck-access}}', value: '{{date-deck-access}}'},
  {label: '{{Date-nda-signature}}', value: '{{date-nda-signature}}'},
]

interface CustomOptionsProps {
  editorState?: EditorState
  onChange?: (state: EditorState) => void
}

/**
 * Select Placeholder to add in your nda
 * {{signature}} or {{address}}
 */
const CustomOptions: React.FC<CustomOptionsProps> = ({
  onChange,
  editorState,
}) => {
  const [value, set] = useState<ValueType<Option> | null>(null)

  const handleSelect = (item: ValueType<Option>) => {
    if (editorState && item) {
      const {value} = item as Option

      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        value,
        editorState.getCurrentInlineStyle()
      )

      onChange &&
        onChange(
          EditorState.push(editorState, contentState, 'insert-characters')
        )
    }
    set(item)
  }

  return (
    <div className="placeholders">
      <Select
        labelLeft
        label="Add Custom Input:"
        placeholder="Placeholder"
        options={data}
        onChange={handleSelect}
        {...{value}}
      />

      <div className="i tooltip">
        i
        <span className="text text-10 tooltiptext">
          Using the syntax available, you can add additional inputs required to
          be filled in the process letter.<br></br>
          <br></br>
          eg{' '}
          <span className="bg-light-grey padding-5 primary bold">{`{{Date}}`}</span>
        </span>
      </div>
    </div>
  )
}

const EditLetter: React.FC = () => {
  const {errors, control} = useFormContext()
  const error = errors?.processLetter

  return (
    <Card
      padding
      title="Process Letter"
      subtitle="Using the below input, type or paste the process letter that applies to this deal."
    >
      <EditorWrapper {...{error}}>
        <Controller
          control={control}
          name="processLetter"
          render={({onChange, value}) => {
            return (
              <Editor
                editorState={value}
                defaultEditorState={value}
                onEditorStateChange={onChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                placeholder="Placeholder..."
                toolbarCustomButtons={[<CustomOptions />]}
                {...{toolbar}}
              />
            )
          }}
        />
      </EditorWrapper>

      {error && <span className="error">{errors.processLetter.message}</span>}
    </Card>
  )
}

export default EditLetter
