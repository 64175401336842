import styled from 'styled-components'

import Colors from 'styles/colors'

import {MarginType, SpinnerSize} from '.'

const getSpinnerSize = (size: SpinnerSize) => {
  switch (size) {
    case 'big':
      return '80px'
    case 'small':
      return '40px'
    case 'xs':
      return '25px'
    default:
      return '50px'
  }
}
const getSpinnerMargin = (type: MarginType) => {
  switch (type?.toLowerCase()) {
    case 'big':
      return '8rem'
    case 'none':
      return 0
    default:
      return '1rem'
  }
}

export const SpinnerContainer = styled.div<{
  primary?: boolean
  right?: boolean
  margin?: MarginType
  size?: SpinnerSize
  white?: boolean
}>`
  display: flex;

  flex-direction: ${({right}) => (right ? 'row' : 'column')};
  justify-content: center;
  align-items: ${({right}) => (right ? 'flex-start' : 'center')};

  span {
    margin: 1em 1em;
  }

  margin: ${({margin}) => getSpinnerMargin(margin)};

  .sc-circle {
    width: ${({size}) => getSpinnerSize(size)};
    height: ${({size}) => getSpinnerSize(size)};
    position: relative;
  }

  .sc-circle .sc-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sc-circle .sc-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${({primary, white}) =>
      white ? Colors.WHITE : primary ? Colors.PRIMARY : Colors.SECONDARY};
    border-radius: 100%;
    -webkit-animation: sc-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sc-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  .sc-circle .sc-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .sc-circle .sc-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .sc-circle .sc-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sc-circle .sc-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .sc-circle .sc-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .sc-circle .sc-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sc-circle .sc-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .sc-circle .sc-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .sc-circle .sc-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .sc-circle .sc-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .sc-circle .sc-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .sc-circle .sc-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sc-circle .sc-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sc-circle .sc-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sc-circle .sc-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sc-circle .sc-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sc-circle .sc-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sc-circle .sc-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sc-circle .sc-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sc-circle .sc-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  .sc-circle .sc-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sc-circle .sc-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sc-circleBounceDelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes sc-circleBounceDelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`
