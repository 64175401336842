import React, {useEffect, useState} from 'react'
import {ValueType} from 'react-select'

import {ROLES} from 'config/data/roles.config'
import {MessageProps, User} from 'types'
import {getUsers} from 'services/users'
import {getDeals} from 'services/deals'
import {errorDefault} from 'utils/errors'

import Input from 'components/Input'
import Spinner from 'components/Spinner'
import Toast from 'components/Toast'
import Select from 'components/Select'
import ContentContainer from 'components/ContentContainer'

import {SearchBar} from './styles'
import UsersTable from './components/UsersTable'

type OptionsType = {label: string; value: string}

type FilterType = 'slug' | 'name'

type SelectedDeal = {
  label: string
  value: string
}

type FiltersType = {
  values: {
    slug?: string
    name?: string
  }
  data: User[]
}

type StateType = {
  data: User[] // initial array of all users
  deals: SelectedDeal[] // populate deal dropdown
  filters: FiltersType // filters active and data found
  loading: boolean
}

const defaultFilters = {
  values: {},
  data: [],
}

const defaultState: StateType = {
  deals: [],
  data: [],
  filters: defaultFilters,
  loading: true,
}

const Users: React.FC = () => {
  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'success',
  })

  const [state, setState] = useState(defaultState)
  const {data, filters, deals, loading} = state

  const activeFilters = Object.keys(filters.values).length > 0

  /**
   * Fetch users and deals - called once
   */
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = (await getUsers())?.filter(
          user => user.role !== ROLES.ADMIN
        )

        const results = await getDeals()
        const deals = results.map(({name, slug}) => {
          return {label: name, value: slug}
        })

        setState({
          ...state,
          deals,
          data: users,
          loading: false,
        })
      } catch (_) {
        setToast({
          value: errorDefault,
          type: 'error',
        })

        setState({...state, loading: false})
      }
    }

    fetchUsers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * On Filters' values change
   */
  useEffect(() => {
    const {values} = filters

    switch (activeFilters) {
      case true: // Apply filter(s)
        let results = data

        const slug = values?.slug?.toLowerCase()
        if (slug) {
          results = results.filter(user =>
            user?.dealInterests?.some(di => di.deal?.slug === slug)
          )
        }

        const name = values?.name?.toLowerCase()
        if (name) {
          results = results.filter(
            ({firstName, lastName}) =>
              firstName?.toLowerCase().startsWith(name) ||
              lastName?.toLowerCase().startsWith(name)
          )
        }

        setState({
          ...state,
          filters: {
            ...filters,
            data: results,
          },
        })

        break
      default:
        // Clear filters
        setState({
          ...state,
          filters: defaultFilters,
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.values])

  const clearFilters = (type: 'slug' | 'name') => {
    let newValues = {...filters.values}
    delete newValues[type]

    setState({
      ...state,
      filters: {
        ...filters,
        values: newValues,
      },
    })
  }

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name as FilterType

    switch (value) {
      case '':
        clearFilters(name)
        break
      default:
        setState({
          ...state,
          filters: {
            ...filters,
            values: {
              ...filters.values,
              [name]: value,
            },
          },
        })
    }
  }

  const handleSearchBySlug = (item?: ValueType<OptionsType>) => {
    switch (item) {
      case null:
        clearFilters('slug')
        break
      default:
        setState({
          ...state,
          filters: {
            ...filters,
            values: {
              ...filters.values,
              slug: (item as OptionsType).value,
            },
          },
        })
    }
  }

  const users = activeFilters ? filters.data : data

  return (
    <ContentContainer>
      <Toast message={toast} action={setToast} />

      <SearchBar>
        <div />
        <div />

        <Select
          placeholder="Search by Deal"
          options={deals}
          onChange={handleSearchBySlug}
          full
          isSearchable
          isClearable
        />

        <Input
          id="search-input"
          type="text"
          placeholder="Search Users"
          name="name"
          value={filters.values?.name || ''}
          onChange={handleSearchByName}
          onClear={handleSearchByName}
        />
      </SearchBar>

      {loading ? (
        <Spinner margin="big" primary label="Loading Users..." />
      ) : (
        <UsersTable users={users} />
      )}
    </ContentContainer>
  )
}

export default Users
