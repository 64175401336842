import React from 'react'

import {Deal} from 'types'
import useDealProgression, {
  DealProgressionStep,
  DealProgressionStepId,
} from 'hooks/useDealProgression'
import {useAuth} from 'hooks/useAuth'

import Badge from 'components/styles/Badge'
import Card from 'components/Card'
import Button from 'components/styles/Button'

import RequestNdaAccess from './components/RequestNdaAccess'
import SignNda from './components/SignNda'
import IndicativeOffer from './components/IndicativeOffer'
import FinalOffer from './components/FinalOffer'

import {
  Steps,
  Step,
  StepCount,
  StepTitle,
  CurrentStepContainer,
  CurrentStepTitle,
  CurrentStepTitleText,
  CurrentStepDescription,
  CurrentStepButtonContainer,
} from './styles'

const getStepTitle = (
  step: DealProgressionStep,
  isSigningNdaInProgress?: boolean,
  short?: boolean
) => {
  if (!step) {
    return ''
  }

  const {id, current, title, titleShort} = step

  if (short) {
    return titleShort
  }

  if (id === 'sign_nda' && current && isSigningNdaInProgress) {
    // in case the signing of the NDA is still in process, show alt title
    return 'Sign NDA (processing)'
  } else {
    return title
  }
}

interface DealProgressProps {
  deal: Deal
  refetchDeal?: () => void
  isSigningNdaInProgress?: boolean
  showDealTitle?: boolean
}

const DealProgress: React.FC<DealProgressProps> = ({
  deal,
  refetchDeal,
  isSigningNdaInProgress,
  showDealTitle,
}) => {
  const {isAdmin, isAuthUserSelfCertified} = useAuth()

  const {
    steps,
    updateIndicativeOffersCount,
    updateFinalOfferStatus,
    hasSignedNDA,
  } = useDealProgression(deal.dealInterest)

  const renderCurrentActionButton = (
    stepId: DealProgressionStepId,
    statusText?: string,
    needsUserAction?: boolean
  ) => {
    if (!statusText) {
      return null
    }

    switch (stepId) {
      case 'request_nda_access':
        return (
          <RequestNdaAccess
            label={statusText}
            dealId={deal.id}
            refetchDeal={refetchDeal}
            buttonDisabled={!needsUserAction}
          />
        )

      case 'wait_for_deck_access':
        return <Button disabled>{'Awaiting Approval'}</Button>

      case 'sign_nda':
        return (
          <SignNda
            label={statusText}
            dealId={deal.id}
            dealInterestId={deal.dealInterest?.id}
            dealName={deal.name}
            skipSignNda={hasSignedNDA}
            isAuthUserSelfCertified={isAuthUserSelfCertified}
            hasNdaFileDocument={Boolean(deal.ndaFileDocument)}
            isSigningNdaInProgress={isSigningNdaInProgress}
            buttonDisabled={!needsUserAction}
          />
        )

      case 'submit_indicative_offer':
        return (
          <IndicativeOffer
            id={deal.dealInterest?.id}
            type={deal.type}
            label={statusText}
            updateIndicativeOffersCount={updateIndicativeOffersCount}
            buttonDisabled={!needsUserAction}
          />
        )

      case 'submit_final_offer':
        return (
          <FinalOffer
            id={deal.dealInterest?.id}
            type={deal.type}
            label={statusText}
            updateFinalOfferStatus={updateFinalOfferStatus}
            buttonDisabled={!needsUserAction}
          />
        )

      default:
        return null
    }
  }

  const currentStep = steps.find(step => {
    return step.current
  })

  const needsUserAction = currentStep?.status === 'need_user_action'
  const isCurrentStepDone = currentStep?.status === 'done'

  if (isAdmin) {
    return null
  }

  return (
    <Card
      title={showDealTitle ? deal.name : 'Progress'}
      padding
      bottomComponent={
        currentStep ? (
          <CurrentStepContainer>
            <div>
              <CurrentStepTitle>
                <CurrentStepTitleText>
                  {getStepTitle(currentStep, isSigningNdaInProgress)}
                </CurrentStepTitleText>

                {!needsUserAction && (
                  <Badge status={isCurrentStepDone ? 'success' : 'warning'}>
                    {currentStep.statusText}
                  </Badge>
                )}
              </CurrentStepTitle>

              {currentStep.description ? (
                <CurrentStepDescription>
                  {currentStep.description}
                </CurrentStepDescription>
              ) : null}
            </div>

            <CurrentStepButtonContainer>
              {renderCurrentActionButton(
                currentStep.id,
                currentStep.statusText,
                needsUserAction
              )}
            </CurrentStepButtonContainer>
          </CurrentStepContainer>
        ) : null
      }
    >
      <Steps>
        {steps.map((step, index) => {
          const stepTitle = getStepTitle(step, isSigningNdaInProgress, true)
          const isFilled = step.status === 'done' || step.current

          return (
            <Step key={step.id} filled={isFilled}>
              <StepCount filled={isFilled}>Step {index + 1}</StepCount>

              <StepTitle>{stepTitle}</StepTitle>
            </Step>
          )
        })}
      </Steps>
    </Card>
  )
}

export default DealProgress
