import styled from 'styled-components'

import Colors from 'styles/colors'

import Icon from 'components/Icon'

export const ContactContainer = styled.div`
  .contact-content {
    .contact-content-title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 24px;
      margin-top: 0px;
    }
  }
`

export const Content = styled.div`
  flex: auto;
  padding: 1.8rem;

  border: 1px solid ${Colors.LIGHT_GREY};
  box-sizing: border-box;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.2rem;

  > :nth-child(n) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.7rem;
  }
`

export const Actions = styled.div<{modal?: boolean}>`
  display: grid;
  grid-template-columns: ${({modal}) => (modal ? '0.5fr 1fr' : '1fr 1fr')};
  grid-gap: 2rem;
  margin-top: 24px;
`

export const IconPhone = styled(Icon)`
  position: relative;
  bottom: 0.1rem;

  path {
    stroke-width: 2.5rem;
    stroke: black;
    fill: white;
  }
`

export const IconQuestion = styled(Icon)`
  font-size: 1.2rem;
  margin-left: 6px;

  path {
    stroke: white;
  }
`

export const IconCalendar = styled(Icon)`
  font-size: 1.2rem;
  margin-left: 6px;
`
