import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

export const Actions = styled.div<{showExtraButton?: boolean}>`
  display: grid;
  grid-template-columns: ${({showExtraButton}) =>
    showExtraButton ? `1fr` : `1fr`};
  gap: 1rem;

  @media (min-width: ${Breakpoints.xl}) {
    display: grid;
    grid-template-columns: ${({showExtraButton}) =>
      showExtraButton ? `0.5fr 1fr 1fr` : `0.5fr 1fr`};
    gap: 1rem;
  }
`

export const Section = styled.section<{show?: boolean}>`
  display: ${({show}) => (show ? 'grid' : 'none')};
  grid-template-columns: 1fr;
  gap: 1.5rem;

  h3 {
    margin: 0 !important;
  }

  .row {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;

    @media only screen and (max-width: ${Breakpoints.md}) {
      grid-template-columns: 1fr;
    }
  }
`

export const DownloadEditableNdaButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  width: auto;
  background: transparent;
  color: ${Colors.BLUE};
  font: inherit;
  display: inline-block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    text-decoration: none;
    color: ${Colors.DARK_GREY};
    cursor: default;
  }
`

export const ModalSteps = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;

  @media (min-width: ${Breakpoints.sm}) {
    padding: 20px 28px;
  }

  border: 1px solid ${Colors.LIGHT_GREY};
`

export const Step = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${Breakpoints.sm}) {
    flex-direction: row;
    text-align: left;
  }
`

export interface StepCountProps {
  current?: boolean
  done?: boolean
}
export const StepCount = styled.div<StepCountProps>`
  border: 2px solid;
  border-radius: 50%;
  border-color: ${({current, done}) =>
    current || done ? Colors.BLUE : Colors.LIGHT_GREY};

  background-color: ${({done}) => (done ? Colors.BLUE : Colors.WHITE)};

  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export interface StepCountTextProps {
  current?: boolean
  done?: boolean
}
export const StepCountText = styled.span<StepCountTextProps>`
  font-size: 14px;
  line-height: 16px;

  color: ${({current}) => (current ? Colors.BLUE : Colors.DARK_GREY)};
  display: ${({done}) => (done ? 'none' : 'inline-block')};
`

export interface StepTitleProps {
  current?: boolean
  done?: boolean
}
export const StepTitle = styled.span<StepTitleProps>`
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;

  @media (min-width: ${Breakpoints.sm}) {
    margin-left: 8px;
  }

  color: ${({current, done}) =>
    done ? Colors.BLACK : current ? Colors.BLUE : Colors.DARK_GREY};
`

export const StepSubtitle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 21px;
  line-height: 32px;
  font-weight: 600;
`
