import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'

import Badge from 'components/styles/Badge'
import Card from 'components/Card'
import OrderedList, {Item} from 'components/styles/OrderedList'
import Modal from 'components/Modal'

import SelfCertification from 'components/SelfCertification'

import {IconState, ActionButton, CompleteProfileTitle} from './styles'

interface CompleteProfileProps {
  certified: boolean
  hasInterests: boolean
}

const CompleteProfile: React.FC<CompleteProfileProps> = ({
  certified,
  hasInterests,
}) => {
  const history = useHistory()

  const [showCertificationModal, setShowCertificationModal] = useState(false)
  const handleOpenCertificationModal = () => setShowCertificationModal(true)
  const handleCloseCertificationModal = () => setShowCertificationModal(false)

  if (hasInterests && certified) {
    return null
  }

  const progress = [
    {
      title: 'Confirm your investment Interests',
      completed: hasInterests,
      buttonLabel: 'Confirm',
      buttonAction: () => history.push(ROUTES.USER_SETTINGS),
    },
    {
      title: 'Self Certification',
      completed: certified,
      buttonLabel: 'Certify',
      buttonAction: handleOpenCertificationModal,
    },
  ]

  return (
    <Card
      padding
      title="Complete Profile"
      disableTitleUnderline
      titleRightComponent={<Badge status="in_progress">In Progress</Badge>}
    >
      <CompleteProfileTitle>
        Complete your profile to access more details and deals.
      </CompleteProfileTitle>

      <OrderedList>
        {progress.map(({title, completed, buttonLabel, buttonAction}) => (
          <Item key={title} className="text-base">
            <div>
              <IconState
                name={completed ? 'checkmark-circle' : 'arrow-circle-right'}
                active={completed}
              />

              <span className="list-item-title">{title}</span>
            </div>

            {!completed && (
              <ActionButton onClick={buttonAction}>{buttonLabel}</ActionButton>
            )}
          </Item>
        ))}
      </OrderedList>

      <Modal
        isShowing={showCertificationModal}
        hide={handleCloseCertificationModal}
        title="Self Certification"
        maxWidth="700px"
      >
        <SelfCertification
          forceCertification
          cardProps={{
            hideOutline: true,
            padding: false,
            title: undefined,
            titleRightComponent: null,
          }}
        />
      </Modal>
    </Card>
  )
}

export default CompleteProfile
