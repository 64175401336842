import React, {useState, useEffect, Fragment} from 'react'
import styled from 'styled-components'

import useModal from 'hooks/useModal'
import {MessageProps} from 'types'
import {exportDealData} from 'services/admin'

import Button from 'components/styles/Button'
import Modal from 'components/Modal'
import Toast from 'components/Toast'

const Options = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 0.5fr 1fr;
`

const ExportButton = ({id}: {id?: string}) => {
  const {isShowing, toggle} = useModal()

  const [loading, setLoading] = useState(false)

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'info',
  })

  useEffect(() => {
    if (loading) {
      const handleExport = async () => {
        try {
          if (!id) {
            throw new Error(`This deal doesn't exist anymore.`)
          }

          await exportDealData(id)

          setToast({
            value: 'Exported deal data successfully.',
            type: 'success',
          })
        } catch (error) {
          setToast({
            value: error.response?.data?.title,
            type: 'error',
          })
        }

        toggle()
        setLoading(false)
      }

      handleExport()
    }
  }, [id, loading, setLoading, toggle])

  return (
    <Fragment>
      <Button onClick={toggle} secondary>
        Export
      </Button>

      <Modal hide={toggle} {...{isShowing}} maxWidth="600px">
        <h2>Export Deal Data</h2>

        <span className="text-lg grey">
          Would you like to export the deal's data?
        </span>
        <Options>
          <Button secondary onClick={toggle} disabled={loading}>
            Cancel
          </Button>

          <Button onClick={() => setLoading(!loading)} disabled={loading}>
            {loading ? 'Please wait...' : 'Export data'}
          </Button>
        </Options>
      </Modal>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default ExportButton
