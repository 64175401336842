import React, {useState, useEffect, Fragment} from 'react'

import {useAuth} from 'hooks/useAuth'
import useModal from 'hooks/useModal'
import useForm from 'hooks/useForm'
import {DealContact} from 'types'
import {askQuestion, requestMeeting} from 'services/deal-interests'

import Icon from 'components/Icon'
import Textarea from 'components/styles/Textarea'
import Button from 'components/styles/Button'
import Modal from 'components/Modal'
import ErrorMessage from 'components/ErrorMessage'

import {
  IconCalendar,
  IconPhone,
  IconQuestion,
  Actions,
  ContactContainer,
  Content,
} from './styles'

export interface ButtonProps {
  id: string
}

const AskQuestionButton: React.FC<ButtonProps> = ({id}) => {
  const {isShowing, toggle} = useModal()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const {inputs, handleChange, clearForm} = useForm(
    {
      question: '',
    },
    isShowing
  )

  useEffect(() => {
    if (loading && isShowing) {
      const sendQuestion = async () => {
        try {
          await askQuestion(id, inputs.question)

          toggle()
          clearForm()
        } catch (error) {
          setError(error)
        }

        setLoading(!loading)
      }

      sendQuestion()
    }
  }, [clearForm, isShowing, id, inputs.question, loading, toggle])

  return (
    <Fragment>
      <Button onClick={toggle} flex>
        Ask Question <IconQuestion name="question-circle" />
      </Button>

      {/** Ask Question Modal */}
      <Modal hide={toggle} {...{isShowing}} maxWidth="400px">
        <h2>Ask a Question</h2>

        <span className="text-lg grey">
          We’re here to help. Please let us know what you’d like to know.
        </span>

        <form
          onSubmit={async (e: any) => {
            e.preventDefault()
            setLoading(!loading)
          }}
        >
          <ErrorMessage {...{error}} />

          <fieldset disabled={loading} aria-busy={loading}>
            <label htmlFor="your-question">Your question:</label>
            <Textarea
              id="your-question"
              name="question"
              onChange={handleChange}
              placeholder="Ask your Question here."
              className="text-base black"
              rows={3}
            />

            <Actions modal>
              <Button type="button" onClick={toggle} secondary>
                Cancel
              </Button>
              <Button type="submit" disabled={inputs?.question === ''}>
                {loading ? 'Please wait...' : 'Submit'}
              </Button>
            </Actions>
          </fieldset>
        </form>
      </Modal>
    </Fragment>
  )
}

const ArrangeMeetingButton: React.FC<ButtonProps> = ({id}) => {
  const {isShowing, toggle} = useModal()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const {inputs, handleChange, clearForm} = useForm(
    {
      message: '',
    },
    isShowing
  )

  useEffect(() => {
    if (loading && isShowing) {
      const request = async () => {
        try {
          const {message} = inputs
          await requestMeeting(id, message)

          toggle()
          clearForm()
        } catch (error) {
          setError(error)
        }

        setLoading(!loading)
      }

      request()
    }
  }, [clearForm, isShowing, id, inputs, loading, toggle])

  return (
    <Fragment>
      <Button onClick={toggle} secondary flex>
        Arrange a Meeting <IconCalendar name="calendar" />
      </Button>

      {/** Arrange a meeting Modal */}
      <Modal hide={toggle} {...{isShowing}} maxWidth="400px">
        <h2>Arrange a meeting</h2>

        <span className="text-lg grey">
          Please provide us with some indactives times you would be available.
        </span>

        <form
          onSubmit={async (e: any) => {
            e.preventDefault()
            setLoading(!loading)
          }}
        >
          <ErrorMessage {...{error}} />

          <fieldset disabled={loading} aria-busy={loading}>
            <label htmlFor="your-message">Your message:</label>
            <Textarea
              id="your-message"
              name="message"
              onChange={handleChange}
              placeholder="Please provide some details about when you’d like to meet."
              className="text-base black"
              rows={3}
            />

            <Actions modal>
              <Button onClick={toggle} secondary>
                Cancel
              </Button>

              <Button type="submit" disabled={inputs?.message === ''}>
                {loading ? 'Please wait...' : 'Submit'}
              </Button>
            </Actions>
          </fieldset>
        </form>
      </Modal>
    </Fragment>
  )
}

export interface ContactProps {
  id?: string
  data?: DealContact
}

const Contact: React.FC<ContactProps> = ({id, data}) => {
  const {isAdmin} = useAuth()

  const {name = '', phone = '', position = '', email = ''} = data ?? {}

  return (
    <ContactContainer>
      <div className="contact-content">
        <p className="contact-content-title">
          For queries or to arrange a meeting, please contact:
        </p>

        <Content>
          <span className="text-base bold black">
            {name}, {position}
          </span>

          <div>
            <IconPhone name="phone" />

            <span>{phone}</span>
          </div>

          <div>
            <Icon name="envelope" />

            <span>{email}</span>
          </div>
        </Content>
      </div>

      <Actions>
        {id && !isAdmin && (
          <Fragment>
            <AskQuestionButton id={id} />
            <ArrangeMeetingButton id={id} />
          </Fragment>
        )}
      </Actions>
    </ContactContainer>
  )
}

export default Contact
