import styled from 'styled-components'
import Colors from 'styles/colors'

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;

  .outer {
    overflow-x: auto;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;

    @media (min-width: 640px) {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    @media (min-width: 1024px) {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    .inner {
      display: inline-block;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      vertical-align: middle;
      min-width: 100%;

      @media (min-width: 640px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      @media (min-width: 1024px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .inner-inner {
        overflow: hidden;

        border-radius: 5px;
        border: 1px solid ${Colors.LIGHT_GREY};

        > table {
          min-width: 100%;
          border-collapse: collapse;
          background-color: ${Colors.WHITE};

          > thead {
            background-color: #fcfdfd;
            border-bottom: 1px solid ${Colors.LIGHT_GREY};

            > tr {
              > th {
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                color: #616161;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                text-align: left;

                .column-inner {
                  display: inline-flex;
                  align-items: center;
                }
              }
            }
          }

          > tbody {
            > tr {
              border-bottom: 1px solid ${Colors.LIGHT_GREY};

              > td {
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                color: ${Colors.GREY};
                font-size: 0.875rem;
                line-height: 1.25rem;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }

          > tfoot {
            .pagination {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 12px 6px;

              .goBackwardButton {
                margin-right: 24px;
                color: ${Colors.BLACK};
                &:disabled {
                  color: ${Colors.LIGHT_GREY};
                }
              }

              .pages {
                display: flex;
                align-items: center;

                > button {
                  background: none;
                  border: none;
                  padding: 12px 15px;
                  font: inherit;
                  cursor: pointer;
                  outline: inherit;

                  color: ${Colors.GREY};
                  &.active {
                    color: ${Colors.BLUE};
                  }
                }
              }

              .goForwardButton {
                margin-left: 24px;
                color: ${Colors.BLACK};
                &:disabled {
                  color: ${Colors.LIGHT_GREY};
                }
              }
            }
          }
        }
      }
    }
  }
`

export const Dots = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;

  > span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${Colors.BLACK};

    &:not(:first-child) {
      margin-left: 3px;
    }
  }
`
