import {useState} from 'react'

const useStepper = () => {
  const [step, setStep] = useState(0)

  const nextStep = () => setStep(prev => prev + 1)
  const previousStep = () => setStep(prev => prev - 1)

  return {step, nextStep, previousStep, setStep}
}

export default useStepper
