import React from 'react'
import {Controller, useForm} from 'react-hook-form'

import Button from 'components/styles/Button'
import Checkbox from 'components/Checkbox'
import Input from 'components/Input'
import Select from 'components/Select'

import {COUNTRIES, DEFAULT_COUNTRY} from 'config/data/options.config'
import {useAuth} from 'hooks/useAuth'

import {RegisterFormData} from 'pages/Auth/Register'
import Spinner from 'components/Spinner'

interface FormProps {
  onSubmit: (data: RegisterFormData) => void
  loading: boolean
  partialUser?: boolean
}

const Form: React.FC<FormProps> = ({onSubmit, loading, partialUser}) => {
  const {register, handleSubmit, errors, control} = useForm()

  const {user} = useAuth()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="form" disabled={loading} aria-busy={loading}>
        <div className="row">
          <Input
            label="First Name"
            name="firstName"
            type="text"
            placeholder="First name"
            error={{
              status: errors.firstName,
              message: 'First name is required',
            }}
            ref={register({
              required: true,
              maxLength: 80,
            })}
          />

          <Input
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Last Name"
            error={{
              status: errors.lastName,
              message: 'Last name is required',
            }}
            ref={register({
              required: true,
              maxLength: 100,
            })}
          />
        </div>

        <Input
          label="Email Address"
          name="email"
          info="Please note, we will use your email to verify your account."
          type="email"
          defaultValue={partialUser ? user?.email || '' : ''}
          placeholder="Email"
          disabled={partialUser}
          error={{
            status: errors.email,
            message: 'This is not a valid email address',
          }}
          ref={register({
            required: true,
            pattern: /^\S+@\S+$/i,
          })}
        />

        <div className="row">
          <Input
            label="Company"
            name="company"
            type="text"
            placeholder="Company Name"
            error={{
              status: errors.company,
              message: 'Company name is required',
            }}
            ref={register({
              required: false,
            })}
          />

          <Controller
            control={control}
            defaultValue={DEFAULT_COUNTRY}
            rules={{required: true}}
            name="country"
            render={props => (
              <Select
                label="Country of Residence"
                defaultValue={DEFAULT_COUNTRY}
                placeholder="Country"
                options={COUNTRIES}
                isSearchable
                {...props}
              />
            )}
          />
        </div>

        <Input
          id="login-password"
          label="Create a Password"
          name="password"
          info="Password should be at least 10 characters long"
          type="password"
          placeholder="Password"
          defaultValue={partialUser ? 'zzzzzzzzzz' : ''}
          error={{
            status: errors.password,
            message: 'Your password has to be at least 10 characters long',
          }}
          showPassword
          ref={register({
            required: true,
            minLength: 10,
          })}
          autoComplete="password"
          disabled={partialUser}
        />

        <Checkbox
          name="mandatorycheck"
          label="I consent to Terms of Use and Privacy Policy"
          ref={register({required: true})}
          error={{
            status: errors.mandatorycheck,
            message: 'This field is required',
          }}
        />

        <Button type="submit" full>
          {loading ? <Spinner size="xs" margin="none" /> : 'Register'}
        </Button>
      </fieldset>
    </form>
  )
}

export default Form
