import React, {useEffect, useState, Fragment} from 'react'
import {useHistory, useParams} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'
import {DEAL_INTEREST_STATUS} from 'config/data/deal-interest.config'
import {useAuth} from 'hooks/useAuth'
import {
  DataroomStatus,
  DealInterest,
  DealInterestStatus,
  MessageProps,
  User,
} from 'types'
import {
  archiveUser,
  blockUser,
  deleteUser,
  exportUserData,
} from 'services/admin'
import {getUserBySlug} from 'services/users'

import Breadcrumbs from 'components/Header/Breadcrumbs'
import Button from 'components/styles/Button'
import Spinner from 'components/Spinner'
import Toast from 'components/Toast'
import ContentContainer from 'components/ContentContainer'
import PageTitle from 'components/PageTitle'

import {ViewUserHeaderButtons, ViewUserGrid} from './styles'

import AdminActions from './components/AdminActions'
import BlockModal from './components/Modals/Block'
import DeleteModal from './components/Modals/Delete'
import ExportModal from './components/Modals/Export'
import UserCard from './components/UserCard'
import UserDeals from './components/UserDeals'
import UserLogs from './components/UserLogs'

const MODAL_TYPE = {
  block: 'BLOCK',
  export: 'EXPORT',
  remove: 'REMOVE',
}

type ModalState = {
  type: string
  loadingModal: boolean
}
const defaultModalState: ModalState = {
  type: '',
  loadingModal: false,
}

type State = {
  data: User | null
  loading: boolean
}

type UserDetailsParams = {
  slug: string
}

const UserDetails: React.FC = () => {
  const {isAdmin} = useAuth()
  const history = useHistory()
  const {slug} = useParams<UserDetailsParams>()

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'info',
  })

  const [modalState, setModalState] = useState(defaultModalState)
  const [state, setState] = useState<State>({
    data: null,
    loading: true,
  })

  const {data, loading} = state
  const {
    dealInterests = [],
    isArchived = false,
    isSuspended = false,
    firstName = '',
    lastName = '',
    isEmailVerified = false,
  } = data ? data : {}

  const handleCloseModal = () => setModalState(defaultModalState)

  useEffect(() => {
    const userId = data?.id

    const fetchUser = async () => {
      try {
        if (slug) {
          const user = await getUserBySlug(slug)

          setState({...state, data: user, loading: false})
        } else {
          setToast({
            value: 'User account does not exist yet or has been removed',
            type: 'error',
          })
        }
      } catch (error) {
        setToast({
          value: error.message,
          type: 'error',
        })
        setState({...state, loading: false})
      }
    }

    if (loading) {
      fetchUser()
    }

    /**
     * Modal Actions
     */
    const handleDelete = async () => {
      if (!userId || !data) return

      try {
        if (isArchived) {
          await deleteUser(userId)

          setToast({
            value: 'Deleted user successfully.',
            type: 'success',
            callback: () => {
              handleCloseModal()
              history.replace(ROUTES.USERS)
            },
          })
        } else {
          await archiveUser(userId)

          setToast({
            value: 'Archived user successfully.',
            type: 'success',
            callback: () => {
              handleCloseModal()

              setState({
                ...state,
                data: {...data, isArchived: true},
              })
            },
          })
        }
      } catch (error) {
        setToast({
          value: error.response?.data?.title,
          type: 'error',
        })
      }
    }
    const handleExport = async () => {
      if (!userId || !data) return

      try {
        await exportUserData(userId)

        setToast({
          value: 'Exported user data successfully.',
          type: 'success',
          callback: () => handleCloseModal(),
        })
      } catch (error) {
        setToast({
          value: error.response?.data?.title,
          type: 'error',
        })
      }
    }
    const handleBlock = async () => {
      if (!userId || !data) return

      try {
        await blockUser(userId, !isSuspended)

        const value = isSuspended
          ? 'User unblocked successfully.'
          : 'User blocked successfully.'

        setToast({
          value,
          type: 'success',
          callback: () => {
            handleCloseModal()
            setState({
              ...state,
              data: {...data, isSuspended: !isSuspended},
            })
          },
        })
      } catch (error) {
        handleCloseModal()

        setToast({
          value: error.response?.data?.title,
          type: 'error',
        })
      }
    }

    if (modalState.loadingModal) {
      switch (modalState.type) {
        case MODAL_TYPE.remove:
          handleDelete()
          break
        case MODAL_TYPE.export:
          handleExport()
          break
        case MODAL_TYPE.block:
          handleBlock()
          break
        default:
      }
    }

    // eslint-disable-next-line
  }, [modalState.loadingModal])

  const handleUpdateNdaRequestSuccess = (updatedDealInterest: DealInterest) => {
    if (!data) return

    const updatedDealInterests = dealInterests.map(di => {
      if (di.id === updatedDealInterest.id) {
        return {
          ...di,
          ...updatedDealInterest,
        }
      } else {
        return di
      }
    })

    setState({
      ...state,
      data: {...data, dealInterests: updatedDealInterests},
    })
  }

  const handleUpdateDeckSuccess = async (
    id: string,
    status: DealInterestStatus | undefined
  ) => {
    if (!data) return

    const updatedDealInterests = dealInterests.map(d => {
      if (d.id === id) {
        let updatedDI = {...d}

        if (d.status === DEAL_INTEREST_STATUS.TEASER_STAGE) {
          updatedDI = {...updatedDI, hasSignedNDA: true}
        }

        return {
          ...updatedDI,
          status,
        }
      }
      return d
    })

    setState({
      ...state,
      data: {...data, dealInterests: updatedDealInterests},
    })
  }

  const handleUpdateDataroomSuccess = async (
    id: string,
    value: DataroomStatus | undefined,
    status: DealInterestStatus | undefined
  ) => {
    if (!data) return
    if (!value) return

    const updatedDealInterests = dealInterests.map(d => {
      if (d.id === id) {
        return {
          ...d,
          dataroomRequestStatus: value,
          status,
        }
      }
      return d
    })

    setState({
      ...state,
      data: {...data, dealInterests: updatedDealInterests},
    })
  }

  const handleInvesting = async (
    id: string,
    value: DealInterestStatus | undefined
  ) => {
    if (!data) return

    const updatedDealInterests = dealInterests.map(d => {
      if (d.id === id) {
        return {
          ...d,
          status: value,
        }
      }
      return d
    })

    setState({
      ...state,
      data: {...data, dealInterests: updatedDealInterests},
    })
  }

  const {type, loadingModal} = modalState
  const showBlock = type === 'BLOCK'
  const showExport = type === 'EXPORT'
  const showRemove = type === 'REMOVE'

  const showActions = !isEmailVerified

  if (loading) {
    return <Spinner margin="big" primary label="Loading User..." />
  }
  if (!data) {
    return null
  }

  return (
    <Fragment>
      <Toast message={toast} action={setToast} />

      <Breadcrumbs
        breadcrumbs={[
          {
            type: 'link',
            to: '/users',
            label: 'Users',
          },
          {
            type: 'text',
            label:
              firstName && lastName
                ? `${data.firstName} ${data.lastName}`
                : 'View User',
          },
        ]}
      />

      <PageTitle title="View user" />

      <ContentContainer>
        <ViewUserHeaderButtons>
          {isAdmin && (
            <Fragment>
              <Button
                onClick={() =>
                  setModalState({...modalState, type: MODAL_TYPE.export})
                }
                disabled={loading}
              >
                {'Export User Data'}
              </Button>

              <Button
                onClick={() =>
                  setModalState({...modalState, type: MODAL_TYPE.remove})
                }
                disabled={loading}
              >
                {isArchived ? 'Delete user' : 'Archive User'}
              </Button>

              <Button
                onClick={() =>
                  setModalState({...modalState, type: MODAL_TYPE.block})
                }
                disabled={loading}
              >
                {isSuspended ? 'Unblock User' : 'Block User'}
              </Button>
            </Fragment>
          )}
        </ViewUserHeaderButtons>

        <ViewUserGrid>
          <div className="col">
            <UserCard user={data} />

            {showActions && (
              <AdminActions
                setToast={setToast}
                state={state}
                setState={setState}
              />
            )}
          </div>

          <div className="col">
            {dealInterests && dealInterests.length > 0 && (
              <UserDeals
                dealInterests={dealInterests}
                onUpdateNdaAccess={handleUpdateNdaRequestSuccess}
                onUpdateDeckAccess={handleUpdateDeckSuccess}
                onUpdateDataroomAccess={handleUpdateDataroomSuccess}
                onInvesting={handleInvesting}
              />
            )}

            <UserLogs slug={slug} />
          </div>
        </ViewUserGrid>
      </ContentContainer>

      <ExportModal
        show={showExport}
        onExport={() => setModalState({...modalState, loadingModal: true})}
        onClose={handleCloseModal}
        loading={loadingModal}
      />
      <DeleteModal
        show={showRemove}
        email={data.email}
        onDelete={() => setModalState({...modalState, loadingModal: true})}
        onClose={handleCloseModal}
        loading={loadingModal}
        {...{isArchived}}
      />
      <BlockModal
        show={showBlock}
        name={`${firstName} ${lastName}`}
        onBlock={() => setModalState({...modalState, loadingModal: true})}
        onClose={handleCloseModal}
        loading={loadingModal}
        isSuspended={Boolean(isSuspended)}
      />
    </Fragment>
  )
}

export default UserDetails
