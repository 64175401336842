import React from 'react'

import {DealStatus, File} from 'types'

import {DealFormContainer} from './styles'
import Deadline from './components/Deadline'
import Desk from './components/Desk'
import EditableNda from './components/EditableNda'
import Details from './components/Details'
import Overview from './components/Overview'
import Teaser from './components/Teaser'
import DealSettings from './components/DealSettings'

type DealFormProps = {
  loading: boolean
  status?: DealStatus
  onRemoveFiles: (files: File[]) => void
  caplinkedWorkspaces: any[]
}

const DealForm = ({
  loading,
  status,
  onRemoveFiles,
  caplinkedWorkspaces,
}: DealFormProps) => {
  return (
    <DealFormContainer>
      <fieldset className="col" disabled={loading}>
        <Overview
          disabled={loading}
          onRemove={onRemoveFiles}
          status={status}
          caplinkedWorkspaces={caplinkedWorkspaces}
        />

        <Teaser disabled={loading} onRemove={onRemoveFiles} />

        <Desk disabled={loading} onRemove={onRemoveFiles} />

        <EditableNda disabled={loading} onRemove={onRemoveFiles} />
      </fieldset>

      <fieldset className="col" disabled={loading}>
        <Details />

        <Deadline />

        <DealSettings />
      </fieldset>
    </DealFormContainer>
  )
}

export default DealForm
