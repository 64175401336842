import React, {useEffect, useState} from 'react'
import {Cell} from 'react-table'

import {MessageProps, UserActions} from 'types'
import {getUserActionsBySlug} from 'services/users'
import {formatFirebaseDate} from 'utils/date'

import Table from 'components/Table'

type UserLogsProps = {
  slug: string
}
type State = {
  data: UserActions[]
  loading: boolean
}

const UserLogs = ({slug}: UserLogsProps) => {
  const [logs, setLogs] = useState<State>({
    loading: true,
    data: [],
  })
  const {data, loading} = logs

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  useEffect(() => {
    const getActions = async () => {
      try {
        const results = await getUserActionsBySlug(slug)
        setLogs({data: results, loading: false})
      } catch (error) {
        setLogs({...logs, loading: false})
        setToast({
          ...toast,
          value: error.message,
        })
      }
    }

    getActions()
    // eslint-disable-next-line
  }, [])

  const isEmpty = !data || data.length < 1

  if (loading || isEmpty) {
    return null
  }

  return (
    <Table
      columns={[
        {
          Header: 'Date',
          accessor: 'createdAt',
          Cell: (cell: Cell<UserActions>) => {
            const {createdAt} = cell.row.original

            return formatFirebaseDate(createdAt)
          },
        },
        {
          Header: 'Deal',
          accessor: ({deal}: UserActions) => deal?.name,
          Cell: (cell: Cell<UserActions>) => {
            const {deal} = cell.row.original

            return <div className="bold black">{deal?.name}</div>
          },
        },
        {
          Header: 'Event',
          accessor: 'action',
        },
      ]}
      data={data}
    />
  )
}

export default UserLogs
