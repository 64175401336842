import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Constants from 'styles/constants'

export const Content = styled.div`
  padding: ${Constants.PADDING_TOP} ${Constants.PADDING_LEFT_RIGHT};

  .interested-container {
    width: 50%;
  }

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: ${Constants.PADDING_TOP} 2% 0;
  }

  @media only screen and (max-width: ${Breakpoints.lg}) {
    .interested-container {
      width: auto;
    }
  }
`
