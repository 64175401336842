import styled, {css} from 'styled-components'

import Colors from 'styles/colors'
import Breakpoints from 'styles/breakpoints'

export const ConContainer = styled.footer`
  align-self: end;
  margin-top: 0;

  width: 100%;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    padding-top: 25px;

    transform: none;
    position: static;
  }
`

const fontStyle = css`
  color: ${Colors.GREY_50};
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0px;
  text-align: center;
`

export const ConditionText = styled.p`
  margin: 0 0 2em 0;
  padding-left: 1em;
  padding-right: 1em;

  ${fontStyle};

  a {
    ${fontStyle};
  }

  @media only screen and (max-width: ${Breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`
