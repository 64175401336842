import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

export const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: ${Breakpoints.sm}) {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
  }
`
