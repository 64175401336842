import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {USER_ACTIONS} from 'config/data/actions.config'
import {useAuth} from 'hooks/useAuth'
import {DealContact, MessageProps} from 'types'
import {recordUserAction} from 'services/users'
import {getDeal} from 'services/deals'
import {errorDefault} from 'utils/errors'

import Button from 'components/styles/Button'
import ReactPdfViewer from 'components/ReactPdfViewer'
import ContactDetails from './components/ContactDetails'
import Toast from 'components/Toast'
import ContentContainer from 'components/ContentContainer'

import {Content} from './styles'

// import RequestMeetingModal from '../Deal/components/Modals/RequestMeeting'
// import GetInTouchModal from '../Deal/components/Modals/GetInTouch'

const modalDefault = {
  showGetInTouch: false,
  showRequestMeeting: false,
}

const DealDeck: React.FC = () => {
  const {isAdmin} = useAuth()
  const history = useHistory()

  const [modal, setModal] = useState(modalDefault)
  // const { showGetInTouch, showRequestMeeting } = modal

  const [state, set] = useState<{contact: DealContact; loading: boolean}>({
    contact: {name: '', phone: '', position: '', email: ''},
    loading: true,
  })
  const {contact, loading} = state

  const location = useLocation()

  const url = (location?.state as any)?.url
  // const fileName = (location?.state as any)?.fileName;
  const dealId = (location?.state as any)?.dealId
  // const dealInterestId = (location?.state as any)?.dealInterestId

  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  useEffect(() => {
    !isAdmin && recordUserAction(USER_ACTIONS.DECK_VIEW, dealId)

    const getData = async () => {
      try {
        const deal = await getDeal(dealId)

        set({contact: deal.contact ? deal.contact : contact, loading: false})
      } catch (error) {
        set({...state, loading: false})

        setToast({
          value: errorDefault,
          type: 'error',
        })
      }
    }

    getData()

    // eslint-disable-next-line
  }, [dealId, isAdmin])

  const onRequestMeeting = () => setModal({...modal, showRequestMeeting: true})
  const onAskQuestion = () => setModal({...modal, showGetInTouch: true})

  return (
    <ContentContainer>
      <Toast message={toast} action={setToast} />

      <Content>
        <h1 className="title-primary">Deck File</h1>

        <Button onClick={() => history.goBack()}>Go Back</Button>

        <ReactPdfViewer file={url.endsWith('.pdf') ? url : `${url}.pdf`} />

        <ContactDetails
          data={contact}
          {...{onRequestMeeting, onAskQuestion, loading}}
        />
      </Content>

      {/* <GetInTouchModal
        handleClose={() => setModal({ ...modal, showGetInTouch: false })}
        {...{ dealInterestId }}
        show={showGetInTouch}
      /> */}

      {/* <RequestMeetingModal
        handleClose={() => setModal({ ...modal, showRequestMeeting: false })}
        {...{ dealInterestId }}
        show={showRequestMeeting}
      /> */}
    </ContentContainer>
  )
}

export default DealDeck
