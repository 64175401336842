/**
 * Add '...' after X characters where X = length
 * @param str
 * @param length
 * @returns
 */
export const trimString = (str: string, length: number) => {
  return str.length > length ? str.substring(0, length - 3) + '...' : str
}

/**
 * For number like investment size: 1,000,000
 * @param num
 * @returns
 */
export const formatNumber = (num: any) => {
  return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * Returns the dotified (short) version of a string.
 *
 * @param {string} string The string to dotify.
 * @param {number} maxLength The maximum length of the dotified string.
 *
 * @returns {string} The dotified string.
 */
export const dotify = (string = '', maxLength = 0) => {
  if (typeof string !== 'string') return ''
  if (typeof maxLength !== 'number') return string

  let sub = string.slice(0, maxLength)

  if (sub.length < string.length) {
    sub = sub.concat('...')
  }

  return sub
}
