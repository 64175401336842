import React from 'react'
import {DropDownCardContainer} from './styles'

type DropDownCardProps = {
  data: any[]
  setOpen: (open: boolean) => void
  dealInterestId: string
  onClick: (id: string) => void
}

const DropDownCard = ({
  data = [],
  setOpen,
  dealInterestId,
  onClick,
}: DropDownCardProps) => {
  const handleClick = () => {
    onClick(dealInterestId)
    setOpen(false)
  }
  return (
    <DropDownCardContainer>
      <ul>
        {data.map((item, i) => (
          <li key={i} onClick={() => handleClick()}>
            {item}
          </li>
        ))}
      </ul>
    </DropDownCardContainer>
  )
}
export default DropDownCard
