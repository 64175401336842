import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'

export const AddToDealTitle = styled.p`
  margin: 0;
  font-size: 26px;
  line-height: 40px;
  font-weight: 600;
`

export const AddToDealDescription = styled.p`
  margin: 12px 0;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #616161;
`

export const AddToDealContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${Breakpoints.sm}) {
    flex-direction: row;
    align-items: center;
  }

  > button {
    margin-top: 16px;
    @media (min-width: ${Breakpoints.sm}) {
      margin-top: 0;
      margin-left: 16px;
    }
  }
`
