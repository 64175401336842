import React, {Fragment} from 'react'
import {useForm} from 'react-hook-form'

import Input from 'components/Input'
import Button from 'components/styles/Button'

interface FormProps {
  onSubmit: (data: any) => void
  loading: boolean
}

const Form: React.FC<FormProps> = ({onSubmit, loading}) => {
  const {register, handleSubmit, errors} = useForm()

  return (
    <Fragment>
      <form className="app-form" onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={loading} aria-busy={loading}>
          <Input
            label="Email Address"
            type="email"
            placeholder="Email"
            error={{
              status: errors.email,
              message: 'This is not a valid email address',
            }}
            name="email"
            ref={register({
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
          />

          <Button full type="submit">
            Send Reset Request
          </Button>
        </fieldset>
      </form>
    </Fragment>
  )
}

export default Form
