import React from 'react'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'

import {Deal} from 'types'

import Card from 'components/Card'

import {
  TimelineContainer,
  TimelineItem,
  TimelineItemTitle,
  TimelineItemDate,
  TimelineItemCount,
  TimelineItemCountText,
} from './styles'

export interface DealTimelineProps {
  deal: Deal
}

const DealTimeline: React.FC<DealTimelineProps> = ({deal}) => {
  const dealLiveDate = deal.publishedAt?.toDate()
  const indicativeOfferDeadlineDate = deal.indicativeOfferDeadline?.toDate()
  const finalOfferDeadlineDate = deal.finalOfferDeadline?.toDate()

  const dealLiveDatePassed = dealLiveDate && isAfter(new Date(), dealLiveDate)
  const indicativeOfferDeadlineDatePassed =
    indicativeOfferDeadlineDate &&
    isAfter(new Date(), indicativeOfferDeadlineDate)
  const finalOfferDeadlineDatePassed =
    finalOfferDeadlineDate && isAfter(new Date(), finalOfferDeadlineDate)

  return (
    <Card title="Deal Timeline">
      <TimelineContainer>
        {/* Deal publish date */}
        <TimelineItem filled={dealLiveDatePassed}>
          <TimelineItemCount filled={dealLiveDatePassed}>
            <TimelineItemCountText filled={dealLiveDatePassed}>
              01
            </TimelineItemCountText>
          </TimelineItemCount>

          <div>
            <TimelineItemTitle filled={dealLiveDatePassed}>
              Deal Live
            </TimelineItemTitle>
            <TimelineItemDate>
              {dealLiveDate ? format(dealLiveDate, 'dd/MM/yy') : ''}
            </TimelineItemDate>
          </div>
        </TimelineItem>

        {/* Indicative offer deadline */}
        <TimelineItem filled={indicativeOfferDeadlineDatePassed}>
          <TimelineItemCount filled={indicativeOfferDeadlineDatePassed}>
            <TimelineItemCountText filled={indicativeOfferDeadlineDatePassed}>
              02
            </TimelineItemCountText>
          </TimelineItemCount>

          <div>
            <TimelineItemTitle filled={indicativeOfferDeadlineDatePassed}>
              Indicative Offers
            </TimelineItemTitle>
            <TimelineItemDate>
              {indicativeOfferDeadlineDate
                ? format(indicativeOfferDeadlineDate, 'dd/MM/yy')
                : ''}
            </TimelineItemDate>
          </div>
        </TimelineItem>

        {/* Final offer deadline */}
        <TimelineItem filled={finalOfferDeadlineDatePassed}>
          <TimelineItemCount filled={finalOfferDeadlineDatePassed}>
            <TimelineItemCountText filled={finalOfferDeadlineDatePassed}>
              03
            </TimelineItemCountText>
          </TimelineItemCount>

          <div>
            <TimelineItemTitle filled={finalOfferDeadlineDatePassed}>
              Final Offers
            </TimelineItemTitle>
            <TimelineItemDate>
              {finalOfferDeadlineDate
                ? format(finalOfferDeadlineDate, 'dd/MM/yy')
                : ''}
            </TimelineItemDate>
          </div>
        </TimelineItem>
      </TimelineContainer>
    </Card>
  )
}

export default DealTimeline
