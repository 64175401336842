import styled from 'styled-components'
import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

export const Tabs = styled.div`
  display: flex;
  background-color: ${Colors.WHITE};

  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  @media only screen and (max-width: ${Breakpoints.sm}) {
    padding-left: 12px;
    padding-right: 12px;

    flex-direction: column;
  }
`

export const Tab = styled.button<{active?: boolean; error?: boolean}>`
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;

  color: ${({active, error}) =>
    error ? 'red' : active ? Colors.BLACK : Colors.GREY};
  background-color: ${({active}) =>
    active ? Colors.LIGHT_GREY : 'transparent'};

  padding: 8px 12px;
  border-radius: 6px;

  font-size: 16px;
  line-height: 28px;

  &:not(:first-child) {
    margin-left: 32px;

    @media only screen and (max-width: ${Breakpoints.sm}) {
      margin-left: 0;
      margin-top: 6px;
    }
  }

  &:disabled {
    color: ${Colors.DARK_GREY};
    background-color: 'transparent';
    cursor: not-allowed;
  }

  &:hover {
    color: ${Colors.BLACK};
    background-color: ${Colors.LIGHT_GREY};
  }
`

export const TabContent = styled.div<{active?: boolean}>`
  margin-top: 32px;
  overflow: hidden;

  display: ${({active}) => (active ? 'block' : 'none')};
`
