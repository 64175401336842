export const autoScrollTop = () => {
  document?.getElementById('header-nav')?.scrollIntoView({
    behavior: 'smooth',
  })
}

export const autoScrollBottom = () => {
  document?.getElementById('auth-footer')?.scrollIntoView({
    behavior: 'smooth',
  })
}
