import * as Sentry from '@sentry/react'
import differenceInDays from 'date-fns/differenceInDays'
import addYears from 'date-fns/addYears'

import firebase from 'lib/firebase'
import axios from 'lib/axios'
import {
  CertificationType,
  DealInterest,
  EventType,
  User,
  UserActions,
  UserUpdatedFields,
} from 'types'
import {USERS_API} from 'config/endpoints.config'
import {ROLES} from 'config/data/roles.config'

import {getDealInterestsByUserId} from './deal-interests'

export const registerUser = async ({
  firstName,
  lastName,
  country,
  company,
}: {
  firstName: string
  lastName: string
  country: string
  company: string | null
}) => {
  const token = await firebase.auth().currentUser?.getIdToken()

  const response = await axios.post(
    `${USERS_API}`,
    {
      firstName,
      lastName,
      country,
      company: company !== '' ? company : null,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return response.data
}

export const getUsers = async () => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('users')
      .orderBy('lastActiveAt', 'desc')
      .get()

    const users = await Promise.all(
      snapshot.docs.map(async user => {
        const data = user.data()
        const dealInterests: DealInterest[] = await getDealInterestsByUserId(
          user.id
        )

        return {
          ...data,
          id: user.id,
          dealInterests,
        } as User
      })
    )

    return users
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const getUserActionsBySlug = async (slug: string) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('users')
      .where('slug', '==', slug)
      .get()

    const docId = snapshot.docs[0].id

    const actions = await firebase
      .firestore()
      .collection('users')
      .doc(docId)
      .collection('actions')
      .orderBy('createdAt', 'desc')
      .get()

    const results = actions.docs.map(d => {
      const doc = d.data()

      return {
        ...doc,
        id: doc.id,
      } as UserActions
    })

    return results
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const getUserBySlug = async (slug: string, isDealInterest = true) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('users')
      .where('slug', '==', slug)
      .limit(1)
      .get()

    const data = snapshot.docs[0].data() as User
    const id = snapshot.docs[0].id

    let dealInterests: DealInterest[] = []
    if (isDealInterest) {
      dealInterests = await getDealInterestsByUserId(id)
    }

    return {
      ...data,
      id,
      dealInterests,
    } as User
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const getUser = async (id: string, isDealInterest = true) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('users')
      .doc(id)
      .get()

    const data = snapshot.data() as User

    let dealInterests: DealInterest[] = []
    if (isDealInterest) {
      dealInterests = await getDealInterestsByUserId(id)
    }

    return {
      ...data,
      id,
      dealInterests,
    } as User
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

/**
 * Trigger a self-certification procedure for the given user. This will
 * either return a DocuSign link where you can redirect the user to sign
 * the required document, or will return the updated user object in case
 * of no need to sign a document.
 *
 * @param userId The ID of the user.
 * @param selfCertifyAs The certification type.
 * @param dealId The ID of the target deal.
 */
export const selfCertifyUser = async (
  userId: string,
  selfCertifyAs: CertificationType,
  dealId?: string
) => {
  const response = await axios.post(`${USERS_API}/${userId}/certifications`, {
    selfCertifyAs,
    dealId: dealId ?? null,
  })

  return response.data
}

export const updateUserById = async (
  userId: string,
  updatedFields: UserUpdatedFields
) => {
  const response = await axios.patch(`${USERS_API}/${userId}`, {
    ...updatedFields,
  })

  return response.data
}

export const recordUserAction = async (event: EventType, dealId: string) => {
  const userId = await firebase.auth().currentUser?.uid

  const response = await axios.post(`${USERS_API}/${userId}/actions`, {
    event,
    dealId,
  })

  return response.data
}

/**
 * Returns the current self-certification status of the given user.
 *
 * @param user The user object.
 *
 * @returns Whether the user is certified or not.
 */
export const isUserCertified = (user: User) => {
  if (!user) {
    return false
  }

  const {role, selfCertifiedAs, lastCertifiedAt} = user

  const mustRenewWithinDays = lastCertifiedAt
    ? differenceInDays(addYears(lastCertifiedAt.toDate(), 1), new Date())
    : 0
  const certificationExpired = mustRenewWithinDays <= 0

  return Boolean(
    role === ROLES.ADMIN ||
      (selfCertifiedAs && lastCertifiedAt && !certificationExpired)
  )
}

/**
 * Returns whether the user has any interests in any particular type of deals.
 * (Not to be confused with having a deal interest.)
 *
 * @param user The user object.
 *
 * @returns Whether the user has interests or not.
 */
export const hasUserInterests = (user: User) => {
  if (!user) {
    return false
  }

  const {dealSectors = [], dealSizes = [], dealStages = []} = user

  const hasInterests =
    (dealSectors && dealSectors.length > 0) ||
    (dealSizes && dealSizes.length > 0) ||
    (dealStages && dealStages.length > 0)

  return hasInterests
}
