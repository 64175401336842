import React, {useState, useEffect, Fragment} from 'react'
import {Link} from 'react-router-dom'

import {MessageProps} from 'types'
import {ROUTES} from 'config/routes.config'
import {sendVerificationEmail} from 'services/email'
import {registerUser} from 'services/users'
import {createUserWithEmailAndPassword} from 'lib/firebase/auth'

import logo from 'assets/images/logo.svg'

import RegistrationLayout from 'components/styles/RegistrationLayout'
import Footer from 'components/Footer'
import Toast from 'components/Toast'
import Card from 'components/Card'

import Email from './components/Email'
import Form from './components/Form'

import {FormHeader, FormFooter, Logo, Line} from './styles'

export type RegisterFormData = {
  company: string
  country: {value: string; label: string}
  email: string
  firstName: string
  lastName: string
  mandatorycheck: boolean
  password: string
}

interface RegisterProps {
  partialUser?: boolean
}

const Register: React.FC<RegisterProps> = ({partialUser}) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const [toast, setToast] = useState<MessageProps>({value: '', type: 'info'})

  const onSubmit = async (data: RegisterFormData) => {
    setLoading(true)

    try {
      const {firstName, lastName, password, email, company, country} = data

      if (!partialUser) {
        await createUserWithEmailAndPassword(email, password)
      }

      await registerUser({
        firstName,
        lastName,
        company,
        country: country.label,
      })
      await sendVerificationEmail(email)

      // On success display feedback to the user
      setEmail(email)
    } catch (error) {
      setToast({
        value: error.message,
        type: 'error',
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    if (email) {
      document?.getElementById('auth')?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [email])

  return (
    <Fragment>
      <RegistrationLayout id="auth">
        <Card className="content" padding shadow>
          {email ? (
            <Fragment>
              <Email {...{email, setToast}} />
            </Fragment>
          ) : (
            <Fragment>
              <FormHeader className="m-bottom-20">
                <Logo className="m-bottom-40" src={logo} alt="NobleCo" />

                <h2>Investor Registration</h2>

                <span className="grey text-lg">
                  Welcome to your dashboard. From here you can view deals,
                  register interest and submit offers on deals.
                </span>
              </FormHeader>

              <Form {...{onSubmit, loading, partialUser}} />

              <Line />

              <FormFooter>
                <span className="text">
                  Already have an account?{' '}
                  <Link className="router-link-register" to={ROUTES.LOGIN}>
                    Log In
                  </Link>
                </span>
              </FormFooter>
            </Fragment>
          )}
        </Card>

        <Footer />
      </RegistrationLayout>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default Register
