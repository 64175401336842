import * as Sentry from '@sentry/react'

import firebase from 'lib/firebase'
import axios from 'lib/axios'
import {Deal, DealFile, DealInterest, DealStatus, OfferQuestion} from 'types'
import {DEAL_INTEREST_API, DEALS_API, VIMEO} from 'config/endpoints.config'
import {LONG_TIMEOUT} from 'config/timeouts.config'
import {handleErrors} from 'utils/errors'

import {getDealInterests, getDealInterestsByUserId} from './deal-interests'
import {getUser} from './users'

/**
 * Get a list of all the deals.
 *
 * @return The deals.
 */
export const getDeals = async () => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('deals')
      .orderBy('createdAt', 'desc')
      .get()

    const deals = await Promise.all(
      snapshot.docs.map(async deal => {
        const data = deal.data()

        return {
          ...data,
          id: deal.id,
        } as Deal
      })
    )

    return deals
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

/**
 * Get a public deal by its slug.
 *
 * @param slug The slug of the deal.
 *
 * @returns The deal.
 */
export const getPublicDeal = async (slug: string) => {
  const response = await axios.get<Deal>(`${DEALS_API}/${slug}`)

  return response.data
}

/**
 * Get a list of all the published deals.
 *
 * @returns The published deals.
 */
export const getPublishedDeals = async () => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('deals')
      .where('status', '==', 'PUBLISHED')
      .get()

    const deals = await Promise.all(
      snapshot.docs.map(async deal => {
        const data = deal.data()

        return {
          ...data,
          id: deal.id,
        } as Deal
      })
    )

    return deals
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

/**
 * Get user's deals.
 *
 * @param userId The ID of the signed in user.
 *
 * @returns User's deals.
 */
export const getYourDeals = async (userId: string) => {
  const [userData, userDealInterests] = await Promise.all([
    getUser(userId, false),
    getDealInterestsByUserId(userId),
  ])

  const yourDeals: Deal[] = []

  await Promise.all(
    userDealInterests.map(async dealInterest => {
      if (dealInterest.deal?.id) {
        const {hasSignedNDA} = dealInterest

        const deal = await getDeal(dealInterest.deal.id)

        const showDealDetails = getShowDealDetails(deal, {
          isAdmin: false,
          hasUserSignedNDA: hasSignedNDA,
        })

        yourDeals.push({
          ...deal,
          showDealDetails,
          dealInterest,
        })
      }
    })
  )

  return yourDeals.filter(deal => !userData.dismissedDeals?.includes(deal.id))
}

/**
 * Get deals for overview screen.
 *
 * @param userId The ID of the signed in user.
 *
 * @returns The list of deals.
 */
export const getOverviewDeals = async (userId: string) => {
  const [userData, userDealInterests] = await Promise.all([
    getUser(userId, false),
    getDealInterestsByUserId(userId),
  ])

  const overviewDeals: Deal[] = []

  await Promise.all(
    userDealInterests.map(async dealInterest => {
      if (dealInterest.deal?.id) {
        const {hasSignedNDA} = dealInterest

        const deal = await getDeal(dealInterest.deal.id)

        const showDealDetails = getShowDealDetails(deal, {
          isAdmin: false,
          hasUserSignedNDA: hasSignedNDA,
        })

        overviewDeals.push({
          ...deal,
          showDealDetails,
          dealInterest,
        })
      }
    })
  )

  return overviewDeals
    .filter(deal => !userData.dismissedDeals?.includes(deal.id))
    .filter(deal => deal.status !== 'CLOSED')
}

/**
 * Get deals that the user can interact with.
 *
 * It can either return:
 * - all deals (public + dismissed)
 * - open deals (public)
 * - dismissed deals (dismissed)
 *
 * @param userId The ID of the signed in user.
 * @param filter Filter the returned deals.
 *
 * @returns The published open deals.
 */
export const getAvailableDeals = async (
  userId: string,
  filter: string = 'ALL'
) => {
  try {
    const [userData, userDealInterests, allPublishedDeals] = await Promise.all([
      getUser(userId, false),
      getDealInterestsByUserId(userId),
      getPublishedDeals(),
    ])

    // This will include dismissed deals too by default
    const allAvailableDeals = allPublishedDeals.filter(
      publishedDeal =>
        !publishedDeal.isPrivate &&
        !userDealInterests.find(
          dealInterest => dealInterest.deal?.id === publishedDeal.id
        )
    )

    let availableDeals: Deal[] = []
    switch (filter) {
      case 'ALL':
        /** Show all deals */
        availableDeals = allAvailableDeals
        break

      case 'OPEN':
        /** Show all deals except dismissed (all - dismissed)  */
        availableDeals = allAvailableDeals.filter(
          deal => !userData.dismissedDeals?.includes(deal.id)
        )
        break

      case 'DISMISSED':
        /** Only show dismissed deals (dismissed) */
        availableDeals = allAvailableDeals.filter(deal =>
          userData.dismissedDeals?.includes(deal.id)
        )
        break

      default:
        availableDeals = allAvailableDeals
        break
    }

    return availableDeals
      .map(deal => ({
        ...deal,
        showDealDetails: getShowDealDetails(deal, {
          isAdmin: false,
          hasUserSignedNDA: false,
        }),
      }))
      .sort((a, b) => {
        if (userData.dismissedDeals?.includes(a.id)) {
          return 1
        } else if (userData.dismissedDeals?.includes(b.id)) {
          return -1
        } else {
          return 0
        }
      })
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const getDealBySlug = async (
  slug: string,
  userId: string,
  isAdmin?: boolean
) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('deals')
      .where('slug', '==', slug)
      .limit(1)
      .get()

    const dealId = snapshot.docs[0].id
    const deal = snapshot.docs[0].data() as any

    const status: DealStatus = deal?.status
    const deskFiles = deal?.deskFiles ? Object.values(deal.deskFiles) : []

    const dealInterests = isAdmin
      ? await getDealInterests(dealId, {hasSignedNda: true})
      : await getDealInterestsByUserId(userId, {dealId})

    return {
      ...deal,
      id: dealId,
      status,
      deskFiles,
      dealInterests,
    } as Deal
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const getDeal = async (id: string) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('deals')
      .doc(id)
      .get()

    const data = snapshot.data() as any
    const status: DealStatus = data?.status
    const deskFiles = data?.deskFiles ? Object.values(data.deskFiles) : []

    return {
      ...data,
      id: snapshot.id,
      status,
      deskFiles,
    } as Deal
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

/**
 * This endpoint will add the deal to the list of dismissed deals
 * on the user record.
 *
 * @param dealId The ID of the deal.
 *
 * @returns The affected deal.
 */
export const dismissDeal = async (
  dealId: string,
  feedback?: {
    feedbackItems?: string[]
    otherFeedback?: string
  }
) => {
  const response = await axios.post<Deal>(
    `${DEALS_API}/${dealId}/actions/dismiss`,
    {
      feedback:
        feedback &&
        Array.isArray(feedback.feedbackItems) &&
        feedback.feedbackItems.length > 0
          ? feedback.feedbackItems
          : undefined,
      otherFeedback:
        feedback &&
        typeof feedback.otherFeedback === 'string' &&
        feedback.otherFeedback.length > 0
          ? feedback.otherFeedback
          : undefined,
    }
  )

  return response.data
}

export const downloadDealFile = async (
  dealId: string | number,
  fileId: string | number
) => {
  const response = await axios.get(`${DEALS_API}/${dealId}/files/${fileId}`, {
    timeout: LONG_TIMEOUT,
  })

  return response.data
}

const getFilesWithUrl = async (dealId: string, files: DealFile[]) => {
  return Promise.all(
    files.map(async file => {
      const {id} = file
      const result: {
        fileName: string
        expiringUrl: string
      } = await downloadDealFile(dealId, id)
      return {...file, url: result?.expiringUrl}
    })
  )
}

export const getDealFiles = async (dealId: string, withUrl?: boolean) => {
  const response = await axios.get(`${DEALS_API}/${dealId}/files`, {
    timeout: LONG_TIMEOUT,
  })

  const files: DealFile[] = response?.data?.map(({id, title}: DealFile) => {
    return {id, title}
  })

  if (withUrl) {
    return await getFilesWithUrl(dealId, files)
  }

  return files
}

export const subscribeToDeal = async (dealId: string) => {
  const response = await axios.post(`${DEALS_API}/${dealId}/subscriptions`, {
    firstName: null,
    lastName: null,
  })

  return response.data
}

export const createDeal = async (data: Record<string, any>) => {
  const response = await axios.post<Deal>(`${DEALS_API}`, {
    ...data,
  })

  return response.data
}

export const updateDeal = async (id: Deal['id'], data: Record<string, any>) => {
  const response = await axios.patch<Deal>(`${DEALS_API}/${id}`, {
    ...data,
  })

  return response.data
}

export const inviteUsersToDealByEmail = async (
  dealId: string,
  users: {
    email: string
    firstName?: string | null
    lastName?: string | null
  }[]
) => {
  const response = await axios.post<DealInterest[]>(DEAL_INTEREST_API, {
    dealId,
    users,
  })

  return response.data
}

/**
 * Manually add a user to a deal (without sending invitation email).
 *
 * @param userEmail Email of the target user.
 * @param dealId ID of the deal to add to.
 *
 * @returns Deal interest.
 */
export const addUserToDeal = async (
  userEmail: string,
  dealId: string
): Promise<DealInterest> => {
  const response = await axios.post<DealInterest[]>(DEAL_INTEREST_API, {
    dealId,
    users: [
      {
        email: userEmail,
        isManualAdd: true,
      },
    ],
  })

  return response.data[0]
}

/**
 * Re-sends invite emails to all users interested in a specific deal.
 *
 * @param dealId ID of the deal.
 * @param userEmails An array containing emails of the targeted users.
 */
export const resendInviteEmails = async (
  dealId: string,
  userEmails: string[]
) => {
  await axios.post(`${DEALS_API}/${dealId}/actions/resend-invite-email`, {
    emails: userEmails,
  })
}

/**
 * Sends an email to all users interested in a specific deal.
 *
 * @param dealId ID of the deal.
 * @param userEmails An array containing emails of the targeted users.
 * @param emailOptions An object containing the subject and the body of the email.
 */
export const sendEmailToDealUsers = async (
  dealId: string,
  userEmails: string[],
  {subject, message}: {subject: string; message: string}
) => {
  await axios.post(`${DEALS_API}/${dealId}/actions/notify-participants`, {
    type: 'EMAIL',
    users: userEmails,
    subject,
    message,
  })
}

export const getContentRawNDA = async (dealId: string) => {
  try {
    const deal = await firebase
      .firestore()
      .collection(`deals`)
      .doc(dealId)
      .get()

    const {ndaFile} = deal.data() as Deal

    return ndaFile
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(handleErrors(error.response.data))
  }
}

export const getOfferQuestions = async (type: string) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection('offer-questions')
      .where('type', '==', type)
      .orderBy('questionNumber', 'asc')
      .get()

    const questions = snapshot.docs.map(doc => {
      const data = doc.data()

      return {
        ...data,
        id: doc.id,
      } as OfferQuestion
    })

    return questions
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(handleErrors(error.message))
  }
}

/**
 * Fetch the video object from Vimeo
 * https://developer.vimeo.com/api/oembed/videos
 */
export const getVideoFromVimeo = async (url: string) => {
  const response = await axios.get(`${VIMEO}${url}`, {
    headers: null,
  })

  return response.data
}

/**
 * Get a list of the deals which are NOT yet associated
 * with the target user through a deal interest.
 *
 * @param userId The ID of the target user.
 *
 * @returns List of deals.
 */
export const getDealsWithoutDealInterest = async (userId: string) => {
  const [userDealInterests, allPublishedDeals] = await Promise.all([
    getDealInterestsByUserId(userId),
    getPublishedDeals(),
  ])

  const dealsWithoutDealInterest = allPublishedDeals.filter(
    publishedDeal =>
      !userDealInterests.find(
        dealInterest => dealInterest.deal?.id === publishedDeal.id
      )
  )

  return dealsWithoutDealInterest
}

/**
 * Calculate whether to show the deal's details or not.
 *
 * @param deal Deal data
 * @param param1 Object containing extra info such as
 * is the auth user admin or have they signed the deal NDA
 */
export const getShowDealDetails = (
  deal: Deal,
  {isAdmin = false, hasUserSignedNDA = false}
) => {
  if (isAdmin) {
    return true
  }

  return Boolean(!deal.isAnoynamousDeal || hasUserSignedNDA)
}
