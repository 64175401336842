import React from 'react'
import {useFormContext} from 'react-hook-form'

import Input from 'components/Input'

import {Section} from '../../styles'

const Step2 = ({formStep}: {formStep: number}) => {
  const {register, setValue, errors} = useFormContext()

  return (
    <Section show={formStep === 1}>
      <h2>Company Details</h2>

      <Input
        name="companyName"
        label="Company Name"
        type="text"
        placeholder="Company Name"
        onClear={() => setValue('companyName', '')}
        error={{
          status: errors.firstName,
          message: 'Comapny Name is required',
        }}
        ref={register({
          required: formStep === 1,
          maxLength: 80,
        })}
      />

      <div className="row">
        <Input
          name="registrationNumber"
          label="Company Registration Number"
          type="text"
          placeholder="SC123456"
          onClear={() => setValue('registrationNumber', '')}
          error={{
            status: errors.registrationNumber,
            message: 'Registration Number is required',
          }}
          ref={register({
            required: formStep === 1,
            maxLength: 20,
          })}
        />

        <Input
          name="country"
          label="Country of Registration"
          type="text"
          placeholder="SC123456"
          onClear={() => setValue('registrationNumber', '')}
          error={{
            status: errors.country,
            message: 'Country is required',
          }}
          ref={register({
            required: formStep === 1,
            maxLength: 35,
          })}
        />
      </div>

      <h3>Company Address</h3>

      <div className="row">
        <Input
          name="companyAddressLine1"
          label="Address Line 1"
          type="text"
          placeholder="Address Line 1"
          onClear={() => setValue('companyAddressLine1', '')}
          error={{
            status: errors.companyAddressLine1,
            message: 'Address Line 1 is required',
          }}
          ref={register({
            required: formStep === 1,
            maxLength: 200,
          })}
        />

        <Input
          name="companyAddressLine2"
          label="Address Line 2"
          type="text"
          placeholder="Address Line 2"
          onClear={() => setValue('registrationNumber', '')}
          error={{
            status: errors.companyAddressLine2,
            message: 'Address Line 2 has a maximum of 100 characters',
          }}
          ref={register({
            maxLength: 100,
          })}
        />
      </div>
      <div className="row">
        <Input
          name="companyCity"
          label="City"
          type="text"
          placeholder="City"
          onClear={() => setValue('companyCity', '')}
          error={{
            status: errors.companyCity,
            message: 'City is required',
          }}
          ref={register({
            required: formStep === 1,
            maxLength: 100,
          })}
        />

        <Input
          name="companyPostcode"
          label="Postcode"
          type="text"
          placeholder="Postcode"
          onClear={() => setValue('companyPostcode', '')}
          error={{
            status: errors.companyPostcode,
            message: 'Postcode is required',
          }}
          ref={register({
            required: formStep === 1,
            maxLength: 40,
          })}
        />
      </div>
    </Section>
  )
}

export default Step2
