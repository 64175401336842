import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

export const Steps = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 32px;
`

export interface StepProps {
  filled?: boolean
}
export const Step = styled.div<StepProps>`
  border-top: 4px solid;
  border-top-color: ${({filled}) => (filled ? Colors.BLUE : Colors.LIGHT_GREY)};
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`

export interface StepCountProps {
  filled?: boolean
}
export const StepCount = styled.span<StepCountProps>`
  color: ${({filled}) => (filled ? Colors.BLUE : Colors.GREY)};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`

export const StepTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`

export const CurrentStepContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;

  @media (min-width: ${Breakpoints.sm}) {
    grid-template-columns: 2fr 1fr;
  }
  @media (min-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr;
  }
  @media (min-width: ${Breakpoints.lg}) {
    grid-template-columns: 2fr 1fr;
  }
  @media (min-width: ${Breakpoints.xl}) {
    grid-template-columns: 3fr 1fr;
  }
`

export const CurrentStepTitle = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin-top: 0;
    margin-left: 12px;
  }

  @media (min-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;

    > span {
      margin-top: 12px;
      margin-left: 0;
    }
  }

  @media (min-width: 1310px) {
    flex-direction: row;
    align-items: center;

    > span {
      margin-top: 0;
      margin-left: 12px;
    }
  }
`

export const CurrentStepTitleText = styled.p`
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  margin: 0;
`

export const CurrentStepDescription = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: ${Colors.GREY};
  margin: 0;
`

export const CurrentStepButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
`
