import React from 'react'
import {LinkProps} from 'react-router-dom'

import {StyledLink} from './styles'

import {ButtonType} from 'types'

export interface InternalLinkProps extends ButtonType, LinkProps {}

const InternalLink: React.FC<InternalLinkProps> = ({
  primary,
  full,
  styleless,
  children,
  ...rest
}) => {
  return (
    <StyledLink {...rest} {...{primary, full, styleless}}>
      {children}
    </StyledLink>
  )
}

export default InternalLink
