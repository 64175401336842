import * as Sentry from '@sentry/react'

import firebase from 'lib/firebase'
import {File} from 'types'
import {downloadFileFromBlob} from 'utils/blob'

export const filesUpload = async (files: File[], id?: string) => {
  try {
    const filesUploaded = await Promise.all(
      files.map(async (file: any) => {
        if (file?.url) {
          // File already on firestore
          const {reference, name} = file
          return {reference, name}
        } else {
          // const date = new Date()
          // const mil = date.getMilliseconds()

          const childPath = id ? `/deals/${id}/${file.name}` : `${file.name}`

          const fileRef = await firebase.storage().ref().child(childPath)

          await fileRef.put(file)

          return {reference: fileRef.fullPath, name: file.name}
        }
      })
    )

    return filesUploaded
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const fileUpload = async (
  file: any,
  id?: string,
  customPath?: string
) => {
  try {
    if (file?.url) {
      // File already on firestore
      const {reference, name} = file

      return {reference, name}
    } else {
      const date = new Date()
      const mil = date.getMilliseconds()

      const editPath = customPath
        ? `/deals/${id}/${customPath}/${file.name}-${mil}`
        : `/deals/${id}/${file.name}-${mil}`

      const childPath = id ? editPath : `${file.name}-${mil}`

      const fileRef = firebase.storage().ref().child(childPath)

      await fileRef.put(file)

      return {reference: fileRef.fullPath, name: file.name, altText: null}
    }
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const deleteFile = async (ref: string) => {
  try {
    const fileRef: firebase.storage.Reference = firebase
      .storage()
      .ref()
      .child(ref)

    await fileRef.delete()
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const deleteFiles = async (files: any[]) => {
  try {
    await Promise.all(
      files.map(async f => {
        const ref = f?.reference

        if (ref) {
          const fileRef: firebase.storage.Reference = firebase
            .storage()
            .ref()
            .child(ref)

          await fileRef.delete()
        }
      })
    )
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

export const downloadFileFromUrl = async (
  url: string,
  {fileName, blobType}: {fileName: string; blobType?: string}
) => {
  try {
    const blob = await fetch(url).then(res => res.blob())

    downloadFileFromBlob(blob, {
      fileName,
      blobType,
    })
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}

/**
 * Make an offer
 * Uploads the files to make an offer against the relevant Deal interest
 */
export const makeOfferFilesUpload = async (
  files: File[],
  dealInterestId?: string
) => {
  try {
    const filesUploaded = await Promise.all(
      files.map(async (file: any) => {
        if (file?.url) {
          // File already on firestore
          const {reference, name} = file
          return {reference, name}
        } else {
          const date = new Date()
          const mil = date.getMilliseconds()

          const childPath = dealInterestId
            ? `/deal-interests/${dealInterestId}/offers/${file.name}-${mil}`
            : `${file.name}-${mil}`

          const fileRef = await firebase.storage().ref().child(childPath)

          await fileRef.put(file)

          return {reference: fileRef.fullPath, name: file.name}
        }
      })
    )

    return filesUploaded || []
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(error)
  }
}
