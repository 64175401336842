import styled from 'styled-components'
import Colors from 'styles/colors'

interface TextareaProps {
  error?: boolean
}

const Textarea = styled.textarea<TextareaProps>`
  border: 2px solid;
  padding: 1rem;
  resize: vertical;
  border-radius: 4px;

  border-color: ${({error}) => (error ? Colors.ERROR : Colors.LIGHT_GREY)};

  ::placeholder {
    color: ${Colors.DARK_GREY};
  }
`

export default Textarea
