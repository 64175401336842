import styled from 'styled-components'
import {Link} from 'react-router-dom'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'
import {SLOW_TRANSITION} from 'styles/globalStyles'

export interface HeaderContainerProps {
  isAuth?: boolean
  showMenu: boolean
  isAdmin: boolean
}

export const HeaderContainer = styled.header<HeaderContainerProps>`
  height: 91px;
  padding: 0 24px;

  display: flex;
  align-items: center;

  background-color: ${Colors.WHITE};

  border-bottom: 1px solid ${Colors.LIGHT_GREY};

  @media only screen and (max-width: ${Breakpoints.sm}) {
    height: auto;
    padding: 12px;

    flex-direction: column;
    align-items: stretch;

    ${SLOW_TRANSITION};
  }

  .top {
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${Breakpoints.sm}) {
      justify-content: space-between;
    }

    .hamburger {
      display: none;

      @media only screen and (max-width: ${Breakpoints.sm}) {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
      }
    }

    .logo {
      cursor: pointer;

      @media only screen and (max-width: ${Breakpoints.sm}) {
        height: 20px;
        width: auto;
      }
    }
  }

  .menu {
    flex: 1;

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${Breakpoints.sm}) {
      display: ${({showMenu}) => (showMenu ? 'block' : 'none')};
      margin-top: 48px;
    }

    .menu-items {
      flex: 1;

      display: flex;
      align-items: center;

      list-style: none;
      margin: 0;

      @media only screen and (max-width: ${Breakpoints.sm}) {
        flex-direction: column;
        align-items: stretch;
      }

      li {
        margin: 0 5%;
        box-sizing: border-box;

        @media only screen and (max-width: ${Breakpoints.sm}) {
          margin: 10px 0;
        }
      }
    }

    .user-menu {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media only screen and (max-width: ${Breakpoints.sm}) {
        margin-top: 12px;
      }

      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }
  }
`

export interface MenuProps {
  active?: boolean
}

export const Menu = styled(Link)<MenuProps>`
  text-decoration: none;
  color: ${({active}) => (active ? Colors.BLACK : Colors.GREY)};
  background-color: ${({active}) =>
    active ? Colors.LIGHT_GREY : 'transparent'};

  padding: 8px 12px;
  border-radius: 6px;

  font-size: 16px;
  line-height: 28px;

  :hover {
    color: ${Colors.BLACK};
    background-color: ${Colors.LIGHT_GREY};
  }
`
