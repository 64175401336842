import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'
import Colors from 'styles/colors'

import Card from 'components/Card'

export const SelectDealCard = styled(Card)`
  @media only screen and (min-width: ${Breakpoints.sm}) {
    max-width: 600px;
  }
`

export const StatisticsCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 32px;

  @media only screen and (max-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: ${Breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`

export const StatisticsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .value {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${Colors.BLACK};
  }

  .label {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: ${Colors.GREY};
  }
`
