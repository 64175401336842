import styled from 'styled-components'

import Colors from 'styles/colors'
import Breakpoints from 'styles/breakpoints'

import Card from 'components/Card'

export const WelcomeCard = styled(Card)`
  overflow: hidden;

  @media (min-width: 1466px) {
    height: 160px;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
`

export const WelcomeTextContainer = styled.div`
  max-width: 448px;
  padding: 24px;
`

export const WelcomeTitle = styled.h1`
  margin: 0;
  margin-bottom: 16px;
  font-size: 26px;
  line-height: 40px;
  font-weight: 600;
`

export const WelcomeDescription = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  color: ${Colors.GREY};
`

export const WelcomeGraphics = styled.img`
  display: none;

  @media (min-width: ${Breakpoints.sm}) {
    display: block;
  }
  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
  @media (min-width: 1338px) {
    display: block;
  }

  @media (min-width: 1466px) {
    height: 160px;
  }
`
