import {useState, useEffect} from 'react'

import {getDealInterests} from 'services/deal-interests'
import {getDeals} from 'services/deals'
import {
  DATAROOM_STATUS,
  DEAL_INTEREST_STATUS,
} from 'config/data/deal-interest.config'
import {DealInterest, MessageProps} from 'types'

export type SelectDeal = {
  label: string // deal name
  value: string // deal slug
}

type Counters = {
  'Invited to Deal': number
  'Signed NDA': number
  'Requested Deck Access': number
  'Submitted Indicative Offer': number
  'Access to Dataroom': number
  'Submitted Final Offer': number
  [key: string]: number
}

type Analytic = {value: number; label: string}

const useAnalytics = () => {
  const [error, setError] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  const [loading, setLoading] = useState(false)

  const [analytics, setAnalytics] = useState<Analytic[]>([])

  const [deal, setDeal] = useState<SelectDeal | null>(null)
  const [deals, setDeals] = useState<SelectDeal[]>([])

  useEffect(() => {
    const fetchDeals = async () => {
      setLoading(true)

      try {
        const results = (await getDeals())?.map(({name, id}) => {
          return {label: name, value: id}
        })

        setDeals(results)
      } catch (error) {
        setError({...error, value: error.message})
      }

      setLoading(false)
    }

    fetchDeals()
  }, [])

  useEffect(() => {
    const createAnalytics = (di: DealInterest[]) => {
      const counters: Counters = {
        'Invited to Deal': di.length,
        'Signed NDA': di.filter(({hasSignedNDA}) => hasSignedNDA).length,
        'Requested Deck Access': di.filter(
          ({status, hasSignedNDA}) =>
            status === DEAL_INTEREST_STATUS.DECK_REQUESTED || hasSignedNDA
        ).length,
        'Submitted Indicative Offer': di.reduce((result, item) => {
          return (result += item.nbIndicativeOffers ?? 0)
        }, 0),
        'Access to Dataroom': di.filter(
          ({dataroomRequestStatus}) =>
            dataroomRequestStatus === DATAROOM_STATUS.APPROVED
        ).length,
        'Submitted Final Offer': di.filter(
          ({hasMadeFinalOffer}) => hasMadeFinalOffer
        ).length,
      }

      const stats: Analytic[] = Object.keys(counters).map(key => {
        return {label: key, value: counters[key]}
      })

      return stats
    }

    const fetchAnalytics = async () => {
      setLoading(true)

      try {
        let results: Analytic[] = []

        const id = deal?.value
        if (id) {
          const dealInterests = await getDealInterests(id)
          results = createAnalytics(dealInterests)
        }

        setAnalytics(results)
      } catch (error) {
        setError({...error, value: error.message})
      }

      setLoading(false)
    }

    fetchAnalytics()
  }, [deal])

  return {analytics, deals, deal, error, loading, setDeal, setError}
}

export default useAnalytics
