import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import {autoScrollTop} from 'utils/pages'

import Button from 'components/styles/Button'
import ContentContainer from 'components/ContentContainer'

import {PrivacyContainer} from './styles'

const PrivacyPolicy: React.FC = () => {
  const history = useHistory()

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    autoScrollTop()
  }, [])

  return (
    <ContentContainer>
      <Button onClick={handleGoBack}>Back</Button>

      <PrivacyContainer>
        <h1>PRIVACY AND COOKIES POLICY</h1>

        <p className="header text-12 grey justify">STATEMENT OF INTENT</p>

        <p className="text-12 grey justify">
          This Privacy and Data Use Policy ("Privacy Policy") of Noble & Company
          (UK) Limited (“Noble & Co”, “we” or “us”) explains how and why we
          collect personal information about you and how we can use your
          information. By visiting our website (capital.nobleandcompany.com)
          ("site") and using our services you agree to this Privacy Policy and
          Terms of Conditions.
        </p>

        <p className="text-12 grey justify">
          We are committed to responsible management of personal information in
          accordance with the Data Protection Act 2018, the General Data
          Protection Regulation and the Privacy and Electronic Communications
          (EC Directive) Regulations 2003 (as amended). Please note that the
          registration number of Noble & Company (UK) Limited on the UK Data
          Protection Register is Z3231478 (see http://www.ico.gov.uk).
        </p>

        <p className="text-12 grey justify">
          Noble & Co is authorised and regulated by the Financial Conduct
          Authority (FCA) (Registration Number 586297).
        </p>

        <p className="text-12 grey justify">
          COLLECTION OF PERSONAL INFORMATION
        </p>

        <p className="text-12 grey justify">
          In order to receive certain information and to use certain services on
          this site you may be asked to provide personal information about
          yourself such as your name, email address, country of residence,
          address, date of birth, bank account number, investment preferences
          and in some circumstances, copies of information such as your passport
          or other proof of ID.
        </p>

        <p className="text-12 grey justify">
          When you provide this information to us you consent to our use of that
          information in accordance with the terms of this Privacy Policy. You
          have the right to withdraw or amend your consent at any time, however
          we may not be able to provide you with some or all the services
          offered on our Website.
        </p>

        <p className="text-12 grey justify">
          In addition, if you contact us, we may keep a copy of any
          correspondence; and we may record details of your visits to this site
          including, but not limited to, traffic data, location data, weblogs
          and other communication data, and the resources you access (for
          example, this may include records of which investment deals uploaded
          to the site you have had access to). Please also see INFORMATION ABOUT
          OUR USE OF COOKIES below.
        </p>

        <p className="text-12 grey justify">
          This site logs your Internet Protocol (I.P.) address. All computers
          that are linked to the internet have an I.P. address. An I.P. address
          does not provide identifiable personal information.
        </p>

        <p className="text-12 grey justify">
          We will use the information that we obtain from you in accordance with
          the provisions set out in USE OF AND SHARING OF INFORMATION below.
        </p>

        <p className="text-12 grey justify">STORAGE OF PERSONAL INFORMATION</p>

        <p className="text-12 grey justify">
          We will keep your personal information for as long as we need it to
          provide our services to you. If you make an investment in a company
          which is listed on this site then we will hold your personal
          information for a period of 7 years from the date of your last
          investment so that we can comply with our legal obligations.
        </p>

        <p className="text-12 grey justify">
          We will try to keep personal information which we hold about you up to
          date, but if you think that we are holding information which is
          inaccurate or you are otherwise unhappy about our use of your personal
          information then please send us an email (capital@nobleandcompany.com)
          to let us know.
        </p>

        <p className="text-12 grey justify">
          The data that we collect from you may be transferred to, and stored
          at, a destination outside the European Economic Area ("EEA"), where we
          are legally permitted to do so. It may also be processed by staff
          operating outside the EEA who work for us or for one of our suppliers,
          where we are legally permitted to do so. Such staff may be engaged in
          the provision of support services. By submitting your personal data,
          you agree to this transfer, storing and processing. We will take all
          steps reasonably necessary to ensure that your data is treated
          securely and in accordance with this Privacy Policy.
        </p>

        <p className="text-12 grey justify">
          All information you provide to us or our third party suppliers is
          stored on secure servers. Where we have given you (or where you have
          chosen) a password which enables you to access certain parts of this
          site, you are responsible for keeping this password confidential.
          Please do not share this password with anyone.
        </p>

        <p className="text-12 grey justify">
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted to this site; any transmission is at your own risk. Once
          we have received your information, we will use strict procedures and
          security features to try to prevent unauthorised access.
        </p>

        <p className="text-12 grey justify">
          USE, PROCESSING OF, AND SHARING OF INFORMATION
        </p>

        <p className="text-12 grey justify">
          Use and processing of your information
        </p>

        <p className="text-12 grey justify">
          We may use your personal information for the following purposes:
        </p>

        <ul>
          <li>
            to provide you with the services that you have requested (such as
            access to information on potential investment deals);
          </li>
          <li>
            to provide you with news and information about potential investee
            companies, whether information on these companies is available on
            our site or available from us through some other medium;
          </li>
          <li>
            to provide you with information on products or services that we feel
            may interest you, both offered by or through us and from third
            parties, but only where you have consented to being contacted for
            such purposes;
          </li>
          <li>
            to ensure that content from our site is presented in the most
            effective manner for you and for your computer or device;
          </li>
          <li>to fulfil any obligations between you and us;</li>
          <li>
            to generally assist with the facilitation (by use of a platform but
            not by giving investment advice) and completion of the investments
            which users of this site wish to make in investee companies which
            are listed on this site;
          </li>
          <li>
            to allow you to participate in interactive features of our service,
            when you choose to do so;
          </li>
          <li>
            to create and manage accounts necessary to access certain areas of
            our site; and/or
          </li>
          <li>to notify you about changes to our service.</li>
        </ul>

        <p className="text-12 grey justify">Lawful basis for processing</p>

        <p className="text-12 grey justify">
          We collect and process your personal information in the ways described
          above as necessary to fulfil our website’s Terms of Use and consistent
          with your consent for using the services provided by our Website when
          registering and creating an account. The processing of some
          information also satisfies a legitimate interest overriding your data
          protection interests such as for the purposes of promoting our
          business and further development and of the services provided by our
          Website. In other cases, the data we process about you will be
          necessary for us to comply with our legal obligations or to fulfil our
          contractual obligations to you or to our investee clients. You have
          the right to withdraw or restrict your consent at any time, however we
          may not be able to provide you with some or all the services our
          Website offers.
        </p>

        <p className="text-12 grey justify">
          Use of your information in respect of third parties
        </p>

        <p className="text-12 grey justify">
          We may disclose your personal information to members of our group,
          which means our subsidiaries, our ultimate holding company and its
          subsidiaries, as defined in section 1159 of the UK Companies Act 2006.
        </p>

        <p className="text-12 grey justify">
          We will NOT sell the personal information that we collect from you.
          Some data processing in relation to this site may be carried out for
          us by a third party, and in some cases that may mean that the third
          party will receive your personal information. Furthermore, as part of
          you making an investment in a company which is listed on this site,
          the investee company, the company's professional advisors and our
          solicitors may be provided with your personal information as well as
          copies of your personal identity documents to enable the investee
          company to issue securities to you and to generally comply with the
          law and perform personal identity checks of its own.
        </p>

        <p className="text-12 grey justify">
          We may also disclose your personal information to third parties in the
          following circumstances:
        </p>

        <ul>
          <li>
            in the event that we sell or buy any business or assets, in which
            case we may disclose your personal data to the prospective seller or
            buyer of such business or assets (although this will be done under
            obligations of confidentiality);
          </li>
          <li>
            if the shares of Noble & Co or substantially all of its assets are
            acquired by a third party, in which case personal data held by it
            about its users, investors and investee companies will be one of the
            transferred assets (although again this will be done under
            obligations of confidentiality);
          </li>
          <li>
            if we are under a duty to disclose or share your personal data in
            order to comply with any legal obligation or request from a relevant
            regulator (e.g. in the UK to the Police, the Financial Conduct
            Authority, the Information Commissioner etc.);
          </li>
          <li>
            in order to enforce or apply any of our website terms of use, the
            investor terms and conditions, or the investee terms and conditions,
            or any other contracts entered into between you and us, or to
            enforce any other rights that we have against you; and/or
          </li>
          <li>
            in order to protect the rights, property, or safety of Noble & Co,
            our users, or others (this includes without limitation exchanging
            information with other companies and organisations for the purposes
            of fraud protection and credit risk reduction).
          </li>
        </ul>

        <p className="header text-12 grey justify">YOUR LEGAL RIGHTS</p>

        <p className="text-12 grey justify">
          You are entitled under the Data Protection Act 2018 and the General
          Data Protection Regulation to:
        </p>

        <ul>
          <li>
            Know about the personal information we hold about you, to request
            access to, correction, or deletion of that information.
          </li>
          <li>
            Request that we stop collecting or holding data about you or that
            the data is restricted, or transferred{' '}
          </li>
          <li>Complain to the Information Commissioner’s Office.</li>
        </ul>

        <p className="text-12 grey justify">
          If you would like to exercise any of these rights, please write to us
          at:
        </p>

        <p className="text-12 grey justify data">The Data Protection Officer</p>

        <p className="text-12 grey justify">
          Noble & Company (UK) Limited <br />
          95A George Street
          <br />
          Edinburgh <br />
          EH2 3ES
          <br />
          United Kingdom
        </p>

        <p className="text-12 grey justify">
          Or let us know by email at: capital@nobleandcompany.com
        </p>

        <p className="header text-12 grey justify">LINKS</p>

        <p className="text-12 grey justify">
          This site may provide links to other websites. Our Privacy Policy
          applies only to this site and we are not responsible for the privacy
          and/or data capture and use practices of any third party sites.
        </p>

        <p className="header text-12 grey justify">
          CHANGES TO OUR PRIVACY AND DATA USE POLICY
        </p>

        <p className="text-12 grey justify">
          Any changes we may make to this Policy in the future will be uploaded
          to our website, so please check the Privacy Policy regularly.
        </p>

        <p className="header text-12 grey justify">CONTACT</p>

        <p className="text-12 grey justify">
          Questions, comments and requests regarding this Privacy Policy are
          welcomed and should be addressed to capital@nobleandcompany.com
        </p>

        <p className="header text-12 grey justify">
          INFORMATION ABOUT OUR USE OF COOKIES
        </p>

        <p className="text-12 grey justify">
          This site (capital.nobleandcompany.com) uses cookies to distinguish
          you from other users of this site. This helps us to provide you with a
          good experience when you browse this site and also allows us to
          improve our site.{' '}
        </p>

        <p className="text-12 grey justify">
          This Cookies Policy provides you with information about the cookies
          Noble & Company (UK) Limited ("we" or "us") uses and the purposes for
          using them.
        </p>

        <p className="header text-12 grey justify">What is a cookie?</p>

        <p className="text-12 grey justify">
          A cookie is a small file of letters and numbers that we store on your
          browser or the hard drive of your computer. Unless you have adjusted
          your browser setting so that it will refuse cookies, our system will
          issue cookies as soon as your visit our site.
        </p>

        <p className="text-12 grey justify">
          Cookies contain information that is transferred to your computer's
          hard drive.
        </p>

        <p className="header text-12 grey justify">
          What cookies do we use and why?
        </p>

        <p className="text-12 grey justify">We use the following cookies:</p>

        <ul>
          <li>
            <i>Strictly-necessary cookies.</i> These are cookies that are
            required for the operation of this site. They include, for example,
            cookies that enable you to log into secure areas of this site. If
            these cookies are blocked it may affect the functionality of the
            site.
          </li>
          <li>
            <i> Analytical/performance cookies.</i>
            They allow us to recognise and count the number of visitors that use
            our site and to see how visitors move around this site when they are
            using it. This helps us to improve the way this site works, for
            example, by ensuring that users can find what they are looking for
            easily.
          </li>
          <li>
            <i> Functionality cookies.</i>These are used to recognise you when
            you return to this site. This enables us to personalise our content
            for you, greet you by name and remember your preferences (for
            example, your choice of language or region).
          </li>
          <li>
            <i>Targeting cookies.</i>These cookies record your visit to this
            site, the pages you have visited and the links you have followed. We
            will use this information to make this site and the advertising
            displayed on it more relevant to your interests. We may also share
            this information with third parties to help us determine which of
            our advertisements are most likely to be of interest to you.{' '}
          </li>
        </ul>

        <p className="header text-12 grey justify">Third-party cookies</p>

        <p className="text-12 grey justify">
          Please note that third parties (including, for example, advertising
          networks and providers of external services like web traffic analysis
          services) may also use cookies, over which we have no control. These
          cookies are likely to be analytical/performance cookies or targeting
          cookies.
        </p>

        <p className="header text-12 grey justify">How to block cookies</p>

        <p className="text-12 grey justify">
          You can block cookies by activating the setting on your browser that
          allows you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (in particular
          strictly-necessary cookies) you may not be able to access all or parts
          of this site.
        </p>

        <p className="header text-12 grey justify">I.P. Address</p>

        <p className="text-12 grey justify">
          This site logs your Internet Protocol (I.P.) address. All computers
          that are linked to the internet have an I.P. address. An I.P. address
          does not provide identifiable personal information.
        </p>

        <p className="header text-12 grey justify">
          Changes to this Cookies Policy
        </p>

        <p className="text-12 grey justify">
          Any changes we may make to this Cookies Policy in the future will be
          uploaded to our website, so please check the policy regularly.
        </p>

        <p className="header text-12 grey justify">Contact</p>

        <p className="text-12 grey justify">
          Questions, comments and requests regarding our privacy policy or use
          of cookies are welcomed and should be addressed to
          capital@nobleandcompany.com
        </p>

        <p className="footer text-12 grey justify">
          Noble & Company (UK) Limited is authorised and regulated by the
          Financial Conduct Authority (FCA) (Registration Number 586297)
        </p>
      </PrivacyContainer>
    </ContentContainer>
  )
}

export default PrivacyPolicy
