import React, {useState} from 'react'
import {Hamburger, User, Entrance} from 'react-ikonate'
import {useHistory, useLocation} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'
import {useAuth} from 'hooks/useAuth'
import {firebaseSignOut} from 'lib/firebase/auth'

import Button from 'components/styles/Button'
import Logo from 'components/Logo'

import {HeaderContainer, Menu} from './styles'

const Header: React.FC = () => {
  const {isAdmin, isAuth} = useAuth()

  const history = useHistory()
  const {pathname} = useLocation()

  const [showMenu, setShowMenu] = useState(false)

  const handleLogout = async () => {
    await firebaseSignOut()
    history.push(ROUTES.HOME)
  }

  return (
    <HeaderContainer
      id="header-nav"
      showMenu={showMenu}
      isAuth={isAuth}
      isAdmin={isAdmin}
    >
      <div className="top">
        <Logo className="logo" onClick={() => history.push(ROUTES.HOME)} />

        <Hamburger
          className="hamburger"
          onClick={() => setShowMenu(!showMenu)}
        />
      </div>

      <div className="menu">
        {isAuth && (
          <>
            {/* Admin Menu Items */}
            {isAdmin && (
              <ul className="menu-items">
                <li>
                  <Menu
                    to={ROUTES.USERS}
                    active={pathname.includes(ROUTES.USERS)}
                  >
                    Users
                  </Menu>
                </li>

                <li>
                  <Menu
                    to={ROUTES.DEALS}
                    active={pathname.includes(ROUTES.DEALS)}
                  >
                    Deals
                  </Menu>
                </li>

                <li>
                  <Menu
                    to={ROUTES.ANALYTICS}
                    active={pathname.includes(ROUTES.ANALYTICS)}
                  >
                    Analytics
                  </Menu>
                </li>
              </ul>
            )}

            {/* Extra Menu Items (Right Side) */}
            <div className="user-menu">
              {!isAdmin && (
                <Button
                  styleless
                  onClick={() => history.push(ROUTES.USER_SETTINGS)}
                  flex
                >
                  <User
                    style={{
                      marginRight: 8,
                    }}
                  />
                  Account
                </Button>
              )}

              <Button styleless onClick={handleLogout} flex>
                <Entrance
                  style={{
                    marginRight: 8,
                  }}
                />
                Logout
              </Button>
            </div>
          </>
        )}
      </div>
    </HeaderContainer>
  )
}

export default Header
