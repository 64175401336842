import React from 'react'
import {Route, Switch} from 'react-router-dom'

import {ROUTES} from 'config/routes.config'

import Disclaimer from 'pages/Disclaimer'
import PageNotFound from 'pages/NotFound'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import TermsOfUse from 'pages/TermsOfUse'

import AuthLayout from 'layouts/AuthLayout'
import Maintenance from 'components/Maintenance'

const MaintenanceRoutes: React.FC = () => {
  return (
    <AuthLayout>
      <Switch>
        <Route path="/">
          <Maintenance />
        </Route>

        <Route exact path={ROUTES.DISCLAIMER}>
          <Disclaimer />
        </Route>

        <Route exact path={ROUTES.PRIVACY_POLICY}>
          <PrivacyPolicy />
        </Route>

        <Route exact path={ROUTES.TERMS_OF_USE}>
          <TermsOfUse />
        </Route>

        <PageNotFound />
      </Switch>
    </AuthLayout>
  )
}

export default MaintenanceRoutes
