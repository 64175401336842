import styled, {css} from 'styled-components'

import Colors from 'styles/colors'

const defaultStyling = css`
  border: 1px solid ${Colors.LIGHT_GREY};
  background-color: ${Colors.WHITE};
  box-sizing: border-box;
  border-radius: 5px;
`
const neatStyling = css`
  border: none;
`

interface DetailsProps {
  neat?: boolean
}

export const Details = styled.div<DetailsProps>`
  ${({neat}) => (neat ? neatStyling : defaultStyling)};

  .overview {
    .overview-title {
      padding: 20px 24px;
      margin: 0;
      border-bottom: 1px solid ${Colors.GREY_200};
      font-size: 26px;
      line-height: 40px;
      font-weight: 600;
    }
  }

  .deal-info {
    padding: 20px 24px;

    .image {
      width: 100%;
      height: 367px;
      object-fit: cover;
      border-radius: 5px;
      margin-bottom: 24px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .name {
        .deal-name {
          margin: 0;
          font-size: 21px;
          line-height: 32px;
          font-weight: 600;
        }

        .deal-company {
          margin-top: 4px;
          margin-bottom: 16px;
          font-size: 18px;
          line-height: 21px;
        }
      }
    }

    .content {
      width: 100%;

      .badges {
        margin-bottom: 22px;

        > span:not(:first-child) {
          margin-left: 24px;
        }
      }

      .deal-content-description {
        font-size: 16px;
        line-height: 28px;
        margin: 0;
        margin-bottom: 12px;
      }

      .buttons {
        display: flex;

        > button {
          :not(:first-child) {
            margin-left: 18px;
          }
        }
      }
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 0.5fr 0.5fr 0.5fr;
  max-width: 100%;
  margin-bottom: 24px;

  > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`

export const DismissModalText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  color: ${Colors.GREY};
`

export const DismissModalFeedback = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px;

  .dismiss-deal-feedback-other {
    display: flex;
    flex-direction: column;
  }
`

export const DismissModalButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 18px;
`
