import React from 'react'

import {DealStatus as DealStatusType} from 'types'

import {StatusContainer} from './styles'

export interface StatusProps {
  status: DealStatusType
  small?: boolean
}

const DealStatus: React.FC<StatusProps> = ({status, small}) => {
  const getStatus = () => {
    switch (status?.toLocaleLowerCase()) {
      case 'draft':
        return 'Draft'
      case 'published':
        return 'Live'
      case 'closed':
        return 'Closed'
      default:
        return ''
    }
  }

  return (
    <StatusContainer
      {...{
        status,
        small,
      }}
    >
      <label>{getStatus()}</label>
    </StatusContainer>
  )
}

export default DealStatus
