import styled, {css} from 'styled-components'

import Colors from 'styles/colors'
import {DealInterestStatus, DealStatus, EmailDeliveryStatus} from 'types'

export type BadgeStatus =
  | 'warning'
  | 'success'
  | 'error'
  | 'in_progress'
  | 'draft'
  | 'published'
  | 'closed'
  | 'grey'
  | string
  | undefined

const greyStyling = css`
  background-color: ${Colors.LIGHT_GREY};
  color: ${Colors.GREY};
`

const warningStyling = css`
  background-color: ${Colors.BACKGROUND_WARNING};
  color: ${Colors.WARNING_700};
`

const successStyling = css`
  background-color: ${Colors.BACKGROUND_SUCCESS};
  color: ${Colors.SUCCESS_600};
`

const errorStyling = css`
  background-color: ${Colors.BACKGROUND_ERROR};
  color: ${Colors.ERROR_600};
`

const defaultStyling = css`
  background-color: ${Colors.BACKGROUND_DEAL_TYPE};
  color: ${Colors.BLUE};
`

export const dealStatusToBadgeStatus = (
  dealStatus: DealStatus
): BadgeStatus => {
  switch (dealStatus) {
    case 'CLOSED':
      return 'grey'
    case 'DRAFT':
      return 'warning'
    case 'PUBLISHED':
      return 'success'
    default:
      return undefined
  }
}

export const dealInterestStatusToBadgeStatus = (
  dealInterestStatus: DealInterestStatus
): BadgeStatus => {
  switch (dealInterestStatus) {
    case 'TEASER_STAGE':
      return 'grey'
    case 'NDA_REQUESTED':
      return 'warning'
    case 'NDA_STAGE':
      return undefined
    case 'DECK_REQUESTED':
      return 'warning'
    case 'DECK_STAGE':
      return undefined
    case 'INDICATIVE_OFFER_PROPOSED':
      return 'warning'
    case 'DATAROOM_STAGE':
      return undefined
    case 'FINAL_OFFER_PROPOSED':
      return 'warning'
    case 'INVESTED':
      return 'success'
    case 'NOT_INVESTED':
      return 'grey'
    default:
      return undefined
  }
}

export const emailDeliveryStatusToBadgeStatus = (
  emailDeliveryStatus: EmailDeliveryStatus
): BadgeStatus => {
  switch (emailDeliveryStatus) {
    case 'BOUNCED':
      return 'error'
    case 'DEFERRED':
      return 'warning'
    case 'DELIVERED':
      return 'success'
    case 'DROPPED':
      return 'error'
    case 'NOT_SENT':
      return 'warning'
    case 'SENDING':
      return 'success'
    case 'OPENED':
      return 'success'
    default:
      return undefined
  }
}

const getStyleFromStatus = (status?: string) => {
  switch (status) {
    case 'warning':
    case 'in_progress':
    case 'draft':
    case 'closed':
      return warningStyling
    case 'success':
    case 'published':
      return successStyling
    case 'error':
      return errorStyling
    case 'grey':
      return greyStyling
    default:
      return defaultStyling
  }
}

export interface BadgeProps {
  status?: BadgeStatus
  size?: 'small' | 'default'
}

const Badge = styled.span<BadgeProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({size}) => (size === 'small' ? '2px 10px' : '0.375rem 1rem')};

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  height: fit-content;
  border-radius: 4px;

  ${({status}) => (status ? getStyleFromStatus(status) : defaultStyling)};
`

export default Badge
