import {EventType} from 'types'

type Action = {[key: string]: EventType}

export const USER_ACTIONS: Readonly<Action> = Object.freeze({
  DATAROOM_ACCESS_APPROVED: 'dataroom-access-approved',
  DATAROOM_ACCESS_DENIED: 'dataroom-access-denied',
  DATAROOM_ACCESS_REVOKED: 'dataroom-access-revoked',
  DATAROOM_ACCESS_REQUESTED: 'dataroom-access-requested',
  TEASER_VIEW: 'teaser-view',
  DECK_VIEW: 'deck-view',
  DEAL_INVITATION_SENT: 'deal-invitation-sent',
  DEAL_NDA_SIGNED: 'deal-nda-signed',
  COMFORT_NOTES_VIEWED: 'comfort-notes-viewed',
  COMFORT_NOTES_SIGNED: 'comfort-notes-signed',
})
