export const LONG_TIMEOUT = 20000
export const TIMEOUT = 5000
export const SHORT_TIMEOUT = 2000

export const timeouts = {
  small: 1000 * 1.5,
  normal: 1000 * 2,
  normalx2: 1000 * 4,
  medium: 1000 * 10,
  long: 1000 * 30,
  minute: 1000 * 60,
  logout: 1000 * 60 * 30, // Session Expiration 30min
}
