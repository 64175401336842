import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import {autoScrollTop} from 'utils/pages'

import Button from 'components/styles/Button'
import ContentContainer from 'components/ContentContainer'

import {TermsContainer} from './styles'

const TermsOfUse: React.FC = () => {
  const history = useHistory()

  const handleGoBack = () => history.goBack()

  useEffect(() => autoScrollTop(), [])

  return (
    <ContentContainer>
      <Button onClick={handleGoBack}>Back</Button>

      <TermsContainer>
        <h1>TERMS OF USE (“Terms”)</h1>

        <p className="text-12 grey justify">Last updated: September 2020</p>

        <p className="text-12 grey justify">
          Welcome to the capital.nobleandcompany.com website (“site” or
          “website”). This website is controlled by Noble & Company (UK) Limited
          with its registered office at 95A George Street, Edinburgh, EH2 3ES,
          United Kingdom (hereinafter referred to as “Noble & Co”, “we” or
          “us”). The use of this site is subject to the following conditions and
          applies to each person who uses this site (each such person an
          “Investor”.)
        </p>

        <p className="text-12 grey justify">
          Noble & Co is authorised and regulated by the Financial Conduct
          Authority (FCA) (Registration Number 586297).
        </p>

        <p className="text-12 grey justify">
          Please read the undernoted conditions carefully before you use this
          site. By using any part of this site you will be held to have accepted
          that you will be bound by these conditions (whether or not you have
          registered for an account with us). Noble & Co may change these
          conditions at any time by posting new or amended conditions on this
          site. Your continued use of this site after any changes are posted
          means that you are held to have accepted that you are bound by the new
          or amended conditions. Please therefore check our conditions
          regularly.
        </p>

        <p className="text-12 grey justify">
          Each Investor who uses this website is required to acknowledge and
          agree to these conditions, as well as the Privacy and Cookies Policy.
          These should be read carefully and if an Investor is in any doubt then
          he should take his own professional advice.
        </p>

        <p className="text-12 grey justify">
          Moreover, please note our full Disclaimer, which relates to the
          content posted on this site in relation to potential investment
          opportunities with our investee companies. This Disclaimer is hereby
          incorporated into these conditions. As such, any Investor who uses
          this site acknowledges and agrees to the restrictions on liability
          contained in the Disclaimer.
        </p>

        <p className="header text-12 grey justify">PERMITTED AUDIENCE</p>

        <p className="text-12 grey justify">
          The information contained on this website is not intended for or
          directed towards the general public. In order to register and access
          information on the website, you must certify that you fall into one of
          the Investor categories described below (required at registration):
        </p>

        <ul>
          <li>Investment Professional;</li>
          <li>High Net Worth Company;</li>
          <li>Certified High Net Worth Individual; or</li>
          <li>Self-certified Sophisticated Investor.</li>
        </ul>

        <p className="text-12 grey justify">
          Full information on the definitions of the above categories will be
          provided at registration. Where steps must be carried out for an
          Investor before the Investor falls within the relevant category (such
          as the signing of prescribed certificates), these steps must be fully
          completed. Any Investor who does not fall into one of the above
          categories must leave the site immediately and must not continue to
          use the website.
        </p>

        <p className="text-12 grey justify">
          There are areas of the website that you may not access without
          registering for an account with us. You must not attempt to access
          such areas of the website without having first complied with the
          account-opening procedure.
        </p>

        <p className="text-12 grey justify">
          Noble & Co reserves the right in its absolute discretion (i) not to
          accept any application by any person to register for an account on the
          website in any circumstances, including where Noble & Co is not
          satisfied with any of the information provided or
          declarations/certifications by the Investor (for the avoidance of
          doubt, Noble & Co is not obliged or under any duty to make further
          inquiries in relation to any of the Investor information provided),
          and (ii) to de-register (or procure the de-registration) of the
          Investor from the site at any time and for any reason. There shall be
          no cause of action open to the relevant person/Investor because access
          has not been granted to the Investor, or been taken away from that
          Investor.
        </p>

        <p className="header text-12 grey justify">
          GENERAL WEBSITE DISCLAIMERS & TERMS
        </p>

        <p className="text-12 grey justify">
          Materials relating to investee companies
        </p>

        <p className="text-12 grey justify">
          This site content is provided for information purposes only without
          any warranty, express or implied, that the content is accurate,
          current or complete or fit for a particular purpose. Accordingly,
          materials provided on the website, whether posted in relation to
          investee companies or otherwise, which might take effect as
          representations, warranties, conditions or other terms if it were not
          for this clause, are excluded to the extent permissible by applicable
          law.
        </p>

        <p className="text-12 grey justify">
          Before investing in any investee company whose details are displayed
          on our site, it is the Investor's obligation to carry out his own
          investigation into the affairs of that company and to seek his own
          professional advice in respect of any proposed investment. To the
          extent permissible by law, we exclude all liability incurred by an
          Investor placing reliance on the materials posted on our site.
        </p>

        <p className="text-12 grey justify">Loss incurred by viruses etc.</p>

        <p className="text-12 grey justify">
          We will not be liable for any loss or damage to an Investor or third
          party caused by a virus or denial of service that may infect your
          computer hardware or programs, data or other proprietary material due
          to your use of this site and/or your downloading of any material
          posted on it (whether belonging to Noble & Co or not). We do not
          warrant that this website is free from virus or other malware. We
          recommend that you use anti-virus software on your computer or device
          for your protection.
        </p>

        <p className="text-12 grey justify">No investment advice</p>

        <p className="text-12 grey justify">
          Noble & Co does not provide investment advice to Investors/users of
          this site. Commentary by Noble & Co and other materials posted by
          Noble & Co on this site are not intended to amount to advice by Noble
          & Co or by any of its directors, agents, employees or members. We are
          not liable or responsible for any reliance placed on such materials by
          you or any other person.
        </p>

        <p className="text-12 grey justify">
          No guarantee of website availability
        </p>

        <p className="text-12 grey justify">
          Noble & Co will not be liable for any damages or loss of any kind
          resulting from the use of, or inability to use, this site or its
          content. No guarantee is given that this site will be available 24/7
          and Noble & Co may suspend access to the website temporarily or
          permanently without giving notice or reason. In addition, Noble & Co
          may make changes to the material on the website at any time without
          notice. The material on the website may be out of date and Noble & Co
          makes no commitment to update such material.{' '}
        </p>

        <p className="text-12 grey justify">Links to third party websites</p>

        <p className="text-12 grey justify">
          This site may contain links to other websites. Noble & Co does not
          approve or endorse, and accepts no liability for the content of any
          third party websites which may be accessed via, or linked to, this
          site. The existence of a link to another website is not an
          authorisation by Noble & Co for you to access content or material from
          that other website - this is a matter between you and the operator of
          that other website.
        </p>

        <p className="header text-12 grey justify">RESTRICTIONS ON USERS</p>

        <p className="text-12 grey justify">
          This section sets out obligations of, deemed representations by, and
          restrictions on the Investor as a user of this website - please read
          them carefully.
        </p>

        <p className="text-12 grey justify">Restrictions on users</p>

        <p className="text-12 grey justify">
          It is prohibited for an Investor to:
        </p>

        <ul>
          <li>
            attempt to gain unauthorised access to the site or any part of the
            site;
          </li>
          <li>
            transmit any material which is technically harmful (including,
            without limitation, computer viruses, logic bombs, Trojan horses,
            worms, harmful components, corrupted data or other malicious
            software or harmful data);
          </li>
          <li>
            transmit any material which is illegal, threatening, defamatory,
            abusive, offensive, in breach of confidence, in breach of privacy,
            or which the Investor has not obtained all the necessary licenses
            and/or approvals for;
          </li>
          <li>
            engage in any conduct whilst using or in relation to the site which
            constitutes a criminal offence, could give rise to civil liability
            or is otherwise contrary to any law in the UK or other relevant law;
          </li>
          <li>
            decompile, reverse-engineer or disassemble any part of the site or
            its contents, or any software used in connection with the site;
          </li>
          <li>
            misuse the site by hacking or otherwise interfering with or
            disrupting the site; or
          </li>
          <li>
            use the site for any commercial, profit-making or re-sale purposes
            (other than those commercial purposes engaged in with or through
            Noble & Co), including the extraction and/or re-utilisation of any
            part of the site or its content.
          </li>
        </ul>

        <p className="text-12 grey justify">Confidential information</p>

        <p className="text-12 grey justify">
          Information contained on this site is confidential and should not be
          passed on or otherwise made available to any third party, other than
          professional advisors of the Investor who are also bound by
          obligations of confidentiality. This restriction shall not however
          restrict the Investor from passing on the site domain or other
          information to other persons as to the existence of this website, such
          as other potential Investors. Furthermore, an Investor may pass on
          information contained on this site with the prior consent of Noble &
          Co, or to any person where required to do so by law.
        </p>

        <p className="text-12 grey justify">
          Accuracy of information provided by Investor
        </p>

        <p className="text-12 grey justify">
          The Investor represents, warrants and agrees that: (i) all Investor
          information provided by the Investor (during the registration process
          and subsequently) is correct and accurate; (ii) such Investor
          Information may be used by Noble & Co to comply with all applicable
          legal requirements (e.g. anti-money laundering checks and compliance);
          (iii) such Investor information may be passed by Noble & Co to each
          company in which the Investor decides to make an Investment (to enable
          the company to carry out its own checks and ensure legal compliance);
          and (iv) the Investor shall promptly update Noble & Co via email
          (capital@nobleandcompany.com), of any changes to the Investor
          information to ensure that such Investor Information is correct and
          accurate.
        </p>

        <p className="text-12 grey justify">
          If, at any date following registration, the Investor no longer fulfils
          the criteria for the Investor category that it fell within at the
          point of registration, the Investor must inform Noble & Co and cease
          use of the website immediately.
        </p>

        <p className="text-12 grey justify">
          Please also see our Privacy Policy, which sets out further ways which
          Noble & Co may use the Investor's information.
        </p>

        <p className="header text-12 grey justify">COPYRIGHT.</p>

        <p className="text-12 grey justify">
          All rights, including copyright and database rights, in this site
          belong to or are licensed to Noble & Co. United Kingdom and
          international copyright and database right laws and treaties protect
          this site and all content on this site. © Copyright 2020 Noble &
          Company (UK) Limited. All rights reserved.
        </p>

        <p className="text-12 grey justify">
          “Noble & Co” is the copyright of Noble & Company (UK) Limited in the
          United Kingdom. Other product and company names and logos mentioned on
          this site may be the trade marks or registered trade marks of their
          respective owners.
        </p>

        <p className="text-12 grey justify">
          You may not copy any part of this site, or carry out any other act
          which is protected by copyright or extract or re-utilise the whole or
          any substantive part (evaluated quantitatively and/or qualitatively)
          of the content of this site other than:
        </p>

        <ul>
          <li>
            printing in hard copy form portions of the content of this site for
            your own use only;
          </li>
          <li>downloading any page from this site for your own use only;</li>
          <li>
            storing pages from this site in a cache or other temporary retrieval
            system for the sole purpose of your own viewing off-line.
          </li>
        </ul>

        <p className="header text-12 grey justify">LINKING</p>

        <p className="text-12 grey justify">
          You may, subject to the following conditions, provide a link to the
          home page of this site:
        </p>

        <ul>
          <li>
            {' '}
            this right to link to this site is revocable by us and we reserve
            the right to prohibit any link and to employ measures, including
            technical measures, to remove any link;
          </li>
          <li>
            any link to this site must not be misleading or give the impression
            that we are associated with you or that we have approved or endorsed
            any internet site or other medium from which such link is made;
          </li>
          <li>
            any such link must indicate capital.nobleandcompany.com as the
            destination of the link and must not use any Noble & Co logo or
            other proprietary graphic or trade mark without our prior written
            consent; and
          </li>
          <li>
            you may not use framing or deep linking to this site without our
            prior written consent.
          </li>
        </ul>

        <p className="text-12 grey justify">USE AND INDEMNITY</p>

        <p className="text-12 grey justify">
          Noble & Co expressly prohibits the use of this site by you for any
          purpose which may be unlawful in the United Kingdom or other relevant
          jurisdiction (such as the jurisdiction from where you may access our
          site).
        </p>

        <p className="text-12 grey justify">
          You agree to keep Noble & Co indemnified against any claim, loss,
          liability, damages or expenses on a full and unqualified indemnity
          basis arising out of any misuse by you of any part of this site or any
          information embodied in it.
        </p>

        <p className="header text-12 grey justify">WAIVER AND ENFORCEMENT</p>

        <p className="text-12 grey justify">
          Failure by us to take action in respect of a breach by you of these
          conditions shall not prevent us from taking future action in respect
          of the same breach or any other breach by you of these conditions,
          unless we agree to the contrary in writing.
        </p>

        <p className="text-12 grey justify">
          If any of these conditions are held to be unenforceable it will not
          affect the validity and enforceability of the remaining conditions.
        </p>

        <p className="header text-12 grey justify">OUR DETAILS</p>

        <ul>
          <li>
            The service provided through this website is provided by Noble & Co
            which is the trading name of Noble & Company (UK) Limited.
          </li>
          <li>
            Noble & Compnay (UK) Limited’s registered address is 95A George
            Street, Edinburgh, EH2 3ES.
          </li>
          <li>
            Noble & Company (UK) Limited’s registered company number is
            SC420569.
          </li>
          <li>
            Noble & Company (UK) Limited can be contacted via email at
            capital@nobleandcompany.com and via telephone at +44 131 603 7680.
          </li>
          <li>Noble & Company (UK) Limited’s VAT Number is 138407119.</li>
          <li>
            Noble & Company (UK) Limited is authorised and regulated by the
            Financial Conduct Authority (Registration Number 586297).
          </li>
        </ul>

        <p className="header text-12 grey justify">GOVERNING LAW</p>

        <p className="text-12 grey justify">
          These conditions of use are governed by the Law of England and the
          English Courts shall have non-exclusive jurisdiction over any disputes
          between you and us.
        </p>
      </TermsContainer>
    </ContentContainer>
  )
}

export default TermsOfUse
