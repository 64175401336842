export const localStorageKeys = {
  COOKIE: 'cookie-banner',
}

export const localStorageSet = (key: string, value: string) => {
  window?.localStorage?.setItem(key, value)
}

export const localStorageGet = (key: string) => {
  return window?.localStorage?.getItem(key)
}

export const localStorageRemove = (key: string) => {
  window?.localStorage?.removeItem(key)
}
