import {
  RawDraftContentBlock,
  RawDraftContentState,
  EditorState,
  SelectionState,
  Modifier,
} from 'draft-js'

// Convert rawContentState to string value
export const convertRowToStr = (content: RawDraftContentState) => {
  const blocks: RawDraftContentBlock[] = content.blocks
  return blocks
    .map(block => (!block.text.trim() && '\n') || block.text)
    .join('\n')
}

const findWithRegex = (regex: RegExp, contentBlock: any, callback: any) => {
  const text = contentBlock.getText()

  let matchArr, start, end
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    end = start + matchArr[0].length

    callback(start, end)
  }
}

export const replacePlaceholders = (
  editorState: EditorState,
  placeholders: {
    label: string
    value: string
  }[]
) => {
  let newState = editorState

  placeholders.forEach(item => {
    const {label, value} = item

    const regex = new RegExp(label, 'g')
    const selectionsToReplace: any = []

    let contentState: any = newState.getCurrentContent()
    const blockMap = contentState.getBlockMap()

    blockMap.forEach((contentBlock: any) =>
      findWithRegex(regex, contentBlock, (start: any, end: any) => {
        const blockKey = contentBlock.getKey()

        const blockSelection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end,
        })

        selectionsToReplace.push(blockSelection)
      })
    )

    contentState = newState.getCurrentContent()

    selectionsToReplace.forEach((selectionState: SelectionState) => {
      contentState = Modifier.replaceText(contentState, selectionState, value)
    })

    newState = EditorState.push(editorState, contentState, 'change-block-data')
  })

  return newState
}

export const getAddressBlock = ({
  firstName = '',
  lastName = '',
  addressLine1 = '',
  addressLine2 = '',
  postcode = '',
  city = '',
  isCompany = false,
  countryRegistration = '',
  registrationCode = '',
  companyAddressLine1 = '',
  companyAddressLine2 = '',
  companyCity = '',
  companyPostcode = '',
  companyName = '',
}) => {
  if (isCompany) {
    const address = companyAddressLine2
      ? `${companyAddressLine1} ${companyAddressLine2} ${companyCity} ${companyPostcode}`
      : `${companyAddressLine1} ${companyCity} ${companyPostcode}`

    return `${companyName} a company registered and incorporated in ${countryRegistration} with registration number ${registrationCode} and having its registered office at ${address} (hereinafter the "Recipient")`
  }

  const address = addressLine2
    ? `${addressLine1} ${addressLine2} ${city} ${postcode}`
    : `${addressLine1} ${city} ${postcode}`

  return `${firstName} ${lastName} of ${address} (hereinafter the "Recipient").`
}

export const getSignatureBlock = ({
  firstName = '',
  lastName = '',
  isCompany = false,
  companyName = '',
}) => {
  if (isCompany) {
    return `
Signed by ${firstName} ${lastName}
for and on behalf of
${companyName}`
  }

  return `Signed by ${firstName} ${lastName}`
}
