import styled from 'styled-components'

import Colors from 'styles/colors'
import {FAST_TRANSITION} from 'styles/globalStyles'

const handleTemplateColumns = (btn: boolean, icon: boolean) => {
  if (btn) {
    return '1fr minmax(122px, auto)'
  }

  if (icon) {
    return '1fr auto'
  }

  return '1fr'
}

export interface ContainerProps {
  error: boolean
  btn: boolean
  icon: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0;
  }

  .input-wrapper {
    position: relative;

    display: grid;
    grid-template-columns: ${({btn, icon}) => handleTemplateColumns(btn, icon)};

    input {
      padding: 8px 56px 8px 16px;

      font-size: 16px;
      line-height: 24px;
      font-weight: 400;

      color: ${({error}) => (error ? Colors.ERROR : Colors.BLACK)};

      border-radius: ${({btn}) => (btn ? '4px 0 0 4px' : '4px')};
      border: ${({error}) =>
        error ? `2px solid ${Colors.ERROR}` : `2px solid ${Colors.LIGHT_GREY}`};

      ::placeholder {
        color: ${Colors.DARK_GREY};
      }

      :disabled {
        background-color: ${Colors.LIGHT_GREY};
      }
    }

    button {
      cursor: pointer;

      border: 1px solid ${Colors.PRIMARY};
      border-radius: 0 4px 4px 0;

      background: ${Colors.PRIMARY};
      color: ${Colors.WHITE};

      font-size: 12px;
      font-weight: bold;

      :hover {
        background: ${Colors.BTN_HOVER};
      }

      :disabled {
        background-color: ${Colors.LIGHT_GREY};
        border: 1px solid ${Colors.LIGHT_GREY};
        color: ${Colors.GREY};
      }
    }

    .eye,
    .clear {
      position: absolute;
      left: auto;
      right: 10px;
      top: 8px;
      cursor: pointer;
      color: ${Colors.GREY};
    }

    .clear {
      color: ${Colors.SECONDARY_25};
      display: none;

      ${FAST_TRANSITION};

      :hover {
        color: ${Colors.BLACK};
      }
    }

    /* Show clear button if input has value and is focus */
    input:not(:placeholder-shown):focus + .clear {
      display: block;
    }

    .clear:active {
      display: block;
    }
  }
`

export const ikonateStyles = {
  fontSize: '1.5em',
  strokeWidth: 1.5,
}
