import React, {Fragment} from 'react'

import {Container} from './styles'

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  description?: string
  bold?: boolean
  error?: {
    status?: boolean
    message?: string
  }
  classes?: {
    container?: string
  }
}

const Checkbox = React.forwardRef(
  (
    {
      label,
      description,
      bold = false,
      type = 'checkbox',
      error,
      classes,
      ...rest
    }: CheckboxProps,
    forwardedRef: React.LegacyRef<HTMLInputElement> | undefined
  ) => {
    const {status, message} = error || {status: '', message: ''}

    return (
      <Fragment>
        <Container className={classes?.container} disabled={rest.disabled}>
          <label className="container text-label">
            <input ref={forwardedRef} type={type} {...rest} />
            <span className="checkmark" />

            <div className={`checkbox-label ${bold && 'bold'}`}>
              {label ?? rest?.value}
            </div>

            <div className="checkbox-description">{description}</div>
          </label>
        </Container>

        {status && message && <span className="error">{message}</span>}
      </Fragment>
    )
  }
)

export default Checkbox
