import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'

export const EditorWrapper = styled.div<{error?: boolean}>`
  margin-top: 2em;
  margin-bottom: 1em;

  .wrapper-class {
    border: ${({error}) => (error ? '1px solid red' : '1px solid #ccc')};
    border-radius: 3px;
  }

  .rdw-editor-toolbar {
    border: none !important;
    border-bottom: 1px solid #f1f1f1 !important;
    border-radius: 0px !important;
  }

  .editor-class {
    background-color: white;
    padding: 0 1rem;
    height: 450px;

    resize: vertical;

    figure {
      margin: 0 !important;
    }

    .rdw-image-center {
      justify-content: left !important;
    }
  }

  .toolbar-class {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .preview {
    padding: 1rem;
    margin-top: 1rem;
    height: 272px;
  }

  /** rich text editor toolbar ClassName */
  .rdw-editor-toolbar {
    padding: 10px;

    @media only screen and (max-width: ${Breakpoints.md}) {
      padding: 5px;
    }
  }

  /** dropdown wrapper ClassName */
  .placeholders {
    position: relative;
    z-index: 20;

    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
    align-items: center;

    width: auto;
    /* margin-left: auto; */

    @media only screen and (max-width: 1603px) {
      margin-left: 0;
    }
    @media only screen and (max-width: ${Breakpoints.md}) {
      margin-left: 0;
      width: 100%;
    }

    /** dropdown contaienr ClassName */
    > :first-child {
      @media only screen and (max-width: ${Breakpoints.md}) {
        flex: 1;

        #dropdown {
          flex: 1;
        }
      }

      /** dropdown label */
      > :nth-child(1) {
        display: block;
        margin: 0 20px 0;

        @media only screen and (max-width: ${Breakpoints.md}) {
          display: none;
        }
      }

      #dropdown {
        width: 250px !important;
      }
    }
  }
`
