import React, {Fragment} from 'react'

const Statement1 = () => {
  return (
    <Fragment>
      <p className="text-12 grey justify">
        <u>
          <b style={{color: 'black'}}>
            Statement for Certified High Net Worth Individual
          </b>
        </u>
      </p>

      <p className="text-12 grey justify">
        I declare that I am a certified high net worth individual for purposes
        of the Financial Services andMarkets Act 2000 (Financial Promotion)
        Order 2005.
      </p>

      <p className="text-12 grey justify">I understand that this means:</p>
      <p className="text-12 grey justify">
        (a) I can receive financial promotions that may not have been approved
        by a personauthorised by the Financial Services Authority;
      </p>
      <p className="text-12 grey justify">
        (b) the content of such financial promotions may not conform to rules
        issued by the FinancialServices Authority;{' '}
      </p>
      <p className="text-12 grey justify" style={{color: 'black'}}>
        (c){' '}
        <b style={{color: 'black'}}>
          by signing this statement I may lose significant rights;
        </b>
      </p>
      <p className="text-12 grey justify">
        (d) I may have no right to complain to either of the following -
      </p>
      <p className="text-12 grey justify">
        &nbsp;&nbsp;&nbsp; (i) the Financial Services Authority; or
      </p>
      <p className="text-12 grey justify">
        &nbsp;&nbsp;&nbsp; (ii) the Financial Ombudsman Scheme;
      </p>
      <p className="text-12 grey justify">
        (e) I may have no right to seek compensation from the Financial Services
        CompensationScheme.
      </p>
      <p className="text-12 grey justify">
        I am a certified high net worth individual because
        <b>at least one of the following applies -</b>
      </p>
      <p className="text-12 grey justify">
        (a) I had, during the financial year immediately preceding the date
        below, an annual income to the value of £100,000 or more;
      </p>
      <p className="text-12 grey justify">
        (b) I held, throughout the financial year immediately preceding the date
        below, net assets to the value of £250,000 or more. Net assets for these
        purposes do not include -{' '}
      </p>
      <p className="text-12 grey justify">
        &nbsp;&nbsp;&nbsp; (i) the property which is my primary residence or any
        loan secured on that residence;
      </p>
      <p className="text-12 grey justify">
        &nbsp;&nbsp;&nbsp; (ii) any rights of mine under a qualifying contract
        of insurance within the meaning of the Financial Services and Markets
        Act 2000 (Regulated Activities) Order 2001;{' '}
      </p>
      <p className="text-12 grey justify">
        <b>or</b>
      </p>
      <p className="text-12 grey justify">
        &nbsp;&nbsp;&nbsp; (iii) any benefits (in the form of pensions or
        otherwise) which are payable on the termination of my service or on my
        death or retirement and to which I am (or my dependants are), or may be,
        entitled.
      </p>
      <p className="text-12 grey justify">
        <b style={{color: 'black'}}>
          I accept that I can lose my property and other assets from making
          investment decisions based on financial promotions.
        </b>
      </p>
      <p className="text-12 grey justify">
        I am aware that it is open to me to seek advice from someone who
        specialises in advising on investments.
      </p>
    </Fragment>
  )
}

export default Statement1
