import React, {useEffect, useState, Fragment} from 'react'

import {MessageProps} from 'types'
import {overrideVerifyEmail} from 'services/admin'

import Button from 'components/styles/Button'
import Card from 'components/Card'

import VerifyEmailModal from './components/Modals/VerifyEmail'

const MODAL_TYPE = {
  verifyEmail: 'VERIFY_EMAIL',
}

const defaultModal = {
  type: '',
  loadingModal: false,
}

type UserActionsProps = {
  setToast: (message: MessageProps) => void
  state: any
  setState: any
}

const AdminActions = ({setToast, state, setState}: UserActionsProps) => {
  const {data: user} = state || {}
  const {email, isEmailVerified} = user

  const [disabled, setDisabled] = useState(false)

  const [modal, set] = useState(defaultModal)
  const {type, loadingModal} = modal
  const handleCloseModal = () => set(defaultModal)

  const showExport = type === 'VERIFY_EMAIL'

  useEffect(() => {
    /**
     * Modal Actions
     */

    const manuallyVerifyEmail = async () => {
      setDisabled(true)
      try {
        await overrideVerifyEmail(email)
        setState({
          ...state,
          data: {
            ...user,
            isEmailVerified: true,
          },
        })
        setToast({value: 'User Email Verified Successfully', type: 'success'})
      } catch (e) {
        setToast({
          value: e.message,
          type: 'error',
        })
      }
      setDisabled(false)
    }

    if (loadingModal) {
      switch (modal.type) {
        case MODAL_TYPE.verifyEmail:
          manuallyVerifyEmail()
          break

        default:
      }
    }

    // eslint-disable-next-line
  }, [loadingModal])

  if (isEmailVerified) {
    return null
  }

  return (
    <Card
      padding
      title="Manually verify user"
      subtitle="Manually validate a user's email"
    >
      <Fragment>
        <Button
          onClick={() => set({...modal, type: MODAL_TYPE.verifyEmail})}
          disabled={disabled}
          full
        >
          Verify User Manually
        </Button>
      </Fragment>

      <VerifyEmailModal
        show={showExport}
        onVerifyEmail={() => set({...modal, loadingModal: true})}
        onClose={handleCloseModal}
        loading={loadingModal}
      />
    </Card>
  )
}

export default AdminActions
