import React from 'react'
import {useFormContext} from 'react-hook-form'

import Card from 'components/Card'
import Input from 'components/Input'

import {CardContent} from './styles'

interface DetailsProps {
  disabled?: boolean
}

const Details: React.FC<DetailsProps> = ({disabled}) => {
  const {register, errors} = useFormContext()

  return (
    <Card
      padding
      title="Deal Contact Details"
      subtitle="Enter contact details below."
    >
      <CardContent>
        <Input
          id="deal-contact-name"
          label="Deal Contact Name"
          type="text"
          error={{
            status: errors?.contactName,
            message: errors?.contactName?.message,
          }}
          name="contactName"
          ref={register}
          {...{disabled}}
        />

        <Input
          id="deal-contact-number"
          label="Deal Contact Number"
          type="tel"
          error={{
            status: errors?.phone,
            message: errors?.phone?.message,
          }}
          name="phone"
          ref={register}
          {...{disabled}}
        />

        <Input
          id="deal-contact-email"
          label="Deal Contact Email"
          type="email"
          error={{
            status: errors?.email,
            message: errors?.email?.message,
          }}
          name="email"
          ref={register}
          {...{disabled}}
        />

        <Input
          id="deal-contact-position"
          label="Deal Contact Position"
          type="text"
          error={{
            status: errors?.position,
            message: errors?.position?.message,
          }}
          name="position"
          ref={register}
          {...{disabled}}
        />
      </CardContent>
    </Card>
  )
}

export default Details
