import React, {Fragment, useState} from 'react'

import {MessageProps} from 'types'
import {requestNdaAccess} from 'services/deal-interests'

import Toast from 'components/Toast'
import Spinner from 'components/Spinner'
import Button from 'components/styles/Button'

interface RequestNdaAccessProps {
  label: string
  dealId: string
  refetchDeal?: () => void
  buttonDisabled?: boolean
}

const RequestNdaAccess: React.FC<RequestNdaAccessProps> = ({
  label,
  dealId,
  refetchDeal,
  buttonDisabled,
}) => {
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState<MessageProps>({
    value: '',
    type: 'error',
  })

  const handleRequestNdaAccess = async () => {
    setLoading(true)

    try {
      await requestNdaAccess(dealId)

      if (refetchDeal) {
        refetchDeal()
      }
    } catch (error) {
      setToast({
        value:
          error.response?.data?.title ??
          'Could not request NDA access this time, please try again',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Button
        onClick={handleRequestNdaAccess}
        disabled={loading || buttonDisabled}
        flex
      >
        {loading ? 'Requesting' : label}
        {loading ? (
          <span style={{marginLeft: 12}}>
            <Spinner size="xs" margin="none" />
          </span>
        ) : null}
      </Button>

      <Toast message={toast} action={setToast} />
    </Fragment>
  )
}

export default RequestNdaAccess
