import styled, {css} from 'styled-components'

import Colors from 'styles/colors'

const defaultFontStyle = css`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0px;

  flex: 3;
  padding-left: 8px;
`

const labelAsInput = css<{isError?: boolean}>`
  align-self: end;

  flex: 1;

  border-radius: 0 3px 3px 0;

  background-color: ${Colors.PRIMARY};
  display: inline-block;

  :hover {
    background-color: ${Colors.BTN_HOVER};
    color: ${Colors.WHITE};
  }

  cursor: pointer;

  padding: 15px 0;
  min-height: 21px;
`

const dropZoneAsInput = css<{asInput?: boolean; isError?: boolean}>`
  border: ${({isError}) =>
    isError ? `1px solid ${Colors.ERROR}` : `1px solid ${Colors.SECONDARY_25}`};

  .file-name-zone {
    ${defaultFontStyle};

    color: ${({isError}) => (isError ? Colors.ERROR : Colors.SECONDARY)};
  }
  .placeholder {
    ${defaultFontStyle};

    color: ${Colors.PLACEHOLDER};
  }
`

export const Wrapper = styled.div<{labelTop?: boolean}>`
  display: flex;
  flex-direction: column;

  .input-label {
    margin-top: ${({labelTop}) => (labelTop ? '1em' : 0)};
  }
`

export const Section = styled.section<{
  asInput?: boolean
  isError?: boolean
  disabled?: boolean
}>`
  .dropzone {
    display: flex;

    height: ${({asInput}) => (asInput ? 'auto' : '116px')};

    flex-direction: ${({asInput}) => (asInput ? 'row' : 'column')};
    justify-content: ${({asInput}) => (asInput ? 'space-between' : 'center')};

    align-items: center;

    border-radius: 3px;
    border: 1px solid ${Colors.SECONDARY_25};
    border-style: ${({asInput}) => (asInput ? 'solid' : 'dashed')};
    border-color: ${({isError}) =>
      isError ? Colors.ERROR : Colors.SECONDARY_25};
    background-color: ${({disabled}) =>
      disabled ? `${Colors.DISABLED}` : `${Colors.WHITE}`};

    ${({asInput}) => asInput && dropZoneAsInput};

    /* height: 116px; */
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    .label {
      color: ${({asInput}) =>
        asInput ? `${Colors.WHITE}` : `${Colors.SECONDARY}`};
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0px;
      text-align: center;

      ${({asInput}) => asInput && labelAsInput};
    }

    em {
      color: ${Colors.GREY};
      font-size: 10px;
      font-weight: 300;
      letter-spacing: 0px;
      text-align: center;
      display: ${({asInput}) => (asInput ? 'none' : 'block')};
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 0.5em;
  }

  .close-btn {
    cursor: pointer;
  }

  ul {
    padding: 0;
  }
`
