import React, {Fragment} from 'react'
import {useForm} from 'react-hook-form'

import Input from 'components/Input'
import Button from 'components/styles/Button'

interface FormProps {
  onSubmit: (data: any) => void
  loading?: boolean
}

const Form: React.FC<FormProps> = ({onSubmit, loading}) => {
  const {register, handleSubmit, errors} = useForm()

  return (
    <Fragment>
      <form className="app-form" onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={loading} aria-busy={loading}>
          <Input
            id="login-password"
            label="Create New Password"
            info="Password should be at least 10 characters long"
            type="password"
            placeholder="Password"
            error={{
              status: errors.password,
              message: 'Your password has to be at least 10 characters long',
            }}
            name="password"
            showPassword
            ref={register({
              required: true,
              minLength: 10,
            })}
          />

          <Button full type="submit" {...{loading}}>
            Send Reset Request
          </Button>
        </fieldset>
      </form>
    </Fragment>
  )
}

export default Form
