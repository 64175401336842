import styled from 'styled-components'

import Breakpoints from 'styles/breakpoints'

export const ViewUserHeaderButtons = styled.div`
  width: 100%;
  padding: 0 12px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoints.sm}) {
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
  }

  > :not(:first-child) {
    margin-top: 12px;

    @media only screen and (min-width: ${Breakpoints.sm}) {
      margin-top: 0;
      margin-left: 12px;
    }
  }
`

export const ViewUserGrid = styled.div`
  margin-top: 32px;

  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @media (min-width: ${Breakpoints.lg}) {
    grid-template-columns: 35% 1fr;
  }

  @media (min-width: ${Breakpoints.xl}) {
    grid-template-columns: 30% 1fr;
  }

  .col {
    overflow: hidden;

    > :not(:first-child) {
      margin-top: 32px;
    }
  }
`
