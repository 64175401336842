import styled, {css} from 'styled-components'

import Colors from 'styles/colors'
import Breakpoints from 'styles/breakpoints'
import Constants from 'styles/constants'
import {FAST_TRANSITION} from 'styles/globalStyles'

const positionStyle = css`
  position: relative;
  z-index: 1;
`

const styleDefault = css`
  .content-left {
    ${positionStyle};

    display: flex;
    flex-flow: column nowrap;

    > :last-child {
      margin-top: auto !important;
    }

    height: 100vh;

    ${FAST_TRANSITION};

    max-width: 41%;

    /* background-color: ${Colors.WHITE}; */

    form {
      padding: 0 0.5;
    }

    @media only screen and (max-width: ${Breakpoints.lg}) {
      form {
        padding: 0 1em;
      }
    }
  }

  .cover {
    display: block;
    object-fit: cover;

    margin-right: auto;

    /* Set up proportionate scaling */
    margin-left: 41%;
    width: 59%;
    height: 100%;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
  }
`

const smallDevices = css`
  .content-left {
    ${positionStyle}

    ${FAST_TRANSITION};

    @media only screen and (max-width: ${Breakpoints.sm}) {
      max-width: 98%;
      margin: 12px auto;
    }
    max-width: 70%;
    height: auto;

    padding: 1.5em 1em;
    margin: 15% auto;

    display: block;

    background-color: ${Colors.WHITE};

    border-radius: ${Constants.BORDER_RADIUS};
  }

  .cover {
    margin-left: 0;
    width: 100%;
  }
`

export const GuestLayoutContainer = styled.div`
  ${styleDefault};

  @media only screen and (max-width: ${Breakpoints.md}) {
    ${smallDevices};
  }
`

export const LogoutButtonContainer = styled.div`
  position: relative;
  border-radius: 3px;
  box-shadow: 0px 12px 15px 2px rgba(22, 28, 45, 0.1);
  background-color: white;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-right: 32px;
    p:first-child {
      font-weight: bold;
      color: ${Colors.GREY};
      margin-bottom: 8px;
    }

    p:last-child {
      margin: 0;
    }
  }

  > button {
    margin: 0;
    filter: grayscale(100%);
    padding: 8px 16px;
    border: 1px solid ${Colors.GREYISH};
    width: 100%;
  }

  @media (min-width: ${Breakpoints.sm}) {
    position: fixed;
    right: 16px;
    bottom: 16px;
    width: auto;
  }
`
