import React from 'react'

import {SuccessCircleIcon} from 'styles/icons'

import {SuccessContainer} from './styles'

const Success: React.FC = () => {
  return (
    <SuccessContainer>
      <SuccessCircleIcon size="medium" />

      <span className="text text-center">
        You will be automatically redirected shortly.
      </span>
    </SuccessContainer>
  )
}

export default Success
