import styled from 'styled-components'

import Colors from 'styles/colors'
import Constants from 'styles/constants'
import {SLOW_TRANSITION} from 'styles/globalStyles'

export const DealInterestStatusUI = styled.p`
  color: ${Colors.PRIMARY};
`

export const Tr = styled.tr<{border?: boolean}>`
  border-bottom: ${({border}) =>
    border ? `1px solid ${Colors.SECONDARY_05}` : '0px'};

  ${SLOW_TRANSITION};
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    font-size: 16px;
    font-weight: bold;
  }

  td {
    padding-right: 0.5em;
  }
`

export const ButtonsContainer = styled.div`
  display: inline-flex;

  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
`

export const Status = styled.span`
  color: ${Colors.PRIMARY};
  font-size: 12px;
  letter-spacing: 0px;
  text-align: center;
  padding: 10px;

  border-radius: ${Constants.BORDER_RADIUS};

  background-color: ${Colors.BLUEISH_05};
`
